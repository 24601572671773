import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
// Actions
import { enableWelcomeEmail, getCourseWelcomeEmail } from 'redux/actions/course-communications';
// Styles
import { gray4, primary } from 'styles/global_defaults/colors';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';

// Components
import ClickableContainer from 'components/clickable-container';
import { CommunicationAction, CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';
import { CommunicationType } from 'redux/schemas/models/course-communication';
import { useAppDispatch } from 'redux/store';
import { getJourney } from 'redux/selectors/learning-journeys';

const styles = css`
  .enable-email {
    height: ${tripleSpacing}px;
    border-top: 1px solid ${gray4};
    border-bottom: 1px solid ${gray4};
    justify-content: center;
    align-items: center;
  }
`;

const WelcomeEmail = () => {
  const { dispatch } = useContext(CommunicationDispatch);
  const appDispatch = useAppDispatch();
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentJourney = useSelector((state) => getJourney(state, catalogId));

  const openModal = () => {
    dispatch({
      type: CommunicationAction.SHOW_MODAL,
      communicationType: CommunicationType.COURSE_WELCOME_EMAIL,
      triggerType: TriggerType.CREATE,
    } as any);
  };

  const createWelcomeEmail = () => {
    appDispatch(enableWelcomeEmail({
      catalogId: currentJourney.catalogId,
      courseId: currentJourney.id,
    })).then(() => {
      appDispatch(getCourseWelcomeEmail({ catalogId }))
        .then(({ payload: { result } }) => {
          if (result?.[0]?.id) {
            openModal();
          }
        });
    });
  };

  return (
    <div css={styles}>
      <ClickableContainer
        className='text-regular text-primary enable-email'
      >
        <div onClick={() => createWelcomeEmail()}>
          {t.JOURNEY_COMMUNICATIONS.TABS.DESCRIPTION.AUTOMATED.ENABLE()}
        </div>
      </ClickableContainer>
    </div>
  );
};

export default WelcomeEmail;
