import { css } from '@emotion/react';
import React, { useContext, useMemo } from 'react';
import { sortBy } from 'underscore';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';

import { TeamMember } from 'redux/schemas/models/team';
import NvUserAvatar from 'components/nv-user-avatar';
import { isHandheld } from 'styles/global_defaults/media-queries';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';

const overflowClass = 'avatar-overflow rounded-circle bg-gray-6 d-flex justify-content-center align-items-center text-large-regular text-gray-2';

const styles = css`
  .avatar-overflow {
    height: ${doubleSpacing}px;
    width: ${doubleSpacing}px;
  }
`;

const TeamMembers = (props: { creatorId: number }) => {
  const { $state } = useContext(AngularServicesContext);
  const teamMembers: TeamMember[] = useSelector((state => state.models.teams[$state.params.teamId]?.teamMembers));
  // Display 12 team memebers and `+n` more if there are members more than 13
  const sortedTeamMembers = useMemo(() => {
    if (!teamMembers) return [];
    return sortBy(teamMembers, (member) => (member.user?.id === props.creatorId ? 0 : 1));
  }, [teamMembers, props.creatorId]);

  let members = sortedTeamMembers?.length > 13
    ? sortedTeamMembers?.slice(0, 11)
    : sortedTeamMembers;
  let showAvatarOverflow = sortedTeamMembers?.length > 13;
  if (isHandheld()) {
    members = sortedTeamMembers?.length > 5
      ? sortedTeamMembers?.slice(0, 4)
      : sortedTeamMembers;
    showAvatarOverflow = sortedTeamMembers?.length > 5;
  }

  return (
    <div
      css={styles}
      className='d-flex justify-content-center'>
      {members?.map(member => (
        <div
          className='user-avatar-container mr-1'
          key={member.id}
        >
          <NvUserAvatar
            directToProfile
            size='md'
            user={member.user}
            borderType='round'
          />
        </div>
      ))}
      {showAvatarOverflow && (
        <div className={overflowClass}>
          {`+${teamMembers.length - members.length}`}
        </div>
      )}
    </div>
  );
};

export default TeamMembers;
