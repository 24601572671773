import store from '../../redux/store';
import { unsetLecturePageAsCached } from '../../redux/actions/lecture-pages';

/* @ngInject */
export default function TeamMembershipActionsCtrl(
  TeamManager,
  CurrentUserManager,
  ConfirmationOverlays,
  $rootScope,
  $uibModal,
  $scope,
  InvitationsAndRequestsManager,
  config,
  CurrentCourseManager,
) {
  const vm = this;
  vm.config = config;
  vm.TeamManager = TeamManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.requestToJoin = requestToJoin;
  vm.viewRequestToJoin = viewRequestToJoin;
  vm.withdrawRequestToJoin = withdrawRequestToJoin;
  vm.membershipStatus = membershipStatus;
  vm.joinGroup = joinGroup;

  function requestToJoin() {
    vm.TeamManager.currentTeam.newMembershipRequest(vm.CurrentUserManager.user);

    $uibModal.open({
      templateUrl: 'teams/templates/profile/request-to-join-modal.html',
      controller: 'AttachModalResolvesToVmCtrl as vm',
      resolve: {
        vmResolves() {
          return {
            team: vm.TeamManager.currentTeam,
            translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
            config: vm.config,
          };
        },
      },
    }).result.then(() => {
      vm.TeamManager.currentTeam.currentAccountMembershipRequest.save()
        .then((membershipRequest) => {
          InvitationsAndRequestsManager.addRequest(membershipRequest, vm.TeamManager.currentTeam);
          const currentReduxState = store.getState();

          const lecturePagesIdsWithTeamFormation = Object.values(
            currentReduxState.models.lecturePages,
          ).filter(
            (lecturePage) => {
              const isFromCurrentCourse = lecturePage?.course?.catalogId === CurrentCourseManager?.course?.catalogId;

              const lectureComponents = (lecturePage.lectureComponents ?? []).map((lectureComponentId) => currentReduxState.models.lectureComponents[lectureComponentId]);

              const hasTeamFormation = lectureComponents.some((lectureComponent) => lectureComponent.type === 'TeamFormationLectureComponent');

              return isFromCurrentCourse && hasTeamFormation;
            },
          ).map((lecturePage) => lecturePage.id);

          lecturePagesIdsWithTeamFormation.forEach((lecturePageId) => {
            store.dispatch(unsetLecturePageAsCached(lecturePageId));
          });
        });
    });
  }

  function viewRequestToJoin() {
    $uibModal.open({
      templateUrl: 'teams/templates/profile/view-request-to-join-modal.html',
      controller: 'AttachModalResolvesToVmCtrl as vm',
      resolve: {
        vmResolves() {
          return {
            team: vm.TeamManager.currentTeam,
            config: vm.config,
          };
        },
      },
    });
  }

  function withdrawRequestToJoin() {
    ConfirmationOverlays.openConfirmationModal(
      'teams/templates/profile/withdraw-request-overlay.html',
      'AttachModalResolvesToVmCtrl as vm',
      {
        vmResolves() {
          return {
            team: vm.TeamManager.currentTeam,
          };
        },
      },
    ).result.then(() => {
      InvitationsAndRequestsManager.removeRequest(vm.TeamManager.currentTeam.currentAccountMembershipRequest);
      vm.TeamManager.currentTeam.withdrawMembershipRequest();
    });
  }

  function membershipStatus() {
    if (vm.TeamManager.currentTeam.teamSet.currentAccountTeam && vm.TeamManager.currentTeam.teamSet.isTeam) {
      return 'memberOfOtherTeam';
    } if (vm.TeamManager.currentTeam.numApprovedMembers >= vm.TeamManager.currentTeam.teamSet.teamSizeLimit) {
      return 'teamFull';
    } if (vm.TeamManager.currentTeam.canAcceptCurrentAccount && !vm.TeamManager.currentTeam.currentAccountTeamMember
          && (!vm.TeamManager.currentTeam.currentAccountMembershipRequest || !vm.TeamManager.currentTeam.currentAccountMembershipRequest.isPersisted())) {
      return 'canJoinTeam';
    } if (vm.TeamManager.currentTeam.currentAccountMembershipRequest && vm.TeamManager.currentTeam.currentAccountMembershipRequest.isPersisted()) {
      return 'requestPending';
    } if (vm.TeamManager.currentTeam.currentAccountTeamMember) {
      return 'invitationPending';
    }
    return null;
  }

  function joinGroup() {
    vm.TeamManager.currentTeam.newMembershipRequest(CurrentUserManager.user);
    vm.TeamManager.currentTeam.currentAccountMembershipRequest.save().then(() => {
      $rootScope.$emit('reinitialize-team-profile-controller');
    });
  }
}
