import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function NvReportForm(
  StateManager,
  CurrentCourseManager,
  CurrentUserManager,
  S3UploadFactory,
  S3NameSpaces,
  validationConstants,
  ConfirmationOverlays,
  $state,
  ExercisesManager,
  AlertMessages,
  _,
  config,
  $timeout,
) {
  return {
    scope: {
      exercise: '=',
      report: '<',
      onSave: '&',
      onSubmit: '&',
      cancelRevise: '&',
      embedded: '=?',
      previewMode: '=?',
      hideDownloadButton: '=?',
    },
    controller: function ctrl($scope, $element) {
'ngInject';
      const vm = this;
      let elements = [];
      let currentElementFocused = 0;
      let previousElementFocused = null;

      _.extend(vm, {
        course: CurrentCourseManager.course,
        CurrentUserManager,
        validationConstants,
        save,
        submit,
        uploadFileForReportSection,
        removeFileForReportSection,
        onFileLoaded,
        reportSectionChanged,
        privacySettingTranslation,
        privacySettingHelpTextTranslation,
        shouldDisableInput,
        privacySettingIcon,
        deregisterStateChangeStart: null,
        config,
        onNextButtonClick,
        onInputBlur,
        onMousedown,
        onInputFocus,
        getTranslateY,
      });

      initialize();

      function initialize() {
        const msg = 'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY';
        vm.reportCopy = angular.copy(vm.report);

        // Not a hook.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

        vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges, 'shared/templates/modal-navigate-away.html', msg, null, null, () => {
          angular.copy(vm.reportCopy, vm.report);
        });

        $scope.$on('$destroy', () => {
          vm.deregisterStateChangeStart?.();
        });
        elements = _.map(vm.report.currentRevision.sections, (section) => {
          const { id: sectionId, templateSection } = section;
          const { inputType: type } = templateSection;
          return { sectionId, type };
        });
      }

      function save() {
        vm.report.save(vm.course.catalogId, vm.exercise.id).then(() => {
          if (!vm.report.error) {
            vm.report.saved = true;
            vm.reportForm.$setPristine();
            angular.copy(vm.report, vm.reportCopy);

            vm.onSave?.();
          }
        });

        ExercisesManager.shouldReload = true;
      }

      function isEmptySubmission() {
        // Find at least one non-empty input.
        return !vm.report.currentRevision.sections.find((section) => {
          if (Object.keys(section.reportSection).length > 0) {
            // Checking text input types.
            if (typeof section.reportSection.response === 'string'
              && section.reportSection.response.length) {
              let divElement = document.createElement('div');
              divElement.innerHTML = section.reportSection.response;
              // Checking if the child has "outerHTML", indicating it's an iFrame or something other than just text.
              // If it's just a text element, then we get the "textContent" value.
              const actualResponse = divElement.firstChild.firstChild?.outerHTML || divElement.textContent;
              divElement = null;
              return actualResponse.trim().length > 0;
            }
            // Checking documents and audio/video files.
            if (section.reportSection.file && Object.keys(section.reportSection.file).length > 0
              && section.reportSection.fileUploadProgress === 100) {
              return true;
            }
          }
          return false;
        });
      }

      function showEmptySubmissionModal() {
        const modalCtrl = ['$scope', 'courseManager', ($ctrlScope, courseManager) => {
          $ctrlScope.assignmentAlias = courseManager.course.assignmentName.downcasedSingularized;
          $ctrlScope.AssignmentAlias = courseManager.course.assignmentName.capitalizedSingularized;
        }];
        const modalOverlay = ConfirmationOverlays.openConfirmationModal(
          'reports/templates/nv-empty-submission-overlay.html',
          modalCtrl,
          { courseManager: CurrentCourseManager },
        );
      }

      function submit() {
        if (isEmptySubmission()) {
          showEmptySubmissionModal();
          return;
        }
        if (!vm.report.submitted && vm.exercise.evaluationInProgress) {
          ConfirmationOverlays.openConfirmationModal(
            'reports/templates/submit-feedback-can-revise-confirmation-overlay.html',
            'AttachModalResolvesToVmCtrl as vm', {
              vmResolves: () => ({
                approvalRequired: vm.exercise.approvalRequired,
              }),
              // rejection: the user dismissed aka wanted to save as draft
            },
          ).result.then(doSubmit, save);
        } else {
          doSubmit();
        }
      }

      function doSubmit() {
        vm.report.prevIsSubmitted = vm.report.submitted;
        vm.report.submit(vm.course.catalogId, vm.exercise.id).then((submittedReport) => {
          if (!vm.report.error) {
            vm.deregisterStateChangeStart?.();

            if (vm.onSubmit) {
              vm.report.saved = false;
              vm.reportForm.$setPristine();
              vm.onSubmit();
            } else {
              $state.go('report-submitted');
            }
          }
        }, () => {
          if (vm.report.error.code === 'report.already_approved') {
            AlertMessages.error('', 'EXERCISES.ALREADY_APPROVED');
          }
          if (vm.report.error.code === 'report.empty_submission') {
            showEmptySubmissionModal();
          }
        });
      }

      function uploadFileForReportSection(file, section) {
        const { reportSection } = section;
        reportSection.fileUploadProgress = 0;
        reportSection.uploadInProgress = true;
        vm.uploadInProgress = true;
        const upload = S3UploadFactory.uploadToS3(file, S3NameSpaces.REPORTS, true);
        showNextButton(false, previousElementFocused);
        upload.promise.then((resp) => {
          reportSection.uniqueId = resp.config.data.file.uniqueId;
          reportSection.fileUploadProgress = 100;
          reportSection.uploadInProgress = false;
          deleteReportSectionId(reportSection);
          updateFileUploadProgress();
        }, (resp) => {
          reportSection.file = null;
          reportSection.uniqueId = null;
          delete (reportSection.fileUploadProgress);
          reportSection.uploadInProgress = false;
          reportSection.abortUpload = null;
          updateFileUploadProgress();
        }, (evt) => {
          const percent = parseInt((100.0 * evt.loaded) / evt.total, 10);
          reportSection.fileUploadProgress = Math.min(99, percent);
        });
        reportSection.abortUpload = upload.abort;
      }

      function removeFileForReportSection(section) {
        const { reportSection } = section;
        reportSection.file = undefined;
        reportSection.response = undefined;
        vm.reportForm.$setDirty();
        showNextButton(false, section.id);
        deleteReportSectionId(reportSection);
        updateFileUploadProgress();
      }

      function reportSectionChanged(reportSection) {
        deleteReportSectionId(reportSection);
        updateFileUploadProgress();
      }

      function privacySettingIcon(sharedWith) {
        if (sharedWith === 'shared_with_public') {
          return 'icon-language';
        } if (sharedWith === 'shared_with_class' || sharedWith === 'sharable_with_class_only') {
          return 'icon-g-groups';
        } if (sharedWith === 'shared_with_instructor' || sharedWith === 'shared_with_instructor_only' || sharedWith === 'shared_with_instructor_and_team') {
          return 'icon-g-teachers';
        }

        return null;
      }

      function privacySettingTranslation(privacySetting) {
        if (privacySetting === 'shared_with_public') {
          return 'EXERCISES.PRIVACY_SETTING.PUBLIC';
        } if (privacySetting === 'shared_with_instructor_only' || privacySetting === 'shared_with_instructor' || privacySetting === 'shared_with_instructor_and_team') {
          return 'EXERCISES.PRIVACY_SETTING.TEACHING_TEAM_TITLEIZED';
        } if (privacySetting === 'shared_with_class' || privacySetting === 'shared_with_class_only') {
          return 'EXERCISES.PRIVACY_SETTING.CLASS';
        }

        return null;
      }

      function privacySettingHelpTextTranslation(privacySetting) {
        if (privacySetting === 'shared_with_public') {
          return 'EXERCISES.PRIVACY_HELP_TEXT.PUBLIC';
        } if (privacySetting === 'shared_with_instructor' || privacySetting === 'shared_with_instructor_and_team') {
          return 'EXERCISES.PRIVACY_HELP_TEXT.INSTRUCTOR';
        } if (privacySetting === 'shared_with_instructor_only') {
          return 'EXERCISES.PRIVACY_HELP_TEXT.INSTRUCTOR_ONLY';
        } if (privacySetting === 'shared_with_class') {
          return 'EXERCISES.PRIVACY_HELP_TEXT.CLASS';
        } if (privacySetting === 'shared_with_class_only') {
          return 'EXERCISES.PRIVACY_HELP_TEXT.CLASS_ONLY';
        }

        return null;
      }

      function shouldDisableInput() {
        return vm.previewMode || vm.exercise.expired || (!vm.report.id && !vm.exercise.canStartNewSubmission);
      }

      /** Private Helpers * */
      function checkUnsavedChanges() {
        return !vm.previewMode && vm.reportForm?.$dirty;
      }

      function deleteReportSectionId(reportSection) {
        delete reportSection.id;
      }

      function updateFileUploadProgress() {
        vm.uploadInProgress = _.some(vm.report.currentRevision.sections, (section) => section.reportSection.uploadInProgress);
      }

      function showSubmitIndicator(show) {
        const submitIndicator = $element.find('#submit-indicator');
        if (show) submitIndicator.show();
        else submitIndicator.hide();
      }

      function scrollToSubmit() {
        const element = $element.find('.button-bar');
        showSubmitIndicator(true);
        showNextButton(false, elements[elements.length - 1].sectionId);
        if (element) element[0].scrollIntoView();
      }

      function focusElement(options) {
        const { root, selector, handler } = options;
        const element = root.find(selector);
        if (element[0]) {
          if (handler) handler(element);
          else element.focus();
        }
      }

      function focusNvClickToEditText(root) {
        let selector = '[nv-click-to-edit-text] .response';
        const response = root.find(selector);
        let handler = (element) => { element.click(); };
        if (!response[0]) {
          selector = '[nv-click-to-edit-text] input.form-control';
          handler = (element) => {
            $timeout(() => {
              element.trigger({ type: 'keypress', keyCode: 13 });
            });
          };
        }
        focusElement({ root, selector, handler });
      }

      function focusAndScrollToElement(index) {
        const { sectionId, type } = elements[index + 1];
        const root = getInputElement(sectionId);
        root[0].scrollIntoView();
        if (type === 'Short Text') {
          focusNvClickToEditText(root);
        } else if (type === 'Rich Text') {
          focusElement({
            root,
            selector: '[contenteditable="true"]',
            handler: (element) => {
              $timeout(() => { element.focus(); });
            },
          });
        } else {
          focusElement({ root, selector: 'input' });
        }
      }

      function onNextButtonClick(index) {
        if (index < elements.length - 1) {
          focusAndScrollToElement(index);
        } else {
          currentElementFocused = null;
          scrollToSubmit();
        }
        showNextButton(false, elements[index].sectionId);
      }

      function getInputElement(id) {
        return $element.find(`#report_section_${id}`);
      }

      function showNextButton(show, sectionId) {
        const element = getInputElement(sectionId);
        const next = element?.find('nv-animated-next-button');
        const action = show ? 'show' : 'hide';
        if (next && next[action]) next[action]();
      }

      function onMousedown() {
        vm.isSubmitting = true;
      }

      function onInputBlur(section) {
        const show = section.reportSection?.response || section.reportSection?.file;
        previousElementFocused = section.id;
        if (!vm.isSubmitting) {
          showNextButton(show, section.id);
        }
      }

      function onInputFocus(section) {
        currentElementFocused = section?.id;
        showSubmitIndicator(false);
        if (previousElementFocused) {
          showNextButton(false, previousElementFocused);
        }
      }

      function getTranslateY(sectionId) {
        if (sectionId === previousElementFocused) {
          const element = getInputElement(sectionId);
          return element[0] ? element[0].clientHeight : null;
        }
        return null;
      }

      function onFileLoaded(section) {
        $timeout(() => {
          vm.onInputBlur(section);
          showSubmitIndicator(false);
        });
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'reports/templates/form.html',

  };
}
