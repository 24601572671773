import { css } from '@emotion/react';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { addNewComponent } from 'redux/actions/lecture-components';
import { ComponentType, ViewOptions, isTemporaryLectureComponent } from 'redux/schemas/models/lecture-component';
import { updateLectureComponent } from 'redux/actions/lecture-pages';
import { createBookmark, highlightBookmark } from 'redux/actions/bookmarks';
import { BookmarkProps, BookmarkType } from 'redux/schemas/models/bookmark';

import 'react-image-crop/dist/ReactCrop.css';
import { halfSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { gray4 } from 'styles/global_defaults/colors';

import useUploadFile, { NovoEdFile } from 'shared/hooks/use-upload-file';
import useAsyncContent from 'shared/hooks/use-async-content';

import { Button } from 'shared/components/nv-button-popover';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { ProgressBar } from 'react-bootstrap';
import AltTextModal from './alt-text-modal';
import {
  ImageWidth, ImageAlignment, ImageCropping, flexAlignments, ImageRotation,
  getBaseWidthForRotation, ImageDisplayInputs,
} from './image-component-utils';
import CroppingModal from './image-crop-modal';
import ImageEditPopover, { createPopoverButtons } from './image-edit-popover';
import ImageComponentImage from './image-component-image';
import { LectureComponentProps, LecturePageMode } from '..';
import LecturePageContext from '../lecture-page-context';
import ImageFullViewDialog from './image-full-view-dialog';

/** This lecture component shows a selected image in the lecture page which is
 * formatted to given size, rotation, and cropping settings. The image's data is not mutated by those settings but
 * is instead displayed correctly via a combination of CSS transforms (rotations & offests) and visual masking (for the crop).
 * */
export const ImageLectureComponent = (props: LectureComponentProps<ComponentType.IMAGE>) => {
  const currentCatalogId = useSelector(state => state.app.currentCatalogId);

  const { lectureComponent } = props;

  /** Whether or not this component was just now added to the page */
  const isNewComponent = useSelector((state) => state.app.newComponent === lectureComponent?.id) ?? false;

  const ctx = useContext(LecturePageContext);

  const { filesToUpload, setFilesToUpload } = ctx;

  const dispatch = useAppDispatch();

  const fullViewDialogRef = useRef<HTMLDialogElement>(null);

  /** Used to set the image state to the unmodified values from the redux store. This is defined here so we can reuse the logic
   * between the initial useState() calls and the updating useEffect() */
  const initialSettings = {
    width: lectureComponent?.viewOptions.width ?? 'full',
    alignment: lectureComponent?.viewOptions?.alignment ?? 'left',
    rotation: lectureComponent?.viewOptions?.rotation || 0,
    cropping: lectureComponent?.viewOptions?.cropping || null,
    caption: lectureComponent?.viewOptions?.caption || '',
    altText: lectureComponent?.viewOptions?.altText || '',
  };

  const imageUrl = lectureComponent?.picture?.cdnUrl;

  const [imageUrlLoaded, setImageUrlLoaded] = useState(true);

  // This is a bad hack to force the image to rerender after a new image has been uploaded. Changing these inputs outside
  // of a useffect here (such as in the image onload) causes a premature render where the ImageComponentImage's containerRef is null
  useEffect(() => {
    if (imageUrlLoaded) {
      setImageDisplayInputs({
        ...imageDisplayInputs,
        renderId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrlLoaded]);

  const { $state, FlyoutModalManager } = useContext(AngularServicesContext);

  const [imageWidth, setImageWidth] = useState<ImageWidth>(initialSettings.width);
  const [imageAlignment, setImageAlignment] = useState<ImageAlignment>(initialSettings.alignment);
  const [imageRotation, setImageRotation] = useState<ImageRotation>(initialSettings.rotation as ImageRotation);
  const [imageCropping, setImageCropping] = useState<ImageCropping>(initialSettings.cropping);
  const [imageCaption, setImageCaption] = useState(initialSettings.caption);
  const [altText, setAltText] = useState(initialSettings.altText);
  const debouncedSaveCaptionRef = React.useRef<any>();
  const asyncContent = useAsyncContent();

  const [showCroppingModal, setShowCroppingModal] = useState(false);
  const [showAltTextModal, setShowAltTextModal] = useState(false);
  const [isFullViewDialogOpen, setIsFullViewDialogOpen] = useState(false);
  // Default to displaying the edit popover if this component was just added and we're in edit mode
  const [showSettingsPopover, setShowSettingsPopover] = useState(isNewComponent && props.mode === LecturePageMode.EDIT);

  // Receives the image's nonscaled, "normal" width and height values when the image loads
  const [imageBaseWidth, setImageBaseWidth] = useState(0);
  const [imageBaseHeight, setImageBaseHeight] = useState(0);

  /** Resables resizing when images are smaller than 800px wide */
  const [resizeDisabled, setResizeDisabled] = useState(false);

  /** Alignment buttons are disabled in these two widths but is not disabled when disallowed from
   * resizing since this means the image is smaller than 800px. Small images always have
   * alignment enabled. */
  const alignmentDisabled = (imageWidth === '100%' && !resizeDisabled) || imageWidth === 'full';

  /* We have to tweak our styles when the image is sideways due to how CSS rotate transforms don't
  affect layout and us needing to respect the margins & configured alignment. */
  const imageIsRotatedSideways = imageRotation % 90 === 0 && imageRotation % 180 !== 0;

  /** Allows clicking the image when in view mode to show the image at it's native resolution */
  // const enableImageViewModal = imageBaseWidth !== calculatedImageWidth;

  const { uploadFiles, isUploading, filesUploading, hasUploadPercentage } = useUploadFile();

  const [newFile, setNewFile] = useState(null);

  const timelineVisible = useSelector<RootState, boolean>((state) => state.app.timelineVisible);

  /**
   * Saves the component. Picture Data can be supplied to uplade the photo from the
   * S3 data result given by useUploadFile() */
  const saveComponent = (viewOptions: Partial<ViewOptions[ComponentType.IMAGE]>, pictureData?: NovoEdFile) => {
    let newCropping = imageCropping;

    // Reset the crop to null if we're uploading a new image
    if (pictureData) {
      newCropping = null;
      setImageCropping(newCropping);
    }

    const updateLCData = {
      catalogId: currentCatalogId,
      lecturePageId: props.currentLecture.id,
      componentData: {
        id: lectureComponent.id,
        type: lectureComponent.type,
        index: lectureComponent.index,
        viewOptions: {
          alignment: imageAlignment,
          width: imageWidth,
          rotation: imageRotation,
          caption: imageCaption,
          altText,
          cropping: newCropping,
          ...viewOptions,
        },
        picture: pictureData ? {
          contentType: pictureData.type,
          fileName: pictureData.name,
          uniqueId: pictureData.uniqueId,
          fileSize: pictureData.size,
        } : lectureComponent.picture,
      },
    };

    // TODO: This pattern for working with temporary lecture components needs to be
    // generalized out of this component
    if (isTemporaryLectureComponent(lectureComponent)) {
      dispatch(addNewComponent({
        index: lectureComponent.index,
        catalogId: currentCatalogId,
        lecturePageId: props.currentLecture.id,
        lectureComponent: _.omit(updateLCData.componentData, 'id'),
        temporaryComponentId: lectureComponent.id,
      }));
    } else {
      dispatch(updateLectureComponent(updateLCData));
    }
  };

  useEffect(() => {
    if (newFile) {
      saveComponent({}, newFile);
    }
    // I've not found a good way to add saveComponent() as a dependency without causing
    // it to refire infinite times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFile]);

  /** Uploads the file picked in the file picker and sets it as the current image file */
  const setFile = async (file: File) => {
    setImageUrlLoaded(false);
    const [novoEdFile] = await uploadFiles([file], S3NameSpaces.CONTENT_TEMPLATES);
    setNewFile(novoEdFile);
  };

  // Upload any new files added for this component during new component creation
  useEffect(() => {
    const files = filesToUpload[props.lectureComponent.id];

    if (!files?.length) {
      return;
    }

    // empty the array
    setFilesToUpload({
      ...filesToUpload,
      [props.lectureComponent.id]: [],
    });

    // This component should only ever have a single file to upload, if any.
    setFile(files[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload, props.lectureComponent.id, setFilesToUpload]);

  // Set the base image dimensions
  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setImageBaseWidth(img.width);
      setImageBaseHeight(img.height);
      setImageUrlLoaded(true);
    };

    img.src = imageUrl;
  }, [imageUrl]);

  useEffect(() => {
    const baseWidth = getBaseWidthForRotation(imageBaseWidth, imageBaseHeight, imageCropping, imageIsRotatedSideways);
    setResizeDisabled(baseWidth < 800);
  }, [imageBaseWidth, imageBaseHeight, imageCropping, imageIsRotatedSideways]);

  const styles = css`
    .crop-container {
      align-self: ${flexAlignments[alignmentDisabled ? 'center' : imageAlignment]};
    }

    .image-children {
      align-items: ${flexAlignments[alignmentDisabled ? 'center' : imageAlignment]};
    }

    img {
      &:hover {
        cursor: pointer;
      }
    }

    /* Align the caption text area. It seems strange that we have to apply these styles to the wrapping popover */
    .nv-text-area.popover-wrapper {
      display: flex;
      justify-content: ${flexAlignments[alignmentDisabled ? 'center' : imageAlignment]}
    }

    textarea {
      text-align: center;
      border: none;
      background: transparent;
      resize: none;
    }

    /* Disable the gray background for the caption textarea when not in edit mode */
    .bs4-form-control:disabled {
      background-color: transparent;
    }
  `;

  // TODO: Move this into state and mutate these buttons to update their display instead of recreating on each render, to prevent
  // recreating the button popover every time data changes
  const popoverButtons: Button[] = createPopoverButtons(
    resizeDisabled,
    alignmentDisabled,
    imageWidth,
    imageAlignment,
    imageRotation,
    (alignment: ImageAlignment) => { saveComponent({ alignment }); setImageAlignment(alignment); },
    (width: ImageWidth) => { saveComponent({ width }); setImageWidth(width); },
    setFile,
    setShowSettingsPopover,
    (rotation: ImageRotation) => { saveComponent({ rotation }); setImageRotation(rotation); },
    setShowCroppingModal,
    setShowAltTextModal,
  );

  const handleCaptionChange = (e) => {
    clearTimeout(debouncedSaveCaptionRef.current);

    const { value } = e.target;
    setImageCaption(value);

    debouncedSaveCaptionRef.current = setTimeout(() => {
      saveComponent({ caption: value });
    }, 500);
  };

  const [imageDisplayInputs, setImageDisplayInputs] = useState<Omit<ImageDisplayInputs, 'calculatedImageWidth'>>({
    imageAlignment, imageBaseHeight, imageBaseWidth, imageCropping, imageRotation, imageIsRotatedSideways,
  });

  useEffect(() => {
    // Avoid setting the display props before the image is loaded
    if (!imageBaseWidth || !imageBaseHeight) {
      return;
    }

    setImageDisplayInputs((displayInputs) => ({
      ...displayInputs,
      imageAlignment,
      imageBaseHeight,
      imageBaseWidth,
      imageCropping,
      imageRotation,
      imageIsRotatedSideways,
    }));
  }, [imageAlignment, imageBaseHeight, imageBaseWidth, imageCropping, imageRotation, imageIsRotatedSideways]);

  /** Force the image to update its display whenever the timeline sidebar is toggled */
  useEffect(() => {
    setImageDisplayInputs({
      ...imageDisplayInputs,
      renderId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineVisible]);

  const onCreateBookmark = (e) => {
    closeDialog();
    e.stopPropagation();
    dispatch(createBookmark({
      type: BookmarkType.IMAGE,
      catalogId: $state.params.catalogId,
      componentId: lectureComponent.id,
    })).then((action) => {
      FlyoutModalManager.openFlyout({
        controller: 'BookmarksFlyoutCtrl as vm',
        template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
        isNavigational: false,
      });
    });
  };

  const onHighlightBookmark = (e) => {
    closeDialog();
    e.stopPropagation();
    dispatch(highlightBookmark({ id: lectureComponent.bookmarkId }));
    FlyoutModalManager.openFlyout({
      controller: 'BookmarksFlyoutCtrl as vm',
      template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
      isNavigational: false,
    });
  };

  const bookmarkProps: BookmarkProps = {
    onCreateBookmark,
    onHighlightBookmark,
    bookmarkId: lectureComponent.bookmarkId,
  };

  const progressStyles = css`
    display: flex;
    flex-direction: column;
    border: 1px dashed ${gray4};
    padding-top: ${threeQuartersSpacing}px;
    padding-bottom: ${threeQuartersSpacing}px;
    padding-left: 45px;
    padding-right: 45px;

    .bs4-progress {
      margin-bottom: ${halfSpacing}px;
      border-radius: 0;
    }
  `;

  const showUploadingView = isUploading || !imageUrlLoaded;
  // let uploadingView = null;

  /* TODO: Pull this out into a reusable component */
  if (showUploadingView) {
    let fileUploading;
    return (
      <React.Fragment>
        {Object.keys(filesUploading).map((key) => {
          fileUploading = filesUploading[key];
          let uploadPct = 100;
          if (hasUploadPercentage && isUploading) {
            uploadPct = fileUploading.uploadPercentage;
          }

          return (
            <div key={key} css={progressStyles}>
              <ProgressBar now={uploadPct} />
              <span className='text-small mx-auto'>{t.FORM.SAVING()}</span>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  const openDialog = () => {
    if (fullViewDialogRef?.current) {
      fullViewDialogRef.current.showModal();
    }
  };

  const closeDialog = () => {
    if (isFullViewDialogOpen) {
      fullViewDialogRef.current.close();
      setIsFullViewDialogOpen(false);
    }
  };

  return (
    <React.Fragment>
      {/* Modals */}
      <CroppingModal
        show={showCroppingModal}
        // TODO: For review: is there a good way to not have to redefine this for every modal we create? Maybe we should use a hook for our modals?
        onClose={() => setShowCroppingModal(false)}
        imgUrl={imageUrl}
        rotation={imageRotation}
        cropping={imageCropping}
        setCropping={(cropping) => {
          saveComponent({ cropping });
          setImageCropping(cropping);
        }}
        imageBaseWidth={imageBaseWidth}
        imageBaseHeight={imageBaseHeight}
      />
      <AltTextModal
        show={showAltTextModal}
        onClose={() => setShowAltTextModal(false)}
        altText={altText}
        setAltText={(text) => {
          saveComponent({ altText: text });
          setAltText(text);
        }}
        imgUrl={imageUrl}
        displayInputs={imageDisplayInputs}
      />
      <ImageFullViewDialog
        closeDialog={closeDialog}
        ref={fullViewDialogRef}
        imageUrl={imageUrl}
        displayInputs={imageDisplayInputs}
        altText={altText}
        open={isFullViewDialogOpen}
        {...bookmarkProps}
      />

      {/* Image container */}
      <div
        id={`lecture-component-${lectureComponent.id}`}
        className='image-lecture-component'
        css={styles}
      >
        {/* The black image settings popover */}
        <ImageEditPopover
          width={imageWidth}
          alignment={imageAlignment}
          rotation={imageRotation}
          buttons={popoverButtons}
          show={showSettingsPopover}
          setShow={setShowSettingsPopover}
          mode={props.mode}
        >
          <ImageComponentImage
            {...bookmarkProps}
            altText={altText}
            onClick={() => {
              if (props.mode === LecturePageMode.EDIT) {
                setShowSettingsPopover(true);
              } else {
                openDialog();
                setIsFullViewDialogOpen(true);
              }
            }}
            displayInputs={imageDisplayInputs}
            imageUrl={imageUrl}
            fitToContainer
            imageWidth={imageWidth}
            sizeContainerRef={props.containerRef}
            /** Disabled the tooltip when the edit menu is open.
             * This should be refactored out from being a prop here */
            tooltipEnabled={!showSettingsPopover}
            tooltip={(props.mode !== LecturePageMode.EDIT) ? t.LECTURE_PAGES.COMPONENTS.IMAGE.CLICK_TO_VIEW() : t.LECTURE_PAGES.COMPONENTS.IMAGE.EDIT()}
            onImageReady={asyncContent.onReady}
          >
            {(imageCaption.length || props.mode === LecturePageMode.EDIT)
              && (
                <NvTextArea
                  name='caption-input'
                  placeholder={t.LECTURE_PAGES.COMPONENTS.IMAGE.CAPTION_PLACEHOLDER()}
                  maxLength={255}
                  value={imageCaption}
                  className='text-small'
                  onChange={handleCaptionChange}
                  disabled={props.mode !== LecturePageMode.EDIT}
                />
              )}
          </ImageComponentImage>
        </ImageEditPopover>
      </div>
    </React.Fragment>
  );
};

export default ImageLectureComponent;
