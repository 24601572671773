import React from 'react';
import { useSelector } from 'react-redux';

import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { AdminBitValue, MenteeBitValue, MentorBitValue } from 'redux/schemas/models/mentoring-program-enrollments';

const useMentoringProgramRole = () => {
  const { mentoringProgram } = React.useContext(MentoringProgramContext);
  const { role } = useSelector((state) => getMentoringProgramEnrollment(state, mentoringProgram?.mentorshipProgramEnrollment)) ?? {};

  const hasPermission = (bitField, permissionType) => {
    if (mentoringProgram && bitField) {
      // eslint-disable-next-line no-bitwise
      return (bitField & permissionType) > 0;
    }

    return null;
  };

  const isAdmin = hasPermission(role?.permission, AdminBitValue);

  const isMentor = hasPermission(role?.permission, MentorBitValue);

  const isMentee = hasPermission(role?.permission, MenteeBitValue);

  return {
    isAdmin,
    isMentor,
    isMentee,
  };
};

export default useMentoringProgramRole;
