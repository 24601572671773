import { css } from '@emotion/react';
import CustomRadioGroup from 'components/custom-radio-group';
import { PollOptionNormalized } from 'redux/schemas/models/poll';
import { gray3, gray5, primary } from 'styles/global_defaults/colors';
import { openSans } from 'styles/global_defaults/fonts';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';

interface PollSelectOptionProps {
  options: PollOptionNormalized[];
  onSelectOption(value: number): void;
  selectedOption: undefined | number;
  disabled: boolean;
}

/* @ngInject */
export default function PollSelectOption(props: PollSelectOptionProps) {
  // radio buttons uses string values but we have number Ids, so here we convert them
  const activeValue = typeof props.selectedOption === 'number' ? String(props.selectedOption) : '';

  const optionContainerStyle = css`
    &:hover {
      /* Change outer circle color on hover */
      input[type="radio"]:not([disabled]) {
        border-color: ${primary};
      }
    }

    input[type="radio"] {
      appearance: none; /* Remove default styling */
      opacity: 1 !important;
      margin: 0px;
      top: 50%;
      left: ${standardSpacing}px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border: 2px solid ${gray3};
      border-radius: 50%;
      display: inline-block;
      background-color: white;
    }

    /* Create inner solid circle (psuedo element) when checked */
    input[type="radio"]:checked::before {
      content: "";
      width: 10px; /* Inner circle size */
      height: 10px;
      background-color: ${primary};
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    input[type="radio"]:checked {
      border-color: ${primary};
    }

    input[type="radio"]:focus {
      outline: none;
    }
  `;

  return (
    <div
      css={css`
        margin-top: ${standardSpacing}px;
      `}
    >
      <CustomRadioGroup
        radios={props.options.map((option, index) => ({
          value: String(option.id),
          option, // we pass the entire option object to use it latter in renderRadioButton
          disabled: props.disabled,
        }))}
        optionContainerStyle={optionContainerStyle}
        name='poll'
        onChange={(val) => {
          // convert them back to integer
          props.onSelectOption(parseInt(val, 10));
        }}
        activeValue={activeValue}
        renderRadioButton={({ option, index }) => (
          <div
            css={optionRadioButtonStyles}
          >
            {option.optionContent}
          </div>
        )}
      />
    </div>
  );
}

const optionRadioButtonStyles = css`
  font-size: 16px;
  line-height: ${largeSpacing}px;
  font-weight: normal;
  font-family: ${openSans};
  border-radius: ${quarterSpacing}px;
  box-shadow: 0 0 0 1px ${gray5};
  padding: ${threeQuartersSpacing}px ${doubleSpacing}px ${threeQuartersSpacing}px 55px;
  margin-bottom: ${halfSpacing}px;

  input:checked + & {
    box-shadow: 0 0 0 1px ${primary};
  }
  input:focus + & {
    box-shadow: 0 0 0 2px ${primary};
  }

  input:not([disabled]) + &:hover,
  input:checked + & {
    color: ${primary};
  }
`;
