import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { blue05, novoEdBlue } from 'athena/styles/colors';
import { gray1, gray2, gray3, gray6, gray7, hexToRgbaString, primary } from 'styles/global_defaults/colors';
import { semiBoldFontWeight, textLargeBodyFontSize } from 'styles/global_defaults/fonts';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { sessionDateFormat } from '.';


type SessionCardProps = {
  title: string;
  time: Date;
};

const SessionCard = ({
  title,
  time,
}: SessionCardProps) => {
  const isPast = moment().isAfter(moment(time));

  const [hasOverflow, setHasOverflow] = useState(false);
  const titleRef = useRef(null);
  useEffect(() => {
    setHasOverflow(titleRef.current?.scrollHeight > titleRef.current?.clientHeight);
  }, [titleRef]);

  const styles = css`
    display: flex;
    width: 100%;
    border: 1px solid ${gray6};
    border-radius: ${quarterSpacing}px;

    &:hover {
      box-shadow: 0px 2px 4px 0px ${hexToRgbaString(gray1, 0.08)};

      .session-title {
        color: ${primary};
      }
    }

    .tab {
      width: ${quarterSpacing}px;
      background-color: ${isPast ? gray3 : novoEdBlue};
      border-top-left-radius: ${quarterSpacing}px;
      border-bottom-left-radius: ${quarterSpacing}px;
    }

    .session-info {
      width: calc(100% - ${quarterSpacing}px);
    }

    .icon-calendar {
      color: ${isPast ? gray2 : novoEdBlue};
      background-color: ${isPast ? gray7 : blue05};
      padding: ${quarterSpacing}px;
      border-radius: 13px; // This is height of icon (16) + padding*2 (5*2), all divided by 2 => 13
      margin-right: ${quarterSpacing}px;
    }

    .session-time {
      color: ${gray2};
    }

    .session-title {
      font-weight: ${semiBoldFontWeight};
      font-size: ${textLargeBodyFontSize}px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  `;

  return (
    <div css={styles}>
      <div className='tab' />
      <div className='session-info p-4'>
        <div className='d-flex align-items-center'>
          <NvIcon icon='calendar' size='smallest' />
          <div className='session-time'>{moment(time).format(sessionDateFormat)}</div>
        </div>
        <NvTooltip enabled={hasOverflow} text={title} preventOverflow={false}>
          <div className='session-title' ref={titleRef}>{title}</div>
        </NvTooltip>
      </div>
    </div>
  );
};

export default SessionCard;
