import React, { useState } from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import { isEmpty } from 'underscore';
import { useSelector } from 'react-redux';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { primary } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { Button } from 'react-bootstrap';
import prodPathReplace from 'shared/prod-path-rewrite';

const styles = css`
  width: 600px;
  .selection {
    // Overriding the default 50% width
    min-width: 200px;
    &:focus, &:hover {
      color: ${primary} !important;
      outline: none;
      .selection-circle {
        border-color: ${primary} !important;
      }
    }
    .selection-circle {
      width: 80px;
      height: 80px;
      img {
        width: ${doubleSpacing}px;
      }
    }
  }
`;
type MeetingProps = {
  onSelect: (any) => void
  onCancel: (any) => void
};
const MeetingOptions = ({ onCancel, onSelect }: MeetingProps) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const integrationSettings = useSelector(state => state.app.liveSessions.integrations);

  const hasIntegration = !!integrationSettings?.TeamsLiveSession;

  return (
    <div className='d-flex flex-column mx-auto py-6' css={styles}>
      <div className='border-bottom border-gray-5'>
        {hasIntegration && (
          <div className='mb-4'>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.DESCRIPTION_1(`${hasIntegration}`, 'false')}</div>
        )}
        <div className='mb-6'>
          {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.WORKSPACE_MEETING(`${!hasIntegration}`)}
        </div>
      </div>
      {!isEmpty(integrationSettings) && (
        <div className='mt-6 d-flex flex-column align-items-center'>
          <div>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.SELECT_SESSION()}</div>
          <div className='d-flex justify-content-between mt-5'>
            {hasIntegration && (
              <ClickableContainer
                className='selection align-items-center d-flex flex-column m-1'
                onClick={() => setSelectedOption('ms_teams')}
              >
                <div className='selection-circle  border border-gray-3 rounded-circle p-4'>
                  <img src={prodPathReplace('images/msteam-blue.png')} alt='Microsoft Teams Meeting' />
                </div>
                <div className='mt-2'>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.MS_TEAM()}</div>
              </ClickableContainer>
            )}
            <ClickableContainer
              className='selection align-items-center d-flex flex-column m-1'
              onClick={() => setSelectedOption('external_link')}
            >
              <div className='selection-circle border border-gray-3 rounded-circle d-flex align-items-center justify-content-center p-4'>
                <NvIcon icon='admin-embeds' size='medium' />
              </div>
              <div className='mt-2'>{t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.EXTERNAL()}</div>
            </ClickableContainer>
          </div>
        </div>
      )}
      <div className='button-bar mt-7'>
        <Button
          variant='secondary'
          onClick={(e) => onCancel(e)}
        >
          {t.FORM.CANCEL()}
        </Button>
        <Button
          variant='primary'
          disabled={!selectedOption}
          onClick={() => onSelect(selectedOption)}
        >
          {t.FORM.NEXT()}
        </Button>
      </div>
    </div>
  );
};

export default MeetingOptions;
