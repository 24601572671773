import axios from 'axios';
import { useQuery } from 'react-query';
import t from 'react-translate';
import { css } from '@emotion/react';
import { halfSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { gray6, highTide } from 'styles/global_defaults/colors';
import { topLabelStyle } from './content-search-panel-body';
import { useOnSearchContent } from './content-search-utils';

const recentSearchButtonStyle = css`
  margin-right: ${halfSpacing}px;
  color: ${highTide};
  height: ${largeSpacing}px;
  padding: 0 ${halfSpacing}px;
  font-size: 12px;
  font-weight: 600;
  background: ${gray6};
  border: none;
  border-radius: 5px;
  margin-bottom: ${halfSpacing}px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const recentSearchButtonContainerStyle = css`
  display: flex;
  flex-wrap: wrap;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    display: inline;
  }
`;

/* @ngInject */
export default function RecentSearch(props: { className?: string }) {
  const onSearchContent = useOnSearchContent();
  const { data } = useQuery(
    'recent-content-search',
    () => axios.get('content/recent_terms.json').then((response) => response.data.result),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  if (!data?.length) {
    return null;
  }

  return (
    <div className={props.className}>
      <h3 className='mb-2 mt-0 course-title-xxs' css={topLabelStyle}>
        {t.LHS.CONTENT_SEARCH.RECENT_SEARCH()}
      </h3>
      <div css={recentSearchButtonContainerStyle}>
        <ul>
          {data.map((term) => (
            <li>
              <button
                css={recentSearchButtonStyle}
                key={term}
                type='button'
                onClick={() => {
                  onSearchContent(term);
                }}
              >
                {term}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
