import { gray3, kelp, primary } from 'styles/global_defaults/colors';

// styles to overwrite CSS on Qrvey.
export const baseStyles = `

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700');

*{
font-family: 'Open Sans', sans-serif !important;
}
.dx-navigate-button{
  font-family: DXIcons !important;
}
.ql-editor p strong{
font-family: 'Open Sans Condensed', sans-serif !important;
font-weight: 700;
font-style: normal !important;
}
an-panel{
  box-shadow:none;

}
an-panel-header > qui-tooltip{
  display:none !important;
}
.indicator-metric{
  font-weight: bold !important;
  font-family: 'Open Sans Condensed', sans-serif !important;
}
qeu-grid{
  background-color:#e4e7ec !important;
  padding-top: 40px !important;
}
.q-icon-dots_actions{
  border:none !important;
}
.panel-label{
  background-color: #D4DAE1 !important;
  padding-bottom: 10px !important;
  margin-top: -5px !important;
}
.panel-label .rotate-name{
  color: ${primary} !important;
  font-size:16px !important;
  font-weight: 600 !important;
}
.panel-label .open-icon .hydrated{
  color: ${primary} !important;
  --icon-color: ${primary} !important;
}
.fp-panel-drawer__one{
  background:#e4e7ec !important;
}
.qui-action-menu-container{
  bottom: 96px !important;
}
.floating-button{
  width:56px !important;
  height:56px !important;
}
.qui-action-menu-container{
  right: 40px !important;
}
.an-panel-header__title{
  font-weight:700 !important;
}
.q-icon-dots_actions{
  color: ${primary} !important;
}
.floating-button .dropdown-items qui-select-list-item:first-of-type{
  display:none !important;
}
.floating-button .dropdown-items qui-select-list-item:last-of-type{
  display:none !important;
}
.panel-label{
  width:30px !important;
}
.dx-datagrid-headers i[class^=q-icon]{
  display: none !important;
}
.an-panel-body-error__message:before {
  content: "" !important;
  visibility: hidden !important;
}

.an-panel-body-error__message {
  padding: 0px 16px 16px !important;
  color: rgb(0,0,0,.5) !important;
}
qui-tooltip[message='Log In'] {
  display:none !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td.dx-datagrid-header-group {
  background-image: none !important;
}

`;
export const courseStyles = `
an-qv-list-item[title="CSV"] {
  display:none !important;
}
`;
export const practiceStyles = `
  .bar-figure {
    background: rgb(3, 165, 105) !important;
  }
`;

export const journeyStyles = `
`;

export const institutionStyles = `
an-qv-list-item[title="CSV"] {
  display:none !important;
}
.bar-figure {
  background-color:${gray3} !important;
}
`;

export const skillsStyles = `
.bar-figure {
  background-color:#1A2172 !important;
}
an-qv-list-item[title="CSV"] {
  display:none !important;
}
`;
export const cohortStyles = `
an-qv-list-item[title="CSV"] {
  display:none !important;
}
`;

export const questionSetStyles = `
  an-qv-list-item[title="CSV"] {
    display:none !important;
  }
  .indicator-metric {
    color: ${kelp} !important;
  }
`;
