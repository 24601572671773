import t from 'react-translate';
import { Button } from 'react-bootstrap';
import ClickableContainer from 'components/clickable-container';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { novoEdBlack, novoEdBlue } from 'athena/styles/colors';
import {
  textLargeBodyFontSize,
} from 'styles/global_defaults/fonts';
import { danger, gray2 } from 'styles/global_defaults/colors';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import NvTruncatedTextWithTooltip from 'shared/components/nv-truncated-text-with-tooltip';
import { config } from '@config/pendo.config.json';
import AthenaTextarea from '../textarea';
import { tooltipZIndex } from '../constants';

const DESCRIPTION_MAX_LINES = 3;

type ActionDescriptionProps = {
  actionItemId: string | number;
  description: string;
  isCompleted: boolean;
  onChange: () => void;
  onReset: () => void;
  isEditing: boolean;
  setEditingId: (id: string | number) => void;
};

const textStyles = css`
  color: ${novoEdBlack} !important;
  font-size: ${textLargeBodyFontSize}px !important;
  line-height: 22px !important;
`;

const styles = css`
  &.add-action-item-button {
    padding: 0;
    color: ${gray2};

    &:focus,
    &:active {
      box-shadow: none !important;
      border-radius: unset;
      outline-offset: 0;
    }
  }

  &.action-item-description {
    display: grid;
    ${textStyles}
    .input-label {
      margin-bottom: 0 !important;
    }
    .input-content {
      ${textStyles}
    }
    textarea {
      display: flex;
      padding: 0 !important;
      min-height: 22px !important;
      border: none !important;
      border-radius: unset !important;
      outline: 0px solid transparent;
      border-bottom: 1px solid ${novoEdBlue} !important;

      ::placeholder {
        ${textStyles}
        color: ${gray2} !important;
      }
      &.error {
        border-bottom: 1px solid ${danger} !important;
      }
    }
    .truncated-text {
      height: unset;
      max-height: ${DESCRIPTION_MAX_LINES * 22}px;
      border-bottom: 1px solid transparent !important;
    }
  }

  &.completed {
    color: ${gray2} !important;
    text-decoration: line-through;
  }
`;

const ActionDescription = ({
  actionItemId,
  isEditing,
  description,
  isCompleted,
  setEditingId,
  onChange,
  onReset,
}: ActionDescriptionProps) => {
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.ITEM;
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.actionItems.item;

  const clearDescription = () => {
    onReset();
    setEditingId(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!errors.description) {
        onChange();
      }
    } else if (e.key === 'Escape') {
      clearDescription();
    }
  };

  const commonClassNames = `action-item-description ${isCompleted ? 'completed' : ''}`;

  const delayClearDescription = () => {
    setTimeout(() => {
      clearDescription();
    }, 500);
  };

  if (isEditing) {
    return (
      <AthenaTextarea
        withForm
        autoFocus
        css={styles}
        showLabel={false}
        name='description'
        value={description}
        onKeyDown={handleKeyDown}
        className={commonClassNames}
        inputClassName={`px-0 input-content ${errors.description ? 'error' : ''}`}
        placeholder={translationBase.ADD_BUTTON()}
        onChange={({ target: { value } }) => setValue('description', value)}
        onBlur={actionItemId && !errors.description ? onChange : delayClearDescription}
        validationErrorTitle={t.FORM.WARNING()}
        onFocus={({ target }) => target.setSelectionRange(target.value.length, target.value.length)}
        dataQa={dataQATag.editActionItemDescriptionTextField}
        dataQaId={`${dataQATag.editActionItemDescriptionTextField}-${actionItemId}`}
      />
    );
  }
  if (description) {
    return (
      <ClickableContainer
        css={styles}
        className={commonClassNames}
        onClick={() => setEditingId(actionItemId)}
        data-qa={dataQATag.editActionItemDescription}
        data-qa-id={`${dataQATag.editActionItemDescription}-${actionItemId}`}
      >
        <NvTruncatedTextWithTooltip
          text={description}
          linesCount={DESCRIPTION_MAX_LINES}
          className='truncated-text d-flex align-items-start'
          zIndex={tooltipZIndex}
        />
      </ClickableContainer>
    );
  }
  return (
    <Button
      css={styles}
      variant='link'
      onClick={() => setEditingId(actionItemId)}
      className='add-action-item-button text-left text-large-body border-0'
      data-qa={dataQATag.createNewActionItem}
    >
      {translationBase.ADD_BUTTON()}
    </Button>
  );
};

export default ActionDescription;
