import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { isEmpty } from 'underscore';
import t from 'react-translate';

// Actions
import { getTimelineWithCommunications } from 'redux/actions/timeline';
import {
  getCourseActivities,
  resetLecturePageExpandedInAutomatedCommunications,
  enableWelcomeEmail,
  getCourseWelcomeEmail,
} from 'redux/actions/course-communications';
import { getInstitutionData } from 'redux/actions/institutions';

// Selectors
import { getLectureSubsectionIds, getLectureSectionIds } from 'redux/selectors/timeline';
import { isTimelineLoadedInAutomatedCommunications } from 'redux/selectors/course-communications';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';

// Styles
import { primary } from 'styles/global_defaults/colors';

// Components
import TimelineSection from 'timelines/components/timeline-section';
import TimelineSubsection from 'timelines/components/timeline-subsection';
import NvIcon from 'shared/components/nv-icon';
import EmptyList from 'shared/components/empty-list';
import { addAlertMessage } from 'redux/actions/alert-messages';
import LoadingPlaceholder from './loading-placeholder';
import WelcomeEmailContainer from './welcome-email/container';

type AutomatedCommunicationsProps = {};

const mapDispatchToProps = {
  getTimeline: getTimelineWithCommunications,
  getActivities: getCourseActivities,
  resetExpanded: resetLecturePageExpandedInAutomatedCommunications,
  enableCourseWelcomeEmail: enableWelcomeEmail,
  getInstitution: getInstitutionData,
  getWelcomeEmail: getCourseWelcomeEmail,
  alertMessage: addAlertMessage,
};

const styles = css`
  .icon-display {
    display: inline-block;
    color: ${primary};
  }

  .intro-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const AutomatedCommunications = (props: AutomatedCommunicationsProps & typeof mapDispatchToProps) => {
  const {
    getTimeline,
    getActivities,
    resetExpanded,
    getInstitution,
    enableCourseWelcomeEmail,
    getWelcomeEmail,
    alertMessage,
  } = props;

  const institutionId = useSelector((state) => state.app.currentInstitutionId);
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const institution = useSelector(getCurrentInstitution);
  const aliases = useSelector(getCourseAliases);
  const isTimelineLoaded = useSelector(isTimelineLoadedInAutomatedCommunications);

  const [prefix, suffix] = t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.AUTOMATED.INTRO_SECOND({ ...aliases.lectureAliases, ...aliases.courseAliases }).split('SQUARE_ICON');

  useEffect(() => {
    if (catalogId) {
      resetExpanded();
      getTimeline({ catalogId });
    }
  }, [catalogId, getTimeline, resetExpanded]);

  useEffect(() => {
    if (catalogId && !isTimelineLoaded) {
      getActivities({ catalogId });
    }
  }, [catalogId, isTimelineLoaded, getActivities]);

  useEffect(() => {
    getInstitution({ institutionId });
  }, [getInstitution, institutionId]);

  const lectureSubsectionIds = useSelector((state) => getLectureSubsectionIds(state));
  const lectureSectionIds = useSelector((state) => getLectureSectionIds(state));

  return (
    <React.Fragment>
      { !isTimelineLoaded && <LoadingPlaceholder /> }
      { isTimelineLoaded && (
        <React.Fragment>
          <div css={styles}>
            <div className='page-title mt-6 text-center'>{t.COURSE_COMMUNICATIONS.TABS.TITLE.AUTOMATED()}</div>
            <div className='text-body my-4 intro-description'>
              <p>{t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.AUTOMATED.INTRO_FIRST({ ...aliases.lectureAliases })}</p>
              <p>
                {prefix}
                <span className='icon-display'><NvIcon icon='add-square' size='xss-smallest' /></span>
                {suffix}
              </p>
            </div>
            {institution.welcomeEmailEnabled && (
              <WelcomeEmailContainer
                catalogId={catalogId}
                enableCourseWelcomeEmail={enableCourseWelcomeEmail}
                getWelcomeEmail={getWelcomeEmail}
                alertMessage={alertMessage}
              />
            )}
          </div>
          {isEmpty(lectureSectionIds) && isEmpty(lectureSubsectionIds)
            ? <EmptyList icon='read' description={t.COURSE_COMMUNICATIONS.NO_CONTENT.AUTOMATED(aliases.courseAliases)} />
            : (
              <React.Fragment>
                {
                  // Lecture subsections
                  lectureSubsectionIds.map(lectureSubsectionId => (
                    <TimelineSubsection key={lectureSubsectionId.toString()} lectureSubsectionId={lectureSubsectionId} />
                  ))
                }
                {
                  // Lecture sections
                  lectureSectionIds.map(lectureSectionId => (
                    <TimelineSection key={lectureSectionId.toString()} lectureSectionId={lectureSectionId} />
                  ))
                }
              </React.Fragment>
            )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ConnectedAutomatedCommunications = connect<{}, typeof mapDispatchToProps>(
  () => ({}),
  mapDispatchToProps,
)(AutomatedCommunications as any);

export default ConnectedAutomatedCommunications;
