import { css } from '@emotion/react';
import { almostWhite } from 'athena/styles/colors';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import { CompletionStatus, StepKey } from 'redux/schemas/models/mentoring-program-enrollments';
import { getSanitizedStyles } from 'shared/utils';
import { white } from 'styles/global_defaults/colors';
import { HomeProps } from '../types';
import { withOverflowFlexGrowClass } from '../../constants';
import ProgramGuidelinesAndExpectations from './program-guidelines-and-expectations';
import ConnectionDetails from './connection-details';
import MatchCelebration from './match-celebration';
import StepList from './step-list';
import SessionsList from './sessions-list';
import UnreleasedFeature from './unreleased-feature';
import ProgramClosed from './program-closed';
import PlanSessionFlyoutModal from './plan-session-flyout-modal';
import MentoringProgramContext from '../../context';
import ActionItemsPanel from './action-items-panel';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import ProfileModal from './step-list/profile-completion/profile-modal';
import t from 'react-translate';


const withOverflowFlexGrowStyles = css`
  .${withOverflowFlexGrowClass} {
    flex: 1;
    min-height: 0;
  }
`;

const unreleasedStyles = (containerTranslate: number) => css`
  padding: 0;
  background-color: ${almostWhite};
  justify-content: center;
  .unreleased-section {
    width: 520px;
    max-height: 540px;
    height: calc(100% + ${120 - containerTranslate}px);
    transform: translateY(-${250 - containerTranslate}px);
    ${withOverflowFlexGrowStyles};
    background-color: ${white};
    border-radius: 10px;
    .description {
      max-width: 350px;
      text-align: center;
    }
  }
}`;

const styles = (containerTranslate: number, isTablet: boolean, isUnauthorized: boolean) => css`
  display: grid;
  align-items: center;
  min-height: ${isUnauthorized ? 'calc(100% - 150px)' : '100%'};
  height: fit-content;
  background-color: ${almostWhite};
  padding: 0 ${standardSpacing}px;
  ${isTablet && css`
    padding-bottom: ${tripleSpacing}px;
  `};

  .participant-home {
    display: grid;
    gap: ${standardSpacing}px;
    grid-template-columns: auto 380px;
    height: calc(100% + ${120 - containerTranslate}px);
    transform: translateY(-${120 - containerTranslate}px);
    ${withOverflowFlexGrowStyles};
    @media(max-width: 834px) {
      grid-template-columns: 1fr;
      .right-panel {
        .info-container {
          > img {
            width: 100%;
            ${isTablet && css`
              height: 120px;
              object-fit: cover;
            `};
          }
        }
      }
    }

    .left-panel {
      gap: ${isTablet ? 0 : standardSpacing}px;
    }
  }
`;

const ParticipantHome = ({ scrollPercentage }: HomeProps) => {
  const containerTranslate = (90 / 100) * scrollPercentage;

  const [showPlanSessionFlyoutModal, setShowPlanSessionFlyoutModal] = useState(false);
  const [sessionToEdit, setSessionToEdit] = useState(null);

  const openPlanSessionFlyout = (session) => {
    setSessionToEdit(session);
    setShowPlanSessionFlyoutModal(true);
  };

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const connectionId = parseInt($state.params.connectionId, 10);

  const { showProfile = false } = $state.params || {};
  const [showProfileModal, setShowProfileModal] = useState<boolean>(showProfile);

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { endDate, releasedDate, mentorshipProgramEnrollment, matchAnnouncementDate } = mentoringProgram || {};
  const isFeatureReleased = releasedDate && new Date() > new Date(releasedDate);
  const isProgramEnded = endDate && new Date() > new Date(endDate);
  const announcementDateHasPassed = moment().isAfter(moment(matchAnnouncementDate));

  const unreleasedFeatureStyles = (isFeatureReleased && !isProgramEnded)
    ? ''
    : unreleasedStyles(containerTranslate);

  const mentoringProgramEnrollment = useSelector(state => (
    state.models.mentoringProgramEnrollments[mentorshipProgramEnrollment]
  ));
  const { assignedConnections, states } = mentoringProgramEnrollment;
  const hasSingleConnection = assignedConnections?.length === 1;

  const connection = assignedConnections?.find((connec) => (
    connec.connectionId === connectionId
  )) ?? assignedConnections?.[0];
  const { isSessionCreated } = connection || {};

  const isTablet = useMediaQuery({
    query: `(max-width: 834px)`,
  });

  const actionItems = connection && announcementDateHasPassed && (
    <ActionItemsPanel
      enrollment={mentoringProgramEnrollment}
      connection={connection}
      showPlanSessionFlyout={openPlanSessionFlyout}
    />
  );

  useEffect(() => {
    if (!connectionId) {
      const id = assignedConnections?.[0]?.connectionId;
      if (id) {
        $state.go(
          'mentoring-program-single-connection',
          { connectionId: id },
          { reload: false },
        );
      }
    }
  }, [connectionId]);

  const checkCompletedState = Object.values(states).every(({ status }) => status === CompletionStatus.COMPLETED);
  const checkWaitingSessionCompleted = states[StepKey.WAITING_FIRST_SESSION]?.status === CompletionStatus.COMPLETED;
  const [areAllStepsCompleted, setAreAllStepsCompleted] = useState(checkCompletedState);
  const [isWaitingFirstSessionCompleted, setIsWaitingFirstSessionCompleted]= useState(checkWaitingSessionCompleted);

  useEffect(() => {
    if (checkCompletedState) {
      setTimeout(() => {
        setAreAllStepsCompleted(true);
      }, 5000);
    }
    if (checkWaitingSessionCompleted) {
      setTimeout(() => {
        setIsWaitingFirstSessionCompleted(true);
      }, 5000);
    }
  }, [checkCompletedState, checkWaitingSessionCompleted]);

  return (
    <div css={getSanitizedStyles([
      styles(containerTranslate, isTablet, (!isFeatureReleased || isProgramEnded)),
      unreleasedFeatureStyles
    ])}>
      {isFeatureReleased && !isProgramEnded && (
        <>
          <div className='participant-home'>
            <div className='d-flex flex-column left-panel'>
              {!areAllStepsCompleted && <StepList showPlanSessionFlyout={openPlanSessionFlyout} />}
              {(isWaitingFirstSessionCompleted && isSessionCreated) ? (
                <SessionsList showPlanSessionFlyout={openPlanSessionFlyout}>
                  {actionItems}
                </SessionsList>
              ) : actionItems}
              <ProgramGuidelinesAndExpectations />
            </div>
            <ConnectionDetails />
          </div>
          {hasSingleConnection && <MatchCelebration />}
        </>
      )}
      {!isFeatureReleased && (
        <UnreleasedFeature date={releasedDate} />
      )}
      {isProgramEnded && (
        <ProgramClosed />
      )}
      {showPlanSessionFlyoutModal && (
        <PlanSessionFlyoutModal
          session={sessionToEdit}
          onClose={() => {
            setSessionToEdit(null);
            setShowPlanSessionFlyoutModal(false);
          }}
        />
      )}
      {showProfileModal && (
        <ProfileModal
          title={showProfile && t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.DEFAULT()}
          setShowModal={setShowProfileModal}
        />
      )}
    </div>
  );
};

export default ParticipantHome;
