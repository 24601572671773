import { SVGProps } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { css } from '@emotion/react';

const styles = css`
  cursor: pointer;
`;

const AnimatedCheckmark = ({ color, width, height, onClick }: SVGProps<SVGSVGElement>) => {
  const checkmarkLength = 28;

  const { strokeDashoffset } = useSpring({
    from: { strokeDashoffset: checkmarkLength },
    to: { strokeDashoffset: 0 },
    config: { duration: 300 },
  });

  return (
    <svg
      width={width}
      height={height}
      css={styles}
      onClick={onClick}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.4328 5.82459C22.7587 5.3074 21.9153 5 21 5H9C6.79086 5 5 6.79086 5 9V21C5 23.2091 6.79086 25 9 25H21C23.2091 25 25 23.2091 25 21V12.7426L23 14.7426V21C23 22.1046 22.1046 23 21 23H9C7.89543 23 7 22.1046 7 21V9C7 7.89543 7.89543 7 9 7H21C21.3616 7 21.7008 7.09597 21.9935 7.26383L23.4328 5.82459Z'
        fill={color}
      />
      <animated.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 14L15 19L28 6'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
        strokeDasharray={checkmarkLength}
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  );
};

export default AnimatedCheckmark;
