import ViewTeamsMeeting from '../components/view-meeting.tsx';

/* @ngInject */
export default function MeetingRsvpModalInstanceController(
  $scope,
  $uibModalInstance,
  StateManager,
  ConfirmationOverlays,
  CurrentUserManager,
  TeamWorkspaceManager,
  TimezonesService,
  validationConstants,
  _,
  humps,
  meeting,
  moment,
  rsvpStatus,
) {
  const vm = this;

  initialize();

  function initialize() {
    vm.CurrentUserManager = CurrentUserManager;
    vm.TeamWorkspaceManager = TeamWorkspaceManager;
    vm.rsvpStatus = rsvpStatus || null;
    vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.ARE_YOU_SURE';
    vm.dismissedByUser = false;
    vm.meeting = meeting;
    vm.meetingAttendance = TeamWorkspaceManager.meetingAttendeesToUserObjects(_.clone(meeting.attendance));
    const timeZoneString = TimezonesService.correctSpelling(CurrentUserManager.user.timeZone || moment.tz.guess());
    const timeZoneKey = TimezonesService.timezones.find((t) => t.longName === timeZoneString)?.translationKey;
    vm.currentTimeZone = timeZoneKey ? `TIMEZONE.${timeZoneKey}` : timeZoneString;

    vm.displayNameForRsvpStatus = displayNameForRsvpStatus;
    vm.submitRsvp = submitRsvp;
    vm.updateRsvpStatus = updateRsvpStatus;
    vm.findCurrentUserRsvp = findCurrentUserRsvp;
    vm.hasUnsavedChanges = hasUnsavedChanges;

    /** Whether the current user is a member of this team */
    vm.isNonMember = !vm.TeamWorkspaceManager.currentTeam.currentAccountTeamMember;
    vm.validationConstants = validationConstants;
    vm.isExternalLink = isExternalLink;
  }

  function displayNameForRsvpStatus(status) {
    if (status === 'attending') {
      return 'TEAMS.WORKSPACE.RSVP.WILL_ATTEND';
    }
    if (status === 'may_attend') {
      return 'TEAMS.WORKSPACE.RSVP.NOT_SURE_YET';
    }
    if (status === 'not_attending') {
      return 'TEAMS.WORKSPACE.RSVP.CANT_MAKE_IT';
    }

    // default: null
    return 'TEAMS.WORKSPACE.RSVP.TITLE';
  }

  function submitRsvp() {
    vm.submitting = true;

    if (rsvpStatus) {
      // we are updating the rsvp
      TeamWorkspaceManager.updateMeetingRsvp({
        userId: vm.CurrentUserManager.user.id,
        rsvp: humps.decamelize(vm.rsvpStatus),
        meetingId: vm.meeting.id,
        attendanceId: findCurrentUserRsvp().id,
      }).then((response) => {
        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close({
          newRsvp: false,
          rsvpData: response.result,
        });
      });
    } else {
      TeamWorkspaceManager.createMeetingRsvp({
        userId: vm.CurrentUserManager.user.id,
        rsvp: humps.decamelize(vm.rsvpStatus),
        meetingId: vm.meeting.id,
      }).then((response) => {
        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close({
          newRsvp: true,
          rsvpData: response.result,
        });
      });
    }
  }

  function updateRsvpStatus(status) {
    vm.rsvpStatus = status;
    const myRsvp = findCurrentUserRsvp();
    if (myRsvp) {
      myRsvp.rsvp = status;
    } else {
      // need to add current user to list of heads
      vm.meetingAttendance.push({
        teamMemberId: TeamWorkspaceManager.currentTeam.currentAccountTeamMember.id,
        rsvp: status,
        user: TeamWorkspaceManager.currentTeam.currentAccountTeamMember.user,
      });
    }
  }

  function findCurrentUserRsvp() {
    return _.findWhere(vm.meetingAttendance, { teamMemberId: TeamWorkspaceManager.currentTeam.currentAccountTeamMember.id });
  }


  function hasUnsavedChanges() {
    return vm.rsvpStatus !== null && !vm.dismissedByUser && (!rsvpStatus || rsvpStatus !== vm.rsvpStatus);
  }

  function isExternalLink() {
    const urlRegex = new RegExp(validationConstants.URL_REGEX);
    return urlRegex.test(vm.meeting?.location);
  }


  // - unsaved changes overlay
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => vm.hasUnsavedChanges(),
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
  );

  $scope.ViewTeamsMeeting = () => (
    <ViewTeamsMeeting meeting={vm.meeting} />
  );

  $scope.$on('modal.closing', ($event) => {
    if (vm.hasUnsavedChanges()) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
