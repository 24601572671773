import React, {
  useCallback, useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { values, difference } from 'underscore';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { SubmissionTab } from 'redux/schemas/models/video-practice';
import { getSummary } from 'redux/actions/skills-feedback';
import { setPracticeRoomState } from 'redux/actions/video-practice';
import { authorRatingsSorted } from 'redux/selectors/skills-feedback';
import { getScenario, getSubmission } from 'redux/selectors/video-practice';
import { makeQueryString } from 'shared/hooks/use-query';
import { NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import { tripleSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { ActionTypes, PracticeSubmissionContext, Tab, selectedViewToIndex } from '../utils';
import { config } from '../../../../../config/pendo.config.json';

const styles = css`
  .tab {
    height: auto !important;
    min-height: ${tripleSpacing}px;
    padding-top: ${halfSpacing}px;
    padding-bottom: ${halfSpacing}px;
  }
`;

const PracticeSubmissionTabs = () => {
  const [{
    submissionId,
    isPracticeFeedback,
    isPracticeRoom,
    selectedView,
    showCommentButton,
    isAdmin,
    isMyPractice,
    skillTags,
    scenarioId,
  }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const { $state } = useContext(AngularServicesContext);
  const {
    isRatedByCurrentUser,
    isViewerMentor,
    hasInsightsEnabled,
  } = useSelector((state) => getSubmission(state, submissionId));
  const dispatch = useAppDispatch();
  const { summary, summaryOnceLoaded } = useSelector((state) => state.app.videoPracticeSubmissions[submissionId]) ?? {};
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const authorRatings = useSelector(state => authorRatingsSorted(state, submissionId, 'videoPracticeSubmissions'));
  const {
    hasCourseAdmin,
  } = useSelector((state) => getScenario(state, scenarioId));
  const {
    submissionId: selectedSubmissionId,
    selectedSubmissionTab,
    selectedTab,
  } = useSelector((state) => state.app.practiceRoom.params);

  const history = useHistory();

  const [activeTabIndex, setActiveTabIndex] = useState(selectedViewToIndex(selectedView));

  const getActiveTabIndex = useCallback((selected) => {
    const allTabs = values(SubmissionTab);
    const availableTabs = tabs.map(tab => tab.tabValue);
    const missingTabs = difference(allTabs, availableTabs);
    const tabsBeforeActiveTab = missingTabs.filter(
      tab => selectedViewToIndex(tab) < selectedViewToIndex(selected),
    );
    return selectedViewToIndex(selected) - tabsBeforeActiveTab.length;
  }, []);

  const onSelectTab = useCallback(async (tab: SubmissionTab) => {
    await practiceSubmissionDispatch({
      type: ActionTypes.SET_SELECTED_VIEW,
      payload: tab,
    });
    // setting tab index
    const tabIndex = await getActiveTabIndex(tab);
    setActiveTabIndex(tabIndex);
    if ($state?.params?.submissionView) {
      /**
       * Reset the URL and practice room state to base
       * ie., the URL don't need additional tab params once it is set inside the context
       * and state don't need to store the submission tab
       * Also adding timeout, to compensate delay to switch to the tabs in the UI.
       */

      setTimeout(() => {
        dispatch(setPracticeRoomState({
          scenarioId,
          selectedTab,
          submissionId,
          selectedSubmissionTab: null,
        }));
        history.push(makeQueryString({
          selected: selectedTab,
        }));
      }, 3000);
    }
  }, [
    $state?.params?.submissionView, getActiveTabIndex,
    selectedSubmissionTab, selectedView, submissionId,
  ]);

  useEffect(() => {
    // set tab index whenever the selected view changes
    const tabIndex = getActiveTabIndex(selectedView);
    setActiveTabIndex(tabIndex);
  }, [getActiveTabIndex, selectedView]);

  useEffect(() => {
    if (submissionId === selectedSubmissionId
      && selectedSubmissionTab
      && selectedView !== selectedSubmissionTab
    ) {
      onSelectTab(selectedSubmissionTab);
    }
  }, [onSelectTab, selectedSubmissionId, selectedSubmissionTab, submissionId]);

  useEffect(() => {
    if (skillTags?.length > 0) {
      dispatch(getSummary({
        institutionId: currentInstitutionId,
        ownerId: submissionId,
        ownerType: 'VideoPracticeSubmission',
      }));
    }
  }, [currentInstitutionId, dispatch, skillTags?.length, submissionId]);

  const tabs: Tab[] = [
    {
      text: t.PRACTICE_ROOM.SUBMISSION.TABS.COMMENTS(),
      onClick: () => onSelectTab(SubmissionTab.COMMENTS),
      pendoTagName: config.pendo.skillsFeedback.practiceTabComments,
      tabValue: SubmissionTab.COMMENTS,
    },
  ];

  const showYourSkillsFeedbackTab = useMemo(() => {
    const isCommonConditionMet = isRatedByCurrentUser || hasCourseAdmin || isViewerMentor;
    if (!isMyPractice && skillTags?.length > 0) {
      if (isPracticeRoom && (authorRatings?.length > 0 || isCommonConditionMet)) {
        return true;
      }
      if (!isPracticeRoom && isCommonConditionMet) {
        return true;
      }
    }
    return false;
  }, [authorRatings?.length, isViewerMentor, hasCourseAdmin, isMyPractice, isPracticeRoom, isRatedByCurrentUser]);

  const showAllSkillsFeedbackTab = useMemo(() => {
    if (summaryOnceLoaded) {
      return summary.some(({ totalRatings }) => totalRatings > 0);
    }
    return false;
  }, [summary, summaryOnceLoaded]);

  if (!isPracticeFeedback) {
    if (showYourSkillsFeedbackTab) {
      tabs.push({
        text: t.PRACTICE_ROOM.SUBMISSION.TABS.YOUR_SKILLS_FEEDBACK(),
        onClick: () => onSelectTab(SubmissionTab.AUTHOR_FEEDBACK),
        pendoTagName: config.pendo.skillsFeedback.practiceTabYourSkillsFeedback,
        tabValue: SubmissionTab.AUTHOR_FEEDBACK,
      });
    }
    if (isMyPractice || hasCourseAdmin || isViewerMentor) {
      if (skillTags?.length > 0 && showAllSkillsFeedbackTab) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.ALL_SKILLS_FEEDBACK(),
          onClick: () => onSelectTab(SubmissionTab.ALL_FEEDBACK),
          pendoTagName: config.pendo.skillsFeedback.practiceTabAllSkillsFeedback,
          tabValue: SubmissionTab.ALL_FEEDBACK,
        });
      }

      if (hasInsightsEnabled) {
        tabs.push({
          text: t.PRACTICE_ROOM.SUBMISSION.TABS.INSIGHTS(),
          onClick: () => onSelectTab(SubmissionTab.INSIGHTS),
          dataQA: 'automated-feedback-insights-tab',
          pendoTagName: config.pendo.practice.insightsTab,
          tabValue: SubmissionTab.INSIGHTS,
        });
      }
    }
  }

  const showTabs = (showCommentButton || isAdmin) && tabs.length > 1;

  return (
    <div
      className='border-bottom border-gray-6 mb-4'
      css={styles}
    >
      {showTabs && tabs.length > 1 && (
        <NvResponsiveTabsRow
          defaultTabs={tabs}
          tabTextClass='card-title'
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          selectedTabIndex={activeTabIndex}
        />
      )}
    </div>
  );
};

export default PracticeSubmissionTabs;
