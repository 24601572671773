/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import { novoEdBlue } from 'athena/styles/colors';
import { white } from 'styles/global_defaults/colors';

export const optionStyles = css`
  background: ${novoEdBlue};
  color: ${white};
  border-radius: 50%;
  padding: 4px;
`;
