/* eslint-disable import/prefer-default-export */
import humps from 'humps';
import { AppDispatch } from 'redux/store';
import PusherService from 'shared/services/pusher-service';
import { Event, EventList } from './event-types';

export const configureChannel = (
  channelName: string,
  events: EventList,
  dispatch: AppDispatch,
) => {
  const pusherChannel = PusherService.setupChannel(channelName);

  events.forEach(({ name, handler }) => pusherChannel.bind(name, (event: Event) => {
    const camelizedEvent = humps.camelizeKeys(event) as Event;
    handler(camelizedEvent, dispatch);
  }));

  return () => {
    events.forEach(({ name, handler }) => pusherChannel.unbind(name, handler));
    PusherService.removeChannel(channelName);
  };
};
