import React from 'react';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import NvPopover from 'shared/components/nv-popover';
import { NvSearchBar } from 'shared/components/nv-search-bar';
import { quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { css } from '@emotion/react';
import { primary, gray2, gray5 } from 'styles/global_defaults/colors';
import { contentSearchContext, isSearchValid, useContentSearchActions } from './content-search-utils';

const searchBarContainerStyle = css`
  height: ${tripleSpacing}px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 ${standardSpacing}px;
  border-bottom: 1px solid ${gray5};

  .nv-search-bar {
    flex: 1;
    input {
      width: 100%;
      font-size: 18px;
      line-height: 25px;
      color: ${gray2};
      font-weight: 400;

      &::placeholder {
        font-weight: 300;
        font-size: 14px;
      }
    }
  }

  .popover-wrapper {
    width: 100%;
  }
`;

const iconButton = css`
  background: none;
  border: none;
  padding: 0;
`;

const cleanButtonStyle = css`
  ${iconButton};
  font-size: 12px;
  color: ${gray2};
  margin-left: ${quarterSpacing}px;
`;

const closeButtonStyle = css`
  ${iconButton};
  color: ${gray2};
  margin-left: ${standardSpacing}px;
  &:hover {
    color: ${primary};
  }
`;

/* @ngInject */
export default function ContentSearchBar() {
  const angularServices = React.useContext(AngularServicesContext);
  const inputRef = React.useRef();
  const [state] = React.useContext(contentSearchContext);
  const { onSearchContent, onSearchInputChange, onResetSearch } = useContentSearchActions();

  const isSearchInvalid = !isSearchValid(state.inputValue);

  return (
    <div css={searchBarContainerStyle}>
      <NvPopover
        show={isSearchInvalid && state.wasSubmitted}
        content={(
          <div>
            <div className='text-center color-alert'>{t.FORM.WARNING()}</div>
            <div
              css={css`
                margin-top: ${quarterSpacing}px;
              `}
            >
              {t.LHS.CONTENT_SEARCH.INVALID_SEARCH()}
            </div>
          </div>
        )}
        placement='bottom'
      >
        <NvSearchBar
          maxLength={255}
          autoFocus
          ref={inputRef}
          aria-label={t.LHS.CONTENT_SEARCH.SEARCH_CONTENT_INPUT_PLACEHOLDER()}
          onSearch={(val) => {
            onSearchContent(val);
          }}
          value={state.inputValue}
          onChange={(e) => {
            onSearchInputChange(e.target.value);
          }}
          title={t.LHS.CONTENT_SEARCH.SEARCH_CONTENT_INPUT_PLACEHOLDER()}
          placeholder={t.LHS.CONTENT_SEARCH.SEARCH_CONTENT_INPUT_PLACEHOLDER()}
          onSearchButtonClick={(val) => {
            onSearchContent(val);
          }}
          searchButtonEnabled
        />
      </NvPopover>
      {state.inputValue !== '' && (
        <button
          css={cleanButtonStyle}
          type='button'
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={(e) => {
            onResetSearch();
          }}
        >
          {t.LHS.CONTENT_SEARCH.CLEAR_INPUT()}
        </button>
      )}

      <button
        css={closeButtonStyle}
        type='button'
        onClick={() => {
          angularServices.FlyoutModalManager.closeFlyout();
        }}
        aria-label={t.NOVOED.CLOSE()}
      >
        <i className='icon icon-close icon-small' />
      </button>
    </div>
  );
}
