class TranslatedComment {
  constructor(
    item,
    catalogId,
    currentUserId,
    ReportsResources,
    onUpdateCallback,
  ) {
    this.originalComment = item;
    this.ReportsResources = ReportsResources;
    this.currentUserId = currentUserId;
    this.onUpdateCallback = onUpdateCallback;
    this.catalogId = catalogId;

    this.translateOptions = {
      isTranslationOn: false,
      isTranslationLoading: false,
      catalogId,
      body: item.comments,
      bodyTranslated: item.comments?.trim() ?? '',
      belongsToCurrentUser: this.belongsToCurrentUser.bind(this),
      onToggleTranslate: this.onToggleTranslate.bind(this),
    };
  }

  approveSubmissionTranslation(language) {
    return this.ReportsResources.translateApprovalComments(
      {
        id: this.originalComment.reportId,
        submission_review: this.originalComment.id,
        catalogId: this.catalogId,
      },
      {
        language,
      },
      (response) => response,
    ).$promise;
  }

  onToggleTranslate(isStateOn, language) {
    if (isStateOn) {
      this.translateOptions.isTranslationLoading = true;
      this.approveSubmissionTranslation(language)
        .then((response) => {
          this.translateOptions.isTranslationOn = true;
          this.translateOptions.bodyTranslated = response?.result?.comments?.trim() ?? '';
        })
        .finally(() => {
          this.translateOptions.isTranslationLoading = false;
          if (this.onUpdateCallback) {
            this.onUpdateCallback();
          }
        });
    } else {
      this.translateOptions.bodyTranslated = this.originalComment?.comments?.trim() ?? '';
      this.translateOptions.isTranslationOn = false;
      if (this.onUpdateCallback) {
        this.onUpdateCallback();
      }
    }
  }

  belongsToCurrentUser() {
    return this.originalComment?.reviewer?.id === this.currentUserId;
  }

  get() {
    return {
      ...this.originalComment,
      translateOptions: this.translateOptions,
    };
  }
}

export default {
  templateUrl: 'submissions/templates/nv-submission-approval-comments.html',
  bindings: {
    approvalComments: '<',
    showPrivateOnly: '<?',
    isOwner: '<?',
  },
  controller: function ctrl(
    _,
    $scope,
    $stateParams,
    CurrentUserManager,
    ReportsResources,
  ) {
'ngInject';
    const STATUS_CODES = {
      REJECTED: 3,
      APPROVED: 1,
    };

    this.STATUS_CODES = STATUS_CODES;

    this.toggleComments = () => {
      this.showAlldisplayableApprovalComments = !this.showAlldisplayableApprovalComments;
    };

    this.previousApprovalComments = null;

    this.refreshApprovalComments = () => {
      $scope.$applyAsync(() => {
        this.getApprovalComments();
        CurrentUserManager.setApprovalCommentTranslations(CurrentUserManager.cachedApprovalComments);
      });
    };

    this.getApprovalComments = () => {
      if (
        !CurrentUserManager.cachedApprovalComments
        || this.previousApprovalComments !== this.approvalComments
      ) {
        this.previousApprovalComments = this.approvalComments;
        CurrentUserManager.setApprovalCommentTranslations(_.map(
          this.approvalComments,
          (comment) => new TranslatedComment(
            comment,
            $stateParams.catalogId,
            CurrentUserManager.user?.id,
            ReportsResources,
            this.refreshApprovalComments,
          ).get(),
        ));
      }
      if (this.showPrivateOnly) {
        return CurrentUserManager.cachedApprovalComments.filter((c) => c.isPrivate);
      }
      return CurrentUserManager.cachedApprovalComments;
    };

    this.$onInit = function () {
      $scope.$watch(
        () => this.approvalComments,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            this.refreshApprovalComments();
          }
        },
      );
    };
  },
  controllerAs: 'vm',
};
