import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'react-bootstrap';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import t from 'react-translate';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { createtemplate } from 'redux/actions/course-templates';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { wrapThunkAction } from 'redux/utils';
import { CourseTemplate } from 'redux/schemas/models/course-templates';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { Template } from '../course-templates-wrapper';
import { config } from '../../../../../config/pendo.config.json';


type CourseStartTemplateProps = {
  handleCloseModal: () => void,
  handleSetActiveTab: (activeTab: Template) => void;
};

interface TemplateFormData {
  catalogId: string,
  courseName: string,
}

const CourseTemplateCreateForm = (props: CourseStartTemplateProps) => {
  const { handleCloseModal, handleSetActiveTab } = props;
  const dispatch = useDispatch();

  const selectedTemplateId = useSelector((state) => state.app.courseTemplates.selectedTemplateId);
  const courseTemplates = useSelector((state) => state.models.courseTemplates);

  const selectedTemplate = courseTemplates.find((ct: CourseTemplate) => ct.id === selectedTemplateId);

  const [catalogIdTaken, setCatalogIdTaken] = useState<boolean>(false);

  const onSubmit = (formFields: TemplateFormData) => {
    wrapThunkAction(dispatch(createtemplate({
      courseTemplateId: selectedTemplate.id,
      catalogId: formFields.catalogId,
      name: formFields.courseName,
    }))).then(() => {
      handleSetActiveTab(Template.CREATING_LOADER);
    }).catch((err) => {
      if (err.payload.code === 'error.catalog_id_taken') {
        setCatalogIdTaken(true);
      } else {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.SOMETHING_WRONG(),
          message: t.FORM.ERROR_TRY_AGAIN(),
        }));
        handleCloseModal();
      }
    });
  };

  const validationSchemaRef = yup.object().shape({
    courseName: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(250, t.VALIDATION.MAX('250')),
    catalogId: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(250, t.VALIDATION.MAX('250'))
      .novoEdCatalogId(t.COURSES.FORM.VALIDATION_TEXT.CATALOG_ID_PATTERN()),
  });

  const methods = useForm<TemplateFormData>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      courseName: selectedTemplate?.name || '',
      catalogId: '',
    },
    resolver: yupResolver(validationSchemaRef),
  });
  const { handleSubmit, formState: { isDirty, isValid } } = methods;

  const handleInputChange = () => {
    // to avoid unnecsessary setstates whene there is no error for input field
    if (catalogIdTaken) {
      setCatalogIdTaken(false);
    }
  };

  return (
    <React.Fragment>
      <NvModal
        show
        type={ModalType.DYNAMIC}
        width={600}
        fullHeight={false}
        header={t.COURSES.COURSE_TEMPLATES.CREATE_FROM_TEMPLATE()}
        onClose={handleCloseModal}
        closeDataQa={config.pendo.courseTemplates.closeTemplate}
        body={(
          <div className='mx-8 mb-3'>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label className='text-small gray-3 mb-0'>{t.COURSES.COURSE_TEMPLATES.COURSE_NAME()}</label>
                  <NvTextInput
                    withForm
                    required
                    name='courseName'
                    ariaLabel={t.COURSES.COURSE_TEMPLATES.COURSE_NAME()}
                    data-qa={config.pendo.courseTemplates.courseNameInput}
                    inputClassName='text-medium'
                    placeholder={t.COURSES.COURSE_TEMPLATES.TYPE_HERE()}
                  />
                </div>
                <div className='mt-4'>
                  <label className='text-small gray-3 mb-0'>{t.COURSES.COURSE_TEMPLATES.CATALOG_ID_UNIQUE()}</label>
                  <NvTextInput
                    withForm
                    required
                    name='catalogId'
                    ariaLabel={t.COURSES.COURSE_TEMPLATES.CATALOG_ID_UNIQUE()}
                    data-qa={config.pendo.courseTemplates.catalogIdInput}
                    inputClassName='text-medium'
                    placeholder={t.COURSES.COURSE_TEMPLATES.TYPE_HERE()}
                    onChange={handleInputChange}
                    error={catalogIdTaken ? { message: t.COURSES.FORM.ERRORS.CATALOG_ID_TAKEN() } as FieldError : undefined}
                  />
                </div>

                <div className='mt-8 d-flex justify-content-center'>
                  <Button
                    variant='secondary'
                    onClick={handleCloseModal}
                    className='mr-2 mt-2'
                    data-qa={config.pendo.courseTemplates.cancelForm}
                  >
                    {t.FORM.CANCEL()}
                  </Button>
                  <Button
                    variant='primary'
                    disabled={!isValid || !isDirty || catalogIdTaken}
                    className='mt-2'
                    type='submit'
                    data-qa={config.pendo.courseTemplates.createTemplate}
                  >
                    {t.FORM.CREATE()}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default CourseTemplateCreateForm;
