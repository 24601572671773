import {
  MentorshipProgramProfileQuestion,
  ProgramLevelQuestion,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import { getMentorshipProgramProfileQuestionsList } from 'redux/selectors/mentorship-program-profile-questions';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { useContext, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'redux/store';
import { useInView } from 'react-intersection-observer';
import { getMentorshipProgramProfileQuestions, ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile-questions';
import { config } from '@config/pendo.config.json';
import MentoringProgramContext from '../../context';
import { GetProgramProfileQuestionParams } from '../../program-configuration/program-profile-setup/types';
import SelectableQuestionRow from './selectable-question-row';
import { PAGE_SIZE } from '../../constants';
import { SelectableQuestionsProps } from './types';
import EmptyQuestions from './empty-questions';

const mapToSelectableQuestion = (
  question: MentorshipProgramProfileQuestion,
) => {
  const { id: questionId, profileQuestion, isAttachedToRule, attachedRules } = question;
  const { questionList } = (profileQuestion as ProgramLevelQuestion) || {};
  const { questionText } = questionList || {};
  return { questionId, questionText, isAttachedToRule, attachedRules };
};

const type = ProgramProfileQuestionType.PROGRAM_LEVEL;

const SelectableProgramLevelQuestions = ({
  modifiedQuestions,
  onChange,
}: SelectableQuestionsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const { ref: endRef, inView } = useInView();

  const params = useMemo(
    () => ({
      type,
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { result, loadMore } = usePaginatedApi<
  MentorshipProgramProfileQuestion,
  GetProgramProfileQuestionParams
  >(
    p => dispatch(
      getMentorshipProgramProfileQuestions({ mentorshipProgramId, ...p }),
    ).then(action => action.payload),
    params,
    getMentorshipProgramProfileQuestionsList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  const dataQATag = config.pendo.athena.mentorshipProgram.settings.matchingRules.flyoutModal.programLevelQuestionSwitch;

  const programLevelQuestions = result?.filter(
    ({ isRequired, rulesAllowed = true }) => isRequired && rulesAllowed,
  );

  return (
    <>
      {programLevelQuestions?.length ? (
        programLevelQuestions
          .map(mapToSelectableQuestion)
          .map(
            ({ questionId, questionText, isAttachedToRule, attachedRules }) => (
              <SelectableQuestionRow
                key={questionId}
                isSelected={modifiedQuestions[questionId]?.isChecked}
                questionId={questionId}
                questionText={questionText}
                onChange={onChange}
                isAttachedToRule={isAttachedToRule}
                attachedRules={attachedRules}
                dataQa={dataQATag}
                dataQaId={`${dataQATag}_${questionId}`}
              />
            ),
          )
      ) : (
        <EmptyQuestions type={type} />
      )}
      <div ref={endRef} />
    </>
  );
};

export default SelectableProgramLevelQuestions;
