/* eslint-disable react/require-default-props */
import React from 'react';
import { css } from '@emotion/react';

import { openSans } from 'styles/global_defaults/fonts';
import LectureHeaderInput from './lecture-header-input';

type Props = {
  name?: string,
  value?: string,
  defaultValue?: string,
  editable?: boolean,
  placeholder?: string,
  onChange?: (val: any) => void,
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => any,
  className?: string,
  required?: boolean,
  ariaLabel?: string,
};

/**
 * Displays some given text and then transforms into an editable textarea when
 * clicked if editable prop is `true`, otherwise it will just be a
 * presentational text used as a lecture component header title
 */
const LectureComponentHeaderInput = (props: Props) => {
  const styles = css`
    width: 100%;

    .bs4-form-control {
      font-size: 24px;
      font-weight: 300;
      line-height: 35px;
      font-family: ${openSans};
    }
  `;

  return (
    <div css={styles}>
      <LectureHeaderInput {...props} isFromLectureComponent />
    </div>
  );
};

export default LectureComponentHeaderInput;
