import { css } from '@emotion/react';
import { useEffect, useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import prodPathReplace from 'shared/prod-path-rewrite';
import pusherService from 'shared/services/pusher-service';
import { white } from 'styles/global_defaults/colors';
import { iconXssSmallestSize } from 'styles/global_defaults/icons';
import { doubleSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

type CourseTemplatePendingProps = {
  handleCloseModal: () => void,
};

const CourseTemplateCreatingLoader = (props: CourseTemplatePendingProps) => {
  const { handleCloseModal } = props;

  const currentInstitution = useSelector(getCurrentInstitution);
  const angularServices = useContext(AngularServicesContext);

  useEffect(() => {
    const pusherChannel = pusherService.setupChannel(`public-institution-${currentInstitution.id}`);
    pusherChannel.bind('course_created', (result) => {
      const catalogId = result.course.catalog_id;
      if (catalogId) {
        angularServices.$state.go('course-home', { catalogId });
      }
    });

    return () => {
      pusherChannel.unbind('course_created');
    };
  }, [angularServices.$state, currentInstitution.id]);

  const styles = css`
    .image-dots {
      height: 35px;
      margin-top: -15px;
    }

    .image-create-template {
      width: 350px;
      height: 250px;
    }

    .text-ai-color {
      background: linear-gradient(45deg, #FFB77C 0%, #E95F48 100%);
       background-size: auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent;
      animation: shine 3s linear infinite;
    }
  `;

  const modalStyles = css`
    &.bs4-modal {
      &.modal-fixed {
        .bs4-modal-dialog {
          .bs4-modal-content {
            border: 0px;

            .bs4-modal-header {
              background: ${white};
              min-height: ${doubleSpacing}px;
              height: ${doubleSpacing}px;

              .bs4-modal-title {
                height: ${doubleSpacing}px;
              }

              .close {
                padding: 0px;
                margin: 0px;
                top: ${standardSpacing}px;

                .icon-close {
                  font-size: ${iconXssSmallestSize}px;
                }
              }
            }

            .bs4-modal-body {
              padding: 0px ${standardSpacing}px ${standardSpacing}px;
              background: ${white};
            }
          }
        }
      }
    }
  `;

  return (
    <NvModal
      show
      onClose={handleCloseModal}
      type={ModalType.FIXED}
      width={440}
      height={480}
      header=''
      modalStyles={modalStyles}
      body={(
        <div css={styles}>
          <p className='d-flex font-weight-light text-xl justify-content-center mb-0'>{t.COURSES.COURSE_TEMPLATES.JUST_A_MOMENT()}</p>
          <img
            className='d-flex image-dots mx-md-auto'
            src={prodPathReplace('images/dots.svg')}
            alt={t.COURSES.COURSE_TEMPLATES.CREATING_COURSE()}
          />
          <p className='font-weight-normal text-large-regular text-align-center mb-4'>{t.COURSES.COURSE_TEMPLATES.CREATING_TEMPLATE()}</p>
          <img
            className='d-flex image-create-template mx-md-auto mb-4'
            src={prodPathReplace('images/create.gif')}
            alt={t.COURSES.COURSE_TEMPLATES.CREATING_COURSE()}
          />
          <span className='d-flex justify-content-center font-weight-bolder text-large-regular mb-0 text-ai-color'>
            {t.FORM.CREATING_LOADING_TEXT()}
          </span>
        </div>
      )}
    />
  );
};

export default CourseTemplateCreatingLoader;
