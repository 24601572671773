import { css, jsx, ClassNames } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import CollapsibleRowMenu, {
  CollapsibleRowMenuButton,
  CollapsibleRowMenuDivider,
} from 'components/collapsible-row-menu';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { gray3, gray5, primary } from 'styles/global_defaults/colors';
import { largeSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { PollOptionNormalized } from 'redux/schemas/models/poll';
import { useAppDispatch } from 'redux/store';
import { updatePollOption } from 'redux/actions/polls';
import { openSans } from 'styles/global_defaults/fonts';
import { config } from '../../../../config/pendo.config.json';

type ButtonProps = React.ComponentProps<'button'>;

interface PollQuestionProps {
  canMoveDown: boolean;
  onMoveDownClick: ButtonProps['onClick'];
  canMoveUp: boolean;
  onMoveUpClick: ButtonProps['onClick'];
  index: number;
  canDelete: boolean;
  onDeleteClick: ButtonProps['onClick'];
  option: PollOptionNormalized;
  catalogId: string;
  indexChange?: {
    currentIndex: number;
    previousIndex: number;
  };
}

/* @ngInject */
export default function EditPollOption(props: PollQuestionProps) {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState(props.option.optionContent);
  const containerRef = React.useRef<HTMLDivElement>();
  const [reorderTransitionState, setReorderTransitionState] = React.useState<'none' | 'started' | 'done'>('none');

  React.useLayoutEffect(() => {
    if (props.indexChange) {
      // state before animating
      setReorderTransitionState('none');

      // run the animation
      setTimeout(() => {
        setReorderTransitionState('started');
      }, 0);

      // remove the animation and keep the final style state
      containerRef.current.addEventListener(
        'transitionend',
        () => {
          setReorderTransitionState('done');
        },
        { once: true },
      );
    }
  }, [props.indexChange]);

  let reOrderDirectionClass = '';
  if (props.indexChange) {
    reOrderDirectionClass = props.indexChange.currentIndex > props.indexChange.previousIndex ? 'moving-down' : 'moving-up';
  }

  return (
    <div css={containerStyle} ref={containerRef} className={`${reOrderDirectionClass} transition-${reorderTransitionState}`}>
      <ClassNames>
        {({ css: innerCss }) => {
          const textAreaStyleClassName = innerCss`${textAreaStyle}`;

          return (
            <NvTextArea
              value={value ?? ''}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onBlur={() => {
                if (value !== props.option.optionContent) {
                  dispatch(
                    updatePollOption({
                      optionContent: value,
                      catalogId: props.catalogId,
                      responseOptionId: props.option.id,
                    }),
                  );
                }
              }}
              className={textAreaStyleClassName}
              textareaClassName={textAreaStyleClassName}
              placeholder={t.LECTURE_PAGES.COMPONENTS.POLLS.OPTION_INPUT_PLACEHOLDER(String(props.index + 1))}
              data-qa={`${config.pendo.polls.answer}${props.index}`}
            />
          );
        }}
      </ClassNames>
      <div className='radio-input-circle' />
      <CollapsibleRowMenu css={rowMenuStyle}>
        {props.canDelete && (
          <React.Fragment>
            <CollapsibleRowMenuButton icon='trash' onClick={props.onDeleteClick} />
            <CollapsibleRowMenuDivider />
          </React.Fragment>
        )}
        <CollapsibleRowMenuButton disabled={!props.canMoveUp} icon='move-up' onClick={props.onMoveUpClick} />
        <CollapsibleRowMenuButton disabled={!props.canMoveDown} icon='move-down' onClick={props.onMoveDownClick} />
      </CollapsibleRowMenu>
    </div>
  );
}

const containerStyle = css`
  position: relative;

  .radio-input-circle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: ${standardSpacing}px;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 2px solid ${gray3};
  }

  &.moving-up:not(.transition-done) {
    transform: translateY(5px);
  }

  &.moving-down:not(.transition-done) {
    transform: translateY(-5px);
  }

  &.transition-started {
    transition: transform 500ms;
    transform: translateY(0) !important;
  }
`;

const textAreaStyle = css`
  font-family: ${openSans};
  border: 0;
  flex: 1;
  font-size: 16px;
  line-height: ${largeSpacing}px;
  min-height: ${largeSpacing}px;
  background-color: transparent;

  textarea {
    border-radius: ${quarterSpacing}px;
    /* it's like outline but with border-radius support */
    box-shadow: 0 0 0 1px ${gray5};

    /* margin of the row menu */
    padding-right: ${largeSpacing}px;
    padding-left: 55px;
  }

  textarea:focus {
    background-color: transparent;
    box-shadow: 0 0 0 1px ${primary};
  }
`;

const rowMenuStyle = css`
  position: absolute;

  top: 0;
  bottom: 0;
  right: 0;

  // z-index lower than a dropdown
  z-index: 999;

  /* same border-radius as the text area */
  border-radius: 0 ${quarterSpacing}px ${quarterSpacing}px 0;
`;
