import { css, jsx } from '@emotion/react';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import React from 'react';
import t from 'react-translate';
import { LectureComponent, ComponentType } from 'redux/schemas/models/lecture-component';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import NvPopover from 'shared/components/nv-popover';
import { useUpdatePoll } from './poll-utils';

const MAX_LENGTH = 255;
interface PollTitleProps {
  lectureComponent: LectureComponent<ComponentType.POLL>;
}
/* @ngInject */
export default function PollTitle(props: PollTitleProps) {
  const [title, setTitle] = React.useState(props.lectureComponent.poll.title);
  const { isEdit } = useLectureComponentContext();
  const pollUpdater = useUpdatePoll();

  return (
    <NvPopover
      className='align-self-stretch'
      content={<ValidationErrorMessage text={t.VALIDATION.MAX_LENGTH(MAX_LENGTH.toString())} />}
      flip
      placement='top'
      showOnHover={false}
      show={title.length > MAX_LENGTH}
    >
      <div className='title-input-wrapper'>
        <LectureComponentHeaderInput
          value={title}
          editable={isEdit}
          onBlur={() => {
            if (title.length <= MAX_LENGTH) {
              pollUpdater({
                title,
              });
            }
          }}
          onChange={(e) => {
            setTitle(e);
          }}
          required
          ariaLabel={t.LECTURE_PAGES.COMPONENTS.POLLS.TITLE_ARIA_LABEL(title)}
        />
      </div>
    </NvPopover>
  );
}
