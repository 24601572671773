import React from 'react';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { getFlyoutMyMentees } from 'redux/actions/courses';
import { BasicMentee } from 'redux/schemas/models/course';
import { getCurrentUserId } from 'redux/selectors/users';
import { LoaderType } from 'shared/components/loading-placeholder';
import { useInfiniteLoading } from 'shared/hooks/use-infinite-loading';
import FlyoutContainer from './flyout-container';
import FlyoutCourseCard from './flyout-course-card';
import { AllLoaded, EmptyContent, LoadingBar } from './loading-placeholders';

export const CourseMenteesPanel = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state, InteroperabilityRoutes] = injectServices([
    '$state',
    'InteroperabilityRoutes',
  ]);
  const { currentCatalogId, currentInstitutionId } = useSelector(state => state.app);
  const institutionBrandColor = useSelector(
    state => state.models.institutions[currentInstitutionId]?.brandColor,
  );
  const allCourses = useSelector(state => state.models.courses);
  const currentUserId = useSelector(getCurrentUserId);
  const {
    items,
    noItems,
    loading,
    loadingMore,
    hasMore,
    ids,
  } = useInfiniteLoading(
    'myMentees',
    'basicMentees',
    { page_size: 30 },
    getFlyoutMyMentees,
  );
  const myMentees = items as BasicMentee[];

  return (
    <FlyoutContainer title={t.LHS.MY_MENTEES()} isHeaderSticky>
      <>
        {loading && <LoadingBar loaderType={LoaderType.FULL} />}
        {loadingMore && <LoadingBar loaderType={LoaderType.HALF} />}
        {!loading && noItems ? (
          <EmptyContent text={t.LHS.NO_MENTEES()} />
        ) : (
          ids.map(id => (
            <FlyoutCourseCard
              index={id}
              variant='secondary'
              tooltip={myMentees[id].catalogId}
              badgeLabel={`${myMentees[id].totalNoOfMentees} ${
                myMentees[id].totalNoOfMentees === 1
                  ? myMentees[id]?.menteeAlias?.singularizedTitleized
                  : myMentees[id]?.menteeAlias?.pluralizedTitleized
              }`}
              label={myMentees[id].name}
              link={InteroperabilityRoutes.myMentees(
                allCourses[myMentees[id].catalogId],
                currentUserId,
              )}
              isCurrentCourse={
                currentCatalogId === myMentees[id].catalogId && $state.includes('course-wrapper')
              }
              key={id}
              image={myMentees[id].thumbnail}
              bgcolor={institutionBrandColor}
            />
          ))
        )}
        {!hasMore && !noItems && <AllLoaded />}
      </>
    </FlyoutContainer>
  );
};

export default CourseMenteesPanel;
