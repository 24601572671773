import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { OverlayTrigger } from 'react-bootstrap';
import ClickableContainer from 'components/clickable-container';
import { css } from '@emotion/react';
import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { almostBlack } from 'athena/styles/colors';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';
import { mergeRefs } from 'shared/react-utils';
import { white } from 'styles/global_defaults/colors';
import { omit } from 'lodash';
import { getAutoMatchingStatus } from 'redux/selectors/mentoring-programs';
import { AutoMatchingStatus } from 'redux/schemas/app/mentoring-programs';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'athena/components/constants';
import { ReactComponent as Spinner } from 'styles/icons/progress-activity.svg';
import { config } from '@config/pendo.config.json';
import MentoringProgramContext from '../../mentoring-program/context';
import AccessibleOverlay from './accessible-overlay';
import AutoMatchingModal from './auto-matching-modal';

const menuOptionStyles = (isAutoMatchingEnabled: boolean) => css`
  z-index: 1000;
  color: ${almostBlack};
  .icon-wrapper {
    border: 1px solid ${almostBlack};
    border-radius: ${quarterSpacing}px;

    ${isAutoMatchingEnabled
      && css`
        color: ${white};
        background-color: ${almostBlack};
      `};

    path {
      fill: ${white};
    }
  }
`;

const AutoMatchingMenu = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const buttonRef = useRef<HTMLDivElement>();

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const {
    id: mentoringProgramId,
    autoMatchingEnabled: isAutoMatchingEnabled,
    autoMatchingLastUpdated,
    matchAnnouncementDate,
  } = mentoringProgram || {};
  const status = useSelector(getAutoMatchingStatus);
  const isGenerationInProgress = status === AutoMatchingStatus.STARTED
    || status === AutoMatchingStatus.PROCESSING;

  const getFormattedDateAndTime = (date: Date) => {
    const lastUpdated = moment(date);
    const lastUpdatedDate = lastUpdated.format(DATE_FORMAT);
    const lastUpdatedTime = lastUpdated.format(TIME_FORMAT);
    return { lastUpdatedDate, lastUpdatedTime };
  };

  const getMenuSettings = () => {
    let buttonLabel = '';
    let icon = isAutoMatchingEnabled
      ? 'auto-connection'
      : 'auto-connection-off';
    if (autoMatchingLastUpdated || status === AutoMatchingStatus.COMPLETED) {
      const { lastUpdatedDate, lastUpdatedTime } = getFormattedDateAndTime(autoMatchingLastUpdated || new Date());
      buttonLabel = t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.AUTO_MATCHING.BUTTON.COMPLETED(
        lastUpdatedDate,
        lastUpdatedTime,
      );
    }
    if (isGenerationInProgress) {
      buttonLabel = t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.AUTO_MATCHING.BUTTON.IN_PROGRESS();
      icon = null;
    }
    if (!buttonLabel || !isAutoMatchingEnabled) {
      const isMatchDateFuture = moment(matchAnnouncementDate).isAfter(moment());
      if (matchAnnouncementDate && isMatchDateFuture) {
        buttonLabel = t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.AUTO_MATCHING.BUTTON.DEFAULT_FUTURE(
          moment(matchAnnouncementDate).format('MMM D'),
        );
      } else {
        buttonLabel = t.MENTORING_PROGRAMS.PROGRAM_HEADER_MENU.AUTO_MATCHING.BUTTON.DEFAULT(
          `${isAutoMatchingEnabled}`,
        );
      }
    }
    return { buttonLabel, icon };
  };

  const { buttonLabel, icon } = getMenuSettings();

  return (
    <OverlayTrigger
      show={showOverlay}
      placement='bottom-end'
      transition={false}
      overlay={props => (
        <AccessibleOverlay
          onClose={closedWithKeyboard => {
            setShowOverlay(false);
            if (closedWithKeyboard) {
              buttonRef.current.focus();
            }
          }}
          {...(omit(props, ['popper', 'arrowProps', 'data-popper-reference-hidden']) as any)}
        >
          <AutoMatchingModal />
        </AccessibleOverlay>
      )}
    >
      {({ ref, ...props }) => (
        <div
          className='d-flex align-items-center'
          css={menuOptionStyles(isAutoMatchingEnabled)}
        >
          <div className='text-small pr-2'>{buttonLabel}</div>
          <ClickableContainer
            ref={mergeRefs(ref, buttonRef)}
            onClick={() => setShowOverlay(true)}
            aria-label={buttonLabel}
            aria-haspopup
            aria-expanded={showOverlay}
            disabled={isGenerationInProgress}
            data-qa={config.pendo.athena.mentorshipProgram.connections.autoMatching}
            data-qa-id={`${config.pendo.athena.mentorshipProgram.connections.autoMatching}_mentorshipProgram_${mentoringProgramId}`}
            {...props}
          >
            <div className='icon-wrapper p-1'>
              {icon ? (
                <NvIcon className='d-flex' icon={icon} size='smallest' />
              ) : (
                <Spinner width={16} height={16} className='spin' />
              )}
            </div>
          </ClickableContainer>
        </div>
      )}
    </OverlayTrigger>
  );
};

export default AutoMatchingMenu;
