import axios from 'axios';
import * as humps from 'humps';
import angular from 'angular';
import setupMocks from '../mocks/index';
import { shouldChangeCase } from '../services/change-case-interceptor';

export const HTTPS_STATUS_CODES = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
};

// eslint-disable-next-line import/prefer-default-export
export const configAxios = () => {
// TODO: We should move this axios setup logic to a different file
// TODO: For reviewer: If I don't do this, the PUT for saving the lecture component fails on a 500 error.
// We also do a similar default header set with angularjs elsewhere in this file
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  // Register request interceptors for Axios
  axios.interceptors.request.use((config) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    // humps operations destory FormData, so don't touch them if the paylod is a FormData
    if (shouldChangeCase(config.url) && !(config.data instanceof FormData)) {
      config.data = humps.decamelizeKeys(config.data);
    }

    return config;
  }, (error) => Promise.reject(error));

  axios.interceptors.response.use((response) => {
  // Avoid camelizing our language .json files
    if (shouldChangeCase(response.config.url)) {
      response.data = humps.camelizeKeys(response.data);
    }
    return response;
  }, (error) => {
    // Handling Unauthorised case
    if (error?.response?.status === HTTPS_STATUS_CODES.UNAUTHORIZED) {
      const injector = angular.element(document.body).injector();
      const $rootScope = injector.get('$rootScope');

      // clearing any pending state or watchers in Angular
      if ($rootScope) {
        $rootScope.$destroy();
      }

      // redirecting to sign_in page when user is unauthorised
      window.location.replace('/users/sign_in');
    }
    return Promise.reject(error);
  });
  // run mocks only on dev with mocks flag set
  if (process.env.NODE_ENV === 'development' && process.env.USE_MOCKS === 'true') {
    setupMocks();
  }
};
