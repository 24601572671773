import t from 'react-translate';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { extend, find, isEmpty, isEqual, map, size } from 'underscore';
import Xarrow, { Xwrapper } from 'react-xarrows';

import { MatchingQuestionResponse, ResponseOption } from 'redux/schemas/models/progressive-quiz';

import { largeSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { danger, hexToRgbaString, primary, success } from 'styles/global_defaults/colors';

import ProgressiveQuizContext, { MatchingAnswerState, QuestionContext } from 'quizzes/components/context';
import useMatchingQuestionAnswer, { getOpionId, MatchingQuestionAnswerProvider } from 'quizzes/components/hooks/use-matching-question-answer';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import MatchingQuestionAnswerResponseOption from './matching-question-answer-response-option';

const MatchingQuestionAnswerSection = () => {
  const [selectedOption, setSelectedOption] = useState<ResponseOption>(null);
  const [hoveredId, setHoveredId] = useState<number>(null);
  const [revealCorrectAnswers, setRevealCorrectAnswers] = useState<boolean>(false);
  const [correctAnswerPairs, setCorrectAnswerPairs] = useState<MatchingAnswerState['pairs']>([]);

  const {
    answerState,
    setAnswerState,
    responseOptions,
  } = useContext(QuestionContext);

  const { reveal } = useContext(ProgressiveQuizContext);

  const {
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const {
    pairs,
    isSubmittedMode,
    checkOptionInPairs,
    getPairedOptionsId,
  } = useMatchingQuestionAnswer();

  const { pairs: answerStatePairs = [] } = (answerState as MatchingAnswerState) || {};

  const questions = useMemo(() => (responseOptions
    .filter((option) => option.parent)
    .sort((a, b) => a.index - b.index)
  ), [responseOptions]);
  const options = useMemo(() => (responseOptions
    .filter((option) => !option.parent)
    .sort((a, b) => a.index - b.index)
  ), [responseOptions]);

  /**
   * Each pair contains two response options. Therefore, the required number of
   * pairs can be determined by dividing the total number of response options by two.
   */
  const requiredPairsCount = useMemo(
    () => Math.round(responseOptions?.length / 2),
    [responseOptions?.length],
  );

  const canShowCorrectAnswers = useCallback(() => {
    if (isReviewMode && reveal && hoveredId) {
      const hoverOption = find(responseOptions, (option) => option.id === hoveredId);
      return !hoverOption?.isCorrect;
    }

    return false;
  }, [hoveredId, isReviewMode, responseOptions, reveal]);

  useEffect(() => {
    setRevealCorrectAnswers(canShowCorrectAnswers());
  }, [canShowCorrectAnswers]);

  useEffect(() => {
    if (
      !isEmpty(answerStatePairs)
      && requiredPairsCount > 1
      && size(answerStatePairs) === requiredPairsCount
    ) {
      const orderedPairs = [];
      const transformedPairs = extend({}, ...answerStatePairs);

      questions.forEach((question) => {
        if (transformedPairs[question.id]) {
          orderedPairs.push({ [question.id]: getPairedOptionsId(question.id) });
        }
      });

      /**
       * Setting the answerState pairs' order to align with the response options
       * order if they are not already aligned.
       */
      if (
        !isEqual(answerStatePairs, orderedPairs)
        && orderedPairs.length === requiredPairsCount
      ) {
        setAnswerState({
          pairs: orderedPairs,
        });
      }
    }
  }, [answerState, answerStatePairs, getPairedOptionsId, questions, requiredPairsCount, setAnswerState]);

  const getCorrectPairs = useCallback(() => {
    const newPairs = [];
    if (responseOptions.length > 0) {
      responseOptions.forEach((option) => {
        if (option.associationId) {
          newPairs.push({ [option.associationId]: [option.id] });
        }
      });
    }

    return newPairs;
  }, [responseOptions]);

  useEffect(() => {
    if (revealCorrectAnswers) {
      setCorrectAnswerPairs(getCorrectPairs);
    }
  }, [getCorrectPairs, revealCorrectAnswers]);

  const getPairs = useCallback((): MatchingQuestionResponse => {
    if (revealCorrectAnswers) {
      return correctAnswerPairs;
    }

    return pairs;
  }, [correctAnswerPairs, pairs, revealCorrectAnswers]);

  const styles = css`
    gap: ${largeSpacing}px;

    .pair-container {
      gap: ${standardSpacing}px;
    }
  `;

  const getArrowColor = (questionId: number) => {
    if (revealCorrectAnswers) {
      return success;
    }
    if (isReviewMode || isSubmittedMode) {
      const keyOption = find(responseOptions, (option) => option.id === questionId);
      return keyOption.isCorrect ? success : danger;
    }

    // Using blured color for arrows
    // When selected an option
    if (!isEmpty(selectedOption) || (
      // When hovering a pair
      (hoveredId && checkOptionInPairs(hoveredId))
      && ![questionId, ...extend({}, ...pairs)[questionId]].includes(hoveredId)
    )) {
      return hexToRgbaString(primary, 0.2);
    }

    return primary;
  };

  const canUseArrowDashes = (key: number) => {
    if (revealCorrectAnswers) {
      return false;
    }

    if (isReviewMode || isSubmittedMode) {
      const keyOption = find(responseOptions, (option) => option.id === key);
      return !keyOption.isCorrect;
    }

    return false;
  };

  return (
    <MatchingQuestionAnswerProvider
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      hoveredId={hoveredId}
      setHoveredId={setHoveredId}
      questions={questions}
      revealCorrectAnswers={revealCorrectAnswers}
      correctAnswerPairs={correctAnswerPairs}
    >
      <Xwrapper>
        <div css={styles} className='d-flex flex-column'>
          {!isReviewMode && (
            <p className='text-small text-center text-gray-1 font-weight-bold'>
              {t.QUIZZES.MATCHING_QUESTION.ANSWER_HEADER()}
            </p>
          )}
          <div className='pair-container d-flex flex-column mb-4' dir='ltr'>
            {Array.from(questions).map((question, index) => (
              !isEmpty(question) && !isEmpty(options?.[index]) && (
                <div
                  key={question.id}
                  css={styles}
                  className='pair-row d-flex align-items-stretch justify-content-between'
                >
                  <MatchingQuestionAnswerResponseOption
                    responseOption={question}
                  />
                  <MatchingQuestionAnswerResponseOption
                    responseOption={options?.[index]}
                  />
                </div>
              )))}
          </div>
          {map(extend({}, ...getPairs()), (optionIds, qId) => (
            optionIds.map((oId) => (
              <Xarrow
                key={qId}
                start={getOpionId(+qId)} // Left start option id
                end={getOpionId(oId)} // Right end option id
                startAnchor='right'
                endAnchor='left'
                strokeWidth={2}
                color={getArrowColor(+qId)}
                showHead={false} // using the end dots separatly in each option
                showTail={false}
                path='straight'
                dashness={canUseArrowDashes(+qId)}
              />
            ))
          ))}
        </div>
      </Xwrapper>
    </MatchingQuestionAnswerProvider>
  );
};

export default MatchingQuestionAnswerSection;
