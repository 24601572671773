import React from 'react';
import { css } from '@emotion/react';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { white } from 'styles/global_defaults/colors';

/**
 * Component to render a role badge
 */
type RoleBadgeProps = {
  initial: string;
  color: string;
  isLast?: boolean;
};

const roleBadgeStyles = css`
  width: ${standardSpacing}px;
  height: ${standardSpacing}px;
  border-radius: 50%;
  color: ${white};
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RoleBadge: React.FC<RoleBadgeProps> = ({
  initial,
  color,
  isLast,
}) => {
  const styles = css`
    ${roleBadgeStyles};
    margin-right: ${!isLast ? '-5px' : '0'};
    background-color: ${color};
  `;

  return (
    <div css={styles}><span className='text-small bold'>{initial}</span></div>
  );
};

/**
 * Container to render one or multiple role badges
 */
export type BadgesProps = {
  badges: Array<RoleBadgeProps>;
};

const badgesStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Badges: React.FC<BadgesProps> = ({
  badges,
}) => (
  <div css={badgesStyles}>
    {badges.map((badge, index) => (
      <RoleBadge
        key={badge.initial}
        isLast={index === (badges.length - 1)}
        {...badge}
      />
    ))}
  </div>
);

export default Badges;
