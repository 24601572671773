import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { gray6 } from 'styles/global_defaults/colors';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import t from 'react-translate';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import { getSettingsSaveStatus } from 'redux/selectors/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '@config/pendo.config.json';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';
import NvPopover from 'shared/components/nv-popover';
import { data } from 'jquery';
import { setSaveStatus, updateMentoringProgram } from 'redux/actions/mentoring-programs';
import { wrapThunkAction } from 'redux/utils';
import { useAppDispatch } from 'redux/store';
import MentoringProgramContext from '../../context';

const INPUT_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

const overlayStyles = css`
  width: 250px;
  .confirmation {
    width: 100% !important;
    max-width: 100% !important;
    .button-container {
      justify-content: center !important;
    }
  }
`;

const datePickerStyles = css`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const programDateStyles = css`
  .program-date-container {
    &:first-of-type {
      border-top: 1px solid ${gray6};
    }
    border-bottom: 1px solid ${gray6};
  }
`;

type ProgramDateType = {
  name: string;
  title: string;
  description: string;
  placeholder: string;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  required?: boolean;
  disabled?: boolean;
  tooltip?: string;
  onSubmit?: () => void;
  showPopoverOnError?: boolean;
  dataQA?: string;
};

const ProgramDate = ({
  name,
  title,
  description,
  placeholder,
  minDate,
  maxDate,
  required = false,
  disabled = false,
  tooltip,
  onSubmit,
  showPopoverOnError = false,
  dataQA,
}: ProgramDateType) => {
  const { setValue, formState } = useFormContext();
  const [dateValue, setDateValue] = useState(null);
  const saveStatus = useSelector(getSettingsSaveStatus);
  const [showPopover, setShowPopover] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const datePickerRef = useRef(null);

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id, autoMatchingEnabled, matchAnnouncementDate } = mentoringProgram || {};

  const matchAnnouncementDateValue = matchAnnouncementDate
    ? moment(matchAnnouncementDate).format(INPUT_DATE_FORMAT)
    : '';

  const dispatch = useAppDispatch();

  const hasError = formState.errors[name];

  const handleChange = (date) => {
    setTimeout(() => {
      setDateValue(date);
    });
  };

  const handleConfirm = () => {
    setShowPopover(false);
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    wrapThunkAction(dispatch(updateMentoringProgram({
      id,
      autoMatchingEnabled,
      matchAnnouncementDate: '',
      [name]: dateValue.format(INPUT_DATE_FORMAT),
    }))).then(() => {
      dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
    }).finally(() => {
      setTimeout(() => {
        dispatch(setSaveStatus({ newStatus: null }));
      }, 3000);
    });
  };

  const handlePopoverCancel = () => {
    datePickerRef.current?.setFocus();
    setTimeout(() => setShowPopover(false));
  };

  useEffect(() => {
    setDisplayError(showPopoverOnError && hasError && showPopover);
  }, [hasError, showPopover, showPopoverOnError]);

  useEffect(() => {
    if (saveStatus !== SaveStatus.IN_PROGRESS) {
      if (dateValue) {
        dateValue.toString = () => dateValue.format(INPUT_DATE_FORMAT);
        if (hasError) setShowPopover(true);
      }
      setValue(name, dateValue);
      onSubmit();
    }
  }, [dateValue]);

  return (
    <div className='d-flex col pb-4 pt-4 program-date-container'>
      <div className='w-50 col'>
        <div className='d-flex w-100 text-regular semi-bold gray-2 pb-1'>
          {title}
        </div>
        <div className='d-flex w-100 description'>{description}</div>
      </div>
      <div className='d-flex w-50 align-items-center pl-4'>
        <NvTooltip text={tooltip} enabled={!!tooltip}>
          <div className='w-100'>
            {showPopoverOnError ? (
              <NvPopover
                enabled
                show={displayError}
                overlayStyles={overlayStyles}
                content={(
                  <NvConfirmationPopover
                    onCancel={handlePopoverCancel}
                    onConfirm={handleConfirm}
                    header={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.ERROR_POPOVER.TITLE()}
                    message={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.ERROR_POPOVER.DESCRIPTION(matchAnnouncementDateValue)}
                    cancelBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.ERROR_POPOVER.CANCEL()}
                    confirmBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.ERROR_POPOVER.CONFIRM()}
                  />
                )}
                placement='top'
                onHide={() => setShowPopover(false)}
                className='d-inline-flex w-100'
                preventOverflow
              >
                <NvDatePicker
                  withForm
                  min={minDate}
                  max={maxDate}
                  name={name}
                  className='w-100'
                  css={datePickerStyles}
                  type={DatePickerType.DATETIME}
                  placeholder={placeholder}
                  required={required}
                  disabled={disabled}
                  onChange={handleChange}
                  dataQa={dataQA}
                  highlightDates={[moment().startOf('day')]}
                  placement='left'
                  onFocus={() => setShowPopover(true)}
                  ref={datePickerRef}
                />
              </NvPopover>
            ) : (
              <NvDatePicker
                withForm
                min={minDate}
                max={maxDate}
                name={name}
                className='w-100'
                css={datePickerStyles}
                type={DatePickerType.DATETIME}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                onChange={handleChange}
                dataQa={dataQA}
                highlightDates={[moment().startOf('day')]}
                placement='left'
              />
            )}
          </div>
        </NvTooltip>
      </div>
    </div>
  );
};

const ProgramDates = ({ onSubmit }) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { releasedDate, endDate, matchAnnouncementDate } = mentoringProgram || {};
  const dataQATag = config.pendo.athena.mentorshipProgram.settings.programSetup;

  return (
    <div css={programDateStyles} className='mb-6'>
      <div className='heading-5 mb-2'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.TITLE()}
      </div>
      <div className='program-dates-container'>
        <ProgramDate
          key='releasedDate'
          onSubmit={onSubmit}
          name='releasedDate'
          title={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.RELEASE_DATE.TITLE()}
          description={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.RELEASE_DATE.DESCRIPTION()}
          placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.RELEASE_DATE.PLACEHOLDER()}
          maxDate={moment(endDate)}
          showPopoverOnError
          dataQA={dataQATag.programDateTimePickerRelease}
        />
        <ProgramDate
          key='endDate'
          onSubmit={onSubmit}
          name='endDate'
          title={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.TITLE()}
          description={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.DESCRIPTION()}
          placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.PLACEHOLDER()}
          minDate={matchAnnouncementDate ? moment(matchAnnouncementDate) : moment(releasedDate)}
          disabled={!releasedDate}
          tooltip={!releasedDate ? t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.TOOLTIP() : ''}
          dataQA={dataQATag.programDateTimePickerClosure}
        />
      </div>
    </div>
  );
};

export default ProgramDates;
