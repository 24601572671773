import React from 'react';
import omit from 'lodash/omit';
import { css } from '@emotion/react';
import { Controller, ControllerRenderProps } from 'react-hook-form';

import useTextMeasurer from 'shared/hooks/use-text-measurer';
import { gray4, purple, primary, warning } from 'styles/global_defaults/colors';
import {
  openSans,
  boldFontWeight,
  textSmallFontSize,
  textSmallLineHeight,
} from 'styles/global_defaults/fonts';
import ClickableContainer, {
  ClickableContainerProps,
} from 'components/clickable-container';
import {
  halfSpacing,
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import NvIcon from '../nv-icon';

const BORDER = 1;
const HEIGHT = standardSpacing;
const LABEL_PADDING = quarterSpacing;
const SWITCH_MIN_WIDTH = doubleSpacing;
// Subtracting border top and bottom
const TOGGLE_SIZE = HEIGHT - (BORDER * 2);

export type NvSwitchRef = {
  toggle: () => void,
  element: HTMLDivElement,
};

type ControlledNvSwitchProps = Omit<ClickableContainerProps, 'onChange' | 'onClick'> & {
  // Value that controlls the switch component.
  checked?: boolean,
  // "aria-label" attribute value of the most parent element.
  ariaLabel?: string,
  // Text/icon for enabled state in the switch.
  enabledLabel?: string,
  // Icon to display when the switch is on.
  enabledIcon?: string,
  // Text for disabled state in the switch.
  disabledLabel?: string,
  // Change event handler.
  onChange?: (checked: boolean) => void,
  // Click event handler.
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, newChecked: boolean) => void,
  // Data QA tag
  dataQa?: string,
  // Data QA ID tag
  dataQaId?: string,
};

const ControlledNvSwitch = React.forwardRef<NvSwitchRef, ControlledNvSwitchProps>((props, ref) => {
  const {
    onBlur,
    onClick,
    disabled,
    onChange,
    ariaLabel,
    className,
    onKeyDown,
    enabledLabel = '',
    enabledIcon = '',
    disabledLabel = '',
    checked: propsChecked,
    dataQa,
    dataQaId,
    ...rest
  } = props;

  const onChangeRef = React.useRef<Function>();
  onChangeRef.current = onChange;
  const elementRef = React.useRef<HTMLDivElement>();
  const isControllingComponent = propsChecked !== undefined;
  const [checked, setChecked] = React.useState(!!propsChecked);

  const measureText = useTextMeasurer({
    fontFamily: openSans,
    fontWeight: boldFontWeight,
    fontSize: textSmallFontSize,
    lineHeight: textSmallLineHeight,
  });

  React.useEffect(() => {
    setChecked(propsChecked);
  }, [propsChecked]);

  const {
    enabledLabelWidth,
    disabledLabelWidth,
  } = React.useMemo(() => ({
    enabledLabelWidth: measureText(enabledLabel) + (LABEL_PADDING * 2),
    disabledLabelWidth: measureText(disabledLabel) + (LABEL_PADDING * 2),
  }), [enabledLabel, disabledLabel, measureText]);

  const maxLabelTextWidth = Math.max(enabledLabelWidth, disabledLabelWidth);
  const additionalSpaceSize = Math.max(BORDER * 2, maxLabelTextWidth);
  const width = Math.max(SWITCH_MIN_WIDTH, TOGGLE_SIZE + additionalSpaceSize);

  const toggleValue = () => {
    const newValue = !checked;

    onChange?.(newValue);

    if (!isControllingComponent) {
      setChecked(newValue);
    }
  };

  React.useImperativeHandle(
    ref,
    () => ({
      toggle: toggleValue,
      element: elementRef.current,
    }),
  );

  const styles = css`
    display: inline-block;
    border-radius: ${halfSpacing}px;

    &:focus {
      outline-color: ${warning} !important;
    }

    .switch-container {
      overflow: hidden;
      width: ${width}px;
      padding: ${BORDER}px;
      background-color: ${gray4};
      height: ${standardSpacing}px;
      border-radius: ${halfSpacing}px
    }

    .switch-content-container {
      width: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 9px;
      height: ${TOGGLE_SIZE}px;

      .checked-background {
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        transition: opacity 0.25s;
        background: linear-gradient(90deg, ${primary}, ${purple});
      }

      .toggle-container {
        position: relative;
        width: ${TOGGLE_SIZE}px;
        height: ${TOGGLE_SIZE}px;
        transition: transform 0.25s;

        .toggle {
          overflow: hidden;
          width: ${TOGGLE_SIZE}px;
          background-color: #fff;
          height: ${TOGGLE_SIZE}px;
          transition: background-color 0.25s;
          border-radius: ${TOGGLE_SIZE / 2}px;
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

          .toggle-icon {
            width: 100%;
            height: 100%;
            background-color: #fff;
            clip-path: path(evenodd, 'M-1 -1 h20 v20 h-20 v-20z M7.8571 12.4418c-0.2362 0.2362 -0.6300 0.2362 -0.8662 0.0000c0.0000 0.0000 -2.8744 -2.9137 -2.8744 -2.9137c-0.2166 -0.2362 -0.2166 -0.6300 0.0197 -0.8662c0.2362 -0.2362 0.6300 -0.2362 0.8662 0.0000l2.4216 2.4609l5.5716 -5.6700c0.2362 -0.2362 0.6300 -0.2362 0.8662 0.0000c0.2362 0.2559 0.2362 0.6497 0.0000 0.8859c0.0000 0.0000 -5.9259 6.0244 -6.0047 6.1031z');
          }
        }

        .enabled-label, .disabled-label, .icon {
          top: 0;
          color: #fff;
          line-height: 18px;
          user-select: none;
          position: absolute;
          font-family: ${openSans};
          padding: 0 ${LABEL_PADDING}px;
          font-weight: ${boldFontWeight};
          font-size: ${textSmallFontSize}px;
        }

        .enabled-label {
          left: -${enabledLabelWidth}px;
        }

        .disabled-label {
          right: -${disabledLabelWidth}px;
        }

        .icon {
          padding: 0;
          left: -15px;
        }
      }
    }

    &.checked {
      .checked-background {
        opacity: 1;
      }

      .switch-content-container {
        .toggle-container {
          transform: translateX(${(width - TOGGLE_SIZE) - (BORDER * 2)}px);

          .toggle {
            background-color: transparent;
          }
        }
      }
    }
  `;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const newValue = !checked;

    onClick?.(e, newValue);

    if (e.defaultPrevented) {
      return;
    }

    toggleValue();
  };

  const containerClassNames = [
    className,
    checked && 'checked',
  ].filter(Boolean).join(' ') || undefined;

  return (
    <ClickableContainer
      css={styles}
      onBlur={onBlur}
      ref={elementRef}
      disabled={disabled}
      onKeyDown={onKeyDown}
      onClick={handleClick}
      aria-label={ariaLabel}
      className={containerClassNames}
      data-qa={dataQa}
      data-qa-id={dataQaId}
      {...rest}
    >
      <div className='switch-container'>
        <div className='switch-content-container'>
          <div className='checked-background' />
          <div className='toggle-container'>
            <div className='toggle'>
              <div className='toggle-icon' />
            </div>
            {enabledLabel && (
              <span className='enabled-label'>{enabledLabel}</span>
            )}
            {enabledIcon && (
              <NvIcon icon={enabledIcon} size='xss-smallest' />
            )}
            {disabledLabel && (
              <span className='disabled-label'>{disabledLabel}</span>
            )}
          </div>
        </div>
      </div>
    </ClickableContainer>
  );
});

type NonFormRenderProps = {
  value: boolean,
  onChange: (checked: boolean) => void,
  onBlur: React.FocusEventHandler<HTMLDivElement>,
};

type Props = ControlledNvSwitchProps & {
  name?: string,
  // Whether we want to use this component in a react-hook-form form.
  // NOTE: Requires "FormProvider" parent wrapper to work.
  withForm?: boolean,
};

/**
 * NovoEd switch component.
 * This component can be used as a controlled and uncontrolled component as well
 * as it can be hooked into a react-hook-form form if you pass the "withForm"
 * prop as "true".
 * If you use withForm make sure you pass the "name" prop as well to make
 * react-hook-form Controller component work.
 */
const NvSwitch = React.forwardRef<NvSwitchRef, Props>((props, ref) => {
  const {
    name,
    checked,
    withForm = false,
    onBlur: propsOnBlur,
    onChange: propsOnChange,
    dataQa,
    dataQaId,
    ...restProps
  } = props;

  const renderElement = (
    renderProps: NonFormRenderProps | ControllerRenderProps,
    renderingInController: boolean = false,
  ) => {
    const {
      value,
      onBlur,
      onChange,
      ...restRenderProps
    } = omit(renderProps, ['ref', 'name']);

    const handleBlur = (e) => {
      onBlur?.(e);

      if (renderingInController) {
        propsOnBlur?.(e);
      }
    };

    const handleChange = (newChecked: boolean) => {
      onChange?.(newChecked);

      if (renderingInController) {
        propsOnChange?.(newChecked);
      }
    };

    return (
      <ControlledNvSwitch
        ref={ref}
        checked={value}
        onBlur={handleBlur}
        onChange={handleChange}
        dataQa={dataQa}
        dataQaId={dataQaId}
        {...restRenderProps}
        {...restProps}
      />
    );
  };

  return withForm ? (
    <Controller
      name={name}
      render={({ field }) => renderElement(field, true)}
    />
  ) : renderElement({
    value: checked,
    onBlur: propsOnBlur,
    onChange: propsOnChange,
  });
});

export default NvSwitch;
