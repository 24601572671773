import { css, Global } from '@emotion/react';
import { Fragment, ReactElement } from 'react';
import { desktop, handheld, notDesktop } from 'styles/global_defaults/media-queries';
import { black, gray1, gray4, gray5, gray7, primary, white } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { Button, Modal } from 'react-bootstrap';
import BootstrapModalManager from 'react-bootstrap/BootstrapModalManager';
import ClickableContainer from 'components/clickable-container';
import useIsAthenaApp from 'athena/hooks/use-is-athena-app';
import { almostBlack, darkGray } from 'athena/styles/colors';
import NvIcon from './nv-icon';

// Inspired on source code https://github.com/react-bootstrap/react-bootstrap/blob/v1.3.0/src/Modal.tsx#L307
// I am passing a manager to Modal component since I want to be able to modify
// the manager stack entries in our code.
let manager;

/* @ngInject */
export function getModalManager() {
  if (!manager) manager = new BootstrapModalManager();
  return manager;
}

export const doubleModalZIndex = 7050;

export enum BackdropType {
  DEFAULT = 'default',
  LIGHT = 'light',
  LIGHTER = 'lighter',
  DARK = 'dark',
}

export enum ModalType {
  NO_DIALOG = 'no_dialog',
  FULL = 'full',
  FIXED = 'fixed',
  DYNAMIC = 'dynamic',
}

export enum ModalTheme {
  DEFAULT = '',
  LIGHT = 'modal-light',
}

interface BaseModal {
  show?: boolean,
  body: ReactElement,
  onClose?: (e?: any) => void,
  doubleModal?: boolean, // this is an escape hatch - react bootstrap does not support 2 modals at once, this is only used by RTE
  enforceFocus?: boolean
  /** Additional class name to apply to the root div of the modal */
  rootClassName?: string,
  /** Additional class name to apply to the <Modal.Body> */
  bodyClassName?: string,
  backdropType?: BackdropType,
  /** This was added a while ago but wasn't used. Now, if passed, it will go on the array of styles of the Modal, appended to default styles */
  modalStyles?: ReturnType<typeof css>,
  closePendoTagName?: string,
  closeDataQa?: string,
}

// Standard Modals are ones that look like the default bootstrap modals - dark background & centered content with light background\
// https://projects.invisionapp.com/d/main#/console/5847843/421848984/preview
interface StandardModal extends BaseModal {
  header: string,
  theme?: ModalTheme.DEFAULT | ModalTheme.LIGHT
  headerClassName?: string
}

interface FullOverlayModal extends BaseModal {
  type: ModalType.NO_DIALOG
  backdrop?: true | false | 'static' // this is directly provided to Modal backdrop attribute
}

interface FullModal extends StandardModal {
  type: ModalType.FULL
  fullHeight?: boolean
}

interface DynamicModal extends StandardModal {
  type: ModalType.DYNAMIC
  width: number
  fullHeight?: boolean
  headerBgColor?: string
}

interface FixedModal extends StandardModal {
  type: ModalType.FIXED
  width: number
  height: number | string
  headerBgColor?: string
}

export type NvModalProps = FullModal | DynamicModal | FixedModal | FullOverlayModal;

const defaultProps = {
  show: true,
  width: 720,
  height: 500,
  fullHeight: true,
  backdrop: 'static',
  doubleModal: false, // this is an escape hatch - react bootstrap does not support 2 modals at once.
  theme: ModalTheme.DEFAULT,
  enforceFocus: true,
};

const backdropStyle = (type: BackdropType = BackdropType.DEFAULT) => {
  let bdStyle;

  switch (type) {
    case BackdropType.LIGHTER:
      bdStyle = css`
        .bs4-modal-backdrop {
          &.show {
            opacity: 0.1;
            background-color: ${gray1};
          }
        }
      `;
      break;
    case BackdropType.LIGHT:
      bdStyle = css`
        .bs4-modal-backdrop {
          &.show {
            opacity: 0.4;
          }
        }
      `;
      break;
    case BackdropType.DARK:
      bdStyle = css`
        .bs4-modal-backdrop {
          &.show {
            opacity: 0.95;
          }
        }
      `;
      break;
    // Fallthrough
    case BackdropType.DEFAULT:
    default:
      return null;
  }

  return <Global styles={bdStyle} />;
};

/** Modal styles updated to support mocks at https://projects.invisionapp.com/d/main#/console/5847843/175180384/preview */
export const NvModal = (props: NvModalProps) => {
  const isAthenaApp = useIsAthenaApp();

  const styles = css`
    ${props.doubleModal && css`
      z-index: ${doubleModalZIndex};
    `}

    .bs4-modal-body {
      max-height: 100%!important;
    };

    &.bs4-modal {
      ${isAthenaApp && css`
        .bs4-modal-content {
          border: 0;
          border-radius: ${halfSpacing}px;

          .bs4-modal-header {
            border-top-left-radius: ${halfSpacing}px;
            border-top-right-radius: ${halfSpacing}px;
          }

          .bs4-modal-title {
            color: ${almostBlack};
          }

          .icon-close {
            color: ${darkGray};
          }
        }
      `};

      &.modal-${ModalType.NO_DIALOG} {
        .bs4-modal-content {
          background-color: transparent;
          height: 100vh;
          border: none;

          .close {
            color: ${white};
            opacity: 1;
            padding: ${standardSpacing}px;
            text-decoration: none;

            ${handheld(css`
              top: 0;
              right: 0;
            `)};
          }
        }
      }

      &.modal-${ModalType.FULL} {
        .bs4-modal-dialog {
          position: absolute;
          top: ${tripleSpacing}px;
          bottom: ${standardSpacing}px;
          left: 100px;
          right: 100px;

          /** TODO: For some reason react-bootstrap is adding a very large calculated top and
          bottom margin here on IE11 only. I'm forcing that margin off here for the
          image-lecture-component project; let's revisit. - Nathan */
          margin: 0 !important;

          ${notDesktop(css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          `)};

          .bs4-modal-content {
            height: 100%;

            /* Allow dynamic height sizing only on desktop */
            /* ${desktop(css`
              height: ${(props as FullModal).fullHeight ? '100%' : 'auto'};
            `)} */

            .bs4-modal-header {
              min-height: 60px;
              background-color: ${gray7}
            }
          }
        }
      }

      &.modal-${ModalType.DYNAMIC} {
        display: flex;
        justify-content: center;

        .bs4-modal-dialog {
          /* Allowing height sizing to dynamic modal */
          height: ${(props as DynamicModal).fullHeight ? '100%' : 'auto'};
          width: ${(props as DynamicModal).width}px;
          max-width: 100%;
          margin: 0 auto;
          padding-top: 60px;
          padding-bottom: 20px;

          ${notDesktop(css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0;
          `)};

          .bs4-modal-content {
            height: 100%;
          }

          /* Allowing header background color setting in dynamic modal */
          .bs4-modal-header {
            background-color: ${(props as DynamicModal).headerBgColor ? (props as DynamicModal).headerBgColor : gray7};
          }
        }
      }

      &.modal-${ModalType.FIXED} {
        .bs4-modal-dialog {
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

          .bs4-modal-content {
            width: ${(props as FixedModal).width}px;
            height: ${(props as FixedModal).height}px;
            max-height: 100%;
            max-width: 100%;

            .bs4-modal-header {
              min-height: ${tripleSpacing}px;
              background-color: ${(props as FixedModal).headerBgColor ? (props as FixedModal).headerBgColor : gray7};
            }
          }

          ${handheld(css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;


            .bs4-modal-content {
              width: 100%;
              height: 100%;
            }
          `)};
        }
      }

      &.modal-light {
        .bs4-modal-dialog {
          max-height: 100%;
          padding-top: ${doubleSpacing}px;
          padding-bottom: ${standardSpacing}px;

          .bs4-modal-content {
            padding-right: ${quarterSpacing}px;
            height: auto;
            border:none;

            .bs4-modal-header {
              background-color: ${white};
            }

            .bs4-modal-body {
              background-color: ${white};
              margin: ${standardSpacing}px;
              scrollbar-color: ${gray5};
              scrollbar-width: thin;

              ::-webkit-scrollbar {
                width: ${quarterSpacing}px;
                height: ${quarterSpacing}px;
              }

              ::-webkit-scrollbar-thumb {
                background: ${gray5};
                border-radius: ${standardSpacing}px;
              }

              ::-webkit-scrollbar-thumb:hover {
                background: ${gray4};
              }
            }
          }
        }
      }

      .modal-title-content {
        // 100px for the close button space
        width: calc(100% - 100px);

        overflow-x: clip;
        overflow-y: visible;
      }

      .close {
        text-shadow: none;

        &:hover, &:focus {
          color: ${primary};
        }
      }
    }
  `;

  return (
    <Fragment>
      {props.doubleModal && (
        <Global
          styles={css`
            .bs4-modal-backdrop.double-modal {
              z-index: ${doubleModalZIndex - 10};
              background-color: ${black};
              opacity: .8;
            }
          `}
        />
      )}
      {props.backdropType && backdropStyle(props.backdropType)}
      <Modal
        css={props.modalStyles ? [styles, props.modalStyles] : styles}
        className={`modal-${props.type} ${(props as StandardModal).theme} ${props.rootClassName ?? ''} ${isAthenaApp ? 'athena-app' : ''}`}
        animation={false}
        onHide={() => props.onClose?.()}
        backdrop={(props as FullOverlayModal).backdrop}
        backdropClassName={props.doubleModal ? 'double-modal' : ''}
        show={props.show}
        enforceFocus={props.enforceFocus}
        manager={getModalManager()}
      >
        {props.type === ModalType.NO_DIALOG ? (
          <Fragment>
            {props.onClose && (
              <Button
                className='pull-right close'
                variant='link'
                onClick={props.onClose}
                pendo-tag-name={props.closePendoTagName}
                data-qa={props.closeDataQa}
              >
                <i className='icon icon-small icon-close' />
              </Button>
            )}
            {props.body}
          </Fragment>
        ) : (
          <Fragment>
            <Modal.Header closeButton={false}>
              <Modal.Title>
                <span className={`ellipsis modal-title-content ${isAthenaApp ? 'heading-4' : ''} ${props.headerClassName}`}>
                  {props.header}
                </span>
              </Modal.Title>
              <ClickableContainer
                className='close gray-3'
                onClick={props.onClose}
                pendo-tag-name={props.closePendoTagName}
                data-qa={props.closeDataQa}
              >
                <NvIcon icon='close' size='small' />
              </ClickableContainer>
            </Modal.Header>
            <Modal.Body className={props.bodyClassName ?? ''}>{props.body}</Modal.Body>
          </Fragment>
        )}
      </Modal>
    </Fragment>
  );
};

NvModal.defaultProps = defaultProps;

export default NvModal;
