export const DATE_FORMAT = 'MMMM DD, YYYY';
export const TIME_FORMAT = 'h:mm a';
export const PROGRAM_DATES_FORMAT = 'MMM D, YYYY';
export const PROGRAM_DATES_FORMAT_WITH_TIME = 'MMM D, YYYY [at] h:mm a';

export const tooltipZIndex = 6000;

export enum PanelStateKey {
  STEPS_LIST_STATE = 'stepsListState',
  ACTION_ITEMS_STATE = 'actionItemsState',
  SESSIONS_LIST_STATE = 'sessionsListState',
  PROGRAM_GUIDELINES_STATE = 'programGuidelinesState',
}

export enum FirstSeenKey {
  ACTION_ITEMS_SEEN = 'actionItemsSeen',
  NEXT_SESSION_SEEN = 'nextSessionSeen',
  SESSIONS_LIST_SEEN = 'sessionsListSeen',
}
