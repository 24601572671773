import { css } from '@emotion/react';
import { sizes } from 'components/nv-avatar';
import {
  black,
  danger,
  gray2,
  gray4,
  gray5,
  gray7,
  hexToRgbaString,
  primary,
  warning,
} from 'styles/global_defaults/colors';
import { handheld, notDesktop, notHandheld } from 'styles/global_defaults/media-queries';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

const linearGradient = 'linear-gradient(315.42deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99.28%), #ffffff';

const styles = (headerCellTop: number, hasUserScrolledDown: boolean) => css`
  z-index: 0;
  width: 100%;
  position: relative;
  height: calc(100vh - 145px);
  background: ${linearGradient};
  display: flex;
  justify-content: center;

  .dashboard-header-container {
    top: 0;
    z-index: 2;
    opacity: 0.95;
    position: absolute;
    transition: all 0.5s;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: inherit;

    .dropdown-option {
      padding: ${halfSpacing}px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: ${gray7};
      }

      .highlight {
        color: ${warning};
      }
    }

    .dashboard-tabs {
      width: 100%;
      max-width: 1000px;
      display: flex;
      justify-content: center;
      padding: ${largeSpacing}px 0 ${doubleSpacing}px 0;

      ${handheld(css`
        overflow-x: auto;
        justify-content: flex-start;
        gap: ${standardSpacing}px;
        padding: ${largeSpacing}px ${standardSpacing}px ${doubleSpacing}px
          ${standardSpacing}px;
      `)};
    }

    .tab-container {
      > div {
        height: ${hasUserScrolledDown ? '70px' : '110px'};
      }
    }

    .dashboard-actions {
      width: 100%;
      max-width: 1000px;
      margin-bottom: ${standardSpacing}px;

      ${notDesktop(css`
        padding: 0 ${standardSpacing}px;
      `)}

      .bs4-dropdown {
        background: transparent;
        width: 450px;

        ${notDesktop(css`
          width: 100%;
        `)}
      }

      .nv-dropdown {
        padding: ${quarterSpacing + 2}px ${halfSpacing}px;

        .bs4-dropdown-menu {
          width: 450px;
          overflow: scroll;
          max-height: 400px;

          ${notDesktop(css`
            width: 100%;
          `)}
        }
      }
    }
  }

  .dashboard-content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    ${notDesktop(css`
      padding: 0 ${standardSpacing}px;
    `)}

    .dashboard-tab-content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      padding-bottom: 20px;

      .nv-responsive-table.course-admin-dashboard-table {
        display: flex;
        flex-direction: column;
        align-items: center;

        ${handheld(css`
          overflow-x: hidden;
        `)}

        .user-cell {
          display: flex;
          align-items: center;
          gap: ${halfSpacing}px;
          color: ${primary};

          .user-name {
            pointer-events: all;
          }
          .user-initials {
            pointer-events: all;
            height: ${sizes.md}px;
            width: ${sizes.md}px;
            font-size: ${0.5 * sizes.md}px;
            background-color: ${gray4};
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .cover-image-container {
          display: flex;
          align-items: center;

          .title {
            font-weight: bold;
            color: ${black};
            margin-bottom: ${quarterSpacing}px;
          }
          .course-catalog-id {
            color: ${gray2};
            margin-bottom: 0;
          }
          .image {
            flex-shrink: 0;
          }
        }

        .course-name-cell {
          .image,
          .title {
            pointer-events: all;
          }
        }
        .course-name-cell,
        .scenario-cell {
          padding: ${standardSpacing}px;
        }

        .mentees-cell {
          display: flex;
          align-items: center;
        }

        .pending-review-cell {
          padding: ${standardSpacing}px 0;
        }

        .grid {
          padding-top: ${headerCellTop}px;
          width: 100%;
          max-width: 1000px;

          .header-cell {
            top: ${headerCellTop}px;
            background: ${linearGradient};
            border-bottom: 1px solid ${gray5};
            border-top: none;
          }

          ${handheld(css`
            grid-template-columns: 100%;
          `)};

          .bkg-row {
            border-bottom: 1px solid ${gray5};
            cursor: pointer;

            &:hover {
              box-shadow: none;
              border-color: ${warning};
            }
          }
        }

        .no-results-panel {
          padding-top: 300px;
        }
      }
    }
  }
`;

export const coverImgStyles = (coverImg, avatarColor) => css`
  width: 100px;
  height: ${tripleSpacing}px;
  margin-right: ${halfSpacing}px;

  padding: ${standardSpacing}px 0 ${standardSpacing}px ${standardSpacing}px;
  ${coverImg
    ? `background-image: url('${coverImg}')`
    : `background-color: ${avatarColor};`};
  background-size: cover;
  background-position: center;
  position: relative;
`;

export const modalStyles = css`
overflow-y: hidden !important;
`;

export const galleryStyles = css`
  .activity-container {
    max-width: 800px;
  }
  .unavailable {
    background-color: ${hexToRgbaString(danger, 0.05)};
    border: 1px dashed ${danger};
  }
  .for-activity {
    white-space: nowrap;
  }
  &.video-practice-gallery {
    margin-left: -${standardSpacing}px;
    margin-right: -${standardSpacing}px;

    .nv-submission-gallery {
      padding-top: ${standardSpacing}px;

      .carousel-next, .carousel-prev {
        margin-top: ${standardSpacing}px;
        ${notHandheld(css`
          height: 240px;
        `)}
      }
    }
  }
  .skill-tag {
    width: fit-content;
    border-radius: ${threeQuartersSpacing}px;
    padding: 3px ${halfSpacing}px;
  }
`;

export default styles;
