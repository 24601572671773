import { css } from '@emotion/react';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import { gray6 } from 'styles/global_defaults/colors';
import {
  halfSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';
import { MentoringProgramActionItem } from 'redux/schemas/models/mentoring-program-action-items';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import SessionMenuOption from './session-menu-option';
import { optionStyles } from './styles';
import SwitchAssigneeOption from './switch-assignee-option';
import DeleteActionOption from './delete-action-option';
import { config } from '@config/pendo.config.json';

type ActionMenuProps = {
  item: MentoringProgramActionItem;
  disabled: boolean;
  isCompleted: boolean;
  isEditing: boolean;
  assignedTo: number;
  sessionId: number;
  enrollments: { [id: number]: MentoringProgramEnrollment };
  onChange: () => void;
  onDelete: () => void;
  showPlanSessionFlyout: (session: MentorshipProgramSession) => void;
};

const editStyles = css`
  ${optionStyles}
  margin: 1px;
  padding: ${quarterSpacing}px;

  &.disabled {
    pointer-events: none;
    background: ${gray6} !important;
  }
`;

const menuStyles = css`
  gap: ${halfSpacing}px;
`;

const ActionMenu = ({
  item,
  disabled,
  isCompleted,
  isEditing,
  assignedTo,
  sessionId,
  enrollments,
  onChange,
  onDelete,
  showPlanSessionFlyout,
}: ActionMenuProps) => {
  const { id: itemId, completedAt, completedBy } = item;
  const dataQATagBase = config.pendo.athena.mentorshipProgram.participantHome.actionItems.item;
  const dataQATag = itemId ? dataQATagBase.updateActionItemCheckButton : dataQATagBase.createNewActionItemAddButton;

  if (isEditing) {
    return (
      <ClickableContainer
        disabled={disabled}
        css={editStyles}
        className={disabled ? 'disabled' : ''}
        onClick={onChange}
        data-qa={dataQATag}
        data-qa-id={`${dataQATag}-${itemId}`}
      >
        <NvIcon icon={itemId ? 'check' : 'create-new-post'} size='smallest' />
      </ClickableContainer>
    );
  }
  if (itemId) {
    return (
      <div css={menuStyles} className='d-flex align-items-center'>
        {sessionId && (
          <SessionMenuOption
            actionItemId={itemId}
            sessionId={sessionId}
            showPlanSessionFlyout={showPlanSessionFlyout}
          />
        )}
        <SwitchAssigneeOption
          actionItemId={itemId}
          assignedTo={assignedTo}
          enrollments={enrollments}
          isCompleted={isCompleted}
          completedAt={completedAt}
          completedBy={completedBy}
        />
        <DeleteActionOption actionItemId={itemId} onDelete={onDelete} />
      </div>
    );
  }
  return null;
};

export default ActionMenu;
