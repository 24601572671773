import React, { useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { range } from 'underscore';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { AngularContext, AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { MeetingParams } from 'redux/schemas/models/team';
import { createTeamMeeting, updateTeamMeeting } from 'redux/actions/teams';

import NvTextArea from 'shared/components/inputs/nv-text-area';
import TimezoneDropdown from 'live_sessions/components/timezone-dropdown';
import NvDatePicker, { DatePickerType, getMomentDate } from 'shared/components/inputs/nv-datepicker';
import NvFormDropdown from 'shared/components/inputs/nv-form-dropdown';
import { durationText } from 'shared/utils';
import { gray4 } from 'styles/global_defaults/colors';
import { isHandheld } from 'styles/global_defaults/media-queries';
import { getCurrentUser } from 'redux/selectors/users';
import NvClickToEdit, { InputType } from 'components/nv-click-to-edit';
import { convertToStandardTime, getStartTime } from 'redux/schemas/models/live-event';
import TeamMembers from './team-members';

const styles = css`
  .timing {
    gap: 10px;
    .timing-start-time {
      flex-grow: 1;
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
    .timing-timezone {
      width: 150px;
    }
    .timing-duration {
      width: 130px;
    }
    .timing-timezone, .timing-duration {
      &.disabled {
        .bs4-dropdown {
          pointer-events: none;
          background-color: transparent;
          border-color: ${gray4};
          color: inherit;
        }
      }
    }
    .bs4-dropdown-menu {
      max-height: 250px;
      overflow-y: scroll;
    }
  }

`;

type MsTeamsMeetingProps = {
  onCancel: (isDirty?: boolean, isAuthenticate?: boolean) => void
  meeting?: any
  afterSubmit?: (data: any) => void
};

const labelClass = 'text-small gray-3 m-0';

const MsTeamsMeeting = ({ onCancel, meeting, afterSubmit }: MsTeamsMeetingProps) => {
  const { $state, CurrentUserManager } = useContext(AngularServicesContext);
  const { injectServices } = React.useContext(AngularContext);
  const [TimezonesService] = injectServices(['TimezonesService']);
  const [timingDisabled, setTimingDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const integrationSettings = useSelector(state => state.app.liveSessions.integrations);
  const userTimeZone = TimezonesService.correctSpelling(CurrentUserManager.user.timeZone || moment.tz.guess());

  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    title: yup.string().trim()
      .required(t.VALIDATION.REQUIRED())
      .max(250, t.VALIDATION.MAX('250')),
    agenda: yup.string().max(255, t.VALIDATION.MAX('255')),
    startTime: yup.date()
      .transform((value, originalValue) => {
        if (originalValue?._isAMomentObject) {
          return originalValue.toDate();
        }
        return value;
      })
      .typeError(t.VALIDATION.DATE())
      .required(t.VALIDATION.REQUIRED()),
    duration: yup.object().required(t.VALIDATION.REQUIRED()),
  });

  const methods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      title: meeting?.title ?? '',
      agenda: meeting?.agenda ?? '',
      startTime: meeting?.sessions[0]?.startTime ? getStartTime(meeting.sessions[0].startTime, { id: meeting.sessions[0].timeZone }, false) : '',
      timeZone: meeting?.sessions?.[0]?.timeZone
        ? { id: meeting.sessions[0].timeZone } : { id: userTimeZone, value: moment.tz(userTimeZone).format('(UTCZ)') },
      duration: meeting?.sessions?.[0]?.duration
        ? { id: meeting.sessions[0].duration }
        : { id: 30 },
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, formState, watch, resetField } = methods;
  const { isDirty, isValid } = formState;
  const startTime = watch?.('startTime');

  useEffect(() => {
    const meetingTime = meeting?.meetingTime
      ? getMomentDate(
        meeting?.meetingTime,
        'L HH:mm',
        userTimeZone,
      )?.toString() : '';
    // All fields will be disabled if the meeting is about to start in 10 mintes
    if (meetingTime === startTime) {
      resetField('startTime');
    } else {
      const newDisabled = startTime && !formState.isDirty
        ? moment().isAfter(moment(startTime).subtract(10, 'minutes'))
        : false;
      setTimingDisabled(newDisabled);
    }
  }, [startTime]);

  useEffect(() => {
    if (integrationSettings?.TeamsLiveSession?.authenticated
      && $state.params.meeting === 'ms_teams') {
      $state.go($state.current, { meeting: null }, { notify: false });
    }
  }, [
    $state.params.meeting,
    integrationSettings?.TeamsLiveSession?.authenticated,
  ]);

  const durations = range(24).map((val) => (val + 1) * 30);

  const getSubmitTitle = useCallback(() => {
    let title;
    if (meeting) {
      title = isSubmitting ? t.FORM.UPDATING() : t.FORM.UPDATE();
    } else {
      title = isSubmitting ? t.FORM.ADDING() : t.FORM.ADD();
    }
    return title;
  }, [isSubmitting, meeting]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const { agenda, title, startTime: time, timeZone, duration } = data;
    const sessionStartTime = moment.isMoment(time) ? convertToStandardTime(time) : time;
    const convertedStartTime = getStartTime(sessionStartTime, timeZone, true);
    const submitPrams: MeetingParams = {
      catalogId: $state.params.catalogId,
      teamId: $state.params.teamId,
      meeting: {
        title,
        agenda,
        meetingTime: convertedStartTime,
        sessionConfigurations: {
          meetingType: 'teams_meeting',
          startTime: convertedStartTime,
          duration: duration?.id,
          timeZone: timeZone?.id ?? '',
        },
      },
    };
    let createdPost;
    if (meeting?.id) {
      submitPrams.meetingId = meeting.id;
      createdPost = await dispatch(updateTeamMeeting(submitPrams));
    } else {
      createdPost = await dispatch(createTeamMeeting(submitPrams));
    }
    setIsSubmitting(false);
    afterSubmit(createdPost.payload);
  };

  if ((!integrationSettings?.TeamsLiveSession?.authenticated
    || (integrationSettings?.TeamsLiveSession?.authenticated
      && !integrationSettings?.TeamsLiveSession?.recordingScope
    )) && !meeting) {
    return (
      <div className='p-6'>
        <div className='bg-gray-6 text-body  p-4'>
          {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.OAUTH_DESCRIPTION.MS_TEAMS.WORKSPACE()}
        </div>
        <div className='button-bar mt-7'>
          <Button
            variant='secondary'
            onClick={() => onCancel()}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            variant='primary'
            onClick={() => onCancel(false, true)}
          >
            {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.OAUTH_AUTHENTICATE.AUTHENTICATE()}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={`${isHandheld() ? 'px-4' : 'px-6'} py-2`} css={styles}>
      <TeamMembers creatorId={CurrentUserManager?.user?.id} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='p-4 my-4 bg-gray-7'>
            <NvClickToEdit
              name='title'
              withForm
              className='mb-4 bg-white'
              type={InputType.SHORT_TEXT}
              text={t.TEAMS.WORKSPACE.MEETING.MS_TEAMS.TITLE()}
              replaceText
              showInputWhenEmpty
              editable={!timingDisabled}
            />
            <NvTextArea
              withForm
              name='agenda'
              placeholder={t.TEAMS.WORKSPACE.MEETING.MS_TEAMS.DESCRIPTION()}
              className='w-100 mb-5 label-margin bg-white'
              disabled={timingDisabled}
            />
            <div className='d-flex timing justify-content-between'>
              <div className='timing-start-time'>
                <label className={labelClass}>
                  {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.START_TIME()}
                </label>
                <NvDatePicker
                  required
                  withForm
                  placement='right'
                  min={moment()}
                  name='startTime'
                  type={DatePickerType.DATETIME}
                  className='w-100 bg-white'
                  disabled={timingDisabled}
                />
              </div>
              <div className='timing-timezone'>
                <label className={labelClass}>
                  {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.TIME_ZONE()}
                </label>
                <TimezoneDropdown
                  name='timeZone'
                  disabled={timingDisabled}
                />
              </div>
              <div className='timing-duration'>
                <label className={labelClass}>
                  {t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.DURATION()}
                </label>

                <NvFormDropdown
                  name='duration'
                  items={durations.map((duration) => ({
                    id: duration,
                    type: 'text',
                    text: durationText(duration),
                  }))}
                  isSmallSize
                  disabled={timingDisabled}
                  className='bg-white'
                />
              </div>
            </div>
          </div>
          <div className='button-bar mb-4 mt-7'>
            <Button
              variant='secondary'
              onClick={() => onCancel(isDirty)}
            >
              {t.FORM.CANCEL()}
            </Button>
            <Button
              variant='primary'
              type='submit'
              disabled={!isDirty || !isValid || isSubmitting}
            >
              {getSubmitTitle()}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default MsTeamsMeeting;
