import PusherChannelNames from 'shared/services/pusher-channel-names';
import { AppDispatch } from 'redux/store';
import {
  setAutoMatchingLastUpdated,
  setAutoMatchingStatus,
  setConnectionsCount,
} from 'redux/actions/mentoring-programs';
import { AutoMatchingStatus } from 'redux/schemas/app/mentoring-programs';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import t from 'react-translate';
import { refreshProgramParticipantsView } from 'redux/actions/mentoring-program-enrollments';
import { AutoMatchingEvent, BulkEnrollmentEvent, Event, EventList, EventType } from './event-types';
import { configureChannel } from './utils';

const events: EventList = [
  {
    name: EventType.AUTO_MATCHING,
    handler: (event: Event, dispatch: AppDispatch) => {
      const {
        mentorshipProgramId,
        autoMatchingStatus,
        totalConnectionsCount,
        createdConnectionsCount,
        autoMatchingLastUpdated = new Date(),
        unmatchedCount = 0,
      } = event as AutoMatchingEvent;
      dispatch(setAutoMatchingStatus({ autoMatchingStatus }));
      dispatch(setConnectionsCount({ totalConnectionsCount }));
      if (autoMatchingStatus === AutoMatchingStatus.COMPLETED) {
        dispatch(setAutoMatchingLastUpdated({
          mentorshipProgramId,
          autoMatchingLastUpdated,
        }));
        let header = t.FORM.SUCCESS_BANG();
        let message = t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE(
          createdConnectionsCount,
        );
        if (unmatchedCount) {
          if (createdConnectionsCount) {
            message = t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE_CONNECTIONS_WITH_UNMATCHED(
              createdConnectionsCount,
              unmatchedCount,
            );
          } else {
            header = t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE_NO_CONNECTIONS_WITH_UNMATCHED_TITLE();
            message = t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE_NO_CONNECTIONS_WITH_UNMATCHED();
          }
        }
        dispatch(
          addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header,
            message,
          }),
        );
      }
    },
  },
  {
    name: EventType.BULK_ENROLLMENT,
    handler: (event: Event, dispatch: AppDispatch) => {
      const { mentorshipProgramId, existingEnrolledCount, newEnrolledCount, errors } = event as BulkEnrollmentEvent;
      if (!errors?.length && mentorshipProgramId) {
        let alertType = AlertMessageType.SUCCESS;
        let message = '';
        if (newEnrolledCount === 0) {
          alertType = AlertMessageType.WARNING;
          message = t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NO_PARTICIPANTS_ADDED(existingEnrolledCount);
        } else if (existingEnrolledCount === 0) {
          message = t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.ALL_PARTICIPANTS_ADDED(newEnrolledCount);
        } else {
          message = t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.SOME_PARTICIPANTS_ADDED(existingEnrolledCount, newEnrolledCount);
        }
        if (newEnrolledCount) {
          dispatch(refreshProgramParticipantsView({ refreshView: true }));
        }
        dispatch(addAlertMessage({
          type: alertType,
          header: newEnrolledCount ? t.FORM.SUCCESS_BANG() : '',
          message,
        }));
      }
    },
  },
];

const setupMentoringProgramEventsListener = (
  mentoringProgramId: number,
  dispatch: AppDispatch,
) => {
  const channelName = PusherChannelNames.mentoringProgramChannel(
    mentoringProgramId,
  );
  return configureChannel(channelName, events, dispatch);
};

export default setupMentoringProgramEventsListener;
