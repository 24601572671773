import { css } from '@emotion/react';

const BUTTON_WIDTH = 94;

// eslint-disable-next-line import/prefer-default-export
export const buttonStyles = css`
  button {
    min-width: ${BUTTON_WIDTH}px;
  }
`;
