import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { isEmpty } from 'underscore';
import t from 'react-translate';
import axios, { AxiosResponse } from 'axios';

import { Communication, CommunicationType, ViewMode } from 'redux/schemas/models/course-communication';
import { useAppDispatch } from 'redux/store';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { TriggerType } from 'communications/course_communications/contexts/communication-context';

import { gray3, gray4, gray5, gray7, info, primary, warning, white } from 'styles/global_defaults/colors';
import { doubleSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import ClickableContainer from 'components/clickable-container';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { BadgesProps, Badges } from './role-badge';

export type Roles = {
  label: string;
  badges: BadgesProps['badges'];
};

export type WelcomeEmailItemProps = {
  id: number;
  courseId: number;
  communicationType: CommunicationType;
  subject: Communication['communication']['subject'];
  body: Communication['communication']['body'];
  trigger: string;
  tooltipText: string | null;
  disabled: boolean;
  dropdownItems?: Array<NvDropdownOption>;
  dropdownDataQa?: string;
  roles?: Roles;
  openModal: (triggerType: TriggerType, viewMode: ViewMode, communicationType: CommunicationType) => void;
};

const commonStyles = css`
  position: relative;
  padding: ${standardSpacing}px ${doubleSpacing}px;
  display: flex;
  flex-direction: column;
  gap: ${standardSpacing}px;
`;
const enabledStyles = css`
  ${commonStyles};
  background-color: ${info};
`;
const disabledStyles = css`
  ${commonStyles};
  background-color: ${gray7};
  border-left: ${quarterSpacing}px solid ${gray4};
  padding-left: 35px;

  .disabled-label {
    text-transform: uppercase;
    color: ${white};
    background-color: ${gray3};
    position: absolute;
    top: 0;
    left: 0;
  }
  .title {
    color: ${gray4};
  }
  .item-row .left {
    color: ${gray5};
  }
`;
const dropdownButtonStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  color: ${primary};
  cursor: pointer;
  font-style: normal;

  .options-btn {
    padding: 0 ${standardSpacing}px ${standardSpacing}px;
    margin-top: ${standardSpacing}px;
  }
`;
const rowStyles = css`
  display: flex;
  align-items: center;
  gap: ${standardSpacing}px;

  .left {
    min-width: 50px;
    text-align: right;
    color: ${warning};
  }
`;

export const WelcomeEmailItem: React.FC<WelcomeEmailItemProps> = ({
  id,
  communicationType,
  subject,
  body,
  trigger,
  tooltipText,
  disabled,
  dropdownItems,
  dropdownDataQa,
  openModal,
  roles,
}) => {
  const dispatch = useAppDispatch();
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const [welcomeEmailSubject, setWelcomeEmailSubject] = React.useState('');

  // Getting the welcome email preview data
  React.useEffect(() => {
    if (subject && body) {
      axios.post(`${catalogId}/course_communications/email_preview.json`, {
        communication: { subject, body },
        communicationType,
      })
        .then((res: AxiosResponse<{ result: { body: string, subject: string, from: string } }>) => {
          const { data } = res;
          setWelcomeEmailSubject(data.result.subject);
        })
        .catch(() => {
          dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.FORM.ERROR(),
            message: t.COURSE_COMMUNICATIONS.SAMPLE_EMAIL.ERROR(),
          }));
        });
    }
  }, [body, catalogId, communicationType, dispatch, subject]);

  const handleOnClick = React.useCallback(
    () => openModal(TriggerType.VIEW, ViewMode.EMAIL_PREVIEW, communicationType),
    [openModal, communicationType],
  );

  return (
    <NvTooltip
      text={tooltipText}
      placement='top'
      enabled={!!tooltipText}
    >
      <ClickableContainer
        css={!disabled ? enabledStyles : disabledStyles}
        className='bg-info'
        onClick={handleOnClick}
      >
        {disabled && (
          <span className='disabled-label course-title-xs px-1'>{t.SHARED.DISABLED()}</span>
        )}
        {dropdownItems && (
          <a
            css={dropdownButtonStyles}
            onClick={(e) => e.stopPropagation()}
          >
            <NvDropdown
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              items={dropdownItems}
              align={NvDropdownAlign.RIGHT}
              offset={-standardSpacing}
              toggleDataQa={dropdownDataQa}
              customTarget={() => (
                <NvTooltip
                  text={t.COURSE_COMMUNICATIONS.TOOLTIP.ACTIONS()}
                  placement='top'
                >
                  <div className='options-btn'>
                    <NvIcon size='xss-smallest' icon='edit' />
                  </div>
                </NvTooltip>
              )}
            />
          </a>
        )}
        <div css={rowStyles} className='item-row'>
          <div className='left'>
            <NvIcon size='xss-smallest' icon='messages' />
          </div>
          <span className='title text-medium font-weight-bolder'>
            { welcomeEmailSubject || <div className='loading w-100 my-1 py-1' /> }
          </span>
        </div>
        <div css={rowStyles} className='item-row'>
          <div className='left'>
            <NvIcon size='xss-smallest' icon='calendar' />
          </div>
          <span className='title text-medium'>
            { welcomeEmailSubject ? trigger : <div className='loading w-100 my-1 py-1' /> }
          </span>
        </div>
        {roles && !isEmpty(roles) && (
          <div css={rowStyles} className='item-row'>
            <div className='left'>
              <Badges badges={roles.badges} />
            </div>
            <span className='text-medium'>
              { roles.label }
            </span>
          </div>
        )}
      </ClickableContainer>
    </NvTooltip>
  );
};

export default WelcomeEmailItem;
