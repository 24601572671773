import React from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';

import { TriggerType } from 'communications/course_communications/contexts/communication-context';
import { CommunicationType, ViewMode } from 'redux/schemas/models/course-communication';

import { primary } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';

import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { config } from '../../../../../config/pendo.config.json';

type AddWelcomeEmailProps = {
  openModal: (triggerType: TriggerType, viewMode: ViewMode, communicationType: CommunicationType) => void;
};

const styles = css`
  color: ${primary};
  max-width: fit-content;
  display: flex;
  align-items: center;
  gap: ${halfSpacing}px;
`;

export const AddWelcomeEmail: React.FC<AddWelcomeEmailProps> = ({
  openModal,
}) => {
  const onAddWelcomeEmail = () => openModal(TriggerType.ADD, ViewMode.FORM_VIEW, CommunicationType.COURSE_MENTOR_WELCOME_EMAIL);

  return (
    <ClickableContainer
      css={styles}
      onClick={() => onAddWelcomeEmail()}
      aria-label={t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ADD.MENTOR()}
      data-qa={config.pendo.courseCommunication.courseMentorRegistrationEmail.add}
    >
      <NvIcon
        icon='add'
        size='md'
      />
      <span>
        {t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ADD.MENTOR()}
      </span>
    </ClickableContainer>
  );
};

export default AddWelcomeEmail;
