import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { getSanitizedStyles } from 'shared/utils';
import ProgramHeader from 'athena/components/program-header/program-header';
import useMentoringProgramRole from 'athena/hooks/use-mentoring-program-role';
import MentoringProgramContext, { MentoringProgramHomeUIContext } from 'athena/components/mentoring-program/context';
import ProgramAdminHome from './home/program-admin';
import ParticipantHome from './home/participant';
import ProgramUnavailable, { errorStyles } from './home/participant/program-unavailable';
import { withOverflowFlexGrowClass } from './constants';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const getScrollHeight = (isTablet: boolean, isUnauthorized: boolean) => {
  if (isTablet) return 'unset';
  if (isUnauthorized) return '100%';
  return 'calc(100vh + 200px)';
}

const Root = () => {
  const { isAdmin, isMentee, isMentor } = useMentoringProgramRole();
  const { error, mentoringProgram } = useContext(MentoringProgramContext);
  const [innerScroll, setInnerScroll] = React.useState(0);
  const scrollContainerRef = React.useRef<HTMLDivElement>();
  const [scrollPercentage, setScrollPercentage] = React.useState(0);

  const { endDate, releasedDate } = mentoringProgram || {};
  const isClosed = endDate && moment().isAfter(moment(endDate));
  const isNotReleased = releasedDate && moment().isBefore(moment(releasedDate));
  const isUnauthorized = (isClosed || isNotReleased) && !isAdmin;

  const isTablet = useMediaQuery({
    query: `(max-width: 834px)`,
  });

  const styles = css`
    flex: 1;
    overflow: hidden;
    ${innerScroll === 0 && css`
      overflow-y: ${isUnauthorized ? 'hidden' : 'auto'};
    `};

    .scroll-content {
      height: ${getScrollHeight(isTablet, isUnauthorized)};
    }
  `;

  const uiContextValue = {
    withOverflowFlexGrowClass,
  };

  const containerTranslate = (90 / 100) * scrollPercentage;
  const errorContainerStyles = error ? errorStyles(containerTranslate) : '';

  return (
    <MentoringProgramHomeUIContext.Provider value={uiContextValue}>
      <div
        css={getSanitizedStyles([styles, errorContainerStyles])}
        ref={scrollContainerRef}
        onScroll={() => {
          const { clientHeight, scrollHeight, scrollTop } = scrollContainerRef.current;
          const total = scrollHeight - clientHeight;
          setScrollPercentage((100 / total) * scrollTop);
        }}
      >
        <div
          className='scroll-content'
        >
          <ProgramHeader scrollPercentage={scrollPercentage} showGoBack />
          {error && <ProgramUnavailable />}
          {isAdmin && <ProgramAdminHome setInnerScroll={setInnerScroll} scrollPercentage={scrollPercentage} />}
          {(isMentor || isMentee) && <ParticipantHome setInnerScroll={setInnerScroll} scrollPercentage={scrollPercentage} />}
        </div>
      </div>
    </MentoringProgramHomeUIContext.Provider>
  );
};

export default Root;
