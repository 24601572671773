import { css } from '@emotion/react';
import React from 'react';

// Styles
import {
  standardSpacing,
  largeSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { handheld } from 'styles/global_defaults/media-queries';

import QrveyComponent from 'analytics_dashboard/components/qrvey-component';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import TabLoadingWrapper from '../shared/tab-loading-wrapper';

const styles = () => css`

  .filter-container {
    margin-bottom: ${largeSpacing}px;
    ${handheld(css`
      margin-bottom: ${standardSpacing}px;
    `)};
  }

  .empty-tab-wrapper {
    padding-top: ${tripleSpacing}px;
  }
`;

const AnalyticsTab = () => (
  <div
    css={styles}
    className='d-flex flex-column analytics-card-tab-container'
  >
    <TabLoadingWrapper isLoaded>
      <React.Fragment>
        <QrveyComponent configKeyName="config_practice_analytics" type={AnalyticsDashBoardType.PRACTICE_ANALYTICS} />
      </React.Fragment>
    </TabLoadingWrapper>
  </div>
);

export default AnalyticsTab;
