import t from 'react-translate';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { lightBlack } from 'athena/styles/colors';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { danger } from 'styles/global_defaults/colors';
import { buttonStyles } from './styles';

const MODAL_WIDTH = 536;
const MODAL_HEIGHT = 282;

const modalStyles = css`
  .content {
    color: ${lightBlack};
  }
  .buttons {
    gap: 14px;
  }
  .danger {
    border-color: ${danger};
    background-color: ${danger};
  }

  ${buttonStyles}
`;

export type ModalContentProps = {
  header?: string;
  content: string;
  confirmButtonType?: 'default' | 'danger';
  confirmButtonLabel?: string;
  onConfirm: () => void;
  dataQa?: string;
};

type ConfirmationModalProps = ModalContentProps & {
  showModal: boolean;
  onClose: () => void;
};

const ConfirmationModal = ({
  showModal = false,
  header = t.FORM.ARE_YOU_SURE(),
  content,
  confirmButtonType = 'default',
  confirmButtonLabel = t.FORM.YES_SURE(),
  onConfirm,
  onClose,
  dataQa,
}: ConfirmationModalProps) => (
  <NvModal
    width={MODAL_WIDTH}
    height={MODAL_HEIGHT}
    type={ModalType.FIXED}
    show={showModal}
    header={header}
    body={(
      <div
        css={modalStyles}
        className='d-flex flex-column justify-content-between h-100'
      >
        <div className='content text-large-body p-2'>{content}</div>
        <div className='buttons pb-2 d-flex justify-content-center'>
          <Button size='lg' variant='outline-primary' onClick={onClose}>
            {t.FORM.CANCEL()}
          </Button>
          <Button
            size='lg'
            variant='primary'
            className={confirmButtonType}
            onClick={onConfirm}
            data-qa={dataQa}
          >
            {confirmButtonLabel}
          </Button>
        </div>
      </div>
      )}
    onClose={onClose}
  />
);

export default ConfirmationModal;
