/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { css } from '@emotion/react';
import moment from 'moment';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import NvAthenaFroala, { ResizeType } from 'athena/components/nv-athena-froala';
import AthenaTextInput from 'athena/components/text-input';
import SaveToast from 'athena/components/mentoring-program/program-configuration/save-toast';
import NvFlyoutModal, { ModalType } from 'shared/components/nv-flyout-modal';
import NvDatePicker, { DatePickerType } from 'shared/components/inputs/nv-datepicker';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { gray1, gray2, gray5, gray6 } from 'styles/global_defaults/colors';
import {
  semiBoldFontWeight,
  textLargeFontSize,
  textMediumLineHeight,
  textMediumFontSize,
  textSmallLineHeight,
  headerLineHeight,
} from 'styles/global_defaults/fonts';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { createMentoringProgramSession, deleteMentoringProgramSession, resetMentoringProgramSessionsList, updateMentoringProgramSession } from 'redux/actions/mentoring-program-sessions';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { config } from '@config/pendo.config.json';
import { ToolbarOptions } from 'froala/helpers/nv-froala-constants';
import ActionItem from 'athena/components/action-item';
import { DEFAULT_ACTION_ITEM } from 'athena/components/action-item/constants';
import { ActionItemOwner, MentoringProgramActionItem } from 'redux/schemas/models/mentoring-program-action-items';
import { createMentorshipProgramActionItem, MentorshipProgramActionItemParams, removeDeletedActionItem } from 'redux/actions/mentoring-program-action-items';
import { getSessionActionItems } from 'redux/selectors/mentorship-program-sessions';
import { getDefaultActionItemHandlers, getDraftActionItemHandlers } from './utils';

const FLYOUT_MODAL_WIDTH = 640;

type PlanSessionFlyoutModalProps = {
  onClose: () => void;
  session?: MentorshipProgramSession;
};

const PlanSessionFlyoutModal = ({
  onClose,
  session,
}: PlanSessionFlyoutModalProps) => {
  const styles = css`
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      padding: ${standardSpacing}px ${largeSpacing}px;
      font-weight: ${semiBoldFontWeight};
      font-size: ${textLargeFontSize}px;
      line-height: ${textMediumLineHeight}px;
      border-bottom: 1px solid ${gray5};
    }

    .content {
      flex: 1;
      padding: ${standardSpacing}px ${largeSpacing}px;

      .session-title, .session-time, .session-action-items, .session-details {
        color: ${gray2};
        font-weight: ${semiBoldFontWeight};
        font-size: ${textMediumFontSize}px;
        line-height: ${textSmallLineHeight}px;
      }

      .title {
        margin-bottom: ${threeQuartersSpacing}px;
      }

      .date-time {
        & > div, .react-datepicker-wrapper {
          width: 100%;
        }

        width: 100%;
        padding-bottom: ${largeSpacing}px;
      }

      .session-action-items {
        border-top: 1px solid ${gray5};
        padding-top: ${threeQuartersSpacing}px;
      }

      .session-details {
        padding-top: ${standardSpacing}px;
        border-top: 1px solid ${gray5};
      }

      .session-details-description {
        color: ${gray1};
        line-height: ${headerLineHeight}px;
        padding-top: ${quarterSpacing}px;
        padding-bottom: ${halfSpacing}px;
      }
    }

    .button-row {
      padding: ${largeSpacing}px;
      text-align: right;
      border-top: 1px solid ${gray6};

      button {
        margin-left: ${threeQuartersSpacing}px;
      }
    }
  `;

  const dispatch = useAppDispatch();

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const connectionId = parseInt($state.params.connectionId, 10);
  const inputRef = useRef(null);

  const { mentoringProgram } = useContext(MentoringProgramContext);

  const { id: mentoringProgramId, mentorshipProgramEnrollment: enrollmentId } = mentoringProgram || {};
  const mentoringProgramEnrollment = useSelector(state => (
    state.models.mentoringProgramEnrollments[enrollmentId]
  ));

  const { assignedConnections, user: currentUser } = mentoringProgramEnrollment;
  const connection = assignedConnections.find((connec) => (
    connec.connectionId === connectionId
  )) ?? assignedConnections[0];
  const { user: connectionUser } = connection?.assignedEnrollment || {};

  const defaultTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.DEFAULT_TITLE(currentUser.firstName, connectionUser.firstName);

  const [sessionTitle, setSessionTitle] = useState<string>(session?.title ?? defaultTitle ?? '');
  const [sessionTime, setSessionTime] = useState<moment.Moment>(session?.scheduledDate ? moment(session.scheduledDate) : null);
  const [sessionDetails, setSessionDetails] = useState<string>(session?.details ?? '');
  const [saveStatus, setSaveStatus] = useState<SaveStatus>();

  const sessionActionItems: MentoringProgramActionItem[] = useSelector(getSessionActionItems(session?.id));

  const isEdit = !!session?.id;

  const [draftActionItems, setDraftActionItems] = useState<MentoringProgramActionItem[]>([]);
  const [editingId, setEditingId] = useState<string | number>();

  const getDefaultActionItem = () => ({
    ...DEFAULT_ACTION_ITEM,
    assignedTo: mentoringProgramEnrollment,
    owner: { id: session?.id, type: ActionItemOwner.SESSION },
  });
  const connectionEnrollments = {
    [mentoringProgramEnrollment.id]: mentoringProgramEnrollment,
    [connection?.assignedEnrollment?.id]: connection?.assignedEnrollment,
  };

  const { onAdd, onUpdate, onDelete } = isEdit
    ? getDefaultActionItemHandlers(mentoringProgramId, dispatch)
    : getDraftActionItemHandlers(setDraftActionItems);

  const deleteSession = () => {
    dispatch(openConfirmationDialog({
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.OPTIONS.DELETE_THIS_SESSION(),
      bodyText: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.OPTIONS.DELETE_DESCRIPTION(),
      confirmText: t.FORM.DELETE(),
      onConfirm: () => {
        dispatch(deleteMentoringProgramSession({
          programId: mentoringProgram.id,
          sessionId: session.id,
        })).then(() => {
          sessionActionItems?.forEach(item => dispatch(removeDeletedActionItem({ connectionId, actionItemId: item.id })));
          dispatch(resetMentoringProgramSessionsList({ resetList: true }));
          onClose();
        });
      },
    }));
  };

  const optionItems: NvDropdownOption[] = [
    {
      type: 'text',
      class: 'text-danger',
      text: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.OPTIONS.DELETE_SESSION(),
      callback: deleteSession,
    },
  ];

  const createDraftActionItems = async (createdSessionId: number) => {
    for (const draftItem of draftActionItems.reverse()) {
      const draftItemParams: MentorshipProgramActionItemParams = {
        mentorshipProgramId: mentoringProgram.id,
        description: draftItem.description,
        status: draftItem.status,
        assignedTo: draftItem.assignedTo.id,
        mentorshipProgramSessionId: createdSessionId,
      };
      await dispatch(createMentorshipProgramActionItem({ connectionId, ...draftItemParams }));
    }
  };

  const handleSubmit = () => {
    dispatch(createMentoringProgramSession({
      programId: mentoringProgram.id,
      enrollmentId,
      title: sessionTitle.trim() || defaultTitle,
      details: sessionDetails,
      mentorshipProgramConnectionId: connection.connectionId,
      scheduledDate: moment(sessionTime).toISOString(),
    })).then(async (action) => {
      const createdSession = action.payload;
      const { id: createdSessionId } = createdSession || {};
      if (createdSessionId && draftActionItems.length) {
        await createDraftActionItems(createdSessionId);
      }
      dispatch(resetMentoringProgramSessionsList({ resetList: true }));
      onClose();
    });
  };

  const handleEdit = () => {
    if (isEdit) {
      setSaveStatus(SaveStatus.IN_PROGRESS);
      dispatch(updateMentoringProgramSession({
        programId: mentoringProgram.id,
        title: sessionTitle.trim() || defaultTitle,
        details: sessionDetails,
        sessionId: session.id,
        scheduledDate: moment(sessionTime).toISOString(),
      })).then(() => {
        setSaveStatus(SaveStatus.COMPLETED);
        dispatch(resetMentoringProgramSessionsList({ resetList: true }));
      }).finally(() => {
        setTimeout(() => {
          setSaveStatus(null);
        }, 3000);
      });
    }
    if (!sessionTitle.trim()) {
      setSessionTitle(defaultTitle);
    }
  };

  const handleClose = () => {
    if (isEdit) {
      onClose();
    } else {
      dispatch(openConfirmationDialog({
        title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.CONFIRMATION_MODAL.HEADER(),
        bodyText: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.CONFIRMATION_MODAL.BODY(),
        confirmText: t.FORM.DISCARD(),
        onConfirm: onClose,
      }));
    }
  };

  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.sessions.planSessionFlyout;

  useEffect(() => {
    if (inputRef.current && !isEdit) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return (
    <Fragment>
      <NvFlyoutModal
        type={ModalType.RIGHT}
        width={FLYOUT_MODAL_WIDTH}
        onClose={handleClose}
      >
        <div css={styles}>
          <div className='header'>
            <div>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.HEADER()}</div>
            {isEdit && (
              <div className='d-flex'>
                {saveStatus ? <SaveToast status={saveStatus} /> : null}
                <NvDropdown
                  buttonStyle={NvDropdownButtonStyle.ICON}
                  iconClass='icon-more icon-small mr-6 ml-4 text-gray-2'
                  items={optionItems}
                  toggleDataQa={dataQATag.sessionMenuDropdown}
                />
              </div>
            )}
          </div>
          <div className='content'>
            <div className='session-title'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.TITLE()}</div>
            <div className='title'>
              <AthenaTextInput
                value={sessionTitle}
                onChange={({ target: { value } }) => setSessionTitle(value)}
                onBlur={handleEdit}
                maxLength={255}
                dataQa={dataQATag.sessionTitleInputField}
                ref={inputRef}
              />
            </div>
            <div className='session-time'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.DATE_AND_TIME()}</div>
            <div className='date-time'>
              <NvDatePicker
                value={sessionTime}
                onChange={(value) => setSessionTime(moment(value))}
                onCalendarClose={handleEdit}
                type={DatePickerType.DATETIME}
                dataQa={dataQATag.sessionDateTimePicker}
                highlightDates={[moment().startOf('day')]}
              />
            </div>
            <div className='session-action-items'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.TITLE()}</div>
            <div className='my-3' data-qa={dataQATag.actionItemsList} data-qa-id={`${dataQATag.actionItemsList}-${session?.id}`}>
              {[
                getDefaultActionItem(),
                ...(isEdit ? sessionActionItems : draftActionItems),
              ].map(
                (item) => item && (
                <ActionItem
                  key={item.id}
                  item={item}
                  onAdd={onAdd}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  isEditing={editingId === item.id}
                  setEditingId={setEditingId}
                  connectionId={connectionId}
                  enrollments={connectionEnrollments}
                />
                ),
              )}
            </div>
            <div className='session-details'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS()}</div>
            <div className='session-details-description'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS_DESCRIPTION()}</div>
            <NvAthenaFroala
              name='description'
              value={sessionDetails}
              onChange={(value) => setSessionDetails(value)}
              onBlur={handleEdit}
              withForm={false}
              resize={ResizeType.VERTICAL}
              placeholder={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PLAN_SESSION_FLYOUT.SESSION_DETAILS_PLACEHOLDER()}
              toolbarButtons={[
                ToolbarOptions.BOLD,
                ToolbarOptions.ITALIC,
                ToolbarOptions.UNDERLINE,
                ToolbarOptions.FORMAT_UL,
                ToolbarOptions.FORMAT_OL,
                ToolbarOptions.INSERT_LINK,
              ]}
            />
          </div>
          {!isEdit && (
            <div className='button-row'>
              <Button
                size='lg'
                variant='secondary'
                onClick={handleClose}
                data-qa={dataQATag.cancel}
              >
                {t.FORM.CANCEL()}
              </Button>
              <Button
                size='lg'
                onClick={() => handleSubmit()}
                disabled={!sessionTitle || !sessionTime}
                data-qa={dataQATag.createSession}
                data-qa-id={`${dataQATag.createSession}_${mentoringProgram?.id}`}
              >
                {t.FORM.ADD()}
              </Button>
            </div>
          )}
        </div>
      </NvFlyoutModal>
    </Fragment>
  );
};

export default PlanSessionFlyoutModal;
