import React, { useContext } from 'react';
import _ from 'underscore';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import ProgramBasicsModal from 'athena/components/program-basics-modal';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { MentoringProgram } from 'redux/schemas/models/mentoring-programs';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import NvResponsiveTableOptionsCell from 'shared/components/nv-responsive-table-options-cell';
import { deleteMentoringProgram, updateMentoringProgram } from 'redux/actions/mentoring-programs';
import { MentoringProgramCellProps, MentoringProgramRowExtraProps } from 'athena/components/mentoring-program-row';
import { config } from '@config/pendo.config.json';
import { AngularContext } from 'react-app';
import ManageProgramAccessModal from './manage-program-access-modal';

type MentoringProgramRowOptionsProps = {
  cellProps: MentoringProgramCellProps,
};

const MentoringProgramRowOptions = ({
  cellProps,
}: MentoringProgramRowOptionsProps) => {
  const { program } = cellProps;

  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentPermissionsManager] = injectServices(['$state', 'CurrentPermissionsManager']);
  const [showManageAccess, setShowManageAccess] = React.useState(false);
  const dataQATag = config.pendo.athena.mentorshipProgram;

  const optionItems: NvDropdownOption[] = [
    { type: 'header', title: t.MENTORING_PROGRAMS.EDIT_PROGRAM() },
    {
      type: 'text',
      text: t.MENTORING_PROGRAMS.PROGRAM_DETAILS(),
      callback: () => setShowModal(true),
      dataQa: dataQATag.programMenu.items.editProgramDetails
    },
    {
      type: 'text',
      text: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_MANAGEMENT(),
      callback: () =>
        program.isEnrolled
          ? $state.go('mentoring-program-participants', {
              programId: program.id,
            })
          : setShowManageAccess(true),
      dataQa: dataQATag.programMenu.items.participantManagement
    },
  ];

  if (CurrentPermissionsManager.hasNovoEdPermissions()) {
    optionItems.push({ type: 'divider' });
    optionItems.push({ type: 'header', title: t.INSTITUTIONS.LICENSES.NOVOED_ADMINS_ONLY() });
    optionItems.push({
      type: 'text',
      class: 'text-danger',
      text: t.NOVOED.DELETE(),
      callback: () => {
        dispatch(openConfirmationDialog({
          title: t.INSTITUTIONS.DASHBOARD.DELETION.ARE_YOU_SURE(),
          bodyText: t.INSTITUTIONS.DASHBOARD.DELETION.WARNING('mentoring-program'),
          confirmText: t.INSTITUTIONS.DASHBOARD.DELETION.YES_SURE(),
          onConfirm: () => {
            dispatch(deleteMentoringProgram(program.id));
          },
        }));
      },
      dataQa: dataQATag.programMenu.items.deleteProgram
    });
  }

  const handleModalSubmit = (formValues) => {
    wrapThunkAction(dispatch(updateMentoringProgram({
      id: program.id,
      description: program.description,
      ...formValues,
    }))).then(() => {
      setShowModal(false);
    });
  };

  return (
    <React.Fragment>
      <ProgramBasicsModal
        show={showModal}
        programId={program.id}
        onSubmit={handleModalSubmit}
        onClose={() => setShowModal(false)}
      />
      <ManageProgramAccessModal
        onClose={() => setShowManageAccess(false)}
        programId={showManageAccess ? program.id : undefined}
      />
      <NvResponsiveTableOptionsCell<MentoringProgramCellProps, MentoringProgram, unknown, MentoringProgramRowExtraProps>
        dataQa={dataQATag.programMenu.button}
        dataQaId={`${dataQATag.programMenu.button}-${program.id}`}
        optionItems={optionItems}
        optionsCellRef={cellProps.optionsCellRef}
        {...cellProps}
      />
    </React.Fragment>
  );
};

export default MentoringProgramRowOptions;
