import store from 'redux/store';
import { getMomentDate } from 'shared/components/inputs/nv-datepicker';
import WorkspaceMeeting from '../components/workspace-meeting';
import MsTeamsMeeting from '../components/ms-teams-meeting';

/* @ngInject */
export default function WorkspaceMeetingModalInstanceController(
  $scope,
  $uibModalInstance,
  $window,
  $stateParams,
  $location,
  AlertMessages,
  ConfirmationOverlays,
  StateManager,
  CurrentUserManager,
  MentionablesModel,
  TeamWorkspaceManager,
  TimezonesService,
  RailsRoutes,
  _,
  existingMeeting,
  moment,
) {
  const vm = this;

  initialize();

  function initialize() {
    const typeMapping = {
      external_meeting: 'external_link',
      teams_meeting: 'ms_teams',
    };
    vm.MentionablesModel = MentionablesModel;
    vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.ARE_YOU_SURE';
    vm.dismissedByUser = false;
    vm.submitting = false; // so we can disable the submit button while in the process of submitting to prevent double submits
    vm.meetingData = existingMeeting || {};
    vm.selectedOption = existingMeeting ? typeMapping?.[existingMeeting?.meetingType] : '';

    vm.submitMeeting = submitMeeting;
    vm.hasUnsavedChanges = hasUnsavedChanges;
    vm.showExternalMeetingForm = showExternalMeetingForm;

    const timeZoneString = TimezonesService.correctSpelling(existingMeeting?.metaContent?.sessions?.[0]?.timeZone || CurrentUserManager.user.timeZone || moment.tz.guess());
    const timeZoneKey = TimezonesService.timezones.find((t) => t.longName === timeZoneString)?.translationKey;
    vm.currentTimeZone = timeZoneKey ? `TIMEZONE.${timeZoneKey}` : timeZoneString;
  }

  function submitMeeting() {
    vm.submitting = true;

    if (existingMeeting) {
      TeamWorkspaceManager.updateMeetingPost(vm.meetingData).then((updatedPost) => {
        const cleanedUpResponse = {
          agenda: updatedPost.metaContent.agenda,
          id: updatedPost.metaContent.id,
          location: updatedPost.metaContent.location,
          meetingTime: updatedPost.metaContent.meetingTime,
          title: updatedPost.metaContent.title,
          userId: updatedPost.metaContent.userId,
        };

        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close(cleanedUpResponse);
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
    } else {
      TeamWorkspaceManager.createMeetingPost(vm.meetingData).then(() => {
        vm.submitting = false;
        vm.dismissedByUser = true;
        $uibModalInstance.close();
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
    }
  }

  function hasUnsavedChanges() {
    return vm.meetingForm?.$dirty;
  }

  function showExternalMeetingForm() {
    return vm.selectedOption === 'external_link';
  }

  // - unsaved changes overlay
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    () => vm.hasUnsavedChanges() && !vm.dismissedByUser,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
  );

  vm.onSelectOption = (option) => {
    $scope.$apply(() => {
      vm.selectedOption = option;
    });
  };

  $scope.$on('modal.closing', ($event) => {
    if (!vm.dismissedByUser && vm.hasUnsavedChanges()) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  vm.onCancel = (isDirty, isAuthenticate) => {
    if (isAuthenticate) {
      const refererUrl = `${$location.protocol()}://${$location.host()}/#!${$location.path()}${$location.hash()}?meeting=ms_teams`;
      window.location.href = RailsRoutes.msTeamOAuthInstallationUrl($stateParams.catalogId, '', refererUrl);
    }
    if (isDirty) {
      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    } else {
      vm.dismissedByUser = true;
      $uibModalInstance.dismiss();
    }
    vm.selectedOption = '';
  };

  vm.getTitle = () => {
    const state = store.getState();
    if (vm.selectedOption === 'ms_teams') {
      if (vm.meetingData?.id) {
        return 'TEAMS.WORKSPACE.MEETING.MS_TEAMS.EDIT';
      }
      if (!state.app.liveSessions?.integrations?.TeamsLiveSession?.authenticated) {
        return 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.AUTH_TITLE';
      }
      return 'TEAMS.WORKSPACE.MEETING.MS_TEAMS.CREATE';
    }
    if (vm.meetingData?.id) {
      return 'TEAMS.WORKSPACE.MEETING.EDIT';
    }
    return 'TEAMS.WORKSPACE.MEETING.CREATE';
  };

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });

  vm.afterSubmit = (meeting) => {
    if (!meeting) {
      console.error('error creating meeting');
      return;
    }
    const startTime = getMomentDate(meeting.metaContent.meetingTime, 'L HH:mm', meeting.metaContent.sessions?.[0].timeZone).toString();
    const minutesLeft = moment(startTime).diff(moment(), 'minutes');
    if (minutesLeft <= 10) {
      // Handle meeting created within 10 minutes before the start time
      TeamWorkspaceManager.meetingNotificationHandler({
        joinUrl: meeting.metaContent.sessions?.[0]?.joinUrl,
        eventName: meeting.metaContent.title,
        endTime: meeting.metaContent.sessions?.[0]?.endTime,
        meetingId: meeting.metaContent.id,
        startTime,
      });
    }
    if (vm.meetingData?.id) {
      const cleanedUpResponse = {
        agenda: meeting.metaContent.agenda,
        id: meeting.metaContent.id,
        location: meeting.metaContent.location,
        meetingTime: meeting.metaContent.meetingTime,
        title: meeting.metaContent.title,
        userId: meeting.metaContent.userId,
      };

      vm.dismissedByUser = true;
      $uibModalInstance.close(cleanedUpResponse);
    } else {
      TeamWorkspaceManager?.afterPostCreate?.(meeting);
      TeamWorkspaceManager?.workspace?.prependNewPost?.(meeting);

      vm.onCancel();
    }
  };

  $scope.WorkspaceMeeting = () => {
    if (vm.meetingData?.id) {
      return (
        <MsTeamsMeeting
          onCancel={(isDirty, isAuthenticate) => vm.onCancel(isDirty, isAuthenticate)}
          meeting={vm.meetingData}
          afterSubmit={(meeting) => vm.afterSubmit(meeting)}
        />
      );
    }
    return (
      <WorkspaceMeeting
        onSelect={(option) => vm.onSelectOption(option)}
        onCancel={(isDirty, isAuthenticate) => vm.onCancel(isDirty, isAuthenticate)}
        afterSubmit={(meeting) => vm.afterSubmit(meeting)}
      />
    );
  };
}
