import { useContext, useMemo } from 'react';
import t from 'react-translate';
import moment from 'moment';
import prodPathReplace from 'shared/prod-path-rewrite';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import MentoringProgramContext from '../../context';

const MODAL_HEIGHT = 340;
const MODAL_WIDTH = 440;

enum STATUS {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
}

const SETTINGS = {
  [STATUS.IN_PROGRESS]: {
    image: 'remaining-questions',
    label: 'IN_PROGRESS',
  },
  [STATUS.COMPLETED]: {
    image: 'all-done',
    label: 'COMPLETED',
  },
};

const getTimeLeft = (date: string, unit: moment.unitOfTime.Diff = 'day') => (
  moment(date).diff(moment(), unit)
);

const getUpcomingDateDescription = (date: string, translationBase: any, label: string) => {
  let timeLeft = getTimeLeft(date);
  let timeUnit = translationBase.TIME_UNIT.DAY();

  if (timeLeft === 0) {
    timeUnit = translationBase.TIME_UNIT.HOUR();
    if (getTimeLeft(date, timeUnit) < 1) {
      timeUnit = translationBase.TIME_UNIT.MINUTE();
    }
  } else if (timeLeft > 30) {
    timeUnit = translationBase.TIME_UNIT.MONTH();
    const daysLeft = timeLeft % 30;
    if (daysLeft > 0) {
      const monthsLeft = Math.floor(timeLeft / 30);
      const daysUnit = translationBase.TIME_UNIT.DAY();
      return translationBase[label].MATCH_DATE_UPCOMING_MONTHS_DAYS(daysLeft, daysUnit, monthsLeft, timeUnit);
    }
  }
  timeLeft = getTimeLeft(date, timeUnit);
  return translationBase[label].MATCH_DATE_UPCOMING(timeLeft, timeUnit);
};

const getMatchDateDescription = (
  matchAnnouncementDate: string,
  translationBase: any,
  label: string,
) => {
  const isMatchPast = moment().isAfter(matchAnnouncementDate);
  const matchDateDescription = isMatchPast
    ? translationBase[label].MATCH_DATE_PASSED()
    : getUpcomingDateDescription(matchAnnouncementDate, translationBase, label);
  const description = matchAnnouncementDate
    ? matchDateDescription
    : translationBase[label].NO_MATCH_DATE();
  return description;
};

type RequiredQuestionsModalProps = {
  show: boolean;
  remainingQuestions: number;
  onClose?: () => void;
};

const RequiredQuestionsModal = ({ show, remainingQuestions, onClose }: RequiredQuestionsModalProps) => {
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.REMAINING_QUESTIONS_MODAL;
  const status = remainingQuestions ? STATUS.IN_PROGRESS : STATUS.COMPLETED;

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { matchAnnouncementDate } = mentoringProgram || {};
  const { image, label } = SETTINGS[status];

  const src = prodPathReplace(`images/${image}.svg`);
  const title = translationBase[label].TITLE(remainingQuestions ?? null);
  const description = useMemo(() => (
    getMatchDateDescription(matchAnnouncementDate, translationBase, label)
  ), [matchAnnouncementDate, translationBase, label]);

  return (
    <NvModal
      show={show}
      width={MODAL_WIDTH}
      height={MODAL_HEIGHT}
      headerBgColor='transparent'
      bodyClassName='p-0'
      type={ModalType.FIXED}
      onClose={onClose}
      header=''
      body={(
        <div className='d-flex flex-column align-items-center text-center'>
          <img src={src} alt={title} />
          <div className='w-100 px-6 pt-5'>
            <div className='page-title mb-2'>{title}</div>
            <div className='text-large-body'>{description}</div>
          </div>
        </div>
      )}
    />
  );
};

export default RequiredQuestionsModal;
