/* eslint-disable react/require-default-props */

import uuid from 'react-uuid';
import omit from 'lodash/omit';
import { css } from '@emotion/react';
import { OverlayTrigger } from 'react-bootstrap';
import React, { ReactNode, FocusEvent, MutableRefObject } from 'react';

import t from 'react-translate';
import { mergeRefs } from 'shared/react-utils';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import {
  info,
  black,
  gray3,
  gray4,
  gray5,
  warning,
  hexToRgbaString,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  largeSpacing,
  dropdownZIndex,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';

type RenderToggle = (data: CustomToggleData) => React.ReactNode;

export type RefType = {
  toggle: HTMLDivElement,
  container: HTMLDivElement,
};

export type ToggleProps = {
  id: string,
  children: string,
  disabled: boolean,
  hasSelected: boolean,
  renderToggle: RenderToggle,
  onClick: (value: any) => void,
  onBlur: (event: FocusEvent<HTMLDivElement>) => void,
  onFocus: (event: FocusEvent<HTMLDivElement>) => void,
};

type CustomToggleData = {
  label: string,
  disabled: boolean,
  hasSelected: boolean,
};

type ItemProps = {
  active: boolean,
  children: ReactNode,
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  dataQa?: string,
};

export type SelectOption = {
  value: any,
  label: string,
  labelExtraInfo?: any,
  dataQa?: string,
};

type Props = {
  options: SelectOption[],
  value?: any,
  className?: string,
  disabled?: boolean,
  placeholder?: string,
  renderToggle?: RenderToggle,
  onChange?: (value: any) => void,
  align?: 'left' | 'right' | 'center',
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void,
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void,
  required?: boolean,
};

const Toggle = React.forwardRef<HTMLDivElement, ToggleProps>((props, ref) => {
  const {
    onBlur,
    onClick,
    onFocus,
    children,
    disabled,
    renderToggle,
    hasSelected = false,
  } = props;

  const styles = css`
    height: 40px;
    display: flex;
    user-select: none;
    border-radius: 5px;
    align-items: center;
    padding: ${halfSpacing}px;
    justify-content: space-between;
    cursor: ${disabled ? 'default' : 'pointer'};
    border: 1px solid ${disabled ? gray5 : gray4};
    ${disabled ? css`
      color: ${gray4};
    ` : css`
      color: ${hasSelected ? black : gray3};
    `}

    .icon {
      color: #777;
      transform: scale(0.75);
      margin-left: ${halfSpacing}px;
    }
  `;

  return (
    <ClickableContainer
      ref={ref}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled}
    >
      {renderToggle ? renderToggle({
        disabled,
        hasSelected,
        label: children,
      }) : (
        <div
          css={styles}
          className='text-medium'
        >
          {children}
          <NvIcon
            size='xss-smallest'
            icon='dropdown-arrow'
          />
        </div>
      )}
    </ClickableContainer>
  );
});

const Item = React.forwardRef((props: ItemProps, ref: MutableRefObject<HTMLDivElement>) => {
  const {
    active,
    onClick,
    children,
    dataQa,
  } = props;

  const styles = css`
    display: flex;
    align-items: center;
    background-color: #fff;
    height: ${largeSpacing}px;
    padding: 0 ${threeQuartersSpacing}px;

    &:hover {
      background-color: ${info};
    }

    .icon-check {
      display: none;
      color: ${warning};
      padding-left: ${standardSpacing}px;
    }

    &.active .icon-check {
      display: inline;
    }
  `;

  return (
    <ClickableContainer
      ref={ref}
      css={styles}
      onClick={onClick}
      className={active ? 'active' : undefined}
      data-qa={dataQa}
    >
      {children}
      <i className='icon icon-smallest icon-check float-right' />
    </ClickableContainer>
  );
});

/**
 * NovoEd select input
 */
const NvSelect = React.forwardRef<RefType, Props>((props, ref) => {
  const {
    onBlur,
    onFocus,
    options,
    onChange,
    className,
    renderToggle,
    align = 'left',
    disabled = false,
    required = false,
    value: propsValue,
    placeholder = t.FORM.DROPDOWN.SELECT(),
  } = props;

  const idRef = React.useRef(uuid());
  const mountedRef = React.useRef(false);
  const [show, setShow] = React.useState(false);
  const toggleRef = React.useRef<HTMLDivElement>();
  const containerRef = React.useRef<HTMLDivElement>();
  const selectMenuRef = React.useRef<HTMLDivElement>();
  const [value, setValue] = React.useState(propsValue);

  React.useEffect(() => {
    if (mountedRef.current) {
      setValue(propsValue);
    } else {
      mountedRef.current = true;
    }
  }, [propsValue]);

  React.useImperativeHandle(ref, () => ({
    toggle: toggleRef.current,
    container: containerRef.current,
  }));

  const getOptionsObject = () => options.reduce((acc, curr) => {
    acc[curr.value as string] = curr;

    return acc;
  }, {});

  const styles = css`
    display: inline-flex;

    .select-menu {
      border-radius: 4px;
      background-color: #fff;
      z-index: ${dropdownZIndex};
      border: 1px solid ${gray5};
      padding: ${quarterSpacing}px 0;
      box-shadow: 0px 5px 5px 0px ${hexToRgbaString(black, 0.1)};
    }

    .required-indicator {
      top: 0;
      right: 0;
      width: 55px !important;
      height: 100% !important;
    }
  `;

  const handleToggleClick = () => setShow((prevValue) => !prevValue);

  const handleTargetBlur = (event) => {
    onBlur?.(event);

    if (!event.relatedTarget || (event.relatedTarget.parentElement !== selectMenuRef.current)) {
      setShow(false);
    }
  };

  const renderToggleContent = () => {
    const option = getOptionsObject()[value as string];

    if (option) {
      return option.label;
    }

    return placeholder;
  };

  const mapAlignmentToOverlayPlacement = (alignment) => {
    switch (alignment) {
      case 'left':
        return 'bottom-start';
      case 'right':
        return 'bottom-end';
      case 'center':
        return 'bottom';
      default:
        throw new Error(`Unhandled aling prop value: "${align}"`);
    }
  };

  return (
    <div
      css={styles}
      ref={containerRef}
      className={className}
    >
      <OverlayTrigger
        show={show}
        container={containerRef}
        placement={mapAlignmentToOverlayPlacement(align)}
        overlay={({ ref: overlayRef, ...overlayProps }) => (
          <div
            {...omit(overlayProps, ['show', 'popper', 'arrowProps'])}
            className='select-menu'
            ref={mergeRefs(overlayRef, selectMenuRef)}
          >
            {options.map((option) => {
              const handleItemClick = () => {
                onChange(option.value);

                if (propsValue === undefined || propsValue === null) {
                  setValue(option.value);
                }

                setShow(false);
              };

              return (
                <Item
                  onClick={handleItemClick}
                  key={option.value as string}
                  active={option.value === value}
                  dataQa={option.dataQa}
                >
                  {option.label}
                  {option.labelExtraInfo}
                </Item>
              );
            })}
          </div>
        )}
      >
        {(targetProps) => {
          // NOTE: Only using the ref from injected props because we don't
          // use the trigger prop, we control the show state of the overlay via
          // "show" prop.
          const {
            ref: targetRef,
          } = targetProps;

          return (
            <Toggle
              onFocus={onFocus}
              disabled={disabled}
              hasSelected={!!value}
              onBlur={handleTargetBlur}
              onClick={handleToggleClick}
              renderToggle={renderToggle}
              id={`${idRef.current}-toggle`}
              ref={mergeRefs(toggleRef, targetRef)}
            >
              {renderToggleContent()}
            </Toggle>
          );
        }}
      </OverlayTrigger>
      {required && (
        <div className='required-indicator position-absolute d-flex align-items-center justify-content-center text-medium gray-3'>
          *
        </div>
      )}
    </div>
  );
});

export default NvSelect;
