import _ from 'underscore';
import moment from 'moment-timezone';
import { MS_LOBBY_BY_PASS_TYPES } from 'live_sessions/components/ms-teams-settings';
import { AngularLiveEvent } from 'live_sessions/components/live-session-form';
import { machineDateAndTimezoneDateConversion } from 'shared/components/inputs/nv-datepicker';

export enum SessionTypes {
  EXTERNAL = 'ExternalLiveSession',
  MS_TEAMS = 'TeamsLiveSession',
  ZOOM = 'ZoomLiveSession',
}

export enum IntegrationTypes {
  ZOOM_USER_LEVEL = 'zoom_oauth',
  ZOOM_ACCOUNT_LEVEL = 'zoom_account_oauth',
  MS_TEAMS_USER_LEVEL = 'microsoft_graph_oauth',
  MS_CALENDAR_USER_LEVEL = 'microsoft_graph_calendar_oauth',
}

export enum AttendeeInfoStatus {
  ATTENDED_AUTO = 'attended_auto',
  ATTENDED_MANUAL = 'attended_manual',
  MISSED = 'missed',
  WATCHED_RECORDING = 'watched_recording',
  NOT_AVAILABLE = 'not_available',
  IN_PROGRESS = 'in_progress',
  PENDING = 'pending',
}
export interface AuthenticatedUser {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  accountEmail?: string;
}
export interface OAuthStatus {
  authenticatedStatus: 'authenticated' | 'not_authenticated';
  authenticatedUser?: AuthenticatedUser;
}

type AttendeeInfo = {
  notificationRead: boolean
  register: boolean
  startPlaybackFrom: number
  status: AttendeeInfoStatus
};

// Session types
type SharedSession = {
  id?: number
  title: string
  description?: string
  startTime?: string
  duration?: any
  recording?: any
  index: number
  timeZone?: any
  attendeeInfo?: AttendeeInfo
};

type ExternalSettings = {
  joinUrl: string
};

export type ZoomSettings = {
  recordMeetingAutomatically: boolean
  automaticallyUploadRecording: boolean
  participantVideo: boolean
  muteUponEntry: boolean
  enableEmailConfirmation: boolean
  trackAttendance: boolean
  authenticationEmail?: string
  alternativeHosts?: string | string[]
};

export type MSTeamsSettings = {
  lobbyBypassSettings?: any
  recordMeetingAutomatically: boolean
  automaticallyUploadRecording: boolean
};

export type ExternalSession = SharedSession & ExternalSettings;
export type ZoomSession = SharedSession & ZoomSettings;
export type MSTeamsSession = SharedSession & MSTeamsSettings;
export type LiveEventSession = ExternalSession | ZoomSession | MSTeamsSession;

// Live Event Settings types
export type SessionSettings = ZoomSettings | MSTeamsSettings | {};
export type LiveEvent = {
  sessionType: SessionTypes
  multisessionSetting: boolean
  sessions: LiveEventSession[]
  settings?: SessionSettings
};

// Live Event values
const commonSession = {
  title: '',
  description: '',
  startTime: '',
  duration: {
    id: 30,
  },
  timeZone: {
    id: 'Etc/GMT+12',
  },
};

const defaultMsTeamsSettingsValues: MSTeamsSettings = {
  lobbyBypassSettings: {
    id: MS_LOBBY_BY_PASS_TYPES.ORGANIZER,
  },
  automaticallyUploadRecording: false,
  recordMeetingAutomatically: false,
};

const defaultZoomSettingsValues: ZoomSettings = {
  recordMeetingAutomatically: false,
  automaticallyUploadRecording: false,
  participantVideo: false,
  muteUponEntry: false,
  enableEmailConfirmation: false,
  trackAttendance: true,
};

const defaultSession = {
  [SessionTypes.EXTERNAL]: {
    ...commonSession,
    joinUrl: '',
  } as Omit<ExternalSession, 'index'>,
  [SessionTypes.ZOOM]: {
    ...commonSession,
    ...defaultZoomSettingsValues,
    alternativeHosts: '',
  } as Omit<ZoomSession, 'index'>,
  [SessionTypes.MS_TEAMS]: {
    ...commonSession,
    ...defaultMsTeamsSettingsValues,
  } as Omit<MSTeamsSession, 'index'>,
};

export const getStartTime = (startTime: string | moment.Moment, timeZone: any, reversed: boolean) => {
  if (!startTime) return '';
  const startTimeToDate = moment.isMoment(startTime)
    ? startTime.toDate()
    : new Date(startTime);
  const date = machineDateAndTimezoneDateConversion(startTimeToDate, timeZone.id, reversed);
  return reversed ? date.toISOString() : date.toString();
};

type DefaultSessionType = {
  sessionType: SessionTypes;
  index?: number;
  extraProps?: SessionSettings;
  session?: LiveEventSession;
  isContentManagementCollection?: boolean;
  timezone?: string;
  isAccountLevel?: boolean;
};

export const getSession = (config: DefaultSessionType): LiveEventSession => {
  const session = _.clone(defaultSession[config.sessionType]);
  const existingSession = config.session;
  const timeZone = existingSession?.timeZone?.id ?? existingSession?.timeZone ?? config.timezone;

  if (config.sessionType === SessionTypes.ZOOM && config.isAccountLevel) {
    (session as ZoomSession).authenticationEmail = '';
  }

  if (existingSession) {
    Object.keys({ ...session, id: null }).forEach((key) => {
      if (existingSession[key] !== undefined && !(existingSession[key] === null && config.sessionType === SessionTypes.ZOOM)) {
        session[key] = existingSession[key];
      }
    });
  }

  if (timeZone) {
    session.timeZone = {
      id: timeZone,
      value: moment.tz(timeZone).format('(UTCZ)'),
    };
  }

  if (config.isContentManagementCollection) {
    // unset default values for duration, timezone and start time
    session.duration = '';
    session.timeZone = '';
    session.startTime = '';
    if (config.sessionType === SessionTypes.ZOOM && config.isAccountLevel) {
      (session as ZoomSession).authenticationEmail = '';
    }
  }

  const fullSession = {
    ...session,
    index: config.index || 0,
    ...config.extraProps,
  };
  return fullSession;
};

// Default values for empty live session
const defaultSessionSettings: SessionSettings = {
  [SessionTypes.EXTERNAL]: {}, // no settings for external
  [SessionTypes.ZOOM]: defaultZoomSettingsValues,
  [SessionTypes.MS_TEAMS]: defaultMsTeamsSettingsValues,
};

const getDefaultSessions = (
  sessionType: SessionTypes,
  isContentManagementCollection: boolean = false,
  timezone = null,
  isAccountLevel: boolean = false,
) => (
  [getSession({
    sessionType,
    isContentManagementCollection,
    timezone,
    isAccountLevel,
  })]
);

export type Draft = {
  id: number
  timezone: string
  sessions: LiveEventSession[]
};

// Get the values from the existing data
const sanitizeSessions = (
  sessionType: SessionTypes,
  draft: Draft,
  isLinked: boolean,
  isAccountLevel: boolean,
  isContentManagementCollection: boolean,
  hasRecordingScope?: boolean,
) => {
  const {
    sessions,
    timezone,
  } = draft;
  if (!sessions) {
    return null;
  }

  return sessions.map((session, index) => {
    const sanitizedSession = getSession({
      sessionType,
      session,
      isAccountLevel,
      timezone,
      isContentManagementCollection,
    });
    if (sanitizedSession.duration && !sanitizedSession.duration.id) {
      sanitizedSession.duration = { id: sanitizedSession.duration };
    }
    if (sanitizedSession.timeZone && !sanitizedSession.timeZone.id) {
      sanitizedSession.timeZone = { id: sanitizedSession.timeZone };
    }
    if (isLinked) {
      sanitizedSession.duration = sanitizedSession.duration ?? { id: 30 };
      sanitizedSession.timeZone = sanitizedSession.timeZone ?? { id: timezone };
    }
    if (!sanitizedSession.duration) {
      sanitizedSession.duration = { id: 30 };
    }

    if ((sanitizedSession as MSTeamsSession).recordMeetingAutomatically === null
      || !hasRecordingScope
    ) {
      (sanitizedSession as MSTeamsSession).recordMeetingAutomatically = false;
    }

    if ((sanitizedSession as MSTeamsSession).automaticallyUploadRecording === null
      || !hasRecordingScope
    ) {
      (sanitizedSession as MSTeamsSession).automaticallyUploadRecording = false;
    }

    sanitizedSession.index = index;
    sanitizedSession.startTime = getStartTime(sanitizedSession.startTime, sanitizedSession.timeZone, false);
    return sanitizedSession;
  });
};

const getSettingsFromDraft = (draft: any) => {
  const { sessions } = draft;
  const session = sessions[0];
  switch (session.sessionType) {
    case SessionTypes.EXTERNAL:
      return {};
    case SessionTypes.ZOOM:
      return {
        recordMeetingAutomatically: session.recordMeetingAutomatically ?? false,
        automaticallyUploadRecording: session.automaticallyUploadRecording ?? false,
        participantVideo: session.participantVideo ?? false,
        muteUponEntry: session.muteUponEntry ?? false,
        enableEmailConfirmation: session.enableEmailConfirmation ?? false,
        trackAttendance: session.trackAttendance ?? true,
      };
    case SessionTypes.MS_TEAMS:
      return {
        lobbyBypassSettings: {
          id: session.lobbyBypassSettings ?? MS_LOBBY_BY_PASS_TYPES.ORGANIZER,
        },
        recordMeetingAutomatically: session.recordingScope ? session.recordMeetingAutomatically : false,
        automaticallyUploadRecording: session.recordingScope ? session.automaticallyUploadRecording : false,
      };
    default:
      return {};
  }
};

export const getDefaultLiveEvent = (
  sessionType: SessionTypes,
  draft: Draft,
  isContentManagementCollection: boolean = false,
  isLinked: boolean = false,
  isAccountLevel: boolean = false,
  hasRecordingScope: boolean = false,
) => ({
  sessionType,
  multisessionSetting: false,
  sessions: draft.id
    ? sanitizeSessions(
      sessionType,
      draft,
      isLinked,
      isAccountLevel,
      isContentManagementCollection,
      hasRecordingScope,
    ) : getDefaultSessions(
      sessionType,
      isContentManagementCollection,
      draft.timezone,
      isAccountLevel,
    ),
  settings: draft.id ? getSettingsFromDraft(draft) : defaultSessionSettings[sessionType],
});

export const convertToStandardTime = (time: moment.Moment) => {
  // Changing temporarily the locale to get the expected numeric/Gregorian date
  // No matter what language/locale is originally.
  const currentLocale = moment.locale();
  moment.locale('en');
  const convertedDate = moment(time.toDate()).format('MM/DD/YYYY hh:mm a');
  moment.locale(currentLocale);
  return convertedDate;
};

const getSessionPayload = (
  session: LiveEventSession,
  sessionType: SessionTypes,
  settingsCopy: SessionSettings,
  isAccountLevel: boolean,
) => {
  const sessionStartTime = moment.isMoment(session.startTime) ? convertToStandardTime(session.startTime) : session.startTime;
  const startTime = getStartTime(sessionStartTime, session.timeZone, true);
  const sessionPayload: any = {
    ...session,
    ...settingsCopy,
    startTime,
    duration: session.duration?.id,
    timeZone: session.timeZone?.id,
    sessionType,
  };

  if (sessionType === SessionTypes.MS_TEAMS) {
    const settings = settingsCopy as MSTeamsSettings;
    sessionPayload.lobbyBypassSettings = settings.lobbyBypassSettings?.id ?? settings.lobbyBypassSettings;
  }

  if (sessionType === SessionTypes.ZOOM && !isAccountLevel) {
    delete sessionPayload.authenticationEmail;
  }

  return sessionPayload;
};

export const getLiveEventPayload = (
  data: LiveEvent,
  creatorUserCourseId: number,
  sessionType: SessionTypes,
  isAccountLevel: boolean,
  draft: AngularLiveEvent,
  isContentManagementCollection: boolean = false,
) => {
  const cloneSettings = _.clone(data.settings);
  const { mainTitle, mainDescription, id } = draft;
  const deleteKeys = ['settings', 'multisessionSetting', 'sessionType'];
  const sessions = data.sessions.map((session: LiveEventSession) => (
    getSessionPayload(session, sessionType, cloneSettings, isAccountLevel)
  ));
  const isMultisession = sessions.length > 1;
  const isSingleSession = sessions.length === 1;
  const isConvertedToMulti = !sessions?.[1]?.id;

  const liveSession = { ...data, sessions, isMultisession: data.multisessionSetting };
  const payload: any = { liveSession };

  if (creatorUserCourseId) {
    payload.creatorUserCourseId = creatorUserCourseId;
  }

  if (isMultisession) {
    const title = isConvertedToMulti ? 'Live Video Events' : (mainTitle ?? 'Live Video Events');
    const description = isConvertedToMulti ? '' : (mainDescription ?? '');
    payload.liveSession.mainTitle = title;
    payload.liveSession.mainDescription = description;
  }

  if (isSingleSession) {
    if (!payload.liveSession.description) {
      payload.liveSession.description = sessions[0].description;
      payload.liveSession.title = sessions[0].title;
    }
    sessions[0].description = payload.liveSession.description;
    sessions[0].title = payload.liveSession.title;
  }

  if (isContentManagementCollection) {
    payload.liveSession.mainDescription = data.sessions[0].description;
    payload.liveSession.mainTitle = data.sessions[0].title;
  }

  deleteKeys.forEach((key) => delete payload.liveSession[key]);

  return payload;
};
