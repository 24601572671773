import React from 'react';
import { kebabCase } from 'lodash';
import { LhsMenuItem, LhsMenuItemProps } from './lhs-menu-item';

export type LhsSectionMenuItem = LhsMenuItemProps & { show: boolean };

export type LhsSectionProps = {
  id: string;
  menuItemSeparator: boolean;
  menuItems: Array<LhsSectionMenuItem>;
};

export const LhsMenuSection = ({
  id,
  menuItemSeparator,
  menuItems,
}: LhsSectionProps) => (
  <React.Fragment key={id}>
    {menuItemSeparator && <li role='separator' className='menu-item-separator' />}
    {menuItems.map(({ label, icon, highlighted, expanded, onClick, dataQa }) => (
      <LhsMenuItem
        key={kebabCase(`lhs-item-${label}`)}
        label={label}
        icon={icon}
        highlighted={highlighted}
        expanded={expanded}
        onClick={onClick}
        dataQa={dataQa}
      />
    ))}
  </React.Fragment>
);

export default LhsMenuSection;
