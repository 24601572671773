import * as Yup from 'yup';
import t from 'react-translate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import {
  halfSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ActionItemOwner,
  ActionItemStatus,
  MentoringProgramActionItem,
} from 'redux/schemas/models/mentoring-program-action-items';
import { yupResolver } from '@hookform/resolvers/yup';
import { gray7 } from 'styles/global_defaults/colors';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import ActionStatus from './action-status';
import { CHECKBOX_SIZE } from './constants';
import ActionDescription from './action-description';
import ActionMenu from './action-menu';
import { ActionItemFormData } from './types';
import { ACTION_ITEM_DESCRIPTION_MAX_LENGTH } from '../mentoring-program/constants';

type ActionItemProps = {
  item: MentoringProgramActionItem;
  onAdd: (formData: ActionItemFormData, connectionId?: number) => void;
  onUpdate: (
    id: number | string,
    formData: ActionItemFormData,
    connectionId?: number
  ) => void;
  onDelete: (
    id: number | string,
    sessionId?: number,
    connectionId?: number
  ) => void;
  isEditing: boolean;
  setEditingId: (id: number) => void;
  connectionId: number;
  enrollments: { [id: number]: MentoringProgramEnrollment };
  showPlanSessionFlyout?: (session: MentorshipProgramSession) => void;
};

const styles = css`
  display: grid;
  gap: ${halfSpacing}px;
  grid-template-columns: ${CHECKBOX_SIZE}px 1fr min-content;
  padding: ${quarterSpacing}px;

  &.highlight-item {
    &:hover {
      background: ${gray7};
      border-radius: ${quarterSpacing / 2}px;
      .delete-action-item {
        opacity: 1;
      }
    }
  }
`;

const ActionItem = ({
  item,
  onAdd,
  onUpdate,
  onDelete,
  isEditing,
  setEditingId,
  connectionId,
  enrollments,
  showPlanSessionFlyout,
}: ActionItemProps) => {
  const [formData, setFormData] = useState<ActionItemFormData>();

  const validationSchema = Yup.object().shape({
    status: Yup.string().oneOf(Object.values(ActionItemStatus)),
    description: Yup.string().max(
      ACTION_ITEM_DESCRIPTION_MAX_LENGTH,
      t.VALIDATION.MAX_LENGTH(`${ACTION_ITEM_DESCRIPTION_MAX_LENGTH}`)
    ),
    assignedTo: Yup.number(),
    dueDate: Yup.string(),
    mentorshipProgramConnectionId: Yup.number(),
    mentorshipProgramSessionId: Yup.number(),
  });

  const defaultValues = useMemo(
    () => ({
      status: item.status,
      description: item.description,
      assignedTo: item.assignedTo?.id,
      dueDate: item.dueDate,
      mentorshipProgramConnectionId:
        item.owner?.type === ActionItemOwner.CONNECTION && item.owner?.id,
      mentorshipProgramSessionId:
        item.owner?.type === ActionItemOwner.SESSION && item.owner?.id,
    }),
    [item]
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const {
    status,
    description,
    assignedTo,
    mentorshipProgramConnectionId,
    mentorshipProgramSessionId,
  } = watch();

  const onChange = useCallback(() => {
    if (formData && description && !errors.description) {
      if (item.id) {
        onUpdate(item.id, formData, connectionId);
      } else {
        onAdd(formData, connectionId);
        reset();
      }
    }
    setEditingId(null);
  }, [formData]);

  const onReset = () => {
    reset(defaultValues);
  };

  const handleDelete = () => {
    onDelete(
      item.id,
      item.owner?.type === ActionItemOwner.SESSION ? item.owner?.id : undefined,
      connectionId
    );
  };

  useEffect(() => {
    if (item.id) {
      setValue('description', item.description);
      setValue('assignedTo', item.assignedTo.id);
    }
  }, [item]);

  useEffect(() => {
    const { unsubscribe } = watch(setFormData);
    return () => unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (status !== item.status || assignedTo !== item.assignedTo.id) {
      onChange();
    }
  }, [status, assignedTo]);

  const isCompleted = status === ActionItemStatus.COMPLETED;

  return (
    <FormProvider {...methods}>
      <form>
        <div
          css={styles}
          className={`${
            item.id && !isEditing ? 'highlight-item' : ''
          } align-items-center`}
        >
          <ActionStatus actionItemId={item.id} status={status} />
          <ActionDescription
            actionItemId={item.id}
            onChange={onChange}
            onReset={onReset}
            isEditing={isEditing}
            description={description}
            setEditingId={setEditingId}
            isCompleted={isCompleted}
          />
          <ActionMenu
            item={item}
            onChange={onChange}
            isCompleted={isCompleted}
            isEditing={isEditing}
            disabled={!description || !!errors.description}
            assignedTo={assignedTo}
            sessionId={mentorshipProgramSessionId}
            enrollments={enrollments}
            onDelete={handleDelete}
            showPlanSessionFlyout={showPlanSessionFlyout}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default ActionItem;
