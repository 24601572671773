/* @ngInject */
export default function ReportsResources(

  $resource,
) {
  return $resource(
    '/:catalogId/reports/:reportId',
    {},
    {
      update: { method: 'PUT', url: '/:catalogId/reports/:reportId' },
      submit: { method: 'POST', url: '/:catalogId/reports/flipsubmit/:reportId' },
      query: { method: 'GET', url: '/:catalogId/reports/all/:exerciseId', isArray: false },
      searchSubmissions: { method: 'GET', url: '/:catalogId/reports/search', cancellable: true },
      getSingleSubmission: { method: 'GET', url: '/:catalogId/reports/:reportId' },
      flagSubmission: { method: 'POST', url: '/:catalogId/flaggings' },
      unflagSubmission: { method: 'DELETE', url: '/:catalogId/flaggings/:flagId.json' },
      updatePrivacy: { method: 'POST', url: '/:catalogId/reports/update_privacy_setting/:reportId.json' },
      likeSubmission: { method: 'GET', url: '/:catalogId/reports/:reportId/vote_up.json' },
      unlikeSubmission: { method: 'GET', url: '/:catalogId/reports/:reportId/vote_down.json' },
      canEditSubmission: { method: 'GET', url: '/:catalogId/reports/:reportId/can_edit.json' },
      getTrendingSubmissions: { method: 'GET', url: '/:catalogId/home/trending_submissions' },
      getLikersInfo: { method: 'GET', url: '/:catalogId/reports/:reportId/voters?page=:page' },
      getAllSubmissionsForUserInCourse: { method: 'GET', url: '/:catalogId/users/:userId/submissions' },

      searchSubmissionsForApproval: { method: 'POST', url: '/submissions_approval/search', cancellable: true },
      approveSubmission: { method: 'POST', url: '/reports/:id/approve' },
      rejectSubmission: { method: 'POST', url: '/reports/:id/reject' },
      markViewed: { method: 'PUT', url: '/:catalogId/reports/:id/mark_viewed' },
      translate: { method: 'POST', url: '/:catalogId/reports/:id/translate' },
      translateApprovalComments: { method: 'POST', url: '/:catalogId/reports/:id/translate_review/:submission_review' },
    },
  );
}
