import React from 'react';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { config } from '@config/pendo.config.json';
import type { RowProps } from 'shared/components/nv-table';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { almostWhite, shadedWhite } from 'athena/styles/colors';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import RoleSelect from 'athena/components/mentoring-program/role-select';
import { OrganizationUsersSearchUserResult } from 'redux/schemas/api/user';
import { enrollUserToProgram } from 'redux/actions/mentoring-program-enrollments';
import { NameCell } from 'athena/components/mentoring-program/participants/participant-row';
import { commonStyles } from 'athena/components/mentoring-program/participants/participant-row/styles';
import type { CellProps } from 'athena/components/mentoring-program/participants/add-participants-modal/participant-row/types';

const AddCell = (props: CellProps) => {
  const { cell, className } = props;

  const value = cell.getValue();
  const dispatch = useAppDispatch();
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { programId } = $state.params;
  const [selectedRole, setSelectedRole] = React.useState(null);

  const handleAdd = () => wrapThunkAction(
    dispatch(enrollUserToProgram({
      programId,
      email: value.email,
      role: selectedRole,
    })),
  ).then((action) => {
    dispatch(addAlertMessage({
      header: t.FORM.SUCCESS_BANG(),
      type: AlertMessageType.SUCCESS,
      message: t.MENTORING_PROGRAMS.PARTICIPANTS.ADD_SUCCESS((action.payload as any).newlyEnrolled.length),
    }));
    value.onAdd?.();
  });

  const styles = css`
    ${commonStyles};

    .user-role-select {
      flex: 1;
    }
  `;

  return (
    <div className={`d-flex align-items-center ${className ?? ''}`} css={styles}>
      <RoleSelect value={selectedRole} onChange={setSelectedRole} showLabel={false} className='user-role-select mr-2' />
      <Button
        disabled={!selectedRole}
        onClick={handleAdd}
        data-qa={config.pendo.athena.mentorshipProgram.participants.participantModal.addParticipantBySearch}
      >
        {t.FORM.ADD()}
      </Button>
    </div>
  );
};

const cells = {
  name: NameCell,
  add: AddCell,
};

export type ParticipantRowProps = OrganizationUsersSearchUserResult & {
  onAdd?: () => void
};

const ParticipantRow = (props: RowProps<ParticipantRowProps>) => {
  const { row, className, getCellStyle } = props;

  const styles = css`
    border-bottom: 1px solid ${shadedWhite};

    &:hover {
      background-color: ${almostWhite};

      .options-target {
        opacity: 1;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  `;

  return (
    <div css={styles} className={className}>
      {row.getVisibleCells().map((cell, index) => {
        const CellComponent = cells[cell.column.id];

        return (
          <CellComponent css={getCellStyle(index)} cell={cell} key={cell.id} />
        );
      })}
    </div>
  );
};

export default ParticipantRow;

