import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import t from 'react-translate';
import { css, SerializedStyles } from '@emotion/react';

import { AngularServicesContext } from 'react-app';

import { Course } from 'redux/schemas/models/course';
import { Institution } from 'redux/schemas/models/institution';
import tinycolor from 'tinycolor2';

import NvIcon from 'shared/components/nv-icon';
import { NvTableCellProps, NvTableRowProps } from 'shared/components/nv-responsive-table';
import { createGridStyles, halfSpacing, quarterSpacing, standardSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { boldFontWeight, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { gray3, gray5, primary, warning, white } from 'styles/global_defaults/colors';
import { isHandheld } from 'styles/global_defaults/media-queries';

import { CompletionRateProgress } from 'institutions/components/course-row';
import Badge from 'cohort_management/components/badge';
import NvBadge from 'shared/components/nv-badge';

import { SortAttr } from './types';

const getOfferingCoverImage = (offering: Course) => {
  const courseCoverImage = offering.thumbnail && !offering.thumbnail.includes('temp.png') ? offering.thumbnail : '';

  const journeyCoverImage = offering.headerBackground ?? offering.logo?.url;

  return offering.isJourney ? journeyCoverImage : courseCoverImage;
};

const isWhite = (color: string) => tinycolor(color).toHex() === tinycolor(white).toHex();

const getOfferingAvatarColor = (offering: Course, institution: Institution, coverImg?: string) => {
  const courseAvatarColor = coverImg ? '' : offering.headerColor ?? institution.brandColor;

  const isOfferingHeaderWhite = isWhite(offering.headerColor);
  const isInstitutionHeaderWhite = isWhite(institution.brandColor);

  const headerWhiteFallback = isInstitutionHeaderWhite ? gray3 : institution.brandColor;

  const journeyAvatarColor = isOfferingHeaderWhite ? headerWhiteFallback : offering.headerColor;

  return offering.isJourney ? journeyAvatarColor : courseAvatarColor;
};

const commonStyles = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const nameCellStyles = css`
  ${commonStyles}
  flex-direction: row;
  justify-content: 'center';
  grid-column: 1;
  padding: ${standardSpacing}px;
  gap: ${halfSpacing}px;

  .cover-image-container {
    width: 100px;
    min-width: 100px;
    height: 60px;
    pointer-events: all;
    position: relative;

    .course-cover-img {
      display: bock;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .course-name-container {
    display: flex;
    flex-direction: column;
    gap: ${quarterSpacing}px;
    font-size: ${textSmallFontSize}px;
    line-height: ${textSmallLineHeight}px;
    overflow: hidden;

    .course-name {
      font-weight: ${boldFontWeight};

      &:hover {
        color: ${primary};
      }
    }
    .nv-badge {
      height: ${standardSpacing}px;
    }
  }
`;

const courseCardStyles = css`
  display: flex;
  gap: ${halfSpacing}px;
  padding: ${standardSpacing}px ${halfSpacing}px;
  border-bottom: 1px solid ${gray5};
  z-index: 2;

  &:hover {
    border-bottom: 1px solid ${warning};
  }

  .cover-image-container {
    width: 100px;
    min-width: 100px;
    height: 60px;
    pointer-events: all;
    position: relative;

    .course-cover-img {
      display: bock;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .course-container {
    display: flex;
    flex-direction: column;
    gap: ${quarterSpacing}px;

    .course-name {
      font-size: ${textSmallFontSize}px;
      font-weight: ${boldFontWeight};

      &:hover {
        color: ${primary};
      }
    }

    .release-and-enrollees {
      padding: ${quarterSpacing}px 0;
      display: flex;
      flex-direction: column;
      gap: ${halfSpacing}px;
    }

    .completion {
      margin-top: ${standardSpacing}px;
    }
  }
`;

export type CourseRowExtraProps = {
  institution: Institution,
  selectedSortAttr: SortAttr,
};

type CourseCellProps = NvTableCellProps<Course, unknown, CourseRowExtraProps> & {
  course: Course,
  // eslint-disable-next-line react/no-unused-prop-types
  institution: Institution,
  selectedSortAttr: SortAttr,
};

const CourseRow = (props: NvTableRowProps<Course, {}, CourseRowExtraProps>) => {
  const cells: [string, (props: CourseCellProps) => JSX.Element, SerializedStyles][] = [
    ['name-cell', NameCell, nameCellStyles],
    ['sort-date-cell', SortDateCell, commonStyles],
    ['num-enrollees-cell', NumEnrolleesCell, commonStyles],
    ['completion-rate-cell', CompletionRateCell, commonStyles],
  ];

  const [showMobileView, setShowMobileView] = useState(isHandheld());

  useEffect(() => {
    function handleResize() {
      setShowMobileView(isHandheld());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      {showMobileView ? (
        <CourseCard
          {...props}
          reactKey={`${props.rowIndex}-mobile`}
          serializedStyles={courseCardStyles}
          divProps={{ style: createGridStyles(1, props.rowIndex, 2, props.rowIndex + 1) }}
          course={props.data}
          institution={props.extraProps.institution}
          selectedSortAttr={props.extraProps.selectedSortAttr}
        />
      ) : (
        cells.map((Cell, i) => {
          const [key, Component, styles] = Cell;
          const cellProps: CourseCellProps = {
            ...props,
            reactKey: `${props.rowIndex}-${i + 1}`,
            serializedStyles: styles,
            divProps: {
              style: createGridStyles(i + 1, props.rowIndex, i + 2, props.rowIndex + 1),
            },
            course: props.data,
            institution: props.extraProps.institution,
            selectedSortAttr: props.extraProps.selectedSortAttr,
          };

          return <Component key={key} {...cellProps} />;
        })
      )}
    </React.Fragment>
  );
};

const coverImgStyles = (coverImg, avatarColor) => css`
  width: 100%;
  height: 100%;
  background-image: url(${coverImg});
  background-color: ${avatarColor};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const badgeStyles = (avatarColor) => css`
  display: flex;
  gap: ${quarterSpacing}px;
  padding: 2px ${halfSpacing}px;
  color: ${white};
  background-color: ${avatarColor};
  border-radius: ${largeSpacing / 2}px;
  font-size: ${textSmallFontSize}px;
  font-weight: ${boldFontWeight};
  width: fit-content;
`;

type CourseCoverImgProps = {
  catalogId: string;
  goToCourseUrl: string;
  coverImg: string;
  avatarColor: string;
  isPrimary: boolean;
  isCohort: boolean;
  userCourse: number;
};

const CourseCoverImg = ({ catalogId, goToCourseUrl, coverImg, avatarColor, isPrimary, isCohort, userCourse }: CourseCoverImgProps) => (
  <div className='cover-image-container'>
    <a
      target='_blank'
      href={goToCourseUrl}
      rel='noopener noreferrer'
      className='course-cover-img'
      data-offering-name={catalogId}
    >
      <div css={coverImgStyles(coverImg, avatarColor)} />
      <Badge
        course={{
          isPrimary,
          isCohort,
          userCourse,
        } as Course}
      />
    </a>
  </div>
);

const NameCell = (props: CourseCellProps) => {
  const angularServices = useContext(AngularServicesContext);

  const { course, institution } = props;

  const goToCourseUrl = angularServices.$state.href(
    course.isJourney ? 'learning-journey-home' : 'course-home',
    {
      catalogId: course.catalogId,
    },
  );

  const coverImg = getOfferingCoverImage(course);
  const avatarColor = getOfferingAvatarColor(course, institution, coverImg);

  return (
    <div className='name-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <CourseCoverImg
        catalogId={course.catalogId}
        goToCourseUrl={goToCourseUrl}
        coverImg={coverImg}
        avatarColor={avatarColor}
        isPrimary={course.isPrimary}
        isCohort={course.isCohort}
        userCourse={course.userCourse}
      />
      <div className='course-name-container'>
        {course.isJourney && (
          <div className='course-journey-badge' css={badgeStyles(avatarColor)}>
            <NvIcon icon='path' size='xss-smallest' />
            {t.OFFERINGS.CARD.BADGE.LEARNING_JOURNEY()}
          </div>
        )}
        {
          !!course.archivedAt && (
            <NvBadge
              className='nv-badge font-weight-bolder text-gray-1'
            >
              {t.COURSES.ARCHIVE.BADGE()}
            </NvBadge>
          )
        }
        <div className='course-name'>
          <h2 className='course-title-xxs mb-0 font-weight-bolder mt-0'>
            <a
              href={goToCourseUrl}
              target='_blank'
              rel='noopener noreferrer'
              data-offering-name={course.catalogId}
            >
              {course.name}
            </a>
          </h2>
        </div>
        <div className='course-catalog-id'>
          {course.catalogId}
        </div>
      </div>
    </div>
  );
};

const SortAttrPropNameMap: { [key in SortAttr]: string } = {
  [SortAttr.LAST_ACTIVITY_DATE]: 'lastActiveDate',
  [SortAttr.RELEASE_DATE]: 'releaseDate',
  [SortAttr.START_DATE]: 'officialReleaseDate',
  [SortAttr.END_DATE]: 'endDate',
  [SortAttr.CLOSE_DATE]: 'closeDate',
};

const SortDateCell = (props: CourseCellProps) => {
  const { course, selectedSortAttr } = props;

  const sortDate = selectedSortAttr ? course[SortAttrPropNameMap[selectedSortAttr]] : course.lastActiveDate;

  return (
    <div className='sort-date-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {sortDate && moment(sortDate).format('MM/DD/YYYY')}
    </div>
  );
};

const NumEnrolleesCell = (props: CourseCellProps) => {
  const { course } = props;

  return (
    <div className='num-enrolled-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      {course.numEnrolled ?? 0}
    </div>
  );
};

const CompletionRateCell = (props: CourseCellProps) => {
  const { course } = props;

  return (
    <div className='completion-date-cell' css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <CompletionRateProgress course={course} />
    </div>
  );
};

const CourseCard = (props: CourseCellProps) => {
  const angularServices = useContext(AngularServicesContext);

  const { course, institution, selectedSortAttr } = props;

  const goToCourseUrl = angularServices.$state.href('course-home', {
    catalogId: course.catalogId,
  });

  const coverImg = getOfferingCoverImage(course);
  const avatarColor = getOfferingAvatarColor(course, institution, coverImg);

  const sortDate = selectedSortAttr ? course[SortAttrPropNameMap[selectedSortAttr]] : course.releaseDate;

  return (
    <div css={props.serializedStyles} key={props.reactKey} {...props.divProps}>
      <CourseCoverImg
        catalogId={course.catalogId}
        goToCourseUrl={goToCourseUrl}
        coverImg={coverImg}
        avatarColor={avatarColor}
        isPrimary={course.isPrimary}
        isCohort={course.isCohort}
        userCourse={course.userCourse}
      />
      <div className='course-container'>
        {course.isJourney && (
          <div className='course-journey-badge' css={badgeStyles(avatarColor)}>
            <NvIcon icon='path' size='xss-smallest' />
            {t.OFFERINGS.CARD.BADGE.LEARNING_JOURNEY()}
          </div>
        )}
        <div className='course-name'>
          <a
            href={goToCourseUrl}
            target='_blank'
            rel='noopener noreferrer'
            data-offering-name={course.catalogId}
          >
            {course.name}
          </a>
        </div>
        <div>
          {course.catalogId}
        </div>
        <div className='release-and-enrollees'>
          <div>
            {sortDate && moment(sortDate).format('MM/DD/YYYY')}
          </div>
          <div>
            {`${course.numEnrolled ?? 0} ${t.COURSE_ADMIN_DASHBOARD.TABLE.ENROLLEES()}`}
          </div>
        </div>
        <div className='completion'>
          <CompletionRateProgress course={course} />
        </div>
      </div>
    </div>
  );
};

export default CourseRow;
