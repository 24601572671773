import t from 'react-translate';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import { ReactComponent as LightGrayAvatar } from 'styles/icons/light-gray-avatar.svg';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import MentoringProgramContext from '../context';


type EmptyParticipantsProps = {
  role: MentoringProgramRole;
  className?: string;
};

const EmptyParticipants = ({ role, className }: EmptyParticipantsProps) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentoringProgram?.id));

  return (
    <div className={`${className} d-flex flex-column align-items-center justify-content-center empty-ui`}>
      <LightGrayAvatar width={80} height={80} className='mb-3' />
      <div className='text-medium'>
        {t.MENTORING_PROGRAMS.PARTICIPANTS.EMPTY({
          role,
          ...mentoringProgramAliases,
        })}
      </div>
    </div>
  );
};

export default EmptyParticipants;
