import { css } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import t from 'react-translate';

import { BookmarkProps } from 'redux/schemas/models/bookmark';

import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { primary, white } from 'styles/global_defaults/colors';

import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import ImageComponentImage, { ImageComponentImageProps } from './image-component-image';

type ImageFullViewDialogProps = ImageComponentImageProps & BookmarkProps & {
  closeDialog: VoidFunction;
  open: boolean;
};

export const ImageFullViewDialog = React.forwardRef<HTMLDialogElement, ImageFullViewDialogProps>((props, ref) => {
  const { closeDialog, open } = props;
  const [containerRef, setContainerRef] = useState(null);

  const container = useCallback(node => {
    if (open) {
      setContainerRef(node);
    }
  }, [open]);

  const styles = css`
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background-color: white;
    /** TODO : This component was thrown together very quickly to support the ImageLectureComponent release.
    We need to be more mindful about these z-indices and have them defined in a central file somewhere. */
    z-index: 10000;

    .bookmark-icon {
      margin-right: ${standardSpacing}px;
    }

    .top-bar {
      height: 35px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;

      .back-button {
        background-color: unset;
        &:hover {
          cursor: pointer;
          color: ${white};
        }
      }

      .bookmark-icon:hover {
        color: ${primary};
      }
    }

    .img-component-container {
      margin-top: 35px;
    }
  `;

  return (
    <dialog
      className='react-app mw-100 mh-100 w-100 h-100 border-0'
      ref={ref}
      aria-label={t.LECTURE_PAGES.COMPONENTS.IMAGE.FULL_SCREEN_IMAGE()}
    >
      <div className='' css={styles}>
        <div className='top-bar d-flex align-items-center justify-content-between text-white bg-gray-2'>
          <button
            type='button'
            className='back-button d-flex align-items-center p-2 border-0'
            onClick={closeDialog}
            aria-label={t.LECTURE_PAGES.COMPONENTS.SCORM.EXIT_FULL_SCREEN()}
          >
            <span
              className='icon icon-xss-smallest icon-collapse pr-2'
              aria-hidden='true'
            />
            <span className='text-small'>
              {t.LECTURE_PAGES.COMPONENTS.SCORM.EXIT_FULL_SCREEN()}
            </span>
          </button>
          <BookmarkIcon
            size='smallest'
            bookmarked={!!props.bookmarkId}
            onCreate={props.onCreateBookmark}
            onHighlight={props.onHighlightBookmark}
            colors={{
              default: 'white',
              bookmarked: 'white',
            }}
          />
        </div>
        <div className='img-component-container w-100' ref={container}>
          <ImageComponentImage
            bookmarkHidden
            fitToContainer
            maxImageWidth={800}
            sizeContainerRef={{ current: containerRef }}
            {...props}
          />
        </div>
      </div>
    </dialog>
  );
});

export default ImageFullViewDialog;
