/* eslint-disable react/require-default-props */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import t from 'react-translate';
import { pick } from 'underscore';
import ColorPickerPopover from 'components/color-picker-popover';
import {
  OrderedAccordionSectionLectureComponent,
  Style3AccordionSectionViewOptions,
  Style4AccordionSectionViewOptions,
} from 'redux/schemas/models/lecture-component';
import NvTooltip from 'shared/components/nv-tooltip';
import { standardSpacing, halfSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import ClickableContainer, { ClickableContainerProps } from 'components/clickable-container';

import { ConditionalWrap } from 'components/conditional-wrap';
import { useLecturePageFromParams } from 'lecture_pages/hooks/lecture-routing';
import { LecturePageMode } from '..';
import AccordionRtHeader from './accordion-rt-header';
import { SectionContainerProps } from './accordion-types';
import useAccordionSectionContainer from './use-accordion-section-container';
import ApplyToAllSectionsButton from './apply-to-all-sections-button';
import FixedHeightLetter from './fixed-height-letter';
import ArrowToggleIcon from './arrow-toggle-icon';
import { getSanitizedStyles } from 'shared/utils';

export type OrderedSectionType = 'decimal' | 'alphabetical';

const leftHandSymbolContainerStyle = css`
  width: 65px;
  display: flex;
  flex-shrink: 0;
  margin-right: ${halfSpacing}px;
`;

const leftHandSymbolSquareContainerStyle = css`
  min-height: 65px;
  margin-right: ${standardSpacing}px;
`;

const leftHandSymbolCircleContainerStyle = css`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 15px;
  align-self: center;
`;

/**
 * return the proper string for the left-hand icon, e.g. returns `A` for the index 0 in `alphabetical` mode and `1` in  `decimal `
 */
/* @ngInject */
export function getOrderedSectionIndexString(index: number, type: OrderedSectionType) {
  if (type === 'decimal') {
    return String(index + 1);
  }
  return String.fromCodePoint('A'.charCodeAt(0) + index);
}

interface OrderedSectionLeftHandIconProps extends ClickableContainerProps {
  sectionData: OrderedAccordionSectionLectureComponent;
  mode: LecturePageMode;
  orderedSectionType: OrderedSectionType;
}

const OrderedSectionLeftHandButton = React.forwardRef<HTMLDivElement, OrderedSectionLeftHandIconProps>((props, ref) => {
  const { sectionData, mode, ...rest } = props;
  let textClassName = 'text-xl condensed font-weight-bolder';
  let containerClassName = 'align-items-center justify-content-center';
  let containerStyle: SerializedStyles;
  let textStyle: SerializedStyles;
  const lecturePage = useLecturePageFromParams();

  const leftHandSymbol = getOrderedSectionIndexString(props.sectionData.index, props.orderedSectionType);
  let children: React.ReactNode = leftHandSymbol;

  if (props.orderedSectionType === 'decimal') {
    const viewOptions = props.sectionData.viewOptions as Style3AccordionSectionViewOptions;
    if (viewOptions.leftHandSymbolPosition === 'right') {
      containerClassName = 'align-items-end justify-content-end';
      children = <FixedHeightLetter>{leftHandSymbol}</FixedHeightLetter>;
    }
  } else {
    const viewOptions = props.sectionData.viewOptions as Style4AccordionSectionViewOptions;
    if (viewOptions.leftHandSymbolSize === 'big') {
      textClassName = 'condensed font-weight-bolder';
      textStyle = css`
        font-size: 55px;
        line-height: 55px;
      `;
    }
  }

  if (props.sectionData.viewOptions.leftHandSymbolShape === 'circle') {
    containerStyle = leftHandSymbolCircleContainerStyle;
  } else {
    // square style
    containerStyle = leftHandSymbolSquareContainerStyle;
  }

  return (
    <ClickableContainer
      {...rest}
      layoutOnly={props.mode !== LecturePageMode.EDIT}
      css={css`
        ${leftHandSymbolContainerStyle};
        ${containerStyle};
        background-color: ${props.sectionData.viewOptions.leftHandSymbolBackgroundColor};
      `}
      ref={ref}
    >
      <NvTooltip enabled={props.mode === LecturePageMode.EDIT} text={t.LECTURE_PAGES.COMPONENTS.ACCORDION.CLICK_TO_EDIT_STYLE()}>
        <div className='d-flex flex-grow-1'>
          <div className={`overflow-hidden d-flex flex-grow-1 ${containerClassName}`}>
            <div
              className={textClassName}
              css={css`
                ${textStyle};
                color: ${props.sectionData.viewOptions.leftHandSymbolColor};
              `}
            >
              {children}
            </div>
          </div>
        </div>
      </NvTooltip>
    </ClickableContainer>
  );
});

interface AbstractOrderedSectionContainerProps extends SectionContainerProps<OrderedAccordionSectionLectureComponent> {
  defaultHeaderTextColor?: string;
  orderedSectionType: OrderedSectionType;

  /** component that renders to select the left hand symbol style */
  leftHandSymbolStyleSelector: React.ReactElement;
}

/* @ngInject */
export default function AbstractOrderedSectionContainer(props: AbstractOrderedSectionContainerProps) {
  const {
    headerEditorRef,
    onHeaderRTEChange,
    isLeftSideColorPickerOpen,
    onLeftSideColorPickerToggle,
  } = useAccordionSectionContainer(props);
  const edit = props.mode === LecturePageMode.EDIT;

  const leftHandSymbol = (
    // in edit mode we wrap the leftHandSymbol with the ColorPickerPopover
    <ConditionalWrap
      condition={edit}
      wrap={(children) => (
        <ColorPickerPopover<{
          leftHandSymbolColor: string;
          leftHandSymbolBackgroundColor: string;
        }>
          placement='top-start'
          onChange={(section, newColor) => {
            props.onViewOptionsChange({
              [section]: newColor,
            });
          }}
          show={isLeftSideColorPickerOpen}
          sections={[
            {
              name: 'leftHandSymbolColor',
              title: props.orderedSectionType === 'alphabetical'
                ? t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_4.LEFT_HAND_LETTER_COLOR_PICKER_LABEL()
                : t.LECTURE_PAGES.COMPONENTS.ACCORDION_STYLE_3.LEFT_HAND_NUMBER_COLOR_PICKER_LABEL(),
            },
            {
              name: 'leftHandSymbolBackgroundColor',
              title: t.LECTURE_PAGES.COMPONENTS.ABSTRACT_ORDERED_STYLE.BACKGROUND_COLOR_PICKER_LABEL(),
            },
          ]}
          onToggle={onLeftSideColorPickerToggle}
          colorValues={{
            leftHandSymbolColor: props.sectionData.viewOptions.leftHandSymbolColor,
            leftHandSymbolBackgroundColor: props.sectionData.viewOptions.leftHandSymbolBackgroundColor,
          }}
          renderBeforePalette={(!props.isOnlySection || props.leftHandSymbolStyleSelector) ? () => (
            <div>
              {!props.isOnlySection && (
                <div className='d-flex flex-column align-items-center mb-2'>
                  <ApplyToAllSectionsButton
                    onClick={() => {
                      props.applyViewOptionsToAllSections(
                        pick(
                          props.sectionData.viewOptions,
                          'leftHandSymbolBackgroundColor',
                          'leftHandSymbolColor',
                          'leftHandSymbolPosition',
                          'leftHandSymbolShape',
                          'leftHandSymbolSize',
                        ),
                      );
                    }}
                  />
                </div>
              )}
              {props.leftHandSymbolStyleSelector}
            </div>
          ) : undefined}
        >
          {children}
        </ColorPickerPopover>
      )}
    >
      <OrderedSectionLeftHandButton
        mode={props.mode}
        sectionData={props.sectionData}
        orderedSectionType={props.orderedSectionType}
      />
    </ConditionalWrap>
  );

  return (
    <div
      css={css`
        border-bottom: 1px solid #fff;
      `}
    >
      <ClickableContainer
        className='d-flex align-items-stretch'
        onClick={props.toggleExpanded}
        layoutOnly={edit /* disable toggle functionality for the whole header on edit mode */}
        css={css`
          background-color: ${props.sectionData.viewOptions.headerBackgroundColor};
        `}
        aria-expanded={props.expanded}
      >
        {leftHandSymbol}

        <AccordionRtHeader<{
          iconColor: string,
          textBackgroundColor: string,
          headerBackgroundColor: string,
        }>
          defaultTextColor={props.defaultHeaderTextColor}
          currentLecture={props.currentLecture}
          isOnlySection={props.isOnlySection}
          onColorChange={(section, newColor) => {
            props.onViewOptionsChange({
              [section]: newColor,
            });
          }}
          colorValues={{
            iconColor: props.sectionData.viewOptions.iconColor,
            headerBackgroundColor: props.sectionData.viewOptions.headerBackgroundColor,
            textBackgroundColor: props.sectionData.viewOptions.textBackgroundColor,
          }}
          sections={[{
            name: 'headerBackgroundColor',
            title: 'Title Background Color',
          }, {
            name: 'iconColor',
            title: 'Icon Color',
          }, {
            name: 'textBackgroundColor',
            title: 'Text Background Color',
          }]}
          renderBeforePalette={!props.isOnlySection ? () => (
            <div className='d-flex flex-column align-items-center'>
              <ApplyToAllSectionsButton
                onClick={() => {
                  props.applyViewOptionsToAllSections(
                    pick(props.sectionData.viewOptions, 'headerBackgroundColor', 'iconColor', 'textBackgroundColor'),
                    {
                      includeHeaderTextFormat: true,
                    },
                  );
                }}
              />
            </div>
          ) : undefined}
          autoFocus={props.autoFocusHeader}
          backgroundColor={props.sectionData.viewOptions.headerBackgroundColor}
          mode={props.mode}
          sectionData={props.sectionData}
          editorRef={headerEditorRef}
          onChange={onHeaderRTEChange}
        />

        <ArrowToggleIcon
          className='align-self-center'
          onClick={props.toggleExpanded}
          layoutOnly={!edit /* this button only works on edit mode, otherwise behaves as a normal icon */}
          expanded={props.expanded}
          sectionViewOptions={props.sectionData.viewOptions}
          mode={props.mode}
        />
      </ClickableContainer>
      {props.renderSectionContent({
        containerCss: css`
          background-color: ${props.sectionData.viewOptions.textBackgroundColor};
        `,
      })}
    </div>
  );
}
