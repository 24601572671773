import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { config } from '@config/pendo.config.json';
import ClickableContainer from 'components/clickable-container';
import { css } from '@emotion/react';
import { gray4 } from 'styles/global_defaults/colors';

const disabledStyles = css`
  pointer-events: none;
  & * {
    color: ${gray4} !important;
  }
`;

type AddQuestionButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

const AddQuestionButton = ({
  disabled = false,
  onClick,
}: AddQuestionButtonProps) => {
  const addQuestionLabel = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.ADD_QUESTION();
  const styles = disabled ? disabledStyles : null;

  return (
    <ClickableContainer
      onClick={onClick}
      className='add-new-question align-items-center semi-bold pt-2'
      aria-label={addQuestionLabel}
      data-qa={
        config.pendo.athena.mentorshipProgram.settings.programProfileSetup
          .createProgramLevelProfileQuestion
      }
      disabled={disabled}
      css={styles}
    >
      <NvIcon icon='add' size='smallest' />
      <span>{addQuestionLabel}</span>
    </ClickableContainer>
  );
};

export default AddQuestionButton;
