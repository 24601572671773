import { PointsConfiguration } from './points-configuration';
import { MinimalActivity, ActivityType } from './activity';
import { Communication } from './course-communication';
import { Normalized } from '../normalized';

export interface MinimalQuiz extends MinimalActivity {
  type: ActivityType.QUIZ
}

export type TimelineQuiz = {
  id: number;
  title: string;
  ownerTitle?: string;
  titleIndex?: number;
  isRequiredForCompletion: boolean;
  expirationDate: string | null;
  releaseDate: string;
  answerableQuestionsCount: number;
  pointsConfiguration: PointsConfiguration;
  activityType: string;
  totalPoints: number[];
  pointsReceived: number;
  releaseResultsAt?: string;
  timeLimit?: number;
  timeInterval?: string;
  duration?: number;
  timeRemaining?: number;
  expired?: boolean;
  catalogId: string;
  courseCatalogId: string,
  courseId: number;
  lecturePageId: number;
  lectureComponentId: number;

  communications?: Communication[],
  communicationsCount?: number,

  // These 4 props may only exist on the lecture page component
  // TOOD: Move to a new type?
  hardDeadline: boolean,
  graded: boolean,
  maximumAttempts: number,
  isTodo: false,
  hardDeadlinePassed?: boolean;

  hasStructuralIssues?: boolean;
  questionsAttributes?: QuizAIQuestion[];
} & MinimalQuiz;

export type NTimelineQuiz = Normalized<TimelineQuiz, 'communications'>;

export interface TimelineQuizzesNormalized { [id: string]: NTimelineQuiz }

export enum AiQuestionType {
  MULTIPLE_CHOICE_SINGLE_ANSWER = 'multiple_choice_single_answer',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'multiple_choice_multiple_answer',
  TRUE_OR_FALSE = 'true_or_false',
  FILL_BLANKS = 'fill_blanks',
}

export type QuizAIQuestion = {
  id: string;
  questionText: string;
  type: string;
  responseOptionsAttributes: QuizAIQuestionOption[];
};

type QuizAIQuestionOption = {
  optionContent: string,
  explanation: string,
  isCorrect: boolean,
};
