import t from 'react-translate';
import CollapsiblePanel, {
  InitialState,
} from 'athena/components/collapsible-panel';
import { DEFAULT_ACTION_ITEM } from 'athena/components/action-item/constants';
import {
  ActionItemOwner,
  ActionItemStatus,
  MentoringProgramActionItem,
} from 'redux/schemas/models/mentoring-program-action-items';
import ActionItem from 'athena/components/action-item';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import {
  AssignedConnection,
  MentoringProgramEnrollment,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { useMentoringProgramActionItems } from 'athena/hooks/use-mentoring-program-action-items';
import { useInView } from 'react-intersection-observer';
import setupActionItemsEventsListener from 'athena/helpers/action-items-channel-events';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { PanelStateKey } from 'athena/components/constants';
import { config } from '@config/pendo.config.json';
import { animated, useTransition } from '@react-spring/web';
import MentoringProgramContext from '../../../context';
import { getDefaultActionItemHandlers } from '../utils';
import ActionItemsPanelHeader from './action-items-panel-header';

type ActionItemsPanelProps = {
  actionItems: MentoringProgramActionItem[];
  connection: AssignedConnection;
  enrollment: MentoringProgramEnrollment;
  showPlanSessionFlyout: (session?: MentorshipProgramSession) => void;
  className?: string;
};

const ActionItemsPanelContent = ({
  actionItems,
  connection,
  enrollment,
  showPlanSessionFlyout,
  className,
}: ActionItemsPanelProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const { connectionId, assignedEnrollment } = connection;
  const {
    loadMore,
    reset,
  }: {
    loadMore: () => void;
    reset: () => void;
  } = useMentoringProgramActionItems({ mentorshipProgramId, connectionId });
  const { ref: actionItemsRef, inView: isActionItemsEndReached } = useInView();
  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.actionItems.list;
  const [editingId, setEditingId] = useState<number>();

  useEffect(() => {
    if (isActionItemsEndReached) {
      loadMore();
    }
  }, [isActionItemsEndReached, loadMore]);

  useEffect(() => {
    const cleanup = setupActionItemsEventsListener(connectionId, dispatch);

    return () => {
      cleanup();
      reset();
    };
  }, [connectionId, dispatch]);

  const byStatus = (status: ActionItemStatus) => (item: MentoringProgramActionItem) => item?.status === status;

  const [incompleteItems, completedItems] = useMemo(() => {
    const incomplete = actionItems?.filter(
      byStatus(ActionItemStatus.INCOMPLETE),
    );
    const complete = actionItems?.filter(byStatus(ActionItemStatus.COMPLETED));
    return [incomplete, complete];
  }, [actionItems]);

  const connectionEnrollments = {
    [enrollment.id]: enrollment,
    [assignedEnrollment.id]: assignedEnrollment as MentoringProgramEnrollment,
  };

  const getDefaultActionItem = () => ({
    ...DEFAULT_ACTION_ITEM,
    assignedTo: enrollment,
    owner: { id: connectionId, type: ActionItemOwner.CONNECTION },
  });

  const animationConfig = {
    keys: (item) => item.id,
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 'auto' },
    leave: { opacity: 0, height: 0 },
    config: { duration: 300 },
  };

  const incompleteTransitions = useTransition(
    [getDefaultActionItem(), ...(incompleteItems || [])],
    animationConfig,
  );

  const completeTransitions = useTransition(completedItems, animationConfig);

  const { onAdd, onUpdate, onDelete } = getDefaultActionItemHandlers(
    mentorshipProgramId,
    dispatch,
  );

  const toActionItem = (item: MentoringProgramActionItem) => (
    <ActionItem
      key={item.id}
      item={item}
      onAdd={onAdd}
      onUpdate={onUpdate}
      onDelete={onDelete}
      isEditing={editingId === item.id}
      setEditingId={setEditingId}
      connectionId={connectionId}
      enrollments={connectionEnrollments}
      showPlanSessionFlyout={showPlanSessionFlyout}
    />
  );

  return (
    <CollapsiblePanel
      className={className}
      title={<ActionItemsPanelHeader />}
      panelState={{
        persistState: true,
        localStorageKey: PanelStateKey.ACTION_ITEMS_STATE,
      }}
      dataQa={dataQATag.actionItemsPanel}
      dataQaId={`${dataQATag.actionItemsPanel}-${mentorshipProgramId}-${connectionId}`}
    >
      <div className='mt-2'>
        {incompleteTransitions((style, item) => (
          <animated.div style={style}>{toActionItem(item)}</animated.div>
        ))}
        <div ref={actionItemsRef} />
        {completedItems?.length ? (
          <CollapsiblePanel
            isNested
            title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.COMPLETED_ITEMS(
              completedItems.length,
            )}
            panelState={{ initialState: InitialState.COLLAPSED }}
            className='pt-4'
            iconSize='smallest'
            dataQa={dataQATag.actionItemsPanelCompleted}
            dataQaId={`${dataQATag.actionItemsPanelCompleted}-${mentorshipProgramId}-${connectionId}`}
          >
            <div className='pt-2'>
              {completeTransitions((style, item) => (
                <animated.div style={style}>{toActionItem(item)}</animated.div>
              ))}
            </div>
          </CollapsiblePanel>
        ) : null}
      </div>
    </CollapsiblePanel>
  );
};

export default ActionItemsPanelContent;
