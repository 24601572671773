
import { css, jsx } from '@emotion/react';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import React from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { updatePollQuestion } from 'redux/actions/polls';
import { getNormalizedPollQuestion } from 'redux/selectors/polls';
import { useAppDispatch } from 'redux/store';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { gray7 } from 'styles/global_defaults/colors';
import { quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

interface PollTitleProps {
  // eslint false positive: this prop is used by the selector
  // eslint-disable-next-line react/no-unused-prop-types
  pollQuestionId: number;
}

/* @ngInject */
export default function PollQuestion(props: PollTitleProps) {
  const dispatch = useAppDispatch();
  const normalizedQuestion = useSelector((state) => getNormalizedPollQuestion(state, props));
  const [question, setQuestion] = React.useState(normalizedQuestion.questionText);
  const lectureComponentData = useLectureComponentContext();

  if (lectureComponentData.isEdit) {
    return (
      <NvTextArea
        placeholder={t.LECTURE_PAGES.COMPONENTS.POLLS.TITLE_PLACEHOLDER()}
        css={textAreaStyle}
        value={question ?? ''}
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
        onBlur={() => {
          dispatch(
            updatePollQuestion({
              catalogId: lectureComponentData.catalogId,
              questionId: props.pollQuestionId,
              questionText: question,
            }),
          );
        }}
      />
    );
  }

  return <h3 className='my-0' css={learnerStyle}>{question}</h3>;
}

const baseStyle = css`
  font-size: 16px;
  font-family: 'Open Sans';
  font-weight: 700;
  line-height: 30px;
`;

const textAreaStyle = css`
  cursor: pointer;
  margin-bottom: ${threeQuartersSpacing}px;
  textarea {
    ${baseStyle};
    border: none;
    padding: ${quarterSpacing}px 0;
    background-color: transparent;

    &:focus,
    &:hover {
      background-color: ${gray7};
    }

    &::placeholder {
      font-weight: normal;
    }
  }
`;

const learnerStyle = css`
  padding: ${quarterSpacing}px 0;
  font-size: 16px !important;
  font-family: 'Open Sans' !important;
  font-weight: 700 !important;
  line-height: 30px !important;
`;
