import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { ClassNames, css } from '@emotion/react';

import t from 'react-translate';
import { shadedWhite } from 'athena/styles/colors';
import { gray2 } from 'styles/global_defaults/colors';
import Toggle from 'athena/components/dropdown/toggle';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption, NvDropdownRadio } from 'shared/components/inputs/nv-dropdown';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import { config } from '@config/pendo.config.json';

type BaseRoleSelectProps = {
  value?: MentoringProgramRole;
  onChange?: (newValue) => void;
  className?: string;
  showLabel?: boolean;
};

const BaseRoleSelect = (props: BaseRoleSelectProps) => {
  const { value, showLabel, className, onChange } = props;

  const { mentoringProgram } = React.useContext(MentoringProgramContext);

  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentoringProgram?.id));

  const mapValueToLabel = () => {
    switch (value) {
      case MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE:
        return t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTEE(mentoringProgramAliases);
      case MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR:
        return t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTOR(mentoringProgramAliases);
      case MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN:
        return t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.TITLE();
      default:
        return undefined;
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const dataQATag = config.pendo.athena.mentorshipProgram.participants.participantModal.roleSelect;

  const dropdownItems = (accordionClass: string): NvDropdownOption[] => ([{
    type: 'accordion-header',
    title: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.TITLE(),
    class: accordionClass,
    items: [
      {
        type: 'radio',
        class: 'ml-1 mr-1',
        isChecked: value === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
        group: 'role',
        label: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTEE(mentoringProgramAliases),
        callback: () => {
          onChange(MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE);
          setIsDropdownOpen(false);
        },
        dataQa: dataQATag.items.menteeRadioField,
      } as NvDropdownRadio,
      {
        type: 'radio',
        class: 'ml-1 mr-1',
        isChecked: value === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
        group: 'role',
        label: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTOR(mentoringProgramAliases),
        callback: () => {
          onChange(MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR);
          setIsDropdownOpen(false);
        },
        dataQa: dataQATag.items.mentorRadioField,
      },
    ],
  }, {
    type: 'accordion-header',
    title: t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.TITLE(),
    class: accordionClass,
    items: [
      {
        type: 'radio',
        class: 'ml-1 mr-1',
        group: 'role',
        isChecked: value === MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN,
        label: t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.TITLE(),
        callback: () => {
          onChange(MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN);
          setIsDropdownOpen(false);
        },
        dataQa: dataQATag.items.adminRadioField,
      },
    ],
  }]);

  return (
    <div className={className}>
      <ClassNames>
        {({ css: classNameCss }) => {
          const accordionClass = classNameCss`
            .accordion-header {
              color: ${gray2};
              font-weight: 600;
              background-color: ${shadedWhite}!important;

              .icon {
                font-size: ${halfSpacing}px;
                color: ${gray2};
              }

              &.expanded {
                .icon {
                  color: ${gray2};
                }
              }
            }

            .accordion-content {
              label {
                font-weight: 600!important;
              }
            }
          `;

          return (
            <NvDropdown
              show={isDropdownOpen}
              onToggle={handleDropdownToggle}
              ariaExpanded={isDropdownOpen}
              menuClassName={`w-100 pt-0 pb-0 ${classNameCss``}`}
              buttonStyle={NvDropdownButtonStyle.CUSTOM}
              customTarget={() => (
                <Toggle
                  showLabel={showLabel}
                  valueLabel={mapValueToLabel()}
                  css={css`flex: 1; text-align: left;`}
                  // Using already existing translation to not create new one
                  label={t.CUSTOM_DOWNLOADS.DROPDOWN.ROLE()}
                  placeholder={t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.SELECT_ROLE_PLACEHOLDER()}
                />
              )}
              items={dropdownItems(accordionClass)}
              toggleDataQa={dataQATag.toggle}
            />
          );
        }}
      </ClassNames>
    </div>
  );
};

type Props = BaseRoleSelectProps & {
  name?: string;
  withForm?: boolean;
};

const RoleSelect = (props: Props) => {
  const { name, value, onChange, withForm, ...restProps } = props;

  return withForm ? (
    <Controller
      name={name}
      render={({ field }) => {
        const handleChange = (e) => {
          onChange?.(e);
          field.onChange(e);
        };

        return (
          <BaseRoleSelect
            onChange={handleChange}
            value={value ?? field.value}
            {...restProps}
          />
        );
      }}
    />
  ) : (
    <BaseRoleSelect
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};

export default RoleSelect;
