import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { Button, ButtonToolbar } from 'react-bootstrap';
import t from 'react-translate';
import { MediaSourceType, requestMediaStream, stopMediaStreams } from 'recording/services/media-stream';
import useMediaRecorder from 'recording/hooks/use-media-recorder';
import useTimer from 'recording/hooks/use-timer';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { black, danger, hexToRgbaString } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { useAppDispatch } from 'redux/store';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import prodPathReplace from '../../shared/prod-path-rewrite';
import { config } from '../../../config/config.json';
import Stopwatch from './stopwatch';
import VideoStreamPreview from './video-stream-preview';
import VideoBlobPreview from './video-blob-preview';
import AudioBlobPreview from './audio-blob-preview';
import AudioStreamPreview from './audio-stream-preview';

type RecordingOverlayProps = {
  mediaSourceType: MediaSourceType,
  initialMediaStream: MediaStream,
  onClose(blob?: Blob): void,
  titleText?: string;
  submitButtonText: string;
};


const getStyles = (hasTitle: boolean, recordingType: RecordingType, recordingState: RecordingState) => css`
  height: 100%;
  /* Video: no modal backdrop to support proper mask on video before recording */
  /* Audio: normal modal backdrop applied */


  /* Section 1: shared component css (video/audio has components in different locations) */
  .recording-title {
    color: white;
    display: flex;
    justify-content: center;
  }

  .stopwatch-section {
    display: flex;
    justify-content: center;
    height: ${hasTitle ? 75 : 120}px;
  }

  .recording-preview {
    position: relative;
    padding: 0 ${standardSpacing}px;
    width: 100%;

    display: flex;
    justify-content: center;

    .video-stream-preview {
      width: 100%; //browsers don't take percentage for video, but this seems to work
      display: flex;
      justify-content: center;
      align-items: center;
    }

    video {
      max-height: calc(100vh - 244px);
    }

    .start-recording-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      .record-countdown, .record-button {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: 15px solid white;
        background: white;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .record-button {
        cursor: pointer;
        color: red;

        &.video {
          height: 100%;
          width: 100%;
          max-width: 80px;
          max-height: 80px;
          border: none;
          background: none;
        }

        // use an img for the record button to force 1:1 ratio
        img {
          height: 100%;
          max-height: 80px;
        }
      }

      .recording-label {
        margin-top: ${standardSpacing}px;
        color: white;
      }

      .record-countdown {
        color: ${danger};
        text-align: center;
        line-height: 50px;
      }
    }
  }

  .toolbar-section {
    width: 100%;
    display: flex;
    justify-content: center;

    .bs4-btn-toolbar {
      height: 35px;
    }

    .bs4-btn-default {
      background-color: transparent;
      border-color: white;
      color: white;
      margin-right: ${halfSpacing}px;
    }
  }

  // Section 2: video specific css
  ${recordingType === 'video' ? css`
    .recording-title {
      padding-top: ${standardSpacing}px;
      background: ${hexToRgbaString(black, 0.8)};
    }

    .stopwatch-section {
      padding: ${hasTitle ? threeQuartersSpacing : 2 * standardSpacing}px  ${standardSpacing}px 0 ${standardSpacing}px;
      background: ${hexToRgbaString(black, 0.8)};
    }

    .recording-preview {
      height: calc(100vh - 240px);
      align-items: center;
      ${recordingState !== 'awaitingRecord' ? css`
        background: ${hexToRgbaString(black, 0.8)};
      ` : ''}

      .start-recording-overlay {
        justify-content: center;
        ${recordingState === 'awaitingRecord' ? css`
          background: ${hexToRgbaString(black, 0.8)};
        ` : ''}
      }
    }

    .toolbar-section {
      height: 120px;
      padding: ${2 * standardSpacing}px ${standardSpacing}px 0 ${standardSpacing}px;
      background: ${hexToRgbaString(black, 0.8)};
    }
  ` : ''}

  ${recordingType === 'audio' ? css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${hexToRgbaString(black, 0.8)};
    .stopwatch-section {
    }

    .recording-preview {
      height: ${isPrerecording(recordingState) ? 135 : 160}px;
      align-items: flex-start;

      .start-recording-overlay {
        justify-content: flex-start;
      }
    }

    .toolbar-section {
      ${isPrerecording(recordingState) ? css`
        padding-bottom: 60px; /* keep the content aligned throughout the recording process */
      ` : ''};
    }
  ` : ''};
`;

export type RecordingType = 'video' | 'audio';
export type RecordingState = 'awaitingRecord' | 'countdownToRecording' | 'recording' | 'replay';

function isPrerecording(recordingState) {
  return recordingState === 'awaitingRecord' || recordingState === 'countdownToRecording';
}

/**
 * Overlay for video/audio recording for use from nv-file-upload.jsx
 */
export const RecordingOverlay = ({
  mediaSourceType,
  initialMediaStream,
  onClose,
  titleText = '',
  submitButtonText = '',
}: RecordingOverlayProps) => {
  const dispatch = useAppDispatch();

  const recordingType = mediaSourceType === MediaSourceType.MIC ? 'audio' : 'video';

  const [recordingState, setRecordingState] = useState<RecordingState>('awaitingRecord');
  const [isConfirmationOverlayShown, setIsConfirmationOverlayShown] = useState(false);
  const [countdownRemainingSeconds, startCountdown, pauseCountdown, resumeCountdown] = useTimer();
  const [mediaStream, setMediaStream] = useState(initialMediaStream);
  const recorder = useMediaRecorder(initialMediaStream, mediaSourceType, config.recordings.timeLimit.maxLength);

  useEffect(() => () => {
    stopMediaStreams(mediaStream);
  }, [mediaStream]);

  const initiateClose = () => {
    if (recordingState === 'awaitingRecord') {
      onClose();
    } else {
      if (recordingState === 'countdownToRecording') {
        pauseCountdown();
      } else if (recordingState === 'recording') {
        recorder.pauseRecording();
      }

      setIsConfirmationOverlayShown(true);

      dispatch(openConfirmationDialog({
        title: t.FORM.UNSAVED_CHANGES.ARE_YOU_SURE(),
        cancelText: t.FORM.DO_NOT_CLOSE(),
        confirmText: t.FORM.CLOSE_ANYWAY(),
        onCancel: () => {
          setIsConfirmationOverlayShown(false);

          if (recordingState === 'countdownToRecording') {
            resumeCountdown();
          } else if (recordingState === 'recording') {
            recorder.resumeRecording();
          }
        },
        onConfirm: onClose,
      }));
    }
  };

  const initiateRecordingCountdown = () => {
    setRecordingState('countdownToRecording');
    startCountdown(config.recordings.timeLimit.countdownLength, startRecording);
  };

  const stopRecordingCallback = () => {
    stopMediaStreams(mediaStream);
    setMediaStream(null);

    recorder.destroyCurrentRecorder();

    setRecordingState('replay');
  };

  function startRecording() {
    setRecordingState('recording');
    recorder.startRecording(stopRecordingCallback);
  }

  async function requestRestartRecording() {
    if (recordingState === 'recording') {
      recorder.pauseRecording();

      setIsConfirmationOverlayShown(true);

      dispatch(openConfirmationDialog({
        title: t.DASHBOARD.CONFIRMATION.ARE_YOU_SURE(),
        bodyText: t.FILE_UPLOAD.RESTART_RECORDING(),
        confirmText: t.FORM.YES_SURE(),
        onCancel: () => {
          setIsConfirmationOverlayShown(false);

          recorder.resumeRecording();
        },
        onConfirm: async () => {
          setIsConfirmationOverlayShown(false);

          await recorder.stopRecording(true);
          initiateRecordingCountdown();
        },
      }));
    } else {
      dispatch(openConfirmationDialog({
        title: t.DASHBOARD.CONFIRMATION.ARE_YOU_SURE(),
        bodyText: t.FILE_UPLOAD.RESTART_RECORDING(),
        confirmText: t.FORM.YES_SURE(),
        onCancel: () => {
          setIsConfirmationOverlayShown(false);
        },
        onConfirm: async () => {
          setIsConfirmationOverlayShown(false);

          const currentMediaStream = await requestMediaStream(mediaSourceType);
          setMediaStream(currentMediaStream);
          recorder.replaceRecorder(currentMediaStream);
          initiateRecordingCountdown();
        },
      }));
    }
  }

  const stopRecording = async () => {
    await recorder.stopRecording();

    stopRecordingCallback();
  };

  const submit = () => {
    onClose(recorder.mediaBlob);
  };

  const getPreview = () => {
    if (recordingType === 'video') {
      if (recordingState === 'replay') {
        return <VideoBlobPreview blob={recorder.mediaBlob} />;
      }
      return <VideoStreamPreview mediaStream={mediaStream} showRecordIcon={recordingState === 'recording'} />;
    }

    if (recordingType === 'audio') {
      if (recordingState === 'recording') {
        return <AudioStreamPreview mediaStream={mediaStream} isPlaying={!isConfirmationOverlayShown} />;
      }
      if (recordingState === 'replay') {
        return <AudioBlobPreview blob={recorder.mediaBlob} />;
      }
    }

    return undefined;
  };

  return (
    <NvModal
      show
      type={ModalType.NO_DIALOG}
      backdrop={recordingType === 'video' ? false : undefined}
      onClose={initiateClose}
      body={(
        <div css={getStyles(!!titleText, recordingType, recordingState)}>
          {titleText && (
            <div className='recording-title course-title-small'>
              {titleText}
            </div>
          )}
          <div className='stopwatch-section'>
            <Stopwatch
              paddingTime={recorder.recordedLength}
              startTime={recorder.startTime}
              maxTime={config.recordings.timeLimit.maxLength}
              warningLength={config.recordings.timeLimit.warningLength}
              displayWarningText={recordingState === 'recording'}
            />
          </div>
          <div className='recording-preview'>
            {getPreview()}

            {recordingState === 'awaitingRecord' && (
              <div className='start-recording-overlay'>
                {recordingType === 'video' && (
                  <ClickableContainer
                    className='record-button video'
                    onClick={initiateRecordingCountdown}
                  >
                    <img
                      src={prodPathReplace('images/icon-recording.png')}
                      alt='record button'
                    />
                  </ClickableContainer>
                )}
                {recordingType === 'audio' && (
                  <ClickableContainer
                    className='record-button'
                    onClick={initiateRecordingCountdown}
                  >
                    <NvIcon icon='audio-recording' size='large' />
                  </ClickableContainer>
                )}
                <div className='recording-label page-title'>{t.FILE_UPLOAD.START_RECORDING()}</div>
              </div>
            )}

            {recordingState === 'countdownToRecording' && (
              <div className='start-recording-overlay'>
                <div className='record-countdown page-title-xl'>{countdownRemainingSeconds}</div>
              </div>
            )}

          </div>

          <div className='toolbar-section'>
            {recordingState === 'recording'
              && (
                <ButtonToolbar className='mx-auto'>
                  <Button variant='default' className='mr-2' onClick={requestRestartRecording}>{t.FILE_UPLOAD.RESTART()}</Button>
                  <Button variant='primary' onClick={stopRecording}>{t.FILE_UPLOAD.STOP_RECORDING()}</Button>
                </ButtonToolbar>
              )}
            {recordingState === 'replay'
              && (
                <ButtonToolbar className='mx-auto'>
                  <Button variant='default' className='mr-2' onClick={requestRestartRecording}>{t.FILE_UPLOAD.RESTART()}</Button>
                  <Button variant='primary' onClick={submit}>{submitButtonText}</Button>
                </ButtonToolbar>
              )}
          </div>
        </div>
      )}
    />
  );
};

export default RecordingOverlay;
