/* eslint-disable import/prefer-default-export */
import {
  CompletionStatus,
  States,
  StepKey,
} from 'redux/schemas/models/mentoring-program-enrollments';

export const noRequiredQuestions = (states: States) => (
  states[StepKey.PROFILE_COMPLETION]?.status === CompletionStatus.COMPLETED
  && states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.NOT_STARTED
);

// All required questions answered => PROFILE_COMPLETION status is COMPLETED and PROFILE_REVIEW status is IN_PROGRESS
// Otherwise => PROFILE_COMPLETION status is IN_PROGRESS and PROFILE_REVIEW status is NOT_STARTED
export const isAnyRequiredFieldUnanswered = (states: States) => (
  states[StepKey.PROFILE_COMPLETION]?.status !== CompletionStatus.COMPLETED
  && states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.NOT_STARTED
);

export const areAllRequiredFieldsAnswered = (states: States) => (
  states[StepKey.PROFILE_COMPLETION]?.status === CompletionStatus.COMPLETED
  && states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.IN_PROGRESS
);

export const notStarted = (states: States) => (
  states[StepKey.PROFILE_COMPLETION]?.status === CompletionStatus.NOT_STARTED
  && states[StepKey.PROFILE_REVIEW]?.status === CompletionStatus.NOT_STARTED
);

export const profileCompletionStarted = (states: States) => (
  states[StepKey.PROFILE_COMPLETION]?.status !== CompletionStatus.NOT_STARTED
);
