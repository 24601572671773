import { css } from '@emotion/react';
import React, { useContext } from 'react';
import t from 'react-translate';
import _, { isEmpty } from 'underscore';

// redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getCurrentUser } from 'redux/selectors/users';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { updateLecturePageEstimates } from 'redux/actions/lecture-pages';
import { TitleTextAlignment } from 'redux/schemas/models/lecture-page';

// styles
import { hexToRgbaString, gray2, black } from 'styles/global_defaults/colors';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';

// hooks
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';

// components
import LectureHeaderInput, { LectureHeaderInputProps } from 'shared/components/lecture-header-input';
import NvTooltip from 'shared/components/nv-tooltip';
import { LecturePageMode } from '..';
import LectureTimeEstimateButton, { LectureTimeEstimateButtonProps } from './lecture-time-estimate-button';
import { LecturePagePreviewContext } from '../lecture-page-preview-modal';
import LectureContentHeaderStyleToolbar from './lecture-content-header-style-toolbar';
import {
  getLectureHeaderHeight,
  getLectureHeaderTopMargin as getLectureHeaderTopPadding,
  LecturePageEditContentParams,
} from '../lecture-page-content';

const lectureHeaderInputStyles = css`
  width: 100%;
`;

const stretchedLectureHeaderInputStyles = css`
  ${lectureHeaderInputStyles}
`;

const StretchedLectureHeaderInput = (props) => {
  const styles = css`
    .bs4-form-control {
      padding-top: 25px;
      padding-bottom: 25px;
      color: ${props.titleColor ?? 'black'};
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      margin-left: ${props.textAlign === TitleTextAlignment.LEFT ? `${tripleSpacing}px` : 'auto'};
      margin-right: ${props.textAlign === TitleTextAlignment.RIGHT ? `${tripleSpacing}px` : 'auto'};
      text-align: ${props.textAlign};
    }
  `;

  return (
    <div css={stretchedLectureHeaderInputStyles}>
      <LectureHeaderInput css={styles} {...props} />
    </div>
  );
};

type LectureContentHeaderParams = {
  isHoverOnHeader: boolean;
} & LecturePageEditContentParams;

/** Contains header-level content for the lecture page content area, including the title &
 * time estimate display */
export const LectureContentHeader = (props: LectureContentHeaderParams) => {
  const params = useLecturePageParams();
  const dispatch = useAppDispatch();

  const currentUser = useSelector(getCurrentUser);
  const previewParams = useContext(LecturePagePreviewContext);
  const isPreview = !isEmpty(previewParams);

  const editable = params.mode === LecturePageMode.EDIT && !isPreview;

  const styles = css`
    /** TODO: Probably need to lock this behind a permission */
    ${(editable && props.isHoverOnHeader) && css`
      background-color: ${hexToRgbaString(gray2, 0.5)};
    `}

    ${(!!props.lecturePage.coverImageUrl) && css`
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0.1) 100px),
                  linear-gradient(180deg, rgba(58, 66, 77, 0) 100px, rgba(58, 66, 77, 0.25) 100%);
      background-repeat: no-repeat;
    `}

    width: 100%;
    padding-top: ${getLectureHeaderTopPadding(props.lecturePage)};
    min-height: ${getLectureHeaderHeight(props.lecturePage)};

    .course-title {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    // Hide/Show the title toolbar menu when the user hovers over the title text.
    .header-text-container:hover {
      .style-toolbar {
        visibility: visible;
      }
    }
  `;

  const courseAliases = useSelector(state => getFlatCourseAliases(state, params.catalogId));

  const onHeaderBlur: LectureHeaderInputProps['onBlur'] = (e) => {
    const title = e.target.value;
    if (title) {
      props.saveLecturePageData({ title });
    }
  };

  /**
   * The course lesson update has a new endpoint for updating estimation, but the
   * collection lesson uses the same update lecture page API for updating estimation.
   */
  const setEstimate: LectureTimeEstimateButtonProps['setEstimate'] = (draftEstimate) => {
    if (!params.currentCourseIsCollection) {
      dispatch(updateLecturePageEstimates({
        ...params,
        estimate: draftEstimate,
      }));
    } else {
      props.saveLecturePageData({
        ...draftEstimate,
      });
    }
  };

  const isViewMode = params.mode === LecturePageMode.VIEW || isPreview;
  const isLecturePageTranslated = !!currentUser.translationPreferenceLanguage;

  return (
    // Note the mt-auto makes this panel align to the bottom as required when a cover image is applied
    <div css={styles} className='d-flex flex-column align-items-center'>
      <React.Fragment>
        <div className='header-text-container position-relative d-flex flex-column justify-content-center w-100'>
          <NvTooltip text={t.LECTURE_PAGES.ADMIN.HEADER.TITLE_POPOVER(courseAliases)} enabled={editable}>
            <div className='w-100'>
              <StretchedLectureHeaderInput
                defaultValue={(isLecturePageTranslated && isViewMode) ? props.lecturePage.translatedTitle : props.lecturePage.title}
                onBlur={onHeaderBlur}
                editable={editable}
                textAreaClassName='course-title-l'
                textAlign={props.lecturePage.viewOptions.alignment}
                hoverColor='rgba(58,66,77,.3)'
                height={100}
                titleColor={props.lecturePage.viewOptions?.titleColor}
                required
              />
            </div>
          </NvTooltip>
          {editable && (
            <LectureContentHeaderStyleToolbar {...props} />
          )}
        </div>
        <div className='pt-3 pb-5'>
          <LectureTimeEstimateButton
            estimate={props.lecturePage.estimation}
            readOnly={!editable}
            isHoverOnHeader={props.isHoverOnHeader}
            setEstimate={(val) => setEstimate(val)}
            {...props}
          />
        </div>
      </React.Fragment>
    </div>
  );
};

export default LectureContentHeader;
