/* eslint-disable no-plusplus */
/* eslint-disable no-case-declarations */
import { css } from '@emotion/react';
import t from 'react-translate';
import React, {
  useEffect,
  useState,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import ClickableContainer from 'components/clickable-container';
import NvFlyoutModal, { ModalType } from 'shared/components/nv-flyout-modal';
import NvTagButtonSelectable from 'shared/components/nv-tag-button-selectable';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption, NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import { threeQuartersSpacing, standardSpacing, quarterSpacing, halfSpacing, largeSpacing } from 'styles/global_defaults/scaffolding';
import { Button } from 'react-bootstrap';
import { config } from '../../../config/pendo.config.json';

/* The different filter options for the courses list table. The string values are the query params we set in the paged
data request to apply the relevant filter */
export enum Filters {
  ACTIVE = 'active',
  FUTURE = 'future',
  PAST = 'past',
  DEMO = 'demo',
  PRODUCTION = 'production',
  PRIMARY = 'primary',
  PRIMARY_SINGLE = 'by_catalog_id',
  COHORTS_OF = 'cohorts_of',
  PACE_DEADLINE_BASED = 'deadline_based',
  PACE_SELF_PACED = 'self_paced',
  // See courses.rb for these enum values
  REGISTRATION_TYPE_FREE_TO_PUBLIC = '0',
  REGISTRATION_TYPE_OPEN_IN_ORG = '4',
  REGISTRATION_TYPE_BASED_ON_ENTITLEMENT = '5',
  REGISTRATION_TYPE_CLOSED = '3',

  // These do not have corresponding backend values. Omitting all of the above filters of the appropriate category is equivalent
  // to 'all'. These exist to allow for having an explicit 'All' option in the undefined
  PACE_ALL = 'all',
  REGISTRATION_TYPE_ALL = 'registration_type_all',
  COURSE_TYPE_ALL = 'all',
  // With filters comming from My View
  MY_VIEW = 'my_view',
}

type FilterState = {
  metadataValueIds?: number[],
  courseType?: string[],
  coursePace?: string[],
  registrationType?: string[],
  includeArchived?: boolean,
  onlyArchived?: boolean,
};

export enum FilterType {
  METADATA_VALUE_IDS = 'metadataValueIds',
  COURSE_TYPE = 'courseType',
  COURSE_PACE = 'coursePace',
  REGISTRATION_TYPE = 'registrationType',
  COHORTS_OF = 'cohortsOf',
  PRIMARY_SINGLE = 'byCatalogId',
  INCLUDE_ARCHIVED = 'includeArchived',
  ONLY_ARCHIVED = 'onlyArchived',
}

type MetadataFlyoutFormProps = {
  onSubmit: (filters: Object) => void,
  metadataFields: Object[],
  filters: FilterState,
  setFilters: (newState: Object) => void,
  onClose: () => void,
};

const initialFilters: FilterState = {
  metadataValueIds: [],
  courseType: [],
  registrationType: [],
  coursePace: [],
  includeArchived: false,
  onlyArchived: false,
};

const defaultFilters = (translator) => (
  [
    {
      name: translator.COURSES.FILTER.PACE_TYPE.HEADER(),
      metadataValues: [
        {
          id: Filters.PACE_DEADLINE_BASED,
          value: translator.COURSES.FILTER.PACE_TYPE.DEADLINE_BASED(),
          type: FilterType.COURSE_PACE,
        },
        {
          id: Filters.PACE_SELF_PACED,
          value: translator.COURSES.FILTER.PACE_TYPE.SELF_PACED(),
          type: FilterType.COURSE_PACE,
        },
      ],
    },
    {
      name: translator.COURSES.FILTER.REGISTRATION_TYPE.HEADER(),
      metadataValues: [
        {
          id: Filters.REGISTRATION_TYPE_FREE_TO_PUBLIC,
          value: translator.COURSES.REGISTRATION_TYPE.TYPE_0(),
          type: FilterType.REGISTRATION_TYPE,
        },
        {
          id: Filters.REGISTRATION_TYPE_OPEN_IN_ORG,
          value: translator.COURSES.REGISTRATION_TYPE.TYPE_4(),
          disabled: true,
          type: FilterType.REGISTRATION_TYPE,
        },
        {
          id: Filters.REGISTRATION_TYPE_BASED_ON_ENTITLEMENT,
          value: translator.COURSES.REGISTRATION_TYPE.TYPE_5(),
          disabled: true,
          type: FilterType.REGISTRATION_TYPE,
        },
        {
          id: Filters.REGISTRATION_TYPE_CLOSED,
          value: translator.COURSES.REGISTRATION_TYPE.TYPE_3(),
          type: FilterType.REGISTRATION_TYPE,
        },
      ],
    },
    {
      name: translator.COURSES.FILTER.USED_FOR.HEADER(),
      metadataValues: [
        {
          id: Filters.PRODUCTION,
          value: translator.COURSES.FILTER.USED_FOR.TYPE_0(),
          type: FilterType.COURSE_TYPE,
        },
        {
          id: Filters.DEMO,
          value: translator.COURSES.FILTER.USED_FOR.TYPE_1(),
          type: FilterType.COURSE_TYPE,
        },
        {
          id: Filters.PRIMARY,
          value: t.COURSES.FILTER.USED_FOR.TYPE_2(),
          type: FilterType.COURSE_TYPE,
        },
      ],
    },
  ]
);

const MetadataFlyoutForm = React.forwardRef<any, MetadataFlyoutFormProps>(({ metadataFields, filters, setFilters, onClose, onSubmit }, ref) => {
  const [localMetadataFilters, setLocalMetadataFilters] = useState([]);
  const [localDefaultFilters, setLocalDefaultFilters] = useState(defaultFilters(t));
  const filtersRef = React.useRef<HTMLDivElement>();
  const controllerRef = React.useRef<HTMLDivElement>();

  const MAX_VISIBLE_ITEMS = 5;

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      currentFilters: filters,
    },
  });

  useEffect(() => {
    setLocalMetadataFilters(sortFilterBySelected(Object.assign([], metadataFields)));
    setLocalDefaultFilters(sortFilterBySelected(localDefaultFilters));
  }, [metadataFields]);

  React.useImperativeHandle(ref, () => form);

  const {
    control,
    setValue,
    formState,
    getValues,
    handleSubmit,
  } = form;

  const linkStyle = css`
  &.link-button {
    padding: ${halfSpacing}px;
    cursor: pointer;
  }
  `;

  const filterStyles = css`
    &.filters {
      height: 100vh;
      overflow: auto;
    }
    .filters-title {
      display: flex;
      justify-content: center;
      padding: ${standardSpacing}px;
    }
    .filters-buttons {
      display: flex;
      justify-content: space-between;
      padding: ${quarterSpacing}px ${standardSpacing}px;
      align-items: center;
      .link-button {
        padding: 0px;
      }
    }
    .sticky-header {
      position: absolute;
      background-color: white;
      width: 100%;
      height: auto;
      top: 0px;
      .filters-buttons {
        padding-bottom: ${largeSpacing}px;
      }
    }
    .filters-list {
      .field-section {
        padding: ${threeQuartersSpacing}px;
        .field-name {
          padding: ${quarterSpacing}px;
        }
        .field-values {
          display: flex;
          flex-wrap: wrap;
          .field-value {
            padding: ${quarterSpacing}px;
          }
        }
        .nv-dropdown {
          padding-top: ${quarterSpacing}px;
        }
      }
    }
  `;

  const updateFilter = (type: FilterType = FilterType.METADATA_VALUE_IDS, id: any) => {
    const { currentFilters } = getValues();
    switch (type) {
      case FilterType.METADATA_VALUE_IDS: {
        const currentFields = [...currentFilters.metadataValueIds];
        const fieldIndex = currentFields.indexOf(id);
        if (fieldIndex === -1) {
          currentFields.push(id);
        } else {
          currentFields.splice(fieldIndex, 1);
        }
        setValue(
          'currentFilters',
          { ...currentFilters, metadataValueIds: currentFields },
        );
        break;
      }
      case FilterType.COURSE_PACE: {
        const coursePace = [...currentFilters.coursePace];
        const fieldIndex = coursePace.indexOf(id);
        if (fieldIndex === -1) {
          coursePace.push(id);
        } else {
          coursePace.splice(fieldIndex, 1);
        }
        setValue(
          'currentFilters',
          { ...currentFilters, coursePace },
        );
        break;
      }
      case FilterType.COURSE_TYPE: {
        const courseType = [...currentFilters.courseType];
        const fieldIndex = courseType.indexOf(id);
        if (fieldIndex === -1) {
          courseType.push(id);
        } else {
          courseType.splice(fieldIndex, 1);
        }
        setValue(
          'currentFilters',
          { ...currentFilters, courseType },
        );
        break;
      }
      case FilterType.REGISTRATION_TYPE: {
        const registrationType = [...currentFilters.registrationType];
        const fieldIndex = registrationType.indexOf(id);
        if (fieldIndex === -1) {
          registrationType.push(id);
        } else {
          registrationType.splice(fieldIndex, 1);
        }
        setValue(
          'currentFilters',
          { ...currentFilters, registrationType },
        );
        break;
      }
      default:
        break;
    }
  };

  const handleResetClick = () => {
    setValue(
      'currentFilters',
      { ...initialFilters },
    );
  };

  const handleUpdateClick = (formData) => {
    setFilters({ ...formData.currentFilters });
    onSubmit(formData.currentFilters);
    onClose();
  };

  const { isDirty } = formState;

  const isFieldSelected = (type: FilterType = FilterType.METADATA_VALUE_IDS, id) => {
    const { currentFilters = filters } = getValues();
    if (type === FilterType.METADATA_VALUE_IDS) {
      return currentFilters.metadataValueIds.indexOf(id) > -1;
    }
    return currentFilters[type].includes(id);
  };

  const sortFilterBySelected = (fields) => {
    let newFields = Object.assign([], [...fields]);
    newFields = newFields.map((field) => {
      const fieldAux = { ...field };
      // Position where the next "selected value" will be relocated
      let lastSelectedPosition = 0;
      const metadataArray = Object.assign([], [...fieldAux.metadataValues]);
      metadataArray.forEach((element, index) => {
        if (isFieldSelected(element.type, element.id)) {
          const removed = metadataArray.splice(index, 1);
          metadataArray.splice(lastSelectedPosition, 0, removed[0]);
          lastSelectedPosition += 1;
        }
      });
      fieldAux.metadataValues = metadataArray;
      return fieldAux;
    });
    return newFields;
  };

  const getTotalFilters = () => {
    const { currentFilters = filters } = getValues();
    let total = currentFilters.metadataValueIds.length;
    total += currentFilters.coursePace?.length;
    total += currentFilters.courseType?.length;
    total += currentFilters.registrationType?.length;
    total += currentFilters.includeArchived ? 1 : 0;
    total += currentFilters.onlyArchived ? 1 : 0;
    return total;
  };

  const filtersDidNotChange = () => {
    const { currentFilters } = getValues();
    return JSON.stringify(filters) === JSON.stringify(currentFilters);
  };

  const FilterMetadataField = ({ field }: any) => {
    const [showFullElements, setShowFullElements] = useState(false);
    let selected = 0;
    return (
      <div className='field-section'>
        <div className='field-name font-weight-bolder'>
          {`${field.name}:`}
        </div>
        <div className='field-values'>
          {
            field.metadataValues.map((value, index) => {
              const isSelected = isFieldSelected?.(value.type, value.id);
              if (!isSelected && !showFullElements && MAX_VISIBLE_ITEMS <= index) {
                return null;
              }
              selected = isSelected ? selected + 1 : selected;
              return (
                <NvTagButtonSelectable
                  key={value.id}
                  text={value.value}
                  className='field-value'
                  elementId={`${value.id}`}
                  onClick={() => { updateFilter?.(value.type, value.id); }}
                  isSelected={isSelected}
                />
              );
            })
          }
          {
            field.metadataValues.length > Math.max(MAX_VISIBLE_ITEMS, selected)
            && (
              <ClickableContainer
                key={field.id}
                css={linkStyle}
                onClick={() => { setShowFullElements(!showFullElements); }}
                className='text-primary semi-bold link-button'
              >
                {
                  showFullElements
                    ? t.SHARED.VIEW_LESS()
                    : t.SHARED.COUNT_MORE(field.metadataValues.length - Math.max(MAX_VISIBLE_ITEMS, selected))
                }
              </ClickableContainer>
            )
          }
        </div>
      </div>
    );
  };

  const FilterHeader = (props: any) => (
    <React.Fragment>
      <div className='filters-title text-medium'>
        { t.COURSES.FILTER.TITLE() }
      </div>
      <div className='filters-buttons'>
        <ClickableContainer
          css={linkStyle}
          onClick={handleSubmit(handleResetClick)}
          className='text-primary semi-bold link-button'
          tabIndex={0}
          pendo-tag-name={config.pendo.adminDashboard.resetFilters}
        >
          {t.COURSES.FILTER.RESET_FILTERS()}
        </ClickableContainer>
        <Button
          className='ml-2'
          onClick={handleSubmit(handleUpdateClick)}
          disabled={filtersDidNotChange()}
          pendo-tag-name={config.pendo.adminDashboard.applyFilters}
        >
          { t.COURSES.FILTER.APPLY_FILTERS() }
          { props.totalFilters > 0 && ` (${getTotalFilters()})` }
        </Button>
      </div>
    </React.Fragment>
  );

  const ArchiveCoursesOption = () => {
    const { currentFilters } = getValues();
    const styles = css`
      padding: ${standardSpacing}px;
    `;
    const setIncludeArchivedCourses = ({
      includeArchived,
      onlyArchived,
    }) => {
      setValue(
        'currentFilters',
        { ...currentFilters, includeArchived, onlyArchived },
      );
    };

    const options: NvDropdownTextItem[] = [
      {
        text: t.COURSES.FILTER.NOT_ARCHIVED_COURSES(),
        type: 'text',
        callback: () => {
          setIncludeArchivedCourses({
            includeArchived: false,
            onlyArchived: false,
          });
        },
        class: 'text-medium',
        dataQa: config.pendo.adminDashboard.notIncludeArchivedCourses,
        isChecked: (!currentFilters.onlyArchived && !currentFilters.includeArchived),
      },
      {
        text: t.COURSES.FILTER.ARCHIVED_COURSES_ONLY(),
        type: 'text',
        class: 'text-medium',
        callback: () => {
          setIncludeArchivedCourses({
            includeArchived: false,
            onlyArchived: true,
          });
        },
        dataQa: config.pendo.adminDashboard.includeOnlyArchivedCourses,
        isChecked: (currentFilters.onlyArchived),
      },
      {
        text: t.COURSES.FILTER.INCLUDE_ARCHIVED_COURSES(),
        type: 'text',
        class: 'text-medium',
        callback: () => {
          setIncludeArchivedCourses({
            includeArchived: true,
            onlyArchived: false,
          });
        },
        dataQa: config.pendo.adminDashboard.includeArchivedCourses,
        isChecked: (currentFilters.includeArchived),
      },
    ];
    const initialIndex = options.findIndex((item) => item.isChecked);
    return (
      <div className='archived-courses field-section' css={styles}>
        <div className='field-name font-weight-bolder'>
          {`${t.COURSES.FILTER.ARCHIVED_STATUS()}:`}
        </div>
        <NvDropdown
          items={options}
          buttonStyle={NvDropdownButtonStyle.FORM}
          showSelectedIndicator
          toggleDataQa={config.pendo.adminDashboard.archivedCoursesFilter}
          initialIndex={initialIndex}
        />
      </div>
    );
  };

  const FilterList = () => (
    <div className='filters-list'>
      <ArchiveCoursesOption />
      {
        localMetadataFilters.map((field) => (<FilterMetadataField key={field.name} field={field} />))
      }
      {localDefaultFilters.map((field) => (<FilterMetadataField key={field.name} field={field} />))}
    </div>
  );

  const FiltersContainer = () => {
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const handleScroll = () => {
      if (showStickyHeader && filtersRef.current?.scrollTop === 0) {
        setShowStickyHeader(false);
      } else if (!showStickyHeader && filtersRef.current?.scrollTop > 0) {
        setShowStickyHeader(true);
      }
    };
    const totalFilters = getTotalFilters();
    return (
      <div ref={filtersRef} className='filters' css={filterStyles} onScroll={handleScroll}>
        <FilterHeader totalFilters={totalFilters} />
        {
          showStickyHeader && (
            <div className='sticky-header'>
              <FilterHeader totalFilters={totalFilters} />
            </div>
          )
        }
        <FilterList />
      </div>
    );
  };

  return (
    <div ref={controllerRef}>
      <Controller
        name='currentFilters'
        control={control}
        render={(data) => (
          <div className='list-of-metadata'>
            <FiltersContainer />
          </div>
        )}
      />
    </div>
  );
});

const FilterFlyoutModal = ({ onClose, showModal, onSubmit, metadataFields = [], clearFilters, onReset = () => {}, includeArchivedByDefault }) => {
  const [filters, setFilters] = useState(initialFilters);
  useEffect(() => {
    if (clearFilters) {
      let newFilters = { ...initialFilters };
      if (includeArchivedByDefault) {
        newFilters = { ...newFilters, includeArchived: includeArchivedByDefault };
      }
      setTimeout(() => {
        setFilters({ ...newFilters });
        onReset();
      });
    }
  }, [clearFilters]);
  useEffect(() => {
    setFilters({ ...filters, includeArchived: includeArchivedByDefault });
  }, [includeArchivedByDefault]);
  return (
    <React.Fragment>
      {showModal && (
        <NvFlyoutModal
          type={ModalType.RIGHT}
          width={360}
          header='header'
          onClose={onClose}
        >
          <MetadataFlyoutForm onClose={onClose} onSubmit={onSubmit} metadataFields={metadataFields} filters={filters} setFilters={setFilters} />
        </NvFlyoutModal>
      )}
    </React.Fragment>
  );
};

export default FilterFlyoutModal;
