import { mergeWith } from 'lodash';
import { normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';

import { replaceArrays } from 'shared/lodash-utils';
import {
  addCreatedSessionActionItem,
  createMentoringProgramSession,
  deleteMentoringProgramSession,
  getMentoringProgramSessions,
  getSingleMentoringProgramSession,
  removeDeletedSessionActionItem,
  resetMentoringProgramSessionsList,
  updateModifiedSessionActionItem,
} from 'redux/actions/mentoring-program-sessions';
import { MentorshipProgramSessionsSchema } from 'redux/schemas/api/mentoring-program-sessions';
import { CompletionStatus } from 'redux/schemas/models/mentoring-program-enrollments';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getMentoringProgramSessions.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, MentorshipProgramSessionsSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(getSingleMentoringProgramSession.fulfilled, (state, action) => {
      const session = action.payload;
      state.models.mentorshipProgramSessions[session.id] = session;
    })
    .addCase(createMentoringProgramSession.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, MentorshipProgramSessionsSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);

      const { enrollmentId, mentorshipProgramConnectionId } = action.meta.arg;
      const connectionIndex = state.models.mentoringProgramEnrollments[enrollmentId].assignedConnections.findIndex((c) => c.connectionId === mentorshipProgramConnectionId);
      state.models.mentoringProgramEnrollments[enrollmentId].states.waitingFirstSession.status = CompletionStatus.COMPLETED;
      state.models.mentoringProgramEnrollments[enrollmentId].assignedConnections[connectionIndex].isSessionCreated = true;
    })
    .addCase(deleteMentoringProgramSession.fulfilled, (state, action) => {
      const { sessionId } = action.meta.arg;
      delete state.models.mentorshipProgramSessions[sessionId];
    })
    .addCase(resetMentoringProgramSessionsList, (state, action) => {
      const { resetList } = action.payload;
      state.app.mentoringProgram.sessions.resetList = resetList;
    })
    .addCase(addCreatedSessionActionItem, (state, action) => {
      const { sessionId, actionItem } = action.payload;
      const session = state.models.mentorshipProgramSessions[sessionId];
      if (session) {
        session.mentorshipProgramActionItems = [
          actionItem,
          ...session.mentorshipProgramActionItems,
        ];
      }
    })
    .addCase(updateModifiedSessionActionItem, (state, action) => {
      const { sessionId, actionItem } = action.payload;
      const session = state.models.mentorshipProgramSessions[sessionId];
      if (session) {
        const index = session.mentorshipProgramActionItems.findIndex(
          (item) => item.id === actionItem.id,
        );
        session.mentorshipProgramActionItems[index] = actionItem;
      }
    })
    .addCase(removeDeletedSessionActionItem, (state, action) => {
      const { sessionId, actionItemId } = action.payload;
      const session = state.models.mentorshipProgramSessions[sessionId];
      if (session) {
        let sessionActionItems = session.mentorshipProgramActionItems;
        sessionActionItems = sessionActionItems.filter(
          (item) => item.id !== actionItemId,
        );
        state.models.mentorshipProgramSessions[
          sessionId
        ].mentorshipProgramActionItems = sessionActionItems;
      }
    });
});
