import { SessionTypes } from 'redux/schemas/models/live-event';
import t from '../../react-translate';
import LiveSessionForm from '../../live_sessions/components/live-session-form';

/* @ngInject */
export default function LiveSessionEditFormModalCtrl(

  $controller,
  $scope,
  $uibModalInstance,
  $location,
  AlertMessages,
  CurrentUserManager,
  InstitutionsManager,
  moment,
  vmResolves,
  RailsRoutes,
  $stateParams,
  TimezonesService,
  LectureComponentsHelper,
  ConfirmationOverlays,
  ReactLecturePageContext,
  CurrentCourseManager,
  _,
) {
  let hostCopy;
  const vm = Object.assign(this, $controller('LectureComponentModalCtrl', { $uibModalInstance, $scope, vmResolves }), {
    // if saved live session, skip showing host details
    CurrentUserManager,
    InstitutionsManager,
    moment,
    hostError: false,
    loading: true,
    source: null,
    integrationSettings: null,
    SESSION_SOURCES: CurrentUserManager.currentUserCourse.LIVE_SESSION_SOURCES,
  });

  vm.getSessionType = () => vm.lectureComponent.liveSessionDraft?.sessionType ?? vm.lectureComponent.liveSessionDraft?.sessions?.[0].sessionType;

  // Start time not set => LE not configured => edit form is in configure mode - applicable for LL
  vm.isConfigureMode = !vm.lectureComponent?.liveSession?.sessions?.[0]?.startTime;

  vm.lcRecordingScope = vm.lectureComponent?.liveSession?.sessions?.[0]?.recordingScope ?? false;
  vm.msTeamsHasRecordingScope = () => {
    if (vm.getSessionType() === 'TeamsLiveSession') {
      /**
       * while creating new LE, check if the user already authenticated
       * AND, if the existing authentication supports recording scope
       */
      return (!!vm.integrationSettings?.TeamsLiveSession?.authenticated
        && !!vm.integrationSettings.TeamsLiveSession?.recordingScope
      );
    }
    return false;
  };

  $scope.LiveSessionForm = () => {
    const msTeamsEmail = vm.lectureComponent.liveSession.authenticationEmail
      || vm.integrationSettings?.TeamsLiveSession?.authenticatedUser?.accountEmail
      || vm.lectureComponent.liveSession?.sessions?.[0]?.authenticationEmail;
    let sessionType = vm.getSessionType();
    if (sessionType === vm.lectureComponent.constructor.SESSION_TYPE_EXTERNAL) {
      sessionType = SessionTypes.EXTERNAL;
    }
    // sessionType = SessionTypes.ZOOM;
    return (
      <LiveSessionForm
        draft={vm.lectureComponent.liveSessionDraft}
        lectureComponent={vm.lectureComponent}
        save={vm.lectureComponent.id ? vm.update : vm.save}
        msTeamsEmail={msTeamsEmail}
        cancel={$uibModalInstance.dismiss}
        creatorUserCourseId={vm.isContentManagementCollection ? undefined : CurrentUserManager.currentUserCourse?.courseId}
        sessionType={sessionType}
        isLinked={vm.isLinked}
        durations={vm.durations}
        validateHost={vm.validateAndSaveZoomHost}
        showZoomHostValidator={vm.shouldShowZoomHostValidator}
        isContentManagementCollection={vm.isContentManagementCollection}
        isAccountLevel={vm.source === vm.SESSION_SOURCES.ZOOM_ACCOUNT_LEVEL}
        deleteSession={vm.deleteSession}
        hasRecordingScope={vm.lectureComponent.id ? vm.lcRecordingScope : vm.msTeamsHasRecordingScope()}
      />
    );
  };

  vm.update = () => {
    $uibModalInstance.close(false, { onSuccess: ReactLecturePageContext.updateLecturePage });
  };

  vm.isLinked = vm.lectureComponent.lecturePage?.isLinked;
  vm.isContentManagementCollection = vm.CurrentCourseManager.course.isContentManagementCollection;
  vm.showSessionTypeForm = !vm.getSessionType();
  vm.durations = vm.lectureComponent.constructor.durations;
  vm.lobbyByPassTypes = vm.lectureComponent.constructor.lobbyByPassTypes;
  vm.sessionTypes = {
    ZOOM: vm.lectureComponent.constructor.SESSION_TYPE_ZOOM,
    MS_TEAMS: vm.lectureComponent.constructor.SESSION_TYPE_MS_TEAMS,
    EXTERNAL: vm.lectureComponent.constructor.SESSION_TYPE_EXTERNAL,
  };
  vm.timeZones = TimezonesService.timezones;
  if (
    !vm.lectureComponent.id
    || vm.isLinked
    || vm.lectureComponent.liveSession.sessionType === vm.lectureComponent.constructor.SESSION_TYPE_ZOOM
  ) {
    CurrentUserManager.currentUserCourse.getLiveSessionSettings()
      .then((response) => {
        vm.integrationSettings = response;

        if (_.isEmpty(response)) {
          vm.lectureComponent.liveSessionDraft.sessionType = vm.lectureComponent.constructor.SESSION_TYPE_EXTERNAL;
          vm.showSessionTypeForm = false;
          vm.display = 'sessionForm';
        } else if (vm.lectureComponent.liveSession.sessionType === vm.lectureComponent.constructor.SESSION_TYPE_ZOOM) {
          vm.source = vm.integrationSettings?.ZoomLiveSession?.source;
        } else if (vm.lectureComponent.liveSession.sessionType === vm.lectureComponent.constructor.SESSION_TYPE_MS_TEAMS
          && vm.isLinked
        ) {
          // Ms teams LE from LL
          vm.source = vm.integrationSettings?.TeamsLiveSession?.source;
          if (!vm.msTeamsHasRecordingScope()) {
            vm.display = 'sessionFormWithoutRecordingScope';
          }
          if (vm.msTeamsHasRecordingScope()) {
            if (vm.isConfigureMode) {
              // Editing for the first time after LL has been added to course
              // Consider configuring as LE creation and enable recording options
              vm.display = 'sessionForm';
            } else {
              // when the auth has recording scope and is editing LE
              // Disable recording options if the lc doesn't have recording scope
              vm.display = vm.lcRecordingScope
                ? 'sessionForm'
                : 'sessionFormWithoutRecordingScope';
            }
          }
        }
      })
      .finally(() => {
        vm.loading = false;
      });
  } else {
    // Edit modal
    const setEditFormtype = () => {
      if (vm.getSessionType() !== 'TeamsLiveSession') {
        vm.display = 'sessionForm';
      } else if (!vm.msTeamsHasRecordingScope()) {
        vm.display = 'sessionFormWithoutRecordingScope';
      } else {
        /**
           * When user has auth with recording scope,
           * display mode will be depending on the recording scope of that LC
           * Whether it is created with recording_scope or not
           */
        vm.display = vm.lcRecordingScope
          ? 'sessionForm'
          : 'sessionFormWithoutRecordingScope';
      }
    };
    if (!vm.integrationSettings) {
      CurrentUserManager.currentUserCourse.getLiveSessionSettings()
        .then((response) => {
          vm.integrationSettings = response;
          setEditFormtype();
        })
        .finally(() => {
          vm.loading = false;
        });
    } else {
      setEditFormtype();
      vm.loading = false;
    }
  }

  vm.getTitle = () => {
    // title => "New Auth request" for the users who does not have recording scope - (ms teams only)
    if (!vm.lectureComponent.id
      && vm.getSessionType() === 'TeamsLiveSession'
      && !vm.msTeamsHasRecordingScope()
      && vm.display === 'oauthIntro'
      && vm.integrationSettings?.TeamsLiveSession?.authenticated // Only if the user already has an auth token
    ) {
      return t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.AUTH_TITLE();
    }
    return (vm.showSessionTypeForm
      ? t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.TITLE(!!vm.lectureComponent.id, vm.lectureComponent.liveSessionDraft?.isDisabled ?? vm.lectureComponent.liveSessionDraft?.sessions?.[0].isDisabled)
      : t.LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.CONFIGURE_TITLE(vm.getSessionType()));
  };
  vm.isSessionType = (type) => vm.getSessionType() === type;

  vm.setSessiontype = (type) => {
    vm.lectureComponent.liveSessionDraft.sessionType = type;
    if (
      type === vm.lectureComponent.constructor.SESSION_TYPE_MS_TEAMS
      || type === vm.lectureComponent.constructor.SESSION_TYPE_ZOOM
    ) {
      vm.source = vm.integrationSettings?.[type]?.source;
      if (
        vm.source === vm.SESSION_SOURCES.ZOOM_USER_LEVEL
        || vm.source === vm.SESSION_SOURCES.MS_TEAMS_USER_LEVEL
      ) {
        if (vm.source === vm.SESSION_SOURCES.MS_TEAMS_USER_LEVEL && !vm.msTeamsHasRecordingScope()) {
          // Display auth modal for old auth with MS team option selected
          vm.display = 'oauthIntro';
        } else {
          vm.display = vm.integrationSettings?.[type]?.authenticated
            ? 'sessionForm'
            : 'oauthIntro';
        }
      } else {
        vm.host = vm.integrationSettings?.[type]?.zoomUserId;
        vm.display = !(vm.lectureComponent.liveSessionDraft.id || vm.host)
          ? 'hostDetailsForm'
          : 'sessionForm';
      }
    }
  };

  vm.editHostDetails = () => {
    hostCopy = vm.host;
    vm.display = 'hostDetailsForm';
    vm.isEdittingHostDetails = true;
  };

  vm.cancelEditHost = () => {
    if (vm.lectureComponent.id || vm.isEdittingHostDetails) {
      vm.host = hostCopy;
      vm.display = 'sessionForm';
      vm.hostError = false;
    } else {
      vm.showSessionTypeForm = true;
    }
  };

  vm.shouldShowZoomHostValidator = () => (
    vm.isSessionType(vm.sessionTypes.ZOOM)
    && vm.source === vm.SESSION_SOURCES.ZOOM_ACCOUNT_LEVEL
    && !vm.showSessionTypeForm
  );

  vm.validateAndSaveZoomHost = (host = vm.host, onValidating, onSucess, onError) => {
    vm.hostError = false;
    vm.validatingZoomHost = true;
    onValidating?.();

    CurrentUserManager.currentUserCourse.validateAndSaveZoomHostUser(host).then((isValidHost) => {
      if (isValidHost) {
        AlertMessages.success('FORM.SUCCESS_BANG', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ZOOM_HOST_SAVED');
        CurrentUserManager.currentUserCourse.zoomUserId = vm.host;
        vm.display = 'sessionForm';
        onSucess?.();
      } else {
        AlertMessages.error('FORM.OOPS', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ZOOM_HOST_ERROR');
        vm.hostError = true;
        onError?.();
      }

      vm.editLiveSessionForm.$setPristine();
      vm.validatingZoomHost = false;
    }, (response) => {
      AlertMessages.error('FORM.OOPS', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.ZOOM_HOST_ERROR');
      vm.validatingZoomHost = false;
      onError?.();
    });
  };

  vm.hasIntegration = (type) => _.has(vm.integrationSettings, type);

  vm.getLobbySettingsKey = (value) => (
    _.findKey(
      vm.lectureComponent.constructor.lobbyByPassTypes,
      (type) => type === value,
    )
  );

  vm.authenticateZoom = () => {
    const refererUrl = `${$location.protocol()}://${$location.host()}/#!${$location.path()}${$location.hash()}`;
    window.location.href = RailsRoutes.zoomOAuthInstallationUrl($stateParams.catalogId, '', refererUrl);
  };

  vm.oAuthUrl = RailsRoutes.msTeamOAuthInstallationUrl($stateParams.catalogId, $stateParams.id);
  vm.authenticateMsTeams = () => {
    window.location.href = vm.oAuthUrl;
  };

  vm.skipAuthentication = () => {
    vm.display = 'sessionFormWithoutRecordingScope';
  };

  vm.canSkipAuth = () => (
    // Diplay skip option for authenticated user who does not have recording scope
    vm.getSessionType() === 'TeamsLiveSession'
    && vm.integrationSettings?.TeamsLiveSession?.authenticated
    && !vm.integrationSettings?.TeamsLiveSession?.recordingScope
  );

  vm.getInvalidAuthText = () => {
    // Display info on current invalid token while create and LL configure mode
    if (!vm.lectureComponent.id
      || (vm.isLinked && vm.isConfigureMode)
    ) {
      return 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.INVALID_OAUTH.CREATE';
    }
    return 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.MODAL.INVALID_OAUTH.EDIT';
  };

  vm.showLiveSessionForm = () => (
    !vm.showSessionTypeForm
    && !vm.loading
    && (
      vm.display !== 'oauthIntro'
      || vm.isSessionType(vm.sessionTypes.EXTERNAL)
    )
  );

  vm.deleteSession = (sessionId, onSuccess) => {
    vm.lectureComponent.liveSession.deleteSession(sessionId).then(() => {
      AlertMessages.success('FORM.SUCCESS_BANG', 'LECTURE_PAGES.COMPONENTS.LIVE_VIDEO_EVENTS.DELETE_SESSION_SUCCESS');
      ReactLecturePageContext.updateLecturePage();
      onSuccess?.(sessionId);
    }).catch(() => {
      AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
    });
  };

  // Update the modal title to match the current modal view
  $scope.$watchGroup(['vm.currentState', 'vm.showSessionTypeForm'], () => {
    vm.workflowCtx.setSettings({
      ...vm.workflowCtx.settings,
      title: () => vm.getTitle(),
    });
  });
}
