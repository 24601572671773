import t from 'react-translate';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import prodPathReplace from 'shared/prod-path-rewrite';
import { ReactComponent as Reminder } from 'styles/icons/reminder.svg';
import { ReactComponent as EmptyFields } from 'styles/icons/empty-fields.svg';
import { ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile-questions';
import { gray1, gray2, gray6 } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  quarterSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import ClickableContainer from 'components/clickable-container';
import { novoEdBlue } from 'athena/styles/colors';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { AngularContext } from 'react-app';
import { useContext } from 'react';

type EmptyQuestionsProps = {
  type: ProgramProfileQuestionType;
};

const styles = css`
  color: ${gray2};

  .empty-fields-title {
    flex: 1;
  }
`;

const noteStyles = css`
  color: ${gray1};
  border: 1px solid ${gray6};
  padding: ${threeQuartersSpacing}px;
  border-radius: ${quarterSpacing}px;

  .reminder {
    width: ${doubleSpacing}px;
  }
  .note-content {
    gap: ${quarterSpacing}px;

    .go-to-link {
      color: ${novoEdBlue};
    }
  }
`;

type NoteProps = {
  children: React.ReactElement;
};

const Note = ({ children }: NoteProps) => {
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.EMPTY_FIELDS;

  return (
    <div css={noteStyles} className='d-flex align-items-center mb-4'>
      <Reminder width={20} height={20} className='mr-2 reminder' />
      <div className='d-flex flex-column note-content'>
        <div className='text-medium bold'>{translationBase.NOTE()}</div>
        {children}
      </div>
    </div>
  );
};

const EmptyOrgFieldsNote = () => {
  const { id: institutionId } = useSelector(getCurrentInstitution) || {};
  const { injectServices } = useContext(AngularContext);
  const [$state, CurrentPermissionsManager] = injectServices([
    '$state',
    'CurrentPermissionsManager',
  ]);
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.EMPTY_FIELDS;

  const goToInstitutionSettings = () => $state.go(
    'institution-settings',
    {
      institutionId,
      tab: 'data_configuration',
    },
    { replace: true },
  );

  const hasOrgAdminPermissions = CurrentPermissionsManager?.hasOrgAdminPermissions();

  return (
    <Note>
      <>
        <div className='italic'>
          {hasOrgAdminPermissions
            ? translationBase.ORG_FIELDS.NOTE_CONTENT_WITH_PERMISSIONS()
            : translationBase.ORG_FIELDS.NOTE_CONTENT_WITHOUT_PERMISSIONS()}
        </div>
        {hasOrgAdminPermissions && (
          <ClickableContainer
            className='semi-bold go-to-link'
            onClick={goToInstitutionSettings}
          >
            {translationBase.ORG_FIELDS.OPEN_ADVANCED_SETTINGS()}
          </ClickableContainer>
        )}
      </>
    </Note>
  );
};

const EmptyProgramFieldsNote = () => {
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.EMPTY_FIELDS;

  return (
    <Note>
      <>
        <div className='italic'>
          {translationBase.PROGRAM_FIELDS.NOTE_CONTENT()}
        </div>
        <img
          width={315}
          src={prodPathReplace('images/matching-rules-empty-state.gif')}
          alt={translationBase.PROGRAM_FIELDS.NOTE_CONTENT()}
        />
      </>
    </Note>
  );
};

const EmptyQuestions = ({ type }: EmptyQuestionsProps) => {
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.EMPTY_FIELDS;

  const emptyState = {
    [ProgramProfileQuestionType.ORG_LEVEL]: {
      title: translationBase.ORG_FIELDS.NO_FIELDS(),
      content: <EmptyOrgFieldsNote />,
    },
    [ProgramProfileQuestionType.PROGRAM_LEVEL]: {
      title: translationBase.PROGRAM_FIELDS.NO_FIELDS(),
      content: <EmptyProgramFieldsNote />,
    },
  };

  const { title, content } = emptyState[type];

  return (
    <div css={styles} className='d-flex flex-column h-100'>
      <div className='empty-fields-title align-content-center text-align-center w-100'>
        <EmptyFields width={50} height={50} />
        <div className='text-medium pt-4'>{title}</div>
      </div>
      {content}
    </div>
  );
};

export default EmptyQuestions;
