import t from 'react-translate';
import { MutableRefObject } from 'react';
import AthenaTextInput from 'athena/components/text-input';
import NvIcon from 'shared/components/nv-icon';
import { useFormContext } from 'react-hook-form';
import { config } from '@config/pendo.config.json';

type ResponseOptionsProps = {
  responseOptionsRef: MutableRefObject<HTMLInputElement>;
  options: string;
  setOptions: (options: string) => void;
};

const ResponseOptions = ({
  responseOptionsRef,
  options,
  setOptions,
}: ResponseOptionsProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <AthenaTextInput
        required
        withForm
        ref={responseOptionsRef}
        value={options}
        showLabel={false}
        name='responseOptions'
        className='pb-2'
        onBlur={({ target: { value } }) => setOptions(value)}
        placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.PLACEHOLDERS.RESPONSE_OPTIONS()}
        dataQa={config.pendo.athena.mentorshipProgram.settings.programProfileSetup.question.questionResponseOptionsInputField}
      />
      {errors?.responseOptions && (
        <div className='d-flex mb-2'>
          <NvIcon icon='warning' size='small' className='text-danger mr-1' />
          <div className='text-danger'>{errors.responseOptions.message}</div>
        </div>
      )}
    </>
  );
};

export default ResponseOptions;
