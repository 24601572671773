import prodPathReplace from 'shared/prod-path-rewrite';
import { probablySupportsVideoType } from 'recording/services/media-visualizer-helper';
import { AiTranslationProgress } from 'shared/ai-translation-progress';
import { AUTOMATED_TRANSLATION_LANGUAGES } from 'automated_translation/components/language-picker';
import t from 'react-translate';

const TRANSLATED_FILES_STORAGE_KEY = 'listOfTranslatedFiles';
const TRANSLATING_FILES_STORAGE_KEY = 'listOfTranslatingFiles';

/* @ngInject */
export default function nvUploadedFile(
  $sce,
  $uibModal,
  $window,
  _,
  config,
  nvUtil,
  $timeout,
  CurrentCourseManager,
  CurrentUserManager,
  PusherManager,
  humps,
  AlertMessages,
  AttachmentsResources,
) {
  const embeddingStyles = {
    '4:3': 'embed-responsive-4by3',
    '1:1': 'embed-responsive-1by1',
    '21:9': 'embed-responsive-21by9',
    '16:9': 'embed-responsive-16by9',
    a4: 'embed-responsive-a4',
    letter: 'embed-responsive-letter',
  };

  return {
    scope: {
      file: '=',
      editable: '=?',
      displayInline: '=?',
      displayInlineStrict: '=?',
      displayNameOnly: '=?',
      fileName: '=?',
      downloadAlwaysVisible: '=?',
      onDelete: '&?',
      clickHandler: '&?',
      originalUrl: '=?',
      onFileLoaded: '&?',
      hideDownloadButton: '=?',
      bookmarkId: '=?',
      createBookmark: '&?',
      highlightBookmark: '&?',
      attachmentId: '=?',
      translatedFile: '=?',
    },
    controller: function ctrl($scope, $rootScope, $element, $attrs) {
'ngInject';
      const vm = this;

      const currentLanguage = AUTOMATED_TRANSLATION_LANGUAGES.find(lang => lang.isoCode === CurrentUserManager.user.translationPreferenceLanguage);

      function listOfTranslatedFiles() {
        return localStorage.getItem(TRANSLATED_FILES_STORAGE_KEY) ? JSON.parse(localStorage.getItem(TRANSLATED_FILES_STORAGE_KEY))[CurrentUserManager.user.id] : [];
      }
      function listOfTranslatingFiles() {
        return localStorage.getItem(TRANSLATING_FILES_STORAGE_KEY) ? JSON.parse(localStorage.getItem(TRANSLATING_FILES_STORAGE_KEY))[CurrentUserManager.user.id] : [];
      }
      const initialTranslatedFiles = listOfTranslatedFiles();
      const initialTranslatingFiles = listOfTranslatingFiles();
      const translatingFileKey = currentLanguage?.translationKey ? `${vm.attachmentId}_${currentLanguage.translationKey}` : null;
      /** Saving the attachmentId with the language in the list of files that are in translating process */
      function addingToTranslatingList(attachmentId) {
        setTimeout(() => {
          const currentListOfTranslatingFiles = listOfTranslatingFiles();
          const element = `${attachmentId}_${currentLanguage.translationKey}`;
          const indexOfAttachmentInTheTranslatingList = currentListOfTranslatingFiles.indexOf(element);
          if (indexOfAttachmentInTheTranslatingList < 0) {
            currentListOfTranslatingFiles.push(element);
            const newListOfTranslatingFiles = {};
            newListOfTranslatingFiles[CurrentUserManager.user.id] = currentListOfTranslatingFiles;
            localStorage.setItem(TRANSLATING_FILES_STORAGE_KEY, JSON.stringify(newListOfTranslatingFiles));
          }
        });
      }
      /** Removing the attachmentId with the language in the list of files that are in translating process */
      function removingFromTranslatingList(attachmentId) {
        setTimeout(() => {
          const currentListOfTranslatingFiles = listOfTranslatingFiles();
          const element = `${attachmentId}_${currentLanguage.translationKey}`;
          const indexOfAttachmentInTheTranslatingList = currentListOfTranslatingFiles.indexOf(element);
          if (indexOfAttachmentInTheTranslatingList >= 0) {
            currentListOfTranslatingFiles.splice(indexOfAttachmentInTheTranslatingList, 1);
            const newListOfTranslatingFiles = {};
            newListOfTranslatingFiles[CurrentUserManager.user.id] = currentListOfTranslatingFiles;
            localStorage.setItem(TRANSLATING_FILES_STORAGE_KEY, JSON.stringify(newListOfTranslatingFiles));
          }
        });
      }

      /** Checking if the translated file coming from BE is in the same language as the surrent course language */
      const translatedFileHasCurrentLanguage = (() => {
        if (vm.translatedFile && Object.keys(vm.translatedFile).length > 0) {
          return vm.translatedFile.language && currentLanguage && vm.translatedFile.language.toUpperCase() === currentLanguage.translationKey;
        }
        return false;
      })();

      /** If it was in tranlsating mode and the file is ready, then the attachment has been translated
       * Otherwise, check if it exist in the list of translated files.
       */
      function hasAttachmentBeenTranslated() {
        if (initialTranslatingFiles.includes(translatingFileKey)) {
          return translatedFileHasCurrentLanguage;
        }
        return !!CurrentUserManager?.user?.translationPreferenceLanguage && initialTranslatedFiles.includes(vm.attachmentId);
      }

      /** Getting the state of the file, depending on the translated files list and the translating files list */
      function fileState() {
        if (initialTranslatedFiles.includes(vm.attachmentId) && vm.translatedFile && Object.keys(vm.translatedFile).length > 0) {
          return AiTranslationProgress.TRANSLATED;
        }
        if (initialTranslatingFiles.includes(translatingFileKey)) {
          if (translatedFileHasCurrentLanguage) {
            return AiTranslationProgress.TRANSLATED;
          }
          return AiTranslationProgress.TRANSLATING;
        }
        return null;
      }

      _.extend(vm, {
        isContentManagementCollection: CurrentCourseManager.course?.isContentManagementCollection ?? false,
        showConfirmation: false,
        isVideoNotPlayable: false,
        fileHovered: false,
        CurrentCourseManager,
        CurrentUserManager,
        attachmentHasBeenTranslated: hasAttachmentBeenTranslated(),
        fileTranslationState: fileState(),
        AiTranslationProgress,
        replacingFile: false,

        normalizeFileType,
        getImageSrc,
        showDeletionConfirmation,
        confirmDeletion,
        cancelDeletion,
        handleFilePreviewClick,
        formatFileSize,
        canProbablyPreview,
        onVideoError,
        downloadFile,
        translateFileToggle,
        isFileAvailableToDownload,
        isFileAvailableToTranslate,
        isFileTranslated,
        isShortTitle,
        isShorterTitle,
        getTooltipText,
        getTranslateIconClass,
        hasTranslatedFile,
        getTranslatedFile,
        isFileTranslating,
        isFileReplacing,
        getTranslationStateForBadge,
      });

      // In case the file is in translating state but the attachment is translated
      // then it should be remove. Otherwise we should wait for WS response.
      if (initialTranslatingFiles.includes(translatingFileKey)) {
        if (vm.attachmentHasBeenTranslated) {
          removingFromTranslatingList(vm.attachmentId);
        } else {
          PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('auto_translate_document', documentTranslationHandler);
        }
      }

      function launchPreviewModal() {
        $uibModal.open({
          templateUrl: 'shared/templates/nv-file-upload-preview-modal.html',
          windowClass: 'file-preview-modal large-modal tall-modal full-screen-modal-handheld full-screen-modal-tablet',
          controller: 'AttachModalResolvesToVmCtrl as vm',
          resolve: {
            vmResolves: () => ({
              file: vm.file,
              fileType: vm.fileType,
              isVisualMedia: vm.isVisualMedia,
              downloadFile: vm.downloadFile,
              translateFileToggle: vm.translateFileToggle,
              isFileAvailableToDownload: vm.isFileAvailableToDownload,
              isFileAvailableToTranslate: vm.isFileAvailableToTranslate,
              isFileTranslated: vm.isFileTranslated,
              isShortTitle: vm.isShortTitle,
              isShorterTitle: vm.isShorterTitle,
              bookmarkId: vm.bookmarkId,
              createBookmark: vm.createBookmark,
              highlightBookmark: vm.highlightBookmark,
              isContentManagementCollection: vm.isContentManagementCollection,
              getTooltipText: vm.getTooltipText,
              getTranslateIconClass: vm.getTranslateIconClass,
              CurrentCourseManager: vm.CurrentCourseManager,
              CurrentUserManager: vm.CurrentUserManager,
              translatedFile: vm.translatedFile,
              fileTranslationState: vm.fileTranslationState,
              isFileTranslating: vm.isFileTranslating,
              hasTranslatedFile: vm.hasTranslatedFile,
              getTranslatedFile: vm.getTranslatedFile,
              isFileReplacing: vm.isFileReplacing,
              getTranslationStateForBadge: vm.getTranslationStateForBadge,
            }),
          },
        });
      }

      function normalizeFileType(fileType) {
        if (vm.fileType === 'image' || _.includes(config.files.images.split(','), fileType)) {
          return 'image';
        } if (_.includes(config.files.videos.split(','), fileType)) {
          return 'video';
        } if (_.includes(config.files.audios.split(','), fileType)) {
          return 'audio';
        } if (_.includes(config.files.pdfs.split(','), fileType)) {
          return 'pdf';
        } if (_.includes(config.files.documents.split(','), fileType)) {
          return 'document';
        } if (_.includes(config.files.excels.split(','), fileType)) {
          return 'spreadsheet';
        } if (_.includes(config.files.presentations.split(','), fileType)) {
          return 'presentation';
        } if (_.includes(config.files.zips.split(','), fileType)) {
          return 'zip';
        }
        return 'other';
      }

      function getImageSrc(type) {
        // NOTE: have to return the full path string so gulp will be able to process properly
        switch (type) {
          case 'pdf': {
            return prodPathReplace('images/file-pdf.png');
          }
          case 'image': {
            return prodPathReplace('images/file-image.png');
          }
          case 'video': {
            return prodPathReplace('images/file-video.png');
          }
          case 'audio': {
            return prodPathReplace('images/file-audio.png');
          }
          case 'document': {
            return prodPathReplace('images/file-doc.png');
          }
          case 'spreadsheet': {
            return prodPathReplace('images/file-excel.png');
          }
          case 'presentation': {
            return prodPathReplace('images/file-powerpoint.png');
          }
          case 'externaldocument': {
            return prodPathReplace('images/file-externaltools.png');
          }
          case 'zip': {
            return prodPathReplace('images/file-zip.png');
          }
          case 'other': {
            return prodPathReplace('images/file-other.png');
          }
          default: {
            return null;
          }
        }
      }

      function showDeletionConfirmation() {
        vm.showConfirmation = true;
      }

      function confirmDeletion() {
        vm.showConfirmation = false;
        vm.onDelete({ $file: vm.file });
      }

      function cancelDeletion() {
        vm.showConfirmation = false;
      }

      function handleFilePreviewClick($event) {
        $event.stopPropagation();

        if (vm.clickHandler) {
          vm.clickHandler({ $file: vm.file });
        }

        if (vm.isExternalDoc) {
          $event.stopPropagation();
          $window.open(vm.file.url, '_blank');
        } else {
          launchPreviewModal();
        }
      }

      function downloadFile() {
        if (vm.file?.url || vm.hasTranslatedFile()) {
          window.location.href = vm.isFileTranslated() && vm.translatedFile?.documentUrl ? vm.translatedFile.documentUrl : vm.file.url;
        }
      }

      function hasTranslatedFile() {
        return vm.isFileTranslated() && vm.attachmentHasBeenTranslated && vm.translatedFile && Object.keys(vm.translatedFile).length > 0;
      }

      function getTranslatedFile() {
        return vm.translatedFile;
      }

      function getTranslationStateForBadge() {
        return isFileAvailableToTranslate() ? vm.fileTranslationState : null;
      }

      function translateFileToggle() {
        const language = !isFileTranslated() && CurrentUserManager.user.translationPreferenceLanguage ? CurrentUserManager.user.translationPreferenceLanguage : null;
        vm.fileTranslationState = AiTranslationProgress.TRANSLATING;
        if (language) {
          const currentUserLanguage = AUTOMATED_TRANSLATION_LANGUAGES.find(lang => lang.isoCode === language);
          if (vm.translatedFile?.language && vm.translatedFile.language.toUpperCase() === currentUserLanguage.translationKey) {
            vm.replacingFile = true;
            $timeout(() => {
              vm.fileTranslationState = AiTranslationProgress.TRANSLATED;
              vm.attachmentHasBeenTranslated = true;
              vm.replacingFile = false;
            });
          } else {
            addingToTranslatingList(vm.attachmentId);
            AttachmentsResources.translateFile({ id: vm.attachmentId }, { language }).$promise.then((response) => {
              PusherManager.courseChannel(vm.CurrentCourseManager.course.id).bind('auto_translate_document', documentTranslationHandler);
            }).catch((error) => {
              AlertMessages.error('AUTOMATED_TRANSLATION.ERROR', 'AUTOMATED_TRANSLATION.RETRY');
              vm.fileTranslationState = AiTranslationProgress.CANNOT_TRANSLATE;
            });
          }
        } else {
          vm.replacingFile = true;
          $timeout(() => {
            vm.fileTranslationState = null;
            vm.attachmentHasBeenTranslated = false;
            vm.replacingFile = false;
          });
        }
      }

      function stopFileTranslation() {
        if (vm.fileTranslationState === AiTranslationProgress.TRANSLATING) {
          PusherManager.courseChannel(vm.CurrentCourseManager.course.id).unbind('auto_translate_document', documentTranslationHandler);
          vm.fileTranslationState = vm.file.language?.translationKey ? vm.AiTranslationProgress.TRANSLATED : null;
          vm.attachmentHasBeenTranslated = vm.fileTranslationState === vm.AiTranslationProgress.TRANSLATED;
        }
        if (vm.replacingFile) {
          $timeout(() => {
            vm.replacingFile = false;
          });
        }
      }

      function documentTranslationHandler(pusherData) {
        if (vm.fileTranslationState === AiTranslationProgress.TRANSLATING) {
          vm.replacingFile = true;
          pusherData = humps.camelizeKeys(pusherData);
          if (pusherData.translatedFromId === vm.attachmentId) {
            removingFromTranslatingList(vm.attachmentId);
            if (pusherData.errorCode) {
              AlertMessages.error('AUTOMATED_TRANSLATION.ERROR', 'AUTOMATED_TRANSLATION.RETRY');
              stopFileTranslation();
              return;
            }
            vm.translatedFile = { ...pusherData };
            if (vm.translatedFile.documentUrl) {
              $timeout(() => {
                PusherManager.courseChannel(vm.CurrentCourseManager.course.id).unbind('auto_translate_document', documentTranslationHandler);
                vm.fileTranslationState = AiTranslationProgress.TRANSLATED;
                vm.attachmentHasBeenTranslated = true;
                vm.replacingFile = false;
              });
            }
          }
        }
      }

      function isFileAvailableToDownload() {
        return (typeof vm.file.isDownloadable === 'undefined' || vm.file.isDownloadable === null) ? true : vm.file.isDownloadable;
      }

      function isBookmarkIconPresent() {
        return ((vm.createBookmark && !vm.bookmarkId) || (vm.highlightBookmark && vm.bookmarkId)) && !vm.isContentManagementCollection;
      }

      function isFileAvailableToTranslate() {
        const validExtensions = [
          'pdf',
          'doc',
          'docx',
          'ppt',
          'pptx',
          'txt',
        ];
        const fileExtension = vm.file.name.split('.').pop();
        return (
          CurrentCourseManager.course?.automatedTranslationEnabled
          && CurrentUserManager.user?.translationPreferenceLanguage
          && (
            vm.fileType === 'pdf'
            || vm.fileType === 'document'
            || vm.fileType === 'spreadsheet'
            || vm.fileType === 'presentation'
          )
          && validExtensions.includes(fileExtension)
        );
      }

      function isFileReplacing() {
        return vm.replacingFile;
      }

      function isFileTranslated() {
        return vm.fileTranslationState === vm.AiTranslationProgress.TRANSLATED;
      }

      function isFileTranslating() {
        return vm.fileTranslationState === vm.AiTranslationProgress.TRANSLATING;
      }

      function isShortTitle() {
        return (isFileAvailableToDownload() && ((isBookmarkIconPresent() && !isFileAvailableToTranslate()) || (!isBookmarkIconPresent() && isFileAvailableToTranslate())))
          || (!isFileAvailableToDownload() && isBookmarkIconPresent() && isFileAvailableToTranslate());
      }

      function isShorterTitle() {
        return isFileAvailableToDownload() && isBookmarkIconPresent() && isFileAvailableToTranslate();
      }

      function getTranslateIconClass(isModalPreview = false) {
        if (isFileTranslating()) {
          return `icon-gen-ai ai-element text-ai-color${isModalPreview ? ' icon-small' : ' icon-smallest'}`;
        }
        if (isFileTranslated()) {
          return `icon-translate toggled${isModalPreview ? ' icon-smallest' : ' icon-xss-smallest'}`;
        }
        return `icon-translate${isModalPreview ? ' icon-small' : ' icon-smallest'}`;
      }

      function getTooltipText() {
        switch (vm.fileTranslationState) {
          case vm.AiTranslationProgress.TRANSLATING:
            return t.LECTURE_PAGES.TRANSLATE_FILE.TOOLTIP.TRANSLATING();
          case vm.AiTranslationProgress.TRANSLATED:
            return t.LECTURE_PAGES.TRANSLATE_FILE.TOOLTIP.TURN_OFF();
          default:
            return t.LECTURE_PAGES.TRANSLATE_FILE.TOOLTIP.AI_TRANSLATION();
        }
      }

      function formatFileSize(size) {
        return nvUtil.formatFileSize(size);
      }

      // we don't know for sure if a file can be played for sure, but the browser tells us if support is likely
      function canProbablyPreview() {
        return (vm.file && !vm.file.url) // legacy code: assumes that if it is a File(or maybe Blob), then it is playable
          || probablySupportsVideoType(vm.file.mimeType || vm.file.type);
      }

      function onVideoError($event) {
        if ($event.currentTarget.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
          vm.isVideoNotPlayable = true;
        }
      }

      $scope.$watch('vm.CurrentUserManager.user.translationPreferenceLanguage', () => {
        if (vm.CurrentUserManager?.user?.translationPreferenceLanguage) {
          const currentUserLanguage = AUTOMATED_TRANSLATION_LANGUAGES.find(lang => lang.isoCode === vm.CurrentUserManager.user.translationPreferenceLanguage);
          if (vm.isFileTranslated() && vm.translatedFile?.language && vm.translatedFile.language.toUpperCase() !== currentUserLanguage.translationKey) {
            vm.fileTranslationState = null;
          }
        }
      });
      $scope.$watch('vm.attachmentHasBeenTranslated', () => {
        const currentListOfTranslatedFiles = listOfTranslatedFiles();
        if (CurrentUserManager?.user?.translationPreferenceLanguage) {
          const indexOfAttachmentInTheList = currentListOfTranslatedFiles.indexOf(vm.attachmentId);
          if (vm.attachmentHasBeenTranslated && indexOfAttachmentInTheList < 0) {
            currentListOfTranslatedFiles.push(vm.attachmentId);
          } else if (!vm.attachmentHasBeenTranslated && indexOfAttachmentInTheList >= 0) {
            currentListOfTranslatedFiles.splice(indexOfAttachmentInTheList, 1);
          }
          const newListOfTranslatedFiles = {};
          newListOfTranslatedFiles[CurrentUserManager.user.id] = currentListOfTranslatedFiles;
          localStorage.setItem(TRANSLATED_FILES_STORAGE_KEY, JSON.stringify(newListOfTranslatedFiles));
        }
      });
    },
    link(scope, element, attr, vm) {
      scope.$watchGroup(['vm.file.typeFromExtension', 'vm.file.url', 'vm.displayInline', 'vm.file.embeddingStyle'], updateDisplaySettings);

      function notifyFileLoad() {
        $timeout(() => {
          if (vm.isVisualMedia) {
            switch (vm.fileType) {
              case 'image': {
                const image = element.find('img.preview');
                image.on('load', vm.onFileLoaded);
                break;
              }
              case 'video': {
                const video = element.find('video.preview');
                video.on('loadeddata', vm.onFileLoaded);
                break;
              }
              default: if (vm.file.url) vm.onFileLoaded();
            }
          } else if (vm.fileUrl) vm.onFileLoaded();
        });
      }

      function updateDisplaySettings() {
        if (!vm.displayNameOnly) {
          vm.isExternalDoc = vm.file.type === 'externaldocument' || vm.file.source === 'googleDrive';

          // get the file type from provided type or from mimeType
          vm.fileType = vm.isExternalDoc ? vm.file.documentType : (vm.file.type || vm.file.typeFromExtension);

          vm.fileType = vm.fileType?.toLowerCase();
          vm.fileType = vm.fileType.split(';')[0]; // ignore codecs since it may be included

          vm.fileType = vm.normalizeFileType(vm.fileType);

          vm.responsiveEmbeddingStyle = embeddingStyles[vm.file.embeddingStyle] || embeddingStyles['16:9'];

          vm.isVisualMedia = vm.fileType === 'image' || vm.fileType === 'video' || vm.fileType === 'audio';
          vm.shouldDisplayInline = vm.displayInline || (!vm.displayInlineStrict && !vm.isExternalDoc && vm.isVisualMedia);

          if (vm.isVisualMedia || !vm.file.googleViewUrl) {
            if (vm.isExternalDoc && vm.file.documentUrl) {
              vm.fileUrl = $sce.trustAsResourceUrl(vm.file.documentUrl);
            } else {
              const currentFile = vm.hasTranslatedFile() ? vm.translatedFile.documentUrl : vm.file.url;
              vm.fileUrl = $sce.trustAsResourceUrl(currentFile);
            }
          } else {
            vm.fileUrl = $sce.trustAsResourceUrl(vm.file.googleViewUrl);
          }

          vm.isVideoNotPlayable = false;
        }

        if (vm.onFileLoaded) notifyFileLoad();
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-uploaded-file.html',
  };
}
