/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { useMediaQuery } from 'react-responsive';

import { CourseTemplate } from 'redux/schemas/models/course-templates';
import { InstitutionLogoSize } from 'redux/schemas/models/institution';
import { setSelectedTemplateId } from 'redux/actions/course-templates';

import OfferingBrandingHeader from 'offerings/components/offering-branding-header';
import NvModalPagination from 'org_mentor/components/nv-modal-pagination';
import NvIcon from 'shared/components/nv-icon';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { gray5, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, largeSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import { Template } from '../course-templates-wrapper';
import { config } from '../../../../../config/pendo.config.json';

type CourseStartTemplateProps = {
  handleCloseModal: () => void,
  handleSetActiveTab: (activeTab: Template) => void;
};

const CourseTemplatePreview = (props: CourseStartTemplateProps) => {
  const { handleCloseModal, handleSetActiveTab } = props;

  const courseTemplates = useSelector((state) => state.models.courseTemplates);
  const selectedTemplateId = useSelector((state) => state.app.courseTemplates.selectedTemplateId);
  const selectedTemplateData = courseTemplates.find((ct: CourseTemplate) => ct.id === selectedTemplateId);
  const index = courseTemplates.findIndex((item: CourseTemplate) => item.id === selectedTemplateId);

  const [currentIndex, setCurrentIndex] = useState<number>(index);
  const [selectedTemplateLocal, setSelectedTemplateLocal] = useState<CourseTemplate>(selectedTemplateData);

  const dispatch = useDispatch();

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const styles = css`
    overflow-y: auto;
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${halfSpacing}px;
      margin: auto;
      ${isHandheld && css`
         display: block;
      `}
    }

    .preview-img {
      height: ${tripleSpacing * 3}px;
      min-height: ${tripleSpacing * 3}px;
      width: 100%;
      background-color: ${gray5};
    }
  `;

  const modalStyles = css`
    &.bs4-modal {
      &.modal-no_dialog {
        .bs4-modal-dialog {
          .bs4-modal-content {
            ${!isHandheld && css`
              width: calc(100% - 240px);
              margin: auto;
              .close {
                padding: ${halfSpacing}px;
                margin-right: -110px;
              }
           `}

            .bs4-modal-header {
              min-height: ${doubleSpacing}px;
              height: ${doubleSpacing}px;

              .bs4-modal-title {
                height: ${doubleSpacing}px;
              }
            }

            ${isHandheld && css`
              .close {
                margin-right: 0px;
                padding: 18px;
              }
           `}

            .bs4-modal-body {
              padding: 0px ${standardSpacing}px ${standardSpacing}px;
            }
            .preview-body-modal {
              .pagination-buttons {
                top: calc(50vh - 20px);
              }
              .use-template-button {
                margin-right: ${isHandheld ? '80' : '0'}px;
              }
              .back-button {
                background: none;
                box-shadow: none;
                border: none;
                align-items: center;

                &:active {
                  outline: none;
                }
              }

              .description-container {
                padding: ${doubleSpacing}px ${doubleSpacing * 3}px;
                // 330 = header height(250) + top buttons container height(60) + bottom padding(20)
                height: calc(100vh - 330px);

                .additional-info {
                  padding-bottom: ${largeSpacing}px;
                }

                a {
                  color:${primary};
                }
              }
            }
          }
        }
      }
    }
  `;

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % courseTemplates.length;
    setCurrentIndex(nextIndex);
    setSelectedTemplateLocal(courseTemplates[nextIndex]);
  };

  const handlePrev = () => {
    const previousIndex = (currentIndex - 1 + courseTemplates.length) % courseTemplates.length;
    setCurrentIndex(previousIndex);
    setSelectedTemplateLocal(courseTemplates[previousIndex]);
  };

  const handleSelectModal = () => {
    dispatch(setSelectedTemplateId(selectedTemplateLocal.id));
    handleSetActiveTab(Template.CREATE_FORM);
  };

  const PreviewTemplate = () => (
    <div className='preview-body-modal'>
      <div className='d-flex justify-content-between py-2'>
        <div className='d-flex align-items-center'>
          <button type='button' onClick={() => handleSetActiveTab(Template.LISTING)} className='ml-1 d-flex back-button'>
            <NvIcon icon='back' size='small' className='text-white' />
            <div
              className='text-white text-large-regular font-bold font-weight-bolder ml-2 condensed'
              data-qa={config.pendo.courseTemplates.backToTemplate}
            >
              {t.FORM.BACK()}
            </div>
          </button>
        </div>
        <Button
          variant='primary'
          className={`${isHandheld ? 'text-small-body' : 'text-large-body px-4'} py-1 use-template-button`}
          onClick={handleSelectModal}
          data-qa={config.pendo.courseTemplates.useThisTemplate}
        >
          {t.COURSES.COURSE_TEMPLATES.USE_THIS_TEMPLATE()}
        </Button>
      </div>
      <NvModalPagination
        show
        onNext={handleNext}
        onPrev={handlePrev}
      >
        <div css={styles} className='h-100 bg-gray-7'>
          <div className='top-section'>
            <OfferingBrandingHeader
              value={{
                name: selectedTemplateLocal?.name,
                fontColor: 'white',
                logo: {
                  file: undefined,
                  url: selectedTemplateLocal?.institutionLogo,
                  size: InstitutionLogoSize.SMALL,
                },
                brandColor: '',
                background: {
                  file: undefined,
                  url: selectedTemplateLocal?.headerBackground,
                },
              }}
            />
            <div className='description-container'>
              {(selectedTemplateLocal?.classDescription || selectedTemplateLocal?.moreInformation) && (
                <React.Fragment>
                  <div className='text-align-center font-weight-light text-xl mb-2'>{t.COURSES.COURSE_TEMPLATES.DESCRIPTION()}</div>
                  <div dangerouslySetInnerHTML={{ __html: (selectedTemplateLocal?.classDescription) }} />
                  <div className='additional-info'>
                    <div dangerouslySetInnerHTML={{ __html: (selectedTemplateLocal?.moreInformation) }} />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </NvModalPagination>
    </div>
  );

  return (
    <React.Fragment>
      <NvModal
        show
        onClose={handleCloseModal}
        closeDataQa={config.pendo.courseTemplates.closeTemplate}
        type={ModalType.NO_DIALOG}
        fullHeight
        modalStyles={modalStyles}
        body={<PreviewTemplate />}
      />
    </React.Fragment>
  );
};

export default CourseTemplatePreview;
