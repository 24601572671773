import { css } from '@emotion/react';
import { ReactNode, useContext, useEffect } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

// Contexts
import { CommunicationAction, CommunicationDispatch } from 'communications/course_communications/contexts/communication-context';

// Hooks
import useCommunicationForm, { communicationDefaultState, CommunicationFormProvider } from 'communications/course_communications/hooks/use-communication-form';
import { RteTagContextProvider } from 'froala/hooks/use-tags-history';

// Schemas
import { Communication, CommunicationCategory, CommunicationType } from 'redux/schemas/models/course-communication';

// Selectors
import { getCommunication, getCourseWelcomeEmail, getCurrentCourseWelcomeEmail } from 'redux/selectors/course-communications';
import { getCurrentUser } from 'redux/selectors/users';

// Components
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import NvSteps from 'shared/components/nv-steps';
import StepOne from './step-1';
import StepTwo from './step-2';
import StepThree from './step-3';

type CommunicationFormProps = {
  catalogId?: string,
  validationSchema: yup.ObjectSchema,
  children: ReactNode,
};

const styles = (showSteps: boolean) => css`
  padding: ${showSteps ? 40 : 0}px 80px;
  height: 100%;

  .nv-steps {
    display: flex;
    justify-content: center;
  }
`;

export const formDropdownSchema = yup.object().shape<NvDropdownTextItem>({
  id: yup.mixed(),
  type: yup.string(),
  text: yup.string(),
  value: yup.mixed(),
});

const CommunicationForm = (props: CommunicationFormProps) => {
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const catalogIdToUse = props.catalogId ?? catalogId;

  const { State, dispatch } = useContext(CommunicationDispatch);

  const currentUser = useSelector(getCurrentUser);
  const initialCommunication: Communication = useSelector((state) => getCommunication(state, State.communicationId));
  const regularWelcomeEmail: Communication = useSelector(getCurrentCourseWelcomeEmail);
  const welcomeEmail: Communication = useSelector((state) => getCourseWelcomeEmail(state, catalogIdToUse, State.communicationType));
  const defaultCommunication = {
    ...communicationDefaultState,
    communicationType: State.communicationType,
    ownerId: State.activityId,
    ownerType: State.activityType,
    lecturePageId: State.lecturePageId,
    communication: {
      ...communicationDefaultState.communication,
      adminUserId: State.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT
        ? currentUser.id
        : undefined,
    },
  };

  const showSteps = State.communicationCategory === CommunicationCategory.SCHEDULED_EMAIL
    || State.communicationCategory === CommunicationCategory.AUTOMATED_JOURNEY_EMAIL
    || State.communicationCategory === CommunicationCategory.TRIGGERS;

  let communication = initialCommunication;
  if (State.communicationCategory === CommunicationCategory.WELCOME_EMAIL) {
    switch (State.communicationType) {
      case CommunicationType.COURSE_WELCOME_EMAIL:
        communication = welcomeEmail;
        break;
      case CommunicationType.COURSE_MENTOR_WELCOME_EMAIL:
        // For the Mentor Welcome Email, we will use the data from the Regular Welcome Email since we don't have specific data for the Mentor Welcome Email in the backend. Currently, the Regular Welcome Email data is already available. In the future, we may consider obtaining separate data for the Welcome Emails, but at this point, it is not necessary.
        if (!welcomeEmail) {
          communication = {
            ...defaultCommunication,
            communication: regularWelcomeEmail.communication,
          };
        } else {
          communication = welcomeEmail;
        }
        break;
      default:
        communication = welcomeEmail;
        break;
    }
  }

  const communicationFormMethods = useCommunicationForm(
    communication ?? defaultCommunication,
    {
      catalogId: catalogIdToUse,
      triggerType: State.triggerType,
    },
    catalogIdToUse,
  );

  const { steps, activeStep, setActiveStep, lastValidStep, isDirty } = communicationFormMethods;

  // Set this in context so that any changes are made, can be checked when the modal is closed
  useEffect(() => {
    dispatch({
      type: CommunicationAction.SET_IS_DIRTY,
      isDirty,
    });
  }, [dispatch, isDirty]);

  let renderContent = null;
  switch (activeStep) {
    case 2:
      renderContent = <StepThree />;
      break;
    case 1:
      renderContent = <StepTwo />;
      break;
    default:
      renderContent = (
        <StepOne
          validationSchema={props.validationSchema}
          isAnnouncement={State.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT}
        >
          {props.children}
        </StepOne>
      );
      break;
  }

  return (
    <CommunicationFormProvider {...communicationFormMethods}>
      <RteTagContextProvider>
        <div css={styles(showSteps)}>
          {showSteps && (
            <NvSteps
              active={activeStep}
              steps={steps}
              onChange={setActiveStep}
              lastValidStep={lastValidStep}
            />
          )}
          {renderContent}
        </div>
      </RteTagContextProvider>
    </CommunicationFormProvider>
  );
};

export default CommunicationForm;
