import { css } from '@emotion/react';

import React, { useState, useEffect } from 'react';
import t from 'react-translate';

// Styles
import { warning, primary } from 'styles/global_defaults/colors';
import {
  handheld, notHandheld, isHandheld,
} from 'styles/global_defaults/media-queries';

// Components
import { NvDropdown, NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import ClickableContainer from 'components/clickable-container';
import NvPopover from 'shared/components/nv-popover';
import { NvTooltip } from './nv-tooltip';

export type NvTab = {
  /* The text to be displayed in the tab. If given as a function, will be called with the tab's count property as the only argument
  Allows us to make tab names be translated strings with a number given as a translation argument */
  text: string,
  count?: number,
  onClick?: () => void,
  tooltip?: string,
  popOver?: JSX.Element,
  showMobileArrow?: boolean,
  pendoTagName?: string,
  dataQA?: string,
  id?: string | number,
};

export enum NvResponsiveTabsDisplayType {
  TEXT_ONLY,
  STACKED_NUMBERED,
}

type NvResponsiveTabsProps = {
  tabs: NvTab[],
  displayType?: NvResponsiveTabsDisplayType
  tabTextClass?: string,
  revertActiveTab?: number,
  activeClass?: string,
  activeBorderColor?: string,
  /** Preventing active tab setting when clicking on a tab */
  preventActiveTabOnClick?: boolean,
  selectedTabIndex?: number
};

const defaultProps: Partial<NvResponsiveTabsProps> = {
  displayType: NvResponsiveTabsDisplayType.STACKED_NUMBERED,
};

/** This is a comment about the nv responsive tabs component */
export const NvResponsiveTabs = (props: NvResponsiveTabsProps) => {
  const displayType = props.displayType ?? NvResponsiveTabsDisplayType.TEXT_ONLY;

  const styles = css`
    /* TODO: Should this margin be built into the component or separate? It seems to be
    uniform across our different tabbed expereiences */
    margin-left: ${standardSpacing}px;

    .desktop-tabs {
      display: flex;
      ${handheld(css`
        display: none;
      `)};
    }

    .tab {
      display: flex;

      ${notHandheld(css`
        flex-direction: column;
      `)};

      height: ${standardSpacing * 3}px;
      justify-content: center;
      align-items: center;
      padding-left: ${standardSpacing}px;
      padding-right: ${standardSpacing}px;
      cursor: pointer;
      text-align: center;
      position: relative;

      ${displayType === NvResponsiveTabsDisplayType.STACKED_NUMBERED ? css`
        &.active {
          color: ${warning};

          .tab-text {
            font-weight: 600;
          }
        }` : ''}

      &:not(.active):hover {
        color: ${primary};
      };

      &:not(:last-of-type) {
        margin-right: ${standardSpacing}px;
      }
    }

    .tab.active .bottom-border {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-color: ${props.activeBorderColor ?? warning};
    }

    .mobile-dropdown {
      display: flex;
      align-items: center;

      ${notHandheld(css`
        display: none;
      `)};

      .tab {
        /* Overrides the non-mobile tab padding */
        padding: ${halfSpacing}px;
        margin-right: 0;
      }
    }

    .mobile-toggle {
      display: flex;
      align-items: baseline;
    }
  `;

  const onTabClicked = (tab: NvTab, index: number) => {
    if (!props.preventActiveTabOnClick) {
      setActiveTabIndex(index);
    }
    tab.onClick?.();
  };

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // This controls the active tab when switching to and from the results tab (in the inst dashboard, you should return to the first tab, but in the team facil dashboard, you should return to the tab that you were on)
  useEffect(() => {
    if (props.revertActiveTab && props.revertActiveTab < props.tabs.length) {
      setActiveTabIndex(props.revertActiveTab);
    } else {
      // if no default selectedIndex, set to the first tab.
      setActiveTabIndex(props.selectedTabIndex ? props.selectedTabIndex : 0);
    }
  }, [props.revertActiveTab, props.tabs.length, props.selectedTabIndex]);

  // TODO: Do we have a need for the translation strings to be evaluated
  // with a # param?
  const navText = (nvTab: NvTab) => nvTab.text;

  const tabTextClass = (isActive: boolean) => {
    if (isActive && props.activeClass) {
      return props.activeClass;
    }
    if (props.tabTextClass) {
      return props.tabTextClass;
    }

    switch (displayType) {
      case NvResponsiveTabsDisplayType.TEXT_ONLY:
        return 'page-title';
      case NvResponsiveTabsDisplayType.STACKED_NUMBERED:
        return 'page-title-xxs';
      default:
        return '';
    }
  };

  const mobileText = (tab: NvTab): string => {
    switch (displayType) {
      case NvResponsiveTabsDisplayType.TEXT_ONLY:
        return tab.text;
      case NvResponsiveTabsDisplayType.STACKED_NUMBERED:
        return t.SHARED.TABS_NAVIGATION_MOBILE(tab.count, tab.text);
      default:
        return '';
    }
  };

  /* <Nav> is used instead of react-bootstrap's <Tabs> because we often do not want to
  make each tab element contain a different content container */
  const desktopTabs = (
    <React.Fragment>
      {props.tabs.map((tab, i) => {
        const clickableElement = (
          <ClickableContainer
            role='tab'
            aria-selected={activeTabIndex === i}
            className={`tab ${activeTabIndex === i ? `active ${props.activeClass}` : ''}`}
            onClick={() => onTabClicked(tab, i)}
            pendo-tag-name={tab.pendoTagName}
            data-qa={tab.dataQA}
          >
            <div className={`tab-text ${tabTextClass(activeTabIndex === i)}`}>{navText(tab)}</div>
            { displayType === NvResponsiveTabsDisplayType.STACKED_NUMBERED && <div className='page-title tab-number' data-qa={tab.dataQA ? `${tab.dataQA}_COUNT` : null}>{tab.count}</div> }
            <div className='bottom-border' />
          </ClickableContainer>
        );
        if (tab.popOver) {
          return (
            <NvPopover
              key={tab.text}
              content={tab.popOver}
              showOnHover
              placement='top'
            >
              {clickableElement}
            </NvPopover>
          );
        }
        return (
          <NvTooltip key={tab.text} text={tab.tooltip} enabled={!!tab.tooltip}>
            {clickableElement}
          </NvTooltip>
        );
      })}
    </React.Fragment>
  );

  /** Show a dropdown menu when in a handheld resolution */
  const MobileDropdown = () => {
    const items: NvDropdownOption[] = props.tabs.map((tab, i) => ({
      type: 'text',
      text: mobileText(tab),
      callback: () => {
        setActiveTabIndex(i);
        tab.onClick();
      },
      pendoTag: tab.pendoTagName,
      dataQa: tab.dataQA,
    }));

    /* Re-uses the styles for the desktop tabs */
    return (
      <NvDropdown
        items={items}
        initialIndex={activeTabIndex}
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        showSelectedIndicator
        customTarget={(customTargetProps) => (
          <div className='mobile-dropdown'>
            <div className='tab active'>
              <div className='mobile-toggle black'>
                <div className={`tab-text text-large-regular ${props.activeClass ?? 'text-warning'} card-title-small`}>
                  {props.tabs[customTargetProps.selectedIndex]?.text}
                  <span className='tab-text-count text-large-regular font-weight-bold ml-2'>{props.tabs[customTargetProps.selectedIndex]?.count}</span>
                </div>
              </div>
              <div className='bottom-border' />
            </div>
            {items.length > 1 && <div className='icon text-xs icon-dropdown-arrow ml-2' />}
          </div>
        )}
      />
    );
  };

  return (
    <div css={styles} className='nv-responsive-tabs-wrapper'>
      <div className='desktop-tabs' role='tablist'>
        {desktopTabs}
      </div>
      {isHandheld() ? <MobileDropdown /> : null}
    </div>
  );
};

NvResponsiveTabs.defaultProps = defaultProps;

export default NvResponsiveTabs;
