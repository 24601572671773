import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getCourseAliases } from 'redux/selectors/course';
import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { baseComponentStyles } from 'lecture_pages/directives/components/base-lecture-component';
import GradientAiIcon from 'lecture_pages/components/left-panel/novo-ai/components/shared/gradient-ai-icon';
import { discussionCommentWidth } from 'lecture_pages/components/discussion/discussion-insights';
import t from 'react-translate';
import { genAIGradientType1, genAIGradientType2, tealBlue } from 'styles/global_defaults/colors';
import NvPopover from './nv-popover';

type CallOutBoxProps = {
  icon: string;
  message: string;
  showAdminOnlyBadge?: boolean;
};

// Taking the lecture component container styles to keep the consistency across the lecture page
const containerStyles = css`
  ${baseComponentStyles};
  display: flex;
  justify-content: flex-end;
`;

const callOutBoxstyles = css`
  display: flex;
  align-items: center;
  gap: ${halfSpacing}px;
  width: ${discussionCommentWidth};
  padding: ${standardSpacing}px;
  border-radius: ${halfSpacing}px;
  background: ${genAIGradientType2}
  .admin-only-badge {
    margin-left: auto;
    color: ${tealBlue};
    background: ${genAIGradientType1}
    padding: 2px ${quarterSpacing}px;
    border-radius: ${quarterSpacing}px;
  }
`;

export const CallOutBox: React.FC<CallOutBoxProps> = ({
  icon,
  message,
  showAdminOnlyBadge,
}) => {
  const aliases = useSelector((state) => getCourseAliases(state));
  return (
    <section css={containerStyles}>
      <div css={callOutBoxstyles}>
        <GradientAiIcon icon={icon} size='small' />
        <span className='text-body bold'>{message}</span>
        {
          showAdminOnlyBadge && (
            <div className='admin-only-badge font-weight-bold'>
              <NvPopover
                placement='top'
                showOnHover
                content={
                  <div>{t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.PLACEHOLDER.ADMIN_ONLY_TOOLTIP(aliases.learnersAliases)}</div>
                }
              >
                <div>{t.LECTURE_PAGES.COMPONENTS.DISCUSSION.INSIGHTS.PLACEHOLDER.ADMIN_ONLY_BADGE()}</div>
              </NvPopover>
            </div>
          )
        }
      </div>
    </section>
  );
};

export default CallOutBox;
