import React from 'react';
import _ from 'underscore';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import t from 'react-translate';
import { RootState } from 'redux/schemas';
import { AngularContext } from 'react-app';
import LoadingRow from 'shared/components/loading-row';
import L1PageHeader from 'shared/components/l1-page-header';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import { PagedDataQueryParams } from 'redux/create-action-creators';
import NewProgramButton from 'athena/components/new-program-button';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getMentoringPrograms } from 'redux/actions/mentoring-programs';
import MentoringProgramRow from 'athena/components/mentoring-program-row';
import { createGridStyles, tripleSpacing } from 'styles/global_defaults/scaffolding';
import ManageProgramAccessModal from 'athena/components/manage-program-access-modal';
import { MentoringProgramNormalized } from 'redux/schemas/models/mentoring-programs';
import NvResponsiveTable, { ContextValue as NvResponsiveTableContextvalue, ColumnSortings } from 'shared/components/nv-responsive-table';

enum Sorting {
  RELEASE_DATE_DESC = 'released_date_desc',
  RELEASE_DATE_ASC = 'released_date_asc',
  CLOSE_DATE_DESC = 'end_date_desc',
  CLOSE_DATE_ASC = 'end_date_asc',
}

const LoadingRowWithoutAvatar = (props: { rowIndex: number }) => (
  <LoadingRow
    hideAvatar
    rowIndex={props.rowIndex}
  />
);

const disableRow = (program: MentoringProgramNormalized) => program.isBeingDeleted;

const createTableColumns = () => [
  {
    name: t.COURSES.DASHBOARD_TABLE.OFFERING_NAME(),
    className: 'name-cell',
    gridWidth: '40%',
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.RELEASE_DATE(),
    className: 'release-date-cell',
    sortable: true,
    gridWidth: '10%',
    headerTooltip: t.COURSES.DASHBOARD_TABLE.RELEASE_DATE_TOOLTIP(),
  },
  {
    name: t.COURSES.DASHBOARD_TABLE.CLOSE_DATE(),
    className: 'close-date-cell',
    sortable: true,
    gridWidth: 'minmax(40px, 50%)',
  },
  {
    name: '',
    className: 'options-cell',
    gridWidth: `${tripleSpacing}px`,
  },
];

const createMobileTableColumns = () => {
  const tableColumns = createTableColumns();
  const columns = [tableColumns[0], tableColumns[tableColumns.length - 1]];

  columns[0].gridWidth = `calc(100% - ${tableColumns[tableColumns.length - 1].gridWidth})`;

  return columns;
};

const initialPagedFetchParams: PagedDataQueryParams = {
  sorting: [Sorting.RELEASE_DATE_DESC],
};

const Dashboard = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const currentInstitution = useSelector(getCurrentInstitution);
  const tableRef = React.useRef<NvResponsiveTableContextvalue>();
  const [managedProgramAccessId, setManagedProgramAccessId] = React.useState(null);
  const [pagedFetchParams, setPagedFetchParams] = React.useState<PagedDataQueryParams>(initialPagedFetchParams);
  const [columnSortings, setColumnSortings] = React.useState<ColumnSortings>({ 1: true }); // Desc sort the Release Date column by default

  const styles = css`
    flex: 1;

    .programs-table {
      flex: 1;
    }
  `;

  const getStoreProgramsData = (state: RootState) => state.models.mentoringPrograms as Record<number, MentoringProgramNormalized>;

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const columns = isHandheld ? createMobileTableColumns() : createTableColumns();

  const onHeaderSortingClicked = (colIndex: number) => {
    // NOTE: Currently this is hardcoded to only support sorting on columns #2 and #3
    if (colIndex !== 1 && colIndex !== 2) {
      return;
    }

    const newSorting = !columnSortings[colIndex];
    let newSortKey: string = null;

    if (colIndex === 1) {
      newSortKey = newSorting ? Sorting.RELEASE_DATE_DESC : Sorting.RELEASE_DATE_ASC;
    } else if (colIndex === 2) {
      newSortKey = newSorting ? Sorting.CLOSE_DATE_DESC : Sorting.CLOSE_DATE_ASC;
    }

    // Don't preserve anything from the previous sorting; we only allow sorting
    // on one column at a time
    setColumnSortings({ [colIndex]: newSorting });
    setPagedFetchParams({
      sorting: [newSortKey],
    });
  };

  const handleProgramCreate = (programId: number) => {
    tableRef.current?.reload();
    setManagedProgramAccessId(programId);
  };

  return (
    <div css={styles} className='d-flex flex-column'>
      <ManageProgramAccessModal
        programId={managedProgramAccessId}
        onClose={() => setManagedProgramAccessId(null)}
      />
      <L1PageHeader
        title={t.MENTORING_PROGRAMS.TITLE()}
        titleLink={{
          title: currentInstitution.name,
          href: $state.href('institution-dashboard'),
        }}
      >
        <div className='d-flex align-items-center h-100'>
          <NewProgramButton onCreate={handleProgramCreate} />
        </div>
      </L1PageHeader>
      <NvResponsiveTable<MentoringProgramNormalized>
        dataKey='id'
        rowProps={{}}
        fetchParams={{}}
        columns={columns}
        cacheDataKey='id'
        backgroundColor='#fff'
        clearSearch={() => {}}
        className='programs-table'
        currentPageNameParam='page'
        checkRowDisabled={disableRow}
        style={createGridStyles(1, 3)}
        noResultsIconSize='ultra-large'
        columnSortings={columnSortings}
        fetchData={getMentoringPrograms}
        cacheLookup={getStoreProgramsData}
        pagedFetchParams={pagedFetchParams}
        onSortClicked={onHeaderSortingClicked}
        loadingComponent={LoadingRowWithoutAvatar}
        forwardRef={(ref) => { tableRef.current = ref; }}
        rowComponent={MentoringProgramRow as React.FunctionComponent}
      />
    </div>
  );
};

export default Dashboard;
