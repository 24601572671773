/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { FieldError } from 'react-hook-form';

import { AngularServicesContext } from 'react-app';
import { gray6 } from 'styles/global_defaults/colors';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { isEmpty } from 'underscore';

export type LectureHeaderInputProps = {
  name?: string,
  value?: string,
  defaultValue?: string,
  editable?: boolean,
  placeholder?: string,
  onChange?: (val: string) => void,
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => any,
  className?: string,
  textAreaClassName?: string,
  textAlign?: string,
  hoverColor?: string,
  height?: number,
  required?: boolean,
  ariaLabel?: string,
  isFromLectureComponent?: boolean,
};

/**
 * Displays some given text and then transforms into an editable textarea when
 * clicked if editable prop is `true`, otherwise it will just be a
 * presentational text used as a lecture component header title
 */
const LectureHeaderInput = (props: LectureHeaderInputProps) => {
  const {
    name,
    value,
    placeholder,
    defaultValue,
    editable = false,
    onBlur = () => {},
    onChange = () => {},
    hoverColor = gray6,
    height = null,
    required = false,
    ariaLabel = null,
    isFromLectureComponent = false,
  } = props;
  const textAreaRef = React.useRef<HTMLTextAreaElement>();
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [error, setError] = React.useState(null);
  const { $scope } = React.useContext(AngularServicesContext);

  const styles = css`
    .bs4-form-control {
      border: none;
      text-align: center;
      box-sizing: border-box;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      margin-top:0px;
      margin-bottom:0;
      ${editable && !isEditing ? (css`
        cursor: pointer;
      `) : ''}
      overflow: hidden;
    }

    ${props.editable && css`
      &:hover {
        background-color: ${hoverColor};
      }
    `}
  `;

  const handleTextAreaBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsEditing(false);
    if (isEmpty(error)) {
      onBlur(event);
    }
  };

  const handleTextAreaKeydown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 13) { // Enter key code
      /**
       * Avoid new lines by pressing enter, we only want text wrapping when it
       * overflows
       */
      event.preventDefault();

      if (textAreaRef.current && isEmpty(error)) {
        textAreaRef.current.blur();
      }
    }
  };

  useEffect(() => {
    const deRegisterStateChangeStart = $scope.StateManager.registerStateChangeStart(
      () => isEditing,
      'shared/templates/modal-navigate-away.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
    );

    return () => {
      deRegisterStateChangeStart();
    };
  }, [$scope.StateManager, isEditing]);

  let content = (
    <h1
      className={`bs4-form-control h-auto ${props.textAreaClassName ? props.textAreaClassName : ''}`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      data-lecturepage-name={value}
    >
      {value ?? defaultValue}
    </h1>
  );

  if (isFromLectureComponent) {
    content = (
      <h2
        className={`bs4-form-control h-auto ${props.textAreaClassName ? props.textAreaClassName : ''}`}
        aria-label={ariaLabel}
      >
        {value ?? defaultValue}
      </h2>
    );
  }

  if (editable) {
    content = (
      <NvTextArea
        name={name}
        value={value}
        defaultValue={defaultValue}
        ref={textAreaRef}
        onChange={(e) => {
          onChange(e.target.value);

          if (required && !e.target.value.trim()) {
            setError({ message: t.VALIDATION.REQUIRED() });
          } else if (e.target.value.length > 255) {
            setError({ message: t.VALIDATION.MAX_LENGTH('255') });
          } else {
            setError(null);
          }
        }}
        disabled={!editable}
        placeholder={placeholder}
        onBlur={handleTextAreaBlur}
        onFocus={() => setIsEditing(true)}
        onKeyDown={handleTextAreaKeydown}
        textareaClassName={props.textAreaClassName}
        style={{ height: props.height }}
        error={error as FieldError}
        popoverPlacement='bottom'
        showErrorIcon={false}
        showErrorWithoutFocus
        data-qa='lecturepage-name-input'
      />
    );
  }

  return (
    <div css={styles} className={props.className}>
      {content}
    </div>
  );
};

export default LectureHeaderInput;
