
import { css } from '@emotion/react';
import { useState, useReducer, useEffect, useContext } from 'react';
import t from 'react-translate';

import { connect, useSelector } from 'react-redux';

// contexts
import { AngularServicesContext } from 'react-app';
import {
  initialState,
  reducer,
  CommunicationDispatch,
  TriggerType,
  CommunicationAction,
} from 'communications/course_communications/contexts/communication-context';

// styles
import { gray6 } from 'styles/global_defaults/colors';
import { createGridStyles } from 'styles/global_defaults/scaffolding';

// Actions
import { resetFilteredCommunications } from 'redux/actions/course-communications';

// components
import { NvTab, NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import { NvResponsiveTabsRow } from 'shared/components/nv-responsive-tabs-row';
import AutomatedCommunications from 'communications/course_communications/components/automated-communications';
import DraftCommunications from 'communications/course_communications/components/draft-communications';
import { CommunicationType, FilterType } from 'redux/schemas/models/course-communication';
import { NvDropdown, NvDropdownButtonStyle, NvDropdownAlign, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import CommunicationModal from './communication-modal/communication-modal';
import ScheduledCommunications from './scheduled-communications';
import FilterResultCommunications from './filtered-communications';
import FilterButton from './filter-button';
import { config } from '../../../../config/pendo.config.json';

enum Tab {
  AUTOMATED = 'automated',
  SCHEDULED = 'scheduled',
  DRAFTS = 'drafts',
  RESULTS = 'results',
}
const mapDispatchToProps = {
  resetFilter: resetFilteredCommunications,
};

const styles = css`
  .tab-search-row {
    border-bottom: 1px solid ${gray6};

    .page-title {
      font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
    }

    .bs4-dropdown .bs4-dropdown-menu .bs4-dropdown-item {
      white-space: nowrap;
    }
  }

  .tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    /* Course header (60px) + Tab header (60px) + Border bottom (1px) */
    height: calc(100vh - 121px);
  }

  .bs4-btn:focus {
    outline: none;
    box-shadow: none;
  }

  .container {
    max-width: 800px;
  }

  .clear-btn {
    cursor: pointer;
  }
`;

const CourseCommunication = ({
  resetFilter,
}: typeof mapDispatchToProps) => {
  const { $location } = useContext(AngularServicesContext);
  const [activeTab, setActiveTab] = useState(Tab.AUTOMATED);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [resultsCount, setResultsCount] = useState(0);
  const [showResultsTab, setShowResultsTab] = useState(false);
  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const [State, dispatch] = useReducer(reducer, initialState);


  const onClick = (type: CommunicationType) => {
    dispatch({
      type: CommunicationAction.SHOW_MODAL,
      triggerType: TriggerType.CREATE,
      communicationType: type,
      activityType: null,
      activityId: null,
      lecturePageId: null,
      communicationId: null,
    });
  };

  const tabs: NvTab[] = [
    {
      text: t.COURSE_COMMUNICATIONS.TABS.AUTOMATED(),
      onClick: () => setActiveTab(Tab.AUTOMATED),
      dataQA: config.pendo.courseCommunication.automatedTab,
    }, {
      text: t.COURSE_COMMUNICATIONS.TABS.SCHEDULED(),
      onClick: () => setActiveTab(Tab.SCHEDULED),
      dataQA: config.pendo.courseCommunication.scheduledTab,
    }, {
      text: t.COURSE_COMMUNICATIONS.TABS.DRAFTS(),
      onClick: () => setActiveTab(Tab.DRAFTS),
      dataQA: config.pendo.courseCommunication.draftsTab,
    },
  ];

  const tabParam = $location?.search()?.tab as Tab;
  useEffect(() => {
    if (Object.values(Tab).includes(tabParam)) {
      setActiveTab(tabParam);
    }
  }, [tabParam]);

  const onFilterChange = (filterType: FilterType) => {
    setSelectedFilter(filterType);
    setShowResultsTab(true);
    setActiveTab(Tab.RESULTS);
  };

  const dropDownItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.NEW_ANNOUNCEMENT(),
      callback: () => onClick(CommunicationType.MANUAL_ANNOUNCEMENT),

    },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.CREATE_DROPDOWN.NEW_EMAIL(),
      callback: () => onClick(CommunicationType.MANUAL_EMAIL),
    },
  ];

  const clearFilterResults = () => {
    setSelectedFilter(null);
    setResultsCount(0);
    setShowResultsTab(false);
    setActiveTab(Tab.AUTOMATED);
    resetFilter();
  };

  const resultsTab: NvTab = {
    text: t.COURSES.TABS.TAB_RESULTS(),
    count: resultsCount,
    onClick: () => { },
  };

  const clearBtn = <div className='clear-btn page-title-xxs text-primary' onClick={clearFilterResults}>{t.SEARCH.CLEAR_FILTER()}</div>;

  const filterBtn = <FilterButton key='filter-btn' value={selectedFilter} onChange={onFilterChange} />;

  const manualBtn = (activeTab === Tab.SCHEDULED && (
    <NvDropdown
      key='manual-btn'
      title={t.COURSE_COMMUNICATIONS.SCHEDULED.CREATE_NEW()}
      buttonStyle={NvDropdownButtonStyle.BUTTON}
      items={dropDownItems}
      align={NvDropdownAlign.RIGHT}
      iconClass='icon-create-new-post'
      buttonClass='text-medium light py-2 mt-2'
    />
  ));

  return (
    <CommunicationDispatch.Provider value={{ dispatch, State }}>
      <div css={styles}>
        <CommunicationModal catalogId={catalogId} />
        <div className='tab-search-row'>
          <NvResponsiveTabsRow
            style={createGridStyles(1, 2)}
            defaultTabs={tabs}
            filteredTabs={[resultsTab]}
            isFiltered={showResultsTab}
            clearBtn={clearBtn}
            showClearBtn={showResultsTab}
            tabType={showResultsTab ? NvResponsiveTabsDisplayType.STACKED_NUMBERED : NvResponsiveTabsDisplayType.TEXT_ONLY}
            iconBtns={[filterBtn, manualBtn]}
            revertActiveTab={tabParam && Object.values(Tab).indexOf(tabParam)}
          />
        </div>
        <div className='tab-content pb-5' id='communications-tab-content'>
          <div className='container'>
            {activeTab === Tab.AUTOMATED && <AutomatedCommunications />}
            {activeTab === Tab.SCHEDULED && <ScheduledCommunications />}
            {activeTab === Tab.DRAFTS && <DraftCommunications />}
            {activeTab === Tab.RESULTS && selectedFilter && <FilterResultCommunications setResultsCount={setResultsCount} filterBy={selectedFilter} />}
          </div>
        </div>
      </div>
    </CommunicationDispatch.Provider>
  );
};

const ConnectedCourseCommunication = connect<{}, typeof mapDispatchToProps>(
  () => ({}),
  mapDispatchToProps as any,
)(CourseCommunication);

export default ConnectedCourseCommunication;
