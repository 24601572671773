import { ResetSubmissionType } from 'redux/schemas/models/activity';
import getTicksArray from '../../shared/services/ticks-for-sliders';

/* @ngInject */
export default function NvQuestionTemplate(
  validationConstants,
  _,
) {
  return {
    restrict: 'A',
    scope: {
      templateUrl: '=',
      question: '=',
      form: '=?',
      response: '=?',
      showResponse: '=?',
      ngDisabled: '=?',
      quizSubmissionType: '=?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      vm.validationConstants = validationConstants;

      switch (vm.quizSubmissionType) {
        case ResetSubmissionType.VIDEO_QUIZ:
          vm.ariaLiveForFeedback = 'polite';
          break;
        default:
          vm.ariaLiveForFeedback = 'off';
          break;
      }

      if (this.form) {
        vm.formName = this.form.$name;
      }
      vm.getSliderOptions = () => {
        if (!vm.ticksArray) {
          const { floor, ceil } = vm.question.sliderOptions;
          vm.ticksArray = getTicksArray(floor, ceil);
        }
        return _.extend({
          disabled: vm.ngDisabled,
          ariaLabelledBy: `question_label_${vm.question.id}`,
          showTicksValues: true,
          ticksArray: vm.ticksArray,
        }, vm.question.sliderOptions);
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    template: '<div ng-include="vm.templateUrl"></div>',
  };
}
