/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { ClassNames, css } from '@emotion/react';
import { arSA, de, enUS, es, fr, frCA, he, it, ja, ko, pl, pt, ptBR, ru, zhCN, zhTW, th, nl, sv, ro, id, tr } from 'date-fns/locale';
import {
  DEFAULT_LANGUAGE,
  LANGUAGE_MAPPING,
} from 'froala/helpers/nv-froala-constants';
import moment from 'moment';
import React, {
  InputHTMLAttributes, useEffect, useMemo, useRef, useState,
} from 'react';
import { Button, Form } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import DatePicker, { ReactDatePickerProps, registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { MyAccount } from 'redux/schemas/models/my-account';
import { quarterSpacing, halfSpacing, datePickerZIndex } from 'styles/global_defaults/scaffolding';
import {
  gray1, gray3, gray4, gray5, gray6, gray7, info, primary, warning, white,
} from 'styles/global_defaults/colors';
import { handheld } from 'styles/global_defaults/media-queries';

import _ from 'underscore';
import { getCurrentUser } from 'redux/selectors/users';

import t from 'react-translate';
import { BaseProps, FormProps, NonFormProps } from 'shared/components/inputs/form-props';
import NvTextInput, { Props as NvTextInputProps } from 'shared/components/inputs/nv-text-input';
import useIsAthenaApp from 'athena/hooks/use-is-athena-app';
import { getActualPlacement } from '../nv-tooltip';

const CELLS_PER_GRID = 12;

export enum DatePickerType {
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  TIME = 'TIME',
}

const updateLocale = (language: string) => {
  let localeObject;
  switch (language) {
    case LANGUAGE_MAPPING.es_MX:
      localeObject = es;
      break;
    case LANGUAGE_MAPPING.es_ES:
      localeObject = es;
      break;
    case LANGUAGE_MAPPING.fr_FR:
      localeObject = fr;
      break;
    case LANGUAGE_MAPPING.pt_PT:
      localeObject = pt;
      break;
    case LANGUAGE_MAPPING.pt_BR:
      localeObject = ptBR;
      break;
    case LANGUAGE_MAPPING.zh_CN:
      localeObject = zhCN;
      break;
    case LANGUAGE_MAPPING.ja_JP:
      localeObject = ja;
      break;
    case LANGUAGE_MAPPING.ko_KP:
      localeObject = ko;
      break;
    case LANGUAGE_MAPPING.ru_RU:
      localeObject = ru;
      break;
    case LANGUAGE_MAPPING.de_DE:
      localeObject = de;
      break;
    case LANGUAGE_MAPPING.ar_SA:
      localeObject = arSA;
      break;
    case LANGUAGE_MAPPING.he_IL:
      localeObject = he;
      break;
    case LANGUAGE_MAPPING.pl_PL:
      localeObject = pl;
      break;
    case LANGUAGE_MAPPING.it_IT:
      localeObject = it;
      break;
    case LANGUAGE_MAPPING.fr_CA:
      localeObject = frCA;
      break;
    case LANGUAGE_MAPPING.nl_NL:
      localeObject = nl;
      break;
    case LANGUAGE_MAPPING.ro_RO:
      localeObject = ro;
      break;
    case LANGUAGE_MAPPING.sv_SE:
      localeObject = sv;
      break;
    case LANGUAGE_MAPPING.tr_TR:
      localeObject = tr;
      break;
    case LANGUAGE_MAPPING.id_ID:
      localeObject = id;
      break;
    case LANGUAGE_MAPPING.zh_TW:
      localeObject = zhTW;
      break;
    case LANGUAGE_MAPPING.th_TH:
      localeObject = th;
      break;
    case LANGUAGE_MAPPING.en_US:
    default:
      localeObject = enUS;
      break;
  }
  registerLocale(language, localeObject);
  setDefaultLocale(language);
};

interface InputProps extends BaseProps {
  value?: moment.Moment;
  type: DatePickerType;
  min?: moment.Moment;
  max?: moment.Moment;
  /** A Popper.js placement string. Defaults to 'auto' https://popper.js.org/docs/v1/#Popper.placements */
  placement?: Placement;
  onCalendarOpen?(): void;
  onCalendarClose?(): void;
  onFocus?(): void;
  onBlur?(): void;
  fixed?: boolean;
  /** Makes the datepicker default to open */
  autoFocus?: boolean;
  /**
   * There's a known issue when the using portals when the DatePicker is rendered inside a modal
   * (see: https://novoed.atlassian.net/browse/NOV-67692) In case that you need portals in a
   * modal, you'll need to take a look at this line that is causing this bug:
   * https://github.com/react-bootstrap/react-overlays/blob/v4.1.0/src/Modal.tsx#L209
   */
  usePortal?: boolean;
  // withLabel prop value of NvTextInput
  withLabel?: boolean;
  highlightDates?: Array<moment.Moment>;
  dataQa?: string;
  dataQaPendo?: string;
}

type NvDatePickerBaseProps = InputProps & NonFormProps;

export const getMomentDate = (value: string | moment.Moment, dateFormat: string, timeZone: string) => {
  if (!value) {
    return null;
  }
  // Converting to the correct date depending on the timezone when loading its value.
  const date = typeof value === 'string'
    ? moment(machineDateAndTimezoneDateConversion(new Date(value), timeZone, true))
    : value;
  if (!date.isValid()) {
    return null;
  }
  date.toString = () => date.format(dateFormat);
  return date as moment.Moment;
};

/**
 * react-datepicker component works with Date objects which are always
 * represented based on machine's timezone.
 * This function takes care of the necessary conversion so that our date picker
 * component works for different timezones.
 * By default it converts a normal Date to current user timezone date, if
 * `reversed` is true, it converts it the opposite way.
 */
export const machineDateAndTimezoneDateConversion = (
  date: Date,
  timeZone: string,
  reversed: boolean = false,
) => {
  let minutesOffset = 0;

  if (timeZone) {
    minutesOffset = date.getTimezoneOffset() - (moment as any).tz.zone(timeZone).utcOffset(date);
  }

  const milliseconds = minutesOffset * 60 * 1000;

  return new Date(date.getTime() + (reversed ? -milliseconds : milliseconds));
};

const getSelectedDate = (value, dateFormat, timeZone) => {
  let date;

  if (value) {
    date = machineDateAndTimezoneDateConversion(value?.toDate(), timeZone);
    date.toString = () => value.format(dateFormat);
  }

  return date;
};
const getFormat = (type, withForm) => {
  // To avoid problems with format on saving date, and the way date-fns works
  // in the form we use other date standart format to have the correct value
  // Ticket NOV-81544.
  switch (type) {
    case DatePickerType.DATE:
      return withForm ? 'L' : 'MM/dd/yyyy';
    default:
      return withForm ? 'L LT' : 'MM/dd/yyyy h:mm aa';
  }
};
const NvDatePickerBase = React.forwardRef((props: NvDatePickerBaseProps, ref: any) => {
  const currentUser = useSelector<RootState, MyAccount>(getCurrentUser);
  const currentLang = LANGUAGE_MAPPING[currentUser?.platformLanguage] || DEFAULT_LANGUAGE;
  updateLocale(currentLang);
  const dateFormat = getFormat(props.type, props.withForm);
  // Defining a default ref to hide the calendar on blur
  ref = ref ?? useRef(null);

  const dateValue = getMomentDate(props.value, dateFormat, currentUser.timeZone);
  // Defining state at the top since headerMode is used in our styles
  const [draftDate, setDraftDate] = useState(dateValue);

  // TODO: Refactor 'header mode' into a generic calendar mode
  // Actually we might just use DatePickerType instead
  const [headerMode, setHeaderMode] = useState(DatePickerHeaderMode.DAY);

  const { setValue, getValues } = useFormContext() || {};

  const actualPlacement = useMemo(() => getActualPlacement(props.placement), [props.placement]);

  const onMonthYearButtonClicked = () => {
    switch (headerMode) {
      case DatePickerHeaderMode.DAY:
        setHeaderMode(DatePickerHeaderMode.MONTH);
        break;
      case DatePickerHeaderMode.MONTH:
        // We've disabled the year selector and now make this just revert to day selection mode
        setHeaderMode(DatePickerHeaderMode.DAY);
        // setHeaderMode(DatePickerHeaderMode.YEAR);
        break;
      case DatePickerHeaderMode.YEAR:
        // Do nothing, shouldn't be clickable in this mode
        break;
      default:
        break;
    }
  };

  const formValue = getValues?.(props.name);

  if (formValue?.toString() !== dateValue?.toString()) {
    setValue?.(props.name, dateValue);
  }

  let datePickerProps: ReactDatePickerProps & { portalId: string } = {
    selected: getSelectedDate(dateValue, dateFormat, currentUser.timeZone),
    name: props.name,
    required: props.required,
    autoFocus: props.autoFocus,
    onFocus: props.onFocus,
    placeholderText: props.placeholder,
    onBlur: props.onBlur,
    portalId: props.usePortal ? 'nv-date-picker' : undefined,
    onChangeRaw: (e) => {
      const date = moment(e.target.value as unknown as Date);
      if (e?.type === 'change'
        && e.target.value
        && (!date.isValid()
          || (props.min && date?.isBefore(props.min)))) {
        if (props.withForm) {
          /**
           * If we tried to input a value through keyboard that is not valid or the
           * min prop is enabled and the value entered is less than minimum value,
           * then the value will set into the last valid value selected for that.
           */
          props.onChange?.(draftDate);
        }
      }
    },
    onChange: (val, e) => {
      const convertedDate = machineDateAndTimezoneDateConversion(val as Date, currentUser.timeZone, true);
      const m: any = convertedDate ? moment(convertedDate) : convertedDate;

      props.onChange?.(m);

      setDraftDate(m);

      if (props.withForm) {
        const momentObj = _.clone(m);
        if (momentObj) {
          momentObj.toString = () => m.format(dateFormat);
          setValue(props.name, momentObj);
        }
      }
    },
    customInput: <CustomInput withLabel={props.withLabel} data-qa={props.dataQa} data-qa-pendo={props.dataQaPendo} withForm={props.withForm} />,
    useWeekdaysShort: true,
    popperProps: {
      placement: actualPlacement || 'auto',
      strategy: 'fixed',
    },
    renderCustomHeader: createDatePickerHeader(headerMode, onMonthYearButtonClicked, draftDate, setDraftDate, currentUser.timeZone),
    onCalendarOpen: props.onCalendarOpen,
    onCalendarClose: props.onCalendarClose,
    disabled: props.disabled,
  };

  if (props.fixed) {
    // https://github.com/Hacker0x01/react-datepicker/issues/1246
    datePickerProps.popperModifiers = {
      flip: {
        behavior: ['bottom'], // don't allow it to flip to be above
      },
      preventOverflow: {
        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
      },
      hide: {
        enabled: false, // turn off since needs preventOverflow to be enabled
      },
    };
  }

  if (props.min) {
    datePickerProps.minDate = props.min.toDate();
  }

  if (props.max) {
    datePickerProps.maxDate = props.max.toDate();
  }

  if (!_.isEmpty(props.highlightDates)) {
    datePickerProps.highlightDates = props.highlightDates.map((date) => date.toDate());
  }

  const dateIsValid = (testDate: moment.Moment) => {
    let isValid = testDate.isValid();

    if (!isValid) {
      return false;
    }

    if (props.min) {
      isValid = isValid && testDate > props.min;
    }

    if (props.max) {
      isValid = isValid && testDate < props.max;
    }

    return isValid;
  };

  const onDayContentsDateNavigation = () => { };

  const getRenderDayContents = () => {
    const months = moment.monthsShort();
    const monthYearPickerProps = {
      date: draftDate || moment(),
      draftDate: draftDate || moment(),
      setDraftDate,
      setMode: setHeaderMode,
      isValidDate: dateIsValid,
      onDateNavigation: onDayContentsDateNavigation,
      months,
    };

    switch (headerMode) {
      case DatePickerHeaderMode.DAY:
        return <span />;
      case DatePickerHeaderMode.MONTH:
        return <MonthYearPicker {...monthYearPickerProps} mode={DatePickerHeaderMode.MONTH} />;
      case DatePickerHeaderMode.YEAR:
        return <MonthYearPicker {...monthYearPickerProps} mode={DatePickerHeaderMode.YEAR} />;
      default:
        return null;
    }
  };

  switch (props.type) {
    case DatePickerType.DATE: {
      datePickerProps = {
        dateFormat,
        ...datePickerProps,
      };
      break;
    }
    case DatePickerType.TIME: {
      datePickerProps = {
        dateFormat,
        showTimeInput: true,
        timeCaption: 'asdf',
        shouldCloseOnSelect: false,
        ...datePickerProps,
      };
      break;
    }
    case DatePickerType.DATETIME: {
      datePickerProps = {
        dateFormat,
        showTimeInput: true,
        timeCaption: 'zxcvzcv',
        customTimeInput: (
          <TimeInput
            value={dateValue?.format('hh:mm')}
            onChange={props.onChange}
            headerMode={headerMode}
            validPeriodTime={props.validPeriodTime}
          />
        ),
        shouldCloseOnSelect: false,
        ...datePickerProps,
      };
      break;
    }
    default:
      break;
  }

  useEffect(() => {
    const diff = moment(dateValue).diff(draftDate, 'days');
    if (diff !== 0) setDraftDate(dateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const handleBlur = (event) => {
    // Closing the datepicker only when the clicked or focused element
    // is NOT a child of the datepicker
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (props.usePortal) {
        // This is added because when the datepicker has the portal configuration
        // The datepicker is added as a body child. In case another calendar is present,
        // it will remove the current one and replace it, so there are no problems
        // having 2 datepickers with the same ID. Ticket NOV-89113.
        const element = document.getElementById('nv-date-picker');
        if (!element.contains(event.relatedTarget)) {
          ref.current?.setOpen(false);
        }
      }
    }
  };
  // This is to update the preselected day after selecting
  // a month in the custom months selector.
  useEffect(() => {
    ref.current.setPreSelection(draftDate?.toDate());
  }, [draftDate]);

  return (
    <div className={props.className} onBlur={handleBlur}>
      <ClassNames>
        {({ css: innerCss, cx }) => (
          // ClassNames is used here because here we want to use the
          // `popperClassName` prop instead of the normal `className` prop that
          // emotion uses by default. We need to use PopperClassName so that
          // when portals is used, the styles are style applied to the element
          // that was ported
          <DatePicker
            ref={ref}
            popperClassName={`react-app ${innerCss`
              ${staticNvDatePickerStyle};
              ${getDynamicDatePickerStyle({ placement: actualPlacement, headerMode })};
            `}`}
            // Setting this to the draftDate is what causes DatePicker's internal date draft to be updated
            // whenever we select months and years in our custom header picker. Removing this will make the
            // date/year only be updated when you use the back/next buttons in the header not when picking from
            // the grid
            openToDate={draftDate?.toDate()}
            // This avoids the selection loop inside the calendar/popper
            enableTabLoop={false}
            {...datePickerProps}
          >
            {getRenderDayContents()}
          </DatePicker>
        )}
      </ClassNames>
    </div>
  );
});

type Props = InputProps & (FormProps | NonFormProps);

const NvDatePicker = React.forwardRef<any, Props>((props, ref) => {
  const { name, withForm, onChange, onBlur, ...restProps } = props;

  return withForm ? (
    <Controller
      name={name}
      render={(data) => {
        const handleDatePickerBlur = () => {
          onBlur?.();
          data.field.onBlur();
        };

        const handleDatePickerChange = (val) => {
          if (val) {
            onChange?.(val);
            data.field.onChange(val);
          }
        };

        return (
          <NvDatePickerBase
            name={name}
            value={data.field.value}
            onBlur={handleDatePickerBlur}
            onChange={handleDatePickerChange}
            ref={ref}
            withForm={withForm}
            {...restProps}
          />
        );
      }}
    />
  ) : (
    <NvDatePickerBase
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      {...restProps}
    />
  );
});

const staticNvDatePickerStyle = css`
  /* Overriding react-datepicker default styles. These need replaced with Lei's design once it's available */
  z-index: ${datePickerZIndex};
  font-family: 'Open Sans';

  .react-datepicker {
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 5px 7px rgba(29, 33, 38, 0.1);
    & > *:last-of-type {
      /** IE specific styles fix to prevent expanding the container to the right */
      @media screen and (min-width: 0\0) {
        max-width: 300px;
      }
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  /* Should match .react-datepicker__triangle's border-color above */
  .react-datepicker__triangle::before {
    border-bottom-color: aeaeae !important;
  }
  .react-datepicker__navigation {
    top: 14px;
    height: 5px;
    transform: scale(2, 1.3);
  }
  .react-datepicker__navigation--previous {
    border-right-color: black;
  }
  .react-datepicker__navigation--next {
    border-left-color: black;
  }
  .react-datepicker__day-names {
    color: ${gray1};
    background: white;
    padding-top: ${halfSpacing}px;
  }

  .react-datepicker__current-month {
    font-size: 14px;
  }
  .react-datepicker__header {
    background-color: ${info};
    border: 0;
    padding-top: 0;
  }
  /* Should match .react-datepicker__header's background-color */
  .react-datepicker-popper[data-placement^='bottom'],
  .react-datepicker__triangle {
    border-bottom-color: ${info} !important;
  }
  .react-datepicker-popper[data-placement^='top'] {
    border-top-color: ${info} !important;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker-time__caption {
    display: none;
  }
  .react-datepicker-time__input {
    margin: 0 !important;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
  }
  .react-datepicker__day {
    border-radius: 50%;
    overflow: hidden;
  }
  .react-datepicker__day--selected {
    background-color: ${warning};
  }
  .react-datepicker__day--highlighted {
    background-color: ${white};
    color: ${warning};
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: ${quarterSpacing}px;
    padding: ${quarterSpacing}px;
    width: 30px;
    height: 30px;
  }
  .react-datepicker__day {
    padding-top: 6px;
  }
  .react-datepicker__day--outside-month {
    color: ${gray4};
  }
  .react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    background-color: ${gray6};
  }
  /* Spot fixes for IE */
  .bs4-input-group .bs4-form-control {
    line-height: 14px;
    min-width: 100px !important;
  }

  react-datepicker__input-time-container {
    margin: ${quarterSpacing}px;
    margin-top: -${halfSpacing}px;
  }
`;

interface GetDynamicDatePickerStyleParams {
  headerMode: DatePickerHeaderMode;
  placement?: string;
}

const getDynamicDatePickerStyle = (params: GetDynamicDatePickerStyleParams) => {
  const verticalPlacementStyle = handheld(css`
    .react-datepicker__triangle {
      left: 50%;
    }
  `);

  const popperStyle = css`
    .react-datepicker__day-names,
    .react-datepicker__month {
      display: ${params.headerMode !== DatePickerHeaderMode.DAY ? 'none' : 'block'};
    }
    .react-datepicker__input-time-container {
      display: ${params.headerMode === DatePickerHeaderMode.DAY ? 'block' : 'none'};
    }

    /* The datepicker switches to 'top' (centered) alignment in the mobile view and we have to manually fix the traingle positioning to be centered */
    ${(params.placement === 'top' || params.placement === 'bottom') && verticalPlacementStyle};
  `;

  return popperStyle;
};

const customInputStyles = css`
  &.athena-date-picker-container-styles {
    .bs4-input-group-text {
      background-color: ${white};
      border-top-right-radius: ${quarterSpacing}px;
      border-bottom-right-radius: ${quarterSpacing}px;
    }
  }
  .athena-date-picker-input-styles {
    background-color: ${white};
    border-color: ${gray5};
    border-radius: ${quarterSpacing}px;
  }
`;

const CustomInput = React.forwardRef<HTMLInputElement, NvTextInputProps>((props, ref) => {
  const isAthenaApp = useIsAthenaApp();

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ..._.omit(props, ['className', 'name', 'withForm']),
    autoComplete: 'off',
  };

  const { register, unregister } = useFormContext() || {};

  useEffect(() => {
    register?.(props.name);

    return () => {
      unregister?.(props.name);
    };
  }, [props.name, register, unregister]);

  return (
    <NvTextInput
      ref={ref}
      withLabel={props.withLabel}
      withForm={props.withForm}
      autoComplete='off'
      ariaLabel='Date Picker'
      value={props.value}
      name={props.name}
      required={props.required}
      forceShowErrorState={props.withForm}
      placeholder={props.placeholder || t.FORM.SELECT_DATE()}
      errorOnTouching={false}
      css={customInputStyles}
      className={isAthenaApp ? 'athena-date-picker-container-styles' : ''}
      inputClassName={isAthenaApp ? 'athena-date-picker-input-styles' : ''}
      {...inputProps}
    />
  );
});

enum DatePickerHeaderMode {
  DAY,
  MONTH,
  YEAR,
}

const createDatePickerHeader = (
  mode: DatePickerHeaderMode,
  onMonthYearSelectClicked: (event: React.MouseEvent) => void,
  draftDate: moment.Moment,
  setDraftDate: (date: moment.Moment) => void,
  timeZone: string,
) => ({ date, changeYear, changeMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
  // Just fire this whenever this header is rendered; I haven't found a better way to
  // pass the react-datepicker draft date value into the body
  // onDateNavigation(date);
  date = machineDateAndTimezoneDateConversion(date, timeZone, true);
  const styles = css`
    display: flex;
    justify-content: space-between;
    *:first-of-type, *:last-of-type {
      width: 30px;
      height: 30px;
    }
    .date-btn {
      /* TODO: Pull into a class use */
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      flex-grow: 1;
      height: 30px;
      padding: 0;
      padding-left: 5px;
      padding-right: 5px;
      max-width: 127px;
      width: 127px;
      border: 0;
      box-shadow: none;
    }
    .bs4-btn:hover:not([disabled]) {
      color: ${primary};
      background: ${gray7};
    }
    .bs4-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 0;
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      margin: ${quarterSpacing}px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  const updateYear = (newDate: moment.Moment) => {
    changeYear(newDate.year());
    setDraftDate(newDate);
  };

  const updateMonth = (newDate: moment.Moment) => {
    changeMonth(newDate.month());
    changeYear(newDate.year());

    setDraftDate(newDate);
  };

  const decreaseBtnClicked = () => {
    switch (mode) {
      case DatePickerHeaderMode.DAY:
        updateMonth(moment(date).subtract(1, 'month'));
        return;
      case DatePickerHeaderMode.MONTH:
        updateYear(moment(date).subtract(1, 'year'));
        return;
      case DatePickerHeaderMode.YEAR:
        updateYear(moment(date).subtract(CELLS_PER_GRID, 'year'));
        break;
      default:
        break;
    }
  };

  const increaseBtnClicked = () => {
    switch (mode) {
      case DatePickerHeaderMode.DAY:
        updateMonth(moment(date).add(1, 'month'));
        return;
      case DatePickerHeaderMode.MONTH:
        updateYear(moment(date).add(1, 'year'));
        return;
      case DatePickerHeaderMode.YEAR:
        updateYear(moment(date).add(CELLS_PER_GRID, 'year'));
        break;
      default:
        break;
    }
  };

  const headerTitle = () => {
    switch (mode) {
      case DatePickerHeaderMode.DAY:
        return moment(date).format('MMMM YYYY');
      case DatePickerHeaderMode.MONTH:
        return moment(date).format('YYYY');
      case DatePickerHeaderMode.YEAR:
        return 'Select a Year';
      default:
        return '';
    }
  };

  const onItemClicked = (event: React.MouseEvent) => {
    onMonthYearSelectClicked(event);
  };

  return (
    <div css={styles}>
      <Button onClick={decreaseBtnClicked} variant='light' disabled={prevMonthButtonDisabled}>
        <div className='icon icon-small icon-arrow-left' />
      </Button>

      <Button
        className='date-btn'
        disabled={mode === DatePickerHeaderMode.YEAR}
        onClick={onItemClicked}
        variant='light'
      >
        {headerTitle()}
      </Button>

      <Button onClick={increaseBtnClicked} variant='light' disabled={nextMonthButtonDisabled}>
        <div className='icon icon-small icon-arrow-right' />
      </Button>
    </div>
  );
};

const MonthYearPicker = (props: {
  date: moment.Moment;
  draftDate: moment.Moment; // react-datepicker's tracked date that updates as you click through the UI before selecting one
  setDraftDate: (date: moment.Moment) => void;
  mode: DatePickerHeaderMode;
  setMode: (mode: DatePickerHeaderMode) => void;
  isValidDate: (date: moment.Moment) => boolean;
  // Set the current nav date so we can update the header.
  // TODO: rename
  onDateNavigation: (date: Date) => void;
  months: string[];
}) => {
  const columnGap = 20;
  const rowGap = 20;

  const styles = css`
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(3, auto);
    -ms-grid-template-columns: ${'(auto)[3]'};
    grid-template-rows: repeat(3, 60px);
    -ms-grid-template-rows: ${'(60px)[3]'};
    padding: 25px;
    padding-top: 40px;
    width: 100%;
    .cell {
      width: 70px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${gray6};
      /* Avoid applying right margin to right most column */
      &:not(:nth-of-type(3n)) {
        margin-right: ${columnGap}px;
      }
      /* Avoid applying bottom margin to the last row */
      &:not(:nth-last-of-type(-n+3)) {
        margin-bottom: ${rowGap}px;
      }
      &.disabled {
        color: ${gray4};
      }
    }
    .cell:not(.disabled) {
      &.active, &:hover {
        color: white;
        cursor: pointer;
      }
      &.active {
        background: ${warning};
      }
      &:hover {
        background: ${gray4};
      }
    }
  `;

  const getCellDate = (i: number): moment.Moment => {
    switch (props.mode) {
      case DatePickerHeaderMode.MONTH: {
        return moment(props.draftDate).month(i);
      }
      case DatePickerHeaderMode.YEAR: {
        return moment(props.draftDate).year(getYearForIndex(props.draftDate.year(), i));
      }
      default:
        return null;
    }
  };

  const getActiveClass = (newDate: moment.Moment): string => {
    switch (props.mode) {
      case DatePickerHeaderMode.MONTH: {
        return moment(props.draftDate).year() === props.date.year() && props.date.month() === newDate.month()
          ? 'active'
          : '';
      }
      case DatePickerHeaderMode.YEAR: {
        return props.date.year() === newDate.year() ? 'active' : '';
      }
      default:
        return '';
    }
  };

  const getDisabledClass = (newDate: moment.Moment): string => {
    let isValid = false;

    switch (props.mode) {
      case DatePickerHeaderMode.MONTH: {
        isValid = props.isValidDate(moment(newDate).startOf('month')) || props.isValidDate(moment(newDate).endOf('month'));
        break;
      }
      case DatePickerHeaderMode.YEAR: {
        isValid = props.isValidDate(moment(newDate).startOf('year')) || props.isValidDate(moment(newDate).endOf('year'));
        break;
      }
      default:
        break;
    }

    return !isValid ? 'disabled' : '';
  };

  const onCellClicked = (newDate: moment.Moment) => {
    switch (props.mode) {
      case DatePickerHeaderMode.MONTH: {
        if (!props.isValidDate(moment(newDate).startOf('month')) && !props.isValidDate(moment(newDate).endOf('month'))) {
          return;
        }

        props.setMode(DatePickerHeaderMode.DAY);
        break;
      }
      case DatePickerHeaderMode.YEAR: {
        if (!props.isValidDate(moment(newDate).startOf('year')) && !props.isValidDate(moment(newDate).endOf('year'))) {
          return;
        }

        props.setMode(DatePickerHeaderMode.MONTH);
        break;
      }
      default:
        break;
    }

    props.setDraftDate(newDate);
  };

  const getCellZeroYear = (year: number) => year - (year % 12);
  const getYearForIndex = (year: number, i: number) => getCellZeroYear(year) + i;

  const cellText = (i: number): string => {
    switch (props.mode) {
      case DatePickerHeaderMode.MONTH: {
        return props.months[i];
      }
      case DatePickerHeaderMode.YEAR: {
        return getYearForIndex(props.draftDate.year(), i).toString();
      }
      default:
        return '';
    }
  };

  const makeIE11UnitDataStyles = (index: number) => ({
    // Grid column indices are 1 based. We also offset by another '1' to account for the auto filling first column that was added to
    // correct the chart not auto centering
    msGridColumn: `${(index % 3) + 1}`,
    msGridRow: `${Math.floor(index / 3) + 1}`,
  });

  return (
    <div>
      {' '}
      {/* Extra div to make divs sit below the header */}
      <div css={styles}>
        {_.range(CELLS_PER_GRID).map(i => {
          const cellDate = getCellDate(i);
          return (
            <div
              key={i}
              className={`cell ${getActiveClass(cellDate)} ${getDisabledClass(cellDate)}`}
              onClick={() => onCellClicked(getCellDate(i))}
              onKeyPress={() => onCellClicked(getCellDate(i))}
              css={makeIE11UnitDataStyles(i)}
              tabIndex={0}
            >
              {cellText(i)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

enum TimePeriod {
  AM = 'AM',
  PM = 'PM',
}

export const TimeInput = (props: {
  value?: string;
  onChange: Function;
  headerMode: DatePickerHeaderMode,
  validPeriodTime?: boolean;
}) => {
  const styles = css`
    display: flex;
    flex-direction: column;
    .divider {
      width: 260px;
      height: 1px;
      background-color: ${gray4};
      margin-bottom: 20px;
    }
    .time-input-panel {
      display: flex;
      justify-items: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .bs4-form-control {
      width: 60px !important;
      min-width: unset !important;
      height: 40px;
    }
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: inner-spin-button !important;
      margin: initial;
    }
    /* Hide arrows from number input - Firefox */
    input[type=number] {
      -moz-appearance: initial;
    }
    .bs4-form-check {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      font-size: 12px;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      overflow: hidden;
      margin-left: 10px;
      cursor: pointer;
      color: ${gray3};
      background-color: ${gray6};
    }
    input[type=radio] {
      opacity: 0;
      position: fixed;
      width: 0 !important;
    }
    input[type="radio"] + label::before {
      content: none;
    }
    .bs4-form-check input[type=radio] + label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }
    .bs4-form-check input[type=radio]:checked + label {
      color: white;
      background-color: ${warning};
    }
    .bs4-form-check input[type="radio"]:focus + label {
      // outline: rgb(30, 159, 214) solid 2px;
      outline: ${primary} solid 2px;
      outline-offset: -2px;
    }
  `;

  const [hourVal = 0, minuteVal = 0] = props.value?.split(':').filter(Boolean).map((numberStr) => parseInt(numberStr, 10));
  /**
   * Changing the locale in the moment value to avoid problems with AM and PM grammar
   * in other languages such as Arabic. Ticket NOV-89239.
   */
  const momentTime = moment(hourVal, ['HH']).locale('en');
  const [selectedPeriod, setSelectedPeriod] = useState(
    momentTime.format('A') === TimePeriod.AM ? TimePeriod.AM : TimePeriod.PM,
  );
  // This state is to handle if user hits backspace and clear hours field.
  // This field inputs the value and displays the value too. So if this special
  // case appears it won't updates the field and also display it as 0 value
  const [specialHourCase, setSpecialHourCase] = useState(false);

  const meridiemTime = useMemo(() => {
    let amHour = hourVal;
    if (hourVal >= 12) {
      amHour = hourVal - 12;
    }
    amHour = amHour === 0 ? 1 : amHour;
    const meridiem = {
      am: amHour,
      pm: amHour + 12,
    };
    return meridiem;
  }, [hourVal]);

  const onHourChanged = (e: any) => {
    const hour = parseInt(e.target.value, 10) || 0;
    if (hour === 0) {
      setSpecialHourCase(true);
    } else {
      setSpecialHourCase(false);
    }
    if (hour < 1 || hour > 12) {
      return;
    }
    /**
     * Use moment to parse an AM/PM hour string into the correct 24-hours hour value
     * and using the locale in english to avoid errors with other languages such as Arabic
     * Ticket NOV-89239.
     */
    const momentVal = moment(`${hour} ${selectedPeriod}`, 'hh A').locale('en');
    props.onChange(`${momentVal.format('HH')}:${minuteVal}`);
  };

  const onMinuteChanged = (e: any) => {
    const minute = parseInt(e.target.value, 10) || 0;
    if (minute < 0 || minute > 59) {
      return;
    }
    props.onChange(`${hourVal}:${minute}`);
  };

  /** Convert a 24-hours hour value into a 12 hour format */
  const hoursInAMPM = (hours: number, period: TimePeriod) => {
    if (specialHourCase) {
      return 0;
    }

    if (period === TimePeriod.PM) {
      if (hours > 12) {
        // special case 12:00 PM
        return hours - 12;
      }
    }

    if (hours === 0) {
      // special case 12:00 AM
      return 12;
    }

    return hours;
  };

  const minutesToString = (minutes) => {
    if (minutes < 10) {
      return `0${minutes}`;
    }

    return minutes.toString();
  };

  /** Update the AM/PM period, adjusting the 24-hours hour value as approriate */
  const updatePeriod = (period: TimePeriod) => {
    let hours = hourVal;

    if (period === TimePeriod.AM && selectedPeriod === TimePeriod.PM) {
      hours -= 12;
    } else if (period === TimePeriod.PM && selectedPeriod === TimePeriod.AM) {
      hours += 12;
    }

    setSelectedPeriod(period);
    props.onChange(`${hours}:${minuteVal}`);
  };

  useEffect(() => {
    if (momentTime && props.validPeriodTime) {
      setSelectedPeriod(momentTime.format('A') === TimePeriod.AM ? TimePeriod.AM : TimePeriod.PM);
    }
  }, [momentTime]);

  if (props.headerMode !== DatePickerHeaderMode.DAY) {
    return null;
  }

  return (
    <div className='time-input' css={styles}>
      <div className='divider' />
      <div className='time-input-panel'>
        <div>
          <input
            type='number'
            className='bs4-form-control'
            name='hourInput'
            max={12}
            min={1}
            value={Number(hoursInAMPM(hourVal, selectedPeriod)).toString()}
            onChange={(e) => onHourChanged(e)}
            onBlur={(e) => setSpecialHourCase(false)}
          />
        </div>
        <span className='course-title-small text-gray-2 ml-2 mr-2'>:</span>
        <div>
          <input
            type='number'
            className='bs4-form-control'
            name='minuteInput'
            max={59}
            min={0}
            value={minutesToString(minuteVal)}
            onChange={(e) => onMinuteChanged(e)}
          />
        </div>
        {/* Get the localized version of 'AM' */}
        <div
          className='form-check'
          tabIndex={0}
          onKeyDown={(event) => { if (event.key === 'Enter') { updatePeriod(TimePeriod.AM); } }}
        >
          <Form.Check
            type='radio'
            id={moment.localeData().meridiem(meridiemTime.am, null, null)}
            label={moment.localeData().meridiem(meridiemTime.am, null, null)}
            checked={selectedPeriod === TimePeriod.AM}
            onChange={() => updatePeriod(TimePeriod.AM)}
            tabIndex={-1}
          />
        </div>
        {/* Get the localized version of 'PM' */}
        <div
          className='form-check'
          tabIndex={0}
          onKeyDown={(event) => { if (event.key === 'Enter') { updatePeriod(TimePeriod.PM); } }}
        >
          <Form.Check
            type='radio'
            id={moment.localeData().meridiem(meridiemTime.pm, null, null)}
            label={moment.localeData().meridiem(meridiemTime.pm, null, null)}
            checked={selectedPeriod === TimePeriod.PM}
            onChange={() => updatePeriod(TimePeriod.PM)}
            tabIndex={-1}
          />
        </div>
      </div>
    </div>
  );
};

export default NvDatePicker;
