

/* eslint-disable react/require-default-props */
import React from 'react';
import { css } from '@emotion/react';
import { omit } from 'underscore';
import ClickableContainer from 'components/clickable-container';

export type NvIconProps = {
  size: string,
  icon: string,
  altLabel?: string,
} & React.ComponentProps<'i'>;

// Sizes
// 'xss-smallest (12px)': 'xss-smallest',
// 'xs-smallest (14px)': 'xs-smallest,
// 'smallest (16px)': 'smallest',
// 'small (20px)': 'small',
// 'medium (24px)': 'medium',
// 'medium-large (28px)': 'medium-large',
// 'medium-larger (32px)': 'medium-larger',
// 'large (36px)': 'large',
// 'larger (48px)': 'larger',
// 'largest (60px)': 'largest',
// 'super-large (80px)': 'super-large',
// 'ultra-large (100px)': 'ultra-large',

// Icons
// 'announcements', 'arrow-down', 'arrow-left', 'arrow-right', 'arrow-up', 'assignments', 'back', 'badge', 'ban', 'check', 'close', 'collapse', 'comments', 'conversations', 'course', 'course-menu', 'dashboard', 'download', 'drafts', 'dropdown-arrow', 'dueday', 'edit', 'education', 'email-preferences', 'email-template', 'evaluation', 'events', 'expand', 'export', 'facebook', 'files', 'filter', 'format-aligncenter', 'format-alignleft', 'format-alignright', 'format-bold', 'format-bulletlist', 'format-fontstyle', 'format-italic', 'format-makelink', 'format-numberedlist', 'format-underline', 'g-groups', 'g-learners', 'g-mentors', 'g-teachers', 'g-teams', 'gallery', 'group-invitation', 'groups', 'harddeadline', 'help', 'highlight', 'home', 'ideas', 'images', 'improvement', 'info', 'invitations', 'job-title', 'language', 'like', 'linkedin', 'location', 'locked', 'managing-roles', 'menu', 'messages', 'more', 'new-course', 'notifications', 'points', 'profile', 'question', 'quiz', 'read', 'refresh', 'reply', 'search', 'settings', 'share', 'signout', 'sorting-down-active', 'sorting-inactive', 'sorting-up-active', 'success', 'survey', 'switch', 'team', 'timedexam', 'trash', 'upload', 'video', 'videolist', 'warning', 'work', '3rd-party-settings', 'add', 'admin-credits', 'admin-dashboard', 'create-new-post', 'flag', 'html', 'admin-embeds', 'admin-image-left', 'admin-image-middel', 'admin-image-right', 'admin-lti-tools', 'admin-rich-text', 'admin-scorm', 'admin-template', 'admin-text-bottom', 'admin-text-middle', 'admin-text-top', 'audio', 'move-down', 'move-up', 'zoom', 'dash', 'fullscreen', 'error', 'card-view', 'drag-handle', 'enrollment', 'in-progress', 'add-square', 'calendar', 'mobile'

const NvIcon = React.forwardRef<HTMLElement, NvIconProps>((props, ref) => {
  const {
    icon,
    size,
    onClick,
    altLabel,
    className,
    ...restProps
  } = props;

  const iconElement = (
    <i
      /**
       * data-qa is added to ClickableContainer
       * so omit it from the props for icon element
       * if onClick is defined
       */
      {...(onClick
        ? omit(restProps, 'data-qa')
        : restProps
      )}
      ref={ref}
      aria-hidden={altLabel ? 'false' : 'true'}
      className={`icon icon-${size} icon-${icon} ${className ?? ''}`}
      aria-label={altLabel}
    />
  );

  return onClick
    ? (
      <ClickableContainer
        {...restProps as any}
        onClick={onClick}
        aria-label={altLabel}
      >
        {iconElement}
      </ClickableContainer>
    )
    : iconElement;
});

export default NvIcon;
