import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { contains, extend, find, findKey, flatten, isEmpty, keys, some, values } from 'underscore';
import { MatchingQuestionResponse, ResponseOption } from 'redux/schemas/models/progressive-quiz';
import sanitizeHtml from 'sanitize-html';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import ProgressiveQuizContext, { MatchingAnswerState, QuestionContext } from '../context';

type MatchingQuestionAnswerProviderProps = {
  children: React.ReactNode,
  selectedOption: ResponseOption,
  setSelectedOption: (option: ResponseOption) => void,
  hoveredId: number,
  setHoveredId: (optionId: number) => void,
  questions: ResponseOption[],
  revealCorrectAnswers: boolean,
  correctAnswerPairs: MatchingAnswerState['pairs'],
};

const initialContextState = {
  selectedOption: null,
  setSelectedOption: null,
  hoveredId: null,
  setHoveredId: null,
  questions: null,
  revealCorrectAnswers: false,
  correctAnswerPairs: null,
};
export const MatchingQuestionAnswerContext = createContext(initialContextState);

export const MatchingQuestionAnswerProvider = ({
  children,
  ...props
}: MatchingQuestionAnswerProviderProps) => (
  <MatchingQuestionAnswerContext.Provider value={{ ...props }}>
    {children}
  </MatchingQuestionAnswerContext.Provider>
);

export const getOpionId = (id: number) => `pair-response-option-${id}`;

export const getSanitizedContent = (content) => sanitizeHtml(content, { allowedTags: [] });

const useMatchingQuestionAnswer = () => {
  const [pairs, setPairs] = useState<MatchingAnswerState['pairs']>([]);

  const {
    selectedOption,
    hoveredId,
    questions,
  } = useContext(MatchingQuestionAnswerContext);

  const {
    answerState,
  } = useContext(QuestionContext);

  const {
    currentQuestionResponse,
  } = useContext(ProgressiveQuizContext);

  const {
    isAnswerMode,
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const isSubmittedMode = !!(isAnswerMode && currentQuestionResponse?.feedback);

  const getPairs = useCallback(() => {
    if (!(isReviewMode || isSubmittedMode)) {
      return (answerState as MatchingAnswerState)?.pairs ?? [];
    }

    return currentQuestionResponse?.response as MatchingQuestionResponse ?? [];
  }, [answerState, currentQuestionResponse?.response, isReviewMode, isSubmittedMode]);

  useEffect(() => {
    setPairs(getPairs);
  }, [getPairs]);

  const isQuestion = (option: ResponseOption) => option.parent;

  // Find the option in any pair.
  const checkOptionInPairs = useCallback((optionId, currentPairs = pairs) => some(currentPairs, (pair) => {
    // Check value exist in key
    const pairKeys = keys(pair).map(Number);
    if (contains(pairKeys, optionId)) {
      return true;
    }

    // Check the id in any of the values array
    const pairValues = flatten(values(pair)).map(Number);
    return contains(pairValues, optionId);
  }), [pairs]);

  //  Finding the paired response option ids if the option is already paired.
  const getPairedOptionsId = useCallback((optionId: number, currentPairs = pairs) => {
    if (!isEmpty(currentPairs) && !checkOptionInPairs(optionId, currentPairs)) {
      return [];
    }

    const transformedPairs = extend({}, ...currentPairs);

    // return values if option is question
    if (!isEmpty(transformedPairs[optionId])) {
      return transformedPairs[optionId];
    }

    const key = findKey(transformedPairs, (arrow) => contains(arrow, optionId));
    return key ? [+key] : [];
  }, [checkOptionInPairs, pairs]);

  const checkCanDimOption = useCallback((option: ResponseOption) => {
    if (isSubmittedMode || isReviewMode) {
      return false;
    }

    // Disable the other options in the same column when one option is selected.
    if (!isEmpty(selectedOption) && selectedOption.id !== option.id) {
      const currentIsQuestion = isQuestion(option);
      return (
        (currentIsQuestion && isQuestion(selectedOption))
        || (!currentIsQuestion && !isQuestion(selectedOption))
      );
    }

    // Disable the other options when hovering a pair
    if (
      !isEmpty(pairs)
      && (hoveredId && hoveredId !== option.id)
      && selectedOption?.id !== option.id
      && checkOptionInPairs(hoveredId)
    ) {
      return !getPairedOptionsId(option.id).includes(hoveredId);
    }

    return false;
  }, [checkOptionInPairs, getPairedOptionsId, hoveredId, pairs, selectedOption]);

  // Finding the pair arrow dot number based on the index of the response option.
  const getArrowText = useCallback((option, currentPairs = pairs) => {
    if (!checkOptionInPairs(option.id, currentPairs)) {
      return null;
    }

    if (option.parent) {
      return option.index + 1;
    }

    const pairQuestionId = getPairedOptionsId(option.id, currentPairs)?.[0];
    const pairQuestion = find(questions, (question: any) => question.id === pairQuestionId);

    return isEmpty(pairQuestion) ? null : pairQuestion.index + 1;
  }, [checkOptionInPairs, getPairedOptionsId, questions]);

  return {
    pairs,
    isSubmittedMode,
    checkOptionInPairs,
    getPairedOptionsId,
    checkCanDimOption,
    isQuestion,
    getArrowText,
  };
};

export default useMatchingQuestionAnswer;
