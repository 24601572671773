import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ActionItemOwner,
  MentoringProgramActionItem,
} from 'redux/schemas/models/mentoring-program-action-items';
import { PageSizeParams } from 'athena/components/mentoring-program/constants';
import { RootState } from 'redux/schemas';
import {
  addCreatedSessionActionItem,
  removeDeletedSessionActionItem,
  updateModifiedSessionActionItem,
} from './mentoring-program-sessions';

type MentorshipProgramParams = {
  mentorshipProgramId: number;
};

export type MentorshipProgramActionItemParams = MentorshipProgramParams &
Partial<
Pick<MentoringProgramActionItem, 'description' | 'status' | 'dueDate'>
> & {
  assignedTo?: number;
  mentorshipProgramConnectionId?: number;
  mentorshipProgramSessionId?: number;
  connectionId?: number;
};

type MentorshipProgramResponse = {
  connectionId: number;
  actionItem: MentoringProgramActionItem;
};

export const createMentorshipProgramActionItem = createAsyncThunk<
MentorshipProgramResponse,
MentorshipProgramActionItemParams
>(
  'CREATE_MENTORSHIP_PROGRAM_ACTION_ITEM',
  async ({
    mentorshipProgramId,
    description,
    status,
    assignedTo,
    dueDate,
    mentorshipProgramSessionId,
    mentorshipProgramConnectionId,
    connectionId,
  }) => {
    const mentorshipProgramActionItem = {
      description,
      status,
      assignedTo,
      dueDate,
      mentorshipProgramSessionId: mentorshipProgramSessionId || undefined,
      mentorshipProgramConnectionId: mentorshipProgramConnectionId || undefined,
    };
    const response = await axios.post(
      `/mentorship_programs/${mentorshipProgramId}/action_items.json`,
      {
        mentorshipProgramActionItem,
      },
    );
    const actionItem = response.data.result as MentoringProgramActionItem;
    return { connectionId, actionItem };
  },
);

export type GetMentorshipProgramActionItemParams = MentorshipProgramParams &
PageSizeParams & {
  page: number;
  connectionId: number;
};

export const getMentorshipProgramActionItems = createAsyncThunk<
MentoringProgramActionItem[],
GetMentorshipProgramActionItemParams
>(
  'GET_MENTORSHIP_PROGRAM_ACTION_ITEMS',
  async ({ mentorshipProgramId, page, pageSize, connectionId }) => {
    const params = {
      page,
      page_size: pageSize,
      mentorship_program_action_item: {
        mentorship_program_connection_id: connectionId,
      },
    };
    const response = await axios.get(
      `/mentorship_programs/${mentorshipProgramId}/action_items.json`,
      {
        params,
      },
    );
    return response.data.result;
  },
);

type UpdateMentorshipProgramActionItemParams = MentorshipProgramParams &
Partial<Pick<MentoringProgramActionItem, 'description' | 'status'>> & {
  actionItemId: number;
  assignedTo?: number;
} & { connectionId: number };

export const updateMentorshipProgramActionItem = createAsyncThunk<
MentorshipProgramResponse,
UpdateMentorshipProgramActionItemParams
>(
  'UPDATE_MENTORSHIP_PROGRAM_ACTION_ITEM',
  async (
    {
      mentorshipProgramId,
      actionItemId,
      description,
      status,
      assignedTo,
      connectionId,
    },
    thunkAPI,
  ) => {
    const mentorshipProgramActionItem = {
      description,
      status,
      assignedTo,
    };
    const response = await axios.put(
      `/mentorship_programs/${mentorshipProgramId}/action_items/${actionItemId}.json`,
      {
        mentorshipProgramActionItem,
      },
    );
    const actionItem = response.data.result as MentoringProgramActionItem;
    if (actionItem.owner) {
      const {
        owner: { id: sessionId, type },
      } = actionItem;
      if (type === ActionItemOwner.SESSION) {
        thunkAPI.dispatch(
          updateModifiedSessionActionItem({ sessionId, actionItem }),
        );
      }
    }
    return { connectionId, actionItem };
  },
);

type DeleteMentorshipProgramActionItemParams = MentorshipProgramParams & {
  actionItemId: number;
  sessionId?: number;
  connectionId?: number;
};

export const deleteMentorshipProgramActionItem = createAsyncThunk<
boolean,
DeleteMentorshipProgramActionItemParams
>(
  'DELETE_MENTORSHIP_PROGRAM_ACTION_ITEM',
  async ({ mentorshipProgramId, actionItemId, sessionId }, thunkAPI) => {
    const response = await axios.delete(
      `/mentorship_programs/${mentorshipProgramId}/action_items/${actionItemId}.json`,
    );
    const { result: isActionItemDeleted } = response.data;
    if (sessionId && isActionItemDeleted) {
      thunkAPI.dispatch(
        removeDeletedSessionActionItem({ sessionId, actionItemId }),
      );
    }
    return isActionItemDeleted;
  },
);

export const addCreatedActionItem = createAction<{
  connectionId: number;
  actionItem: MentoringProgramActionItem;
}>('ADD_CREATED_MENTORING_PROGRAM_ACTION_ITEM');

export const updateModifiedActionItem = createAction<{
  connectionId: number;
  actionItem: MentoringProgramActionItem;
}>('UPDATE_MODIFIED_MENTORING_PROGRAM_ACTION_ITEM');

export const removeDeletedActionItem = createAction<{
  connectionId: number;
  actionItemId: number;
}>('REMOVE_DELETED_MENTORING_PROGRAM_ACTION_ITEM');

type SearchAndRemoveDeletedActionItemParams = {
  connectionId: number;
  actionItemId: number;
};

export const searchAndRemoveDeletedActionItem = createAsyncThunk<
void,
SearchAndRemoveDeletedActionItemParams
>(
  'SEARCH_AND_REMOVE_DELETED_ACTION_ITEM',
  async ({ connectionId, actionItemId }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { mentorshipProgramActionItems } = state.models;
    if (mentorshipProgramActionItems) {
      const actionItem = mentorshipProgramActionItems[connectionId][actionItemId];
      if (actionItem?.owner) {
        const { id, type } = actionItem.owner;
        if (type === ActionItemOwner.SESSION) {
          thunkAPI.dispatch(
            removeDeletedSessionActionItem({ sessionId: id, actionItemId }),
          );
        }
      }
    }
    thunkAPI.dispatch(removeDeletedActionItem({ connectionId, actionItemId }));
  },
);
