import { AutoMatchingStatus } from 'redux/schemas/app/mentoring-programs';
import { ActionItemOwner, ActionItemStatus } from 'redux/schemas/models/mentoring-program-action-items';
import { User } from 'redux/schemas/models/my-account';
import { AppDispatch } from 'redux/store';

export enum EventType {
  // Mentoring program channel events
  AUTO_MATCHING = 'auto_matching',
  BULK_ENROLLMENT = 'bulk_enrollment',
  // Action items channel events
  NEW_ACTION_ITEM = 'new_action_item',
  UPDATE_ACTION_ITEM = 'update_action_item',
  REMOVE_ACTION_ITEM = 'remove_action_item',
}

type MentorshipProgramEvent = {
  mentorshipProgramId: number;
};

// Mentoring program event types
export type AutoMatchingEvent = MentorshipProgramEvent & {
  totalConnectionsCount: number;
  createdConnectionsCount: number;
  unmatchedCount?: number;
  autoMatchingStatus: AutoMatchingStatus;
  autoMatchingLastUpdated?: Date;
};

export type BulkEnrollmentEvent = MentorshipProgramEvent & {
  newEnrolledCount: number;
  existingEnrolledCount: number;
  errors?: [];
};

// Action items event types
export type ActionItemEvent = {
  actionItemId: number;
  ownerClass?: ActionItemOwner;
  ownerId?: number;
  description?: string;
  status?: ActionItemStatus;
  assignedTo?: User;
  completedAt?: string;
  completedBy?: User;
};

export type Event = AutoMatchingEvent | BulkEnrollmentEvent | ActionItemEvent;

type EventItem = {
  name: EventType;
  handler: (event: Event, dispatch: AppDispatch) => void;
};

export type EventList = EventItem[];
