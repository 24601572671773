import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllTemplates } from 'redux/actions/course-templates';
import CourseTemplateList from './models/course-template-list';
import CourseTemplatePreview from './models/course-template-preview';
import CourseTemplateCreateForm from './models/course-template-creating-form';
import CourseTemplateCreatingLoader from './models/course-template-creating-loader';

export enum Template {
  LISTING = 'listing',
  PREVIEW = 'preview',
  CREATE_FORM = 'create_form',
  CREATING_LOADER = 'creating_loader',
}

const CourseTemplatesWrapper = ({ handleCloseTemplateModal }) => {
  const [activeTab, setActiveTab] = useState<Template>(Template.LISTING);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);

  const handleSetActiveTab = (passedActiveTab: Template) => {
    setActiveTab(passedActiveTab);
  };

  return (
    <div className='body-text-wrapper pb-6'>
      {activeTab === Template.LISTING && (
        <CourseTemplateList
          handleCloseModal={handleCloseTemplateModal}
          handleSetActiveTab={handleSetActiveTab}
        />
      )}
      {activeTab === Template.PREVIEW && (
        <CourseTemplatePreview
          handleCloseModal={handleCloseTemplateModal}
          handleSetActiveTab={handleSetActiveTab}
        />
      )}
      {activeTab === Template.CREATE_FORM && (
        <CourseTemplateCreateForm
          handleCloseModal={handleCloseTemplateModal}
          handleSetActiveTab={handleSetActiveTab}
        />
      )}
      {activeTab === Template.CREATING_LOADER && (
        <CourseTemplateCreatingLoader
          handleCloseModal={handleCloseTemplateModal}
        />
      )}
    </div>
  );
};


export default CourseTemplatesWrapper;
