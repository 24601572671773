import store from 'redux/store';
import { unlinkCollectionLesson } from '../../redux/actions/lecture-pages';

/* @ngInject */
export default function NvTimelineLecturePageItem(
  _,
  TimelinesManager,
  $uibModal,
  moment,
  nvUtil,
  ConfirmationOverlays,
  CurrentCourseManager,
  CurrentUserManager,
  FilterCoursesManager,
  TimelineLecturePageModel,
  config,
  AlertMessages,
) {
  return {
    scope: {
      item: '=',
      decayEnabled: '=?',
      gamificationEnabled: '=?',
      collapseTimelineFunction: '&?',
      cardView: '=?',
      customState: '=?',
      parent: '=?',
      copyToStatus: '<?',
      showAddToCollectionModal: '&',
      hasCollectionsToConvert: '=?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      let previousReleaseDate = vm.item.releaseDate;
      vm.TimelinesManager = TimelinesManager;
      vm.CurrentUserManager = CurrentUserManager;
      vm.showProgress = true;
      vm.isCopyToDropdownOpen = false;
      vm.item.copyToStatus = vm.parent?.copyToStatus;
      vm.config = config;

      vm.$onChanges = (changes) => {
        vm.item.copyToStatus = vm.parent?.copyToStatus;
      };

      vm.openModal = () => {
        vm.showAddToCollectionModal({ lessonId: vm.item.id });
      };

      vm.contentType = 'LESSON';
      vm.content = vm.item;

      const filterCurrentCourse = ({ catalogId, isJourney, isContentManagementCollection }) => catalogId !== CurrentCourseManager.course.catalogId && !isJourney && !isContentManagementCollection;
      const activeOfferings = FilterCoursesManager.coursesForTab(_.filter(CurrentUserManager.courses, filterCurrentCourse), 'active');
      const futureOfferings = FilterCoursesManager.coursesForTab(_.filter(CurrentUserManager.courses, filterCurrentCourse), 'future');
      const demoOfferings = FilterCoursesManager.coursesForTab(_.filter(CurrentUserManager.courses, filterCurrentCourse), 'demo');
      vm.categories = [futureOfferings, activeOfferings, demoOfferings];
      vm.hasCourses = _.some(vm.categories, (c) => c.length);

      vm.lastCopyToOfferingVisible = function () {
        return vm.CurrentUserManager.lastCopyToOffering && (CurrentCourseManager.course.catalogId !== vm.CurrentUserManager.lastCopyToOffering);
      };

      vm.openCopyToDropdown = function () {
        vm.isCopyToDropdownOpen = true;
      };

      vm.updateCopyStatus = function (status) {
        vm.item.copyToStatus = status;
      };

      vm.findLastCopiedOffering = function () {
        let lastCopiedOffering = null;
        _.each(CurrentUserManager.courses, (course) => {
          if (course.catalogId === vm.CurrentUserManager.lastCopyToOffering) {
            lastCopiedOffering = course;
          }
        });
        return lastCopiedOffering;
      };

      vm.copyLastSelected = function () {
        vm.isCopyToDropdownOpen = false;
        const course = vm.findLastCopiedOffering();
        const { content, contentType, updateCopyStatus } = vm;
        vm.uibModalInstance = $uibModal.open({
          backdrop: 'static',
          templateUrl: 'timelines/templates/copy-to-offering-modal.html',
          controller: 'CopyToOfferingModalCtrl as vm',
          windowClass: 'copy-to-modal',
          resolve: {
            course,
            content,
            type: () => contentType,
            updateCopyStatus: () => updateCopyStatus.bind(vm),
          },
        });
      };
      // In edit mode
      vm.expandLectureComponents = false;
      vm.formId = nvUtil.randomPositiveInteger();

      function afterReleaseDateSave() {
        vm.saving = false;
        previousReleaseDate = vm.item.releaseDate;
      }
      function resetReleaseDateUpdate() {
        vm.saving = false;
        vm.item.releaseDate = previousReleaseDate; // Reset to original value
      }
      // For lecture page
      vm.updateLecturePageReleaseDate = function () {
        const currentMoment = moment();
        const releaseDateMoment = moment(vm.item.releaseDate);

        // If lecture page is released and we're making it unreleased
        const unreleaseWarning = moment(previousReleaseDate) < currentMoment && releaseDateMoment > currentMoment;
        const communicationExistWarning = vm.item.scheduledCommunicationsCount > 0 && releaseDateMoment.isBefore(moment(previousReleaseDate));

        vm.saving = true;

        if (unreleaseWarning || communicationExistWarning) {
          const modalInstance = ConfirmationOverlays.openConfirmationModal('timelines/templates/modal-lecture-page-release-date-change-confirmation.html',
            'AttachModalResolvesToVmCtrl', {
              vmResolves() {
                return {
                  CurrentCourseManager,
                  item: vm.item,
                  showUnreleaseWarning: unreleaseWarning,
                  showCommunicationExistWarning: communicationExistWarning,
                };
              },
            });

          modalInstance.result.then(() => {
            vm.item.updateReleaseDate().then(afterReleaseDateSave, resetReleaseDateUpdate);
          }).catch(resetReleaseDateUpdate);
        } else {
          vm.item.updateReleaseDate().then(afterReleaseDateSave, resetReleaseDateUpdate);
        }
      };

      vm.saveLecturePageBasics = function () {
        vm.item.saveBasics().then(() => {
          vm.saving = false;
        });
      };

      vm.unlinkCollectionLesson = function () {
        const modalInstance = ConfirmationOverlays.openConfirmationModal(
          'timelines/templates/modal-unlink-lecture-page-confirmation.html',
          'AttachModalResolvesToVmCtrl as vm',
          {
            vmResolves() {
              return {
                course: CurrentCourseManager.course,
              };
            },
          },
        );

        modalInstance.result.then(() => {
          store.dispatch(unlinkCollectionLesson({
            courseId: CurrentCourseManager.course.id,
            lecturePages: [{
              id: vm.item.id,
            }],
          })).then((response) => {
            if (_.isEmpty(response?.error)) {
              AlertMessages.success('FORM.SUCCESS_BANG', 'TIMELINE.UNLINK.SUCCESS_ALERT', {}, { lectureName: vm.item.title });
              vm.TimelinesManager.updateLecturePageAttribute(vm.item.id, 'isLinked', false);
              vm.TimelinesManager.updateLecturePageAttribute(vm.item.id, 'contentManagementCollection', null);
            }
          });
        });
      };

      vm.deleteLecturePage = function () {
        // Asking for confirmation of whether the lecture page component has content or not (NOV-83703)
        const modalInstance = ConfirmationOverlays.openConfirmationModal(
          'timelines/templates/modal-delete-lecture-page-confirmation.html',
          'AttachModalResolvesToVmCtrl',
          {
            vmResolves() {
              return {
                CurrentCourseManager,
                item: vm.item,
              };
            },
          },
        );

        modalInstance.result.then(() => {
          vm.item.deleteLecturePage();
          vm.TimelinesManager.removeLecturePage(vm.item.id);
        });
      };

      vm.duplicateLecturePage = function () {
        vm.item.copyInProgress = true;
        vm.item.duplicateLecturePage().then((result) => {
          vm.item.copyInProgress = false;
          vm.TimelinesManager.insertLecturePageAfter(vm.item.id, result);
        }, () => {
          vm.item.copyInProgress = true;
        });
      };

      vm.openCopyToDropdown = function () {
        vm.isCopyToDropdownOpen = true;
      };

      vm.updateCopyStatus = function (status) {
        vm.copyToStatus = status;
      };

      vm.findLastCopiedOffering = function () {
        let lastCopiedOffering = null;
        _.each(CurrentUserManager.courses, (course) => {
          if (course.catalogId === vm.CurrentUserManager.lastCopyToOffering) {
            lastCopiedOffering = course;
          }
        });
        return lastCopiedOffering;
      };

      vm.createLecturePageAfter = () => {
        TimelineLecturePageModel.createLecturePage(vm.item.catalogId, vm.item.id).then((result) => {
          vm.TimelinesManager.insertLecturePageAfter(vm.item.id, result);
        });
      };

      vm.updateDeadlines = function () {
        const modal = $uibModal.open({
          templateUrl: 'timelines/templates/bulk-edit-deadline-modal.html',
          controller: 'BulkEditDeadlineCtrl as vm',
          windowClass: 'full-screen-modal-handheld full-screen-modal-tablet bulk-edit-deadline-modal',
          resolve: {
            section: vm.item,
          },
        });
      };

      vm.toggleViewActivities = function () {
        if (!vm.expandLectureComponents) {
          vm.item.getActivities();
        }
        vm.expandLectureComponents = !vm.expandLectureComponents;
      };

      vm.uploadCardViewImage = ($file) => {
        if ($file) {
          vm.savingCardViewImage = true;

          vm.item.saveCardViewImage($file)
            .then((response) => {
              vm.TimelinesManager.updateLecturePageAttribute(vm.item.id, 'cardViewImageUrl', vm.item.cardViewImageUrl);
              vm.savingCardViewImage = false;
            });
        }
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    template: '<div ng-include="vm.item.templateUrl"></div>',
  };
}
