import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { Link } from 'react-router-dom';
import { config } from '@config/pendo.config.json';

const WorkspaceTitle = ({ currentUser, connectionUser, showGoBack }) => {
  const content = (
    <div className='d-flex align-items-center w-100 workspace-title pt-2'>
      {showGoBack && <NvIcon icon='back' size='small' className='mr-2' />}
      <div className='heading-4'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.WORKSPACE_TITLE(currentUser.firstName, connectionUser.firstName)}
      </div>
    </div>
  );

  const dataQATag = config.pendo.athena.mentorshipProgram.programHeader.showMenteesListLink;

  return showGoBack ? (
    <Link to='/my-mentees' className='workspace-title-link' data-qa={dataQATag}>
      {content}
    </Link>
  ) : (
    content
  );
};

export default WorkspaceTitle;
