import { createReducer } from '@reduxjs/toolkit';
import {
  addCreatedActionItem,
  createMentorshipProgramActionItem,
  deleteMentorshipProgramActionItem,
  getMentorshipProgramActionItems,
  removeDeletedActionItem,
  updateMentorshipProgramActionItem,
  updateModifiedActionItem,
} from 'redux/actions/mentoring-program-action-items';
import { normalize } from 'normalizr';
import { MentorshipProgramActionItemsSchema } from 'redux/schemas/api/mentoring-program-action-items';
import { mergeWith } from 'lodash';
import { initialRootState } from '.';

const setProgramActionItemActionHandler = (state, action) => {
  const { meta: { arg: { connectionId } } } = action;
  const { entities: { mentorshipProgramActionItems } } = normalize(
    action.payload,
    MentorshipProgramActionItemsSchema,
  );
  state.models.mentorshipProgramActionItems[connectionId] = {
    ...state.models.mentorshipProgramActionItems[connectionId],
    ...mentorshipProgramActionItems,
  };
};

const addCreatedActionItemHandler = (state, action) => {
  const { connectionId, actionItem } = action.payload;
  state.models.mentorshipProgramActionItems[connectionId][actionItem.id] = actionItem;
};

const updateModifiedActionItemHandler = (state, action) => {
  const { connectionId, actionItem } = action.payload;
  state.models.mentorshipProgramActionItems[connectionId][actionItem.id] = actionItem;
};

const removeDeletedActionItemHandler = (state, action) => {
  const { connectionId, actionItemId } = action.meta?.arg || action.payload;
  delete state.models.mentorshipProgramActionItems[connectionId][actionItemId];
};

export default createReducer(initialRootState, (builder) => {
  builder
    .addCase(
      getMentorshipProgramActionItems.fulfilled,
      setProgramActionItemActionHandler,
    )
    .addCase(createMentorshipProgramActionItem.fulfilled, addCreatedActionItemHandler)
    .addCase(updateMentorshipProgramActionItem.fulfilled, updateModifiedActionItemHandler)
    .addCase(deleteMentorshipProgramActionItem.fulfilled, removeDeletedActionItemHandler)
    .addCase(addCreatedActionItem, addCreatedActionItemHandler)
    .addCase(updateModifiedActionItem, updateModifiedActionItemHandler)
    .addCase(removeDeletedActionItem, removeDeletedActionItemHandler);
});
