import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import t from 'react-translate';
import { InfiniteTableLoadingParams, GetQueryCountParams } from 'redux/create-action-creators';
import { GetTeamsMentionRequest, GetTeamsMentionResponse } from 'redux/schemas/api/team';
import { MeetingParams, Team } from 'redux/schemas/models/team';
import { TeamSet } from 'redux/schemas/models/my-account';
import { objectToFormData } from 'shared/utils';
import { addAlertMessage } from './alert-messages';
import { AlertMessageType } from '../schemas/app/alert-message';
import { makeQueryParams, makeQueryParamString } from './helpers';

type TeamsListPageParams = { catalogId: string, institutionId: number } & InfiniteTableLoadingParams<Team>;

type TeamInfoParams = {
  catalogId: string,
  teamId: number,
};

type TeamUpdateInfoParams = {
  name: string,
  description: string,
  teamSetId: number,
  picture: File,
};

type CurrentTeamIdParams = {
  teamId: number,
  isUploading?: boolean,
};

export const getDemoData = (isTeam: boolean) => (isTeam ? [
  { teamId: 'CREATE NEW', teamName: 'Sample Team A', firstName: 'Demo', lastName: 'User 1', email: 'DemoUser1@email.com', isAdmin: true },
  { teamId: 'CREATE NEW', teamName: 'Sample Team A', firstName: 'Demo', lastName: 'User 2', email: 'DemoUser2@email.com', isAdmin: false },
  { teamId: 'CREATE NEW', teamName: 'Sample Team B', firstName: 'Demo', lastName: 'User 3', email: 'DemoUser3@email.com', isAdmin: true },
  { teamId: 'CREATE NEW', teamName: 'Sample Team B', firstName: 'Demo', lastName: 'User 4', email: 'DemoUser4@email.com', isAdmin: false },
] : [
  { teamId: 'CREATE NEW', teamName: 'Sample Group A', isOpen: false, firstName: 'Demo', lastName: 'User 1', email: 'DemoUser1@email.com', isAdmin: true },
  { teamId: 'CREATE NEW', teamName: 'Sample Group A', isOpen: false, firstName: 'Demo', lastName: 'User 2', email: 'DemoUser2@email.com', isAdmin: false },
  { teamId: 'CREATE NEW', teamName: 'Sample Group B', isOpen: true, firstName: 'Demo', lastName: 'User 3', email: 'DemoUser3@email.com', isAdmin: true },
  { teamId: 'CREATE NEW', teamName: 'Sample Group B', isOpen: true, firstName: 'Demo', lastName: 'User 4', email: 'DemoUser4@email.com', isAdmin: false },
]);

export const setCurrentTeamId = createAction<CurrentTeamIdParams>(
  'SET_CURRENT_TEAM_ID',
);

export const getTeamsCount = createAsyncThunk(
  'GET_TEAMS_SIZE',
  async (params: TeamsListPageParams & GetQueryCountParams) => {
    const queryParams = makeQueryParams(params, params.pageSize, params.pageIndex);
    const paramString = makeQueryParamString(queryParams);

    // TODO: update this with a results count API...
    return axios.get(`${params.catalogId}/teams/sizes.json?${paramString}`).then(response => {
      params.handleCountResponse(response.data.result);
      return response.data.result;
    });
  },
);

export const getTeamsList = createAsyncThunk<any, TeamsListPageParams>(
  'GET_TEAMS_LIST_PAGE',
  async (params) => {
    const queryParams = makeQueryParams(params, params.pageSize, params.pageIndex);
    const paramString = makeQueryParamString(queryParams);

    return axios.get(`${params.catalogId}/teams/team_facilitation.json?${paramString}`).then(response => ({
      response: response.data.result.teamData,
    }));
  },
);

export const getAllUsersForCSV = createAsyncThunk<any, any>(
  'GET_ALL_USERS_FOR_CSV',
  async (params) => {
    const getUsersNotInTeamsPromise = axios.get(`${params.catalogId}/team_sets/${params.teamSetId}/list_student_users_not_in_teams`, {
      params: {
        skip_pagination: 1,
      },
    }).then(response => response.data.result
      .map(({ firstName, lastName, email }) => ({ email, firstName, lastName })));

    if (params.getTeamUsers) {
      const getTeamUsersPromise = axios.get(`${params.catalogId}/team_sets/${params.teamSetId}/list_teams`, {
        params: {
          skip_pagination: 1,
        },
      }).then(response => response.data.result.flatMap(({ verboseTeamMembers, isOpen, id: teamId, name: teamName, hasContributions }) => {
        if (!verboseTeamMembers.length) {
          return { teamId, teamName, isOpen, hasContributions };
        }
        return verboseTeamMembers.map(({ user: { firstName, lastName, email }, isAdmin }) => ({ firstName, email, lastName, isAdmin, teamId, teamName, isOpen, hasContributions }));
      }));

      return Promise.all([getUsersNotInTeamsPromise, getTeamUsersPromise]).then(([usersNotInTeams, teamUsers]) => {
        if (teamUsers.length) {
          return [...teamUsers, ...usersNotInTeams];
        }
        const demoData = getDemoData(params.isTeam);
        return [...demoData, ...usersNotInTeams];
      });
    }
    return getUsersNotInTeamsPromise.then((usersNotInTeams) => {
      const demoData = getDemoData(params.isTeam);
      return [...demoData, ...usersNotInTeams];
    });
  },
);

export const getTeamInfo = createAsyncThunk<any, TeamInfoParams>(
  'GET_TEAM_INFO',
  async (params) => axios.get(`${params.catalogId}/teams/${params.teamId}`).then(response => ({
    response: response.data.result,
  })),
);

export const getTeamMentions = createAsyncThunk<GetTeamsMentionResponse, GetTeamsMentionRequest>(
  'GET_TEAM_MENTIONS',
  async (params) => {
    const response = await axios.get(`${params.catalogId}/teams/${params.teamId}/mentionable_members`);
    return response.data.result;
  },
);

export const getPossibleFutureMembers = createAsyncThunk(
  'GET_POSSIBLE_FUTURE_MEMBERS',
  async (params: any) => axios.get(`${params.catalogId}/team_sets/${params.teamSetId}/list_student_users_except_team/${params.teamId}`, {
    params: {
      text: params.text,
      page: params.pageNumber,
      page_size: params.pageSize,
    },
  }).then(response => response.data.result),
);

export const getPossibleFutureTeams = createAsyncThunk(
  'GET_POSSIBLE_FUTURE_TEAMS',
  async (params: any) => axios.get(`${params.catalogId}/team_sets/${params.teamSetId}/list_teams`, {
    params: {
      text: params.text,
      exclude_team_id: params.teamId,
      page: params.pageNumber,
      page_size: params.pageSize,
    },
  }).then(response => response.data.result),
);

export const getTeamMembers = createAsyncThunk(
  'GET_TEAM_MEMBERS',
  async (params: any) => axios.get(`${params.catalogId}/teams/${params.teamId}/approved_members`, {
    params: {
      text: params.text,
      page: params.pageNumber,
      page_size: params.pageSize,
    },
  }).then((res) => res.data.result),
);

export const addTeamMembers = createAsyncThunk(
  'ADD_TEAM_MEMBERS',
  async (params: any) => axios.post(`${params.catalogId}/teams/create_approved_members/${params.teamId}`, {
    teamMembers: params.teamMembers,
  }).then((res) => res.data.result),
);

export const deleteTeamMember = createAsyncThunk(
  'DELETE_TEAM_MEMBER',
  async (params: any) => axios.post(`${params.catalogId}/team_members/fire/${params.teamId}`, {
    teamMemberId: params.teamMemberId,
  }).then((res) => res.data.result),
);

export const moveTeamMembers = createAsyncThunk(
  'MOVE_TEAM_MEMBERS',
  async (params: any) => axios.post(`${params.catalogId}/teams/update_approved_members/${params.teamId}`, {
    userIds: params.userIds,
    remove_from_team_id: params.currentTeamId,
  }).then((res) => res.data.result),
);

export const deleteTeam = createAsyncThunk(
  'DELETE_TEAM',
  async (params: any) => axios.delete(`${params.catalogId}/teams/${params.teamId}.json`).then((res) => res.data),
);

export const makeTeamAdmin = createAsyncThunk(
  'MAKE_TEAM_ADMIN',
  async (params: any) => axios.post(`${params.catalogId}/teams/appoint/${params.teamId}`, {
    teamMemberId: params.teamMemberId,
    skipReassign: true,
  }).then((res) => res.data),
);
export const deleteTeamAdmin = createAsyncThunk(
  'DELETE_TEAM_ADMIN',
  async (params: any) => axios.post(`${params.catalogId}/teams/remove_ceo/${params.teamId}?team_member_id=${params.teamMemberId}`, {
    skipReassign: true,
  }).then((res) => res.data),
);
export const makeGroupAdmin = createAsyncThunk(
  'MAKE_GROUP_ADMIN',
  async (params: any) => axios.post(`${params.catalogId}/team_members/${params.teamMemberId}/make_admin`, {
    skipReassign: true,
  }).then((res) => res.data.result),
);
export const deleteGroupAdmin = createAsyncThunk(
  'DELETE_GROUP_ADMIN',
  async (params: any) => axios.post(`${params.catalogId}/team_members/${params.teamMemberId}/remove_admin`, {
    skipReassign: true,
  }).then((res) => res.data.result),
);

export const updateTeamInfo = createAsyncThunk(
  'UPDATE_TEAM_INFO',
  async (params: TeamUpdateInfoParams & TeamInfoParams) => {
    const team: any = {
      name: params.name,
      teamSetId: params.teamSetId.toString(),
    };

    if (params.description) {
      team.description = params.description;
    }

    if (params.picture) {
      team.picture = params.picture;
    }

    const formData = objectToFormData({ team });
    return axios.put(
      `${params.catalogId}/teams/${params.teamId}`,
      formData,
    ).then((response) => response.data.result);
  },
);

export const updateProgressDashboardForTeam = createAsyncThunk(
  'TEAM_ENABLE_PROGRESS_DASHBOARD',
  async (params: any) => axios.get(`${params.catalogId}/teams/${params.teamId}/${params.hasProgressDashboard ? 'disable' : 'enable'}_progress_dashboard`).then((res) => res.data.result.teamList),
);

export const updateProgressDashboardForAllTeams = createAsyncThunk(
  'ALL_TEAMS_ENABLE_PROGRESS_DASHBOARD',
  async (params: any) => axios.get(`${params.catalogId}/team_sets/${params.teamSetId}/${params.hasProgressDashboard ? 'disable' : 'enable'}_progress_dashboard`).then((res) => res),
);

export const deleteAllTeams = createAsyncThunk(
  'DELETE_ALL_TEAMS',
  async (params: any, thunkAPI) => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.INFO,
      header: t.TEAM_FACILITATION.MANAGE_TEAMS.DELETE_ALL_FLASH_HEADER(params.translationValues),
      message: t.TEAM_FACILITATION.MANAGE_TEAMS.DELETE_ALL_FLASH_MESSAGE(params.translationValues),
    }));
    return axios.delete(`${params.catalogId}/team_sets/${params.teamSetId}/delete_all_teams`).then((res) => res.data.result);
  },
);

export const bulkUpdateTeams = createAsyncThunk<any, any>(
  'BULK_UPDATE_TEAMS',
  async (params, thunkAPI) => axios.post(`${params.catalogId}/team_sets/${params.teamSetId}/process_json`, {
    headers: params.headers,
    learners: params.learners,
    invalidRows: params.invalidRows,
  }).then(response => {
    if (response.data.result.threshold) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.INFO,
        header: t.TEAM_FACILITATION.BULK_UPLOAD.PROCESSING_UPLOADED_CSV_FILE(),
        message: t.TEAM_FACILITATION.BULK_UPLOAD.WE_WILL_SEND_AN_EMAIL_WHEN_DONE(),
      }));
    } else {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.SUCCESS,
        header: t.FORM.SUCCESS_BANG(),
        message: params.isUpdate
          ? t.TEAM_FACILITATION.BULK_UPLOAD.NUM_TEAMS_WERE_UPDATED(params.translationValues)
          : t.TEAM_FACILITATION.BULK_UPLOAD.NUM_TEAMS_WERE_CREATED(params.translationValues),
      }));
    }
    return response.data.result;
  }).catch(error => {
    thunkAPI.dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FORM.ERROR_SOMETHING_WRONG(),
    }));
  }),
);

export const createTeamMeeting = createAsyncThunk<any, MeetingParams>(
  'CREATE_TEAM_MEETING',
  async ({ catalogId, teamId, meeting }: MeetingParams) => axios.post(
    `${catalogId}/teams/${teamId}/meetings`,
    { meeting },
  ).then((res) => res.data.result),
);

export const updateTeamMeeting = createAsyncThunk<any, MeetingParams>(
  'UPDATE_TEAM_MEETING',
  async ({ catalogId, teamId, meeting, meetingId }: MeetingParams) => axios.patch(
    `${catalogId}/teams/${teamId}/meetings/${meetingId}`,
    { meeting },
  ).then((res) => res.data.result),
);

export const setTeamSet = createAction<{ teamSet: TeamSet }>('SET_TEAM_SET');
