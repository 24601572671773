import t from 'react-translate';
import NvPopover from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';
import prodPathReplace from 'shared/prod-path-rewrite';
import { css } from '@emotion/react';
import { novoEdBlack } from 'athena/styles/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { gray2 } from 'styles/global_defaults/colors';

const panelHeaderStyles = css`
  gap: ${halfSpacing}px;
`;

const overlayStyles = css`
  max-width: 100%;

  .action-items-info {
    width: 120px;
  }
  .action-items-description {
    color: ${gray2};
    text-align: center;
  }
`;
const styles = css`
  .action-items-info-icon {
    color: ${novoEdBlack};
  }
`;

const ActionItemsInfo = () => {
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS;
  return (
    <NvPopover
      showOnHover
      css={styles}
      content={(
        <div className='action-items-info'>
          <img
            src={prodPathReplace('images/action-items.gif')}
            alt={translationBase.ALT_IMAGE()}
          />
          <div className='mt-2 text-small action-items-description'>
            {translationBase.DESCRIPTION()}
          </div>
        </div>
      )}
      placement='top'
      overlayStyles={overlayStyles}
    >
      <NvIcon className='action-items-info-icon' icon='info' size='smallest' />
    </NvPopover>
  );
};

const ActionItemsPanelHeader = () => (
  <div css={panelHeaderStyles} className='d-flex align-items-center'>
    <div>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.TITLE()}</div>
    <ActionItemsInfo />
  </div>
);

export default ActionItemsPanelHeader;
