import uuid from 'react-uuid';
import { AppDispatch } from 'redux/store';
import { ActionItemFormData } from 'athena/components/action-item/types';
import { MentoringProgramActionItem } from 'redux/schemas/models/mentoring-program-action-items';
import {
  createMentorshipProgramActionItem,
  deleteMentorshipProgramActionItem,
  updateMentorshipProgramActionItem,
} from 'redux/actions/mentoring-program-action-items';

export const getDraftActionItemHandlers = (
  setDraftActionItems: (draftItems) => void,
) => {
  const getActionItem = (formData: ActionItemFormData) => ({
    id: uuid(),
    status: formData.status,
    description: formData.description.trim(),
    assignedTo: {
      id: formData.assignedTo,
    },
    owner: null,
    dueDate: null,
    completedAt: null,
    completedBy: null,
  });

  const addDraftActionItem = (formData: ActionItemFormData) => {
    if (formData?.description.trim()) {
      const draftItem = getActionItem(formData);
      setDraftActionItems((currentDraftItems) => [
        draftItem,
        ...currentDraftItems,
      ]);
    }
  };

  const updateDraftActionItem = (
    id: number | string,
    formData: ActionItemFormData,
  ) => {
    if (formData?.description.trim()) {
      const updatedDraftItem = getActionItem(formData);
      setDraftActionItems((currentDraftItems) => currentDraftItems.map((item) => {
        if (item.id === id) return updatedDraftItem;
        return item;
      }));
    }
  };

  const deleteDraftActionItem = (id: number | string) => {
    setDraftActionItems((currentDraftItems) => currentDraftItems.filter((item) => item.id !== id));
  };

  return {
    onAdd: addDraftActionItem,
    onUpdate: updateDraftActionItem,
    onDelete: deleteDraftActionItem,
  };
};

export const getDefaultActionItemHandlers = (
  mentorshipProgramId: number,
  dispatch: AppDispatch,
) => {
  const addActionItem = (formData: ActionItemFormData, connectionId: number) => {
    dispatch(
      createMentorshipProgramActionItem({
        mentorshipProgramId,
        ...formData,
        connectionId,
      }),
    );
  };

  const updateActionItem = (id: number, formData: ActionItemFormData, connectionId: number) => {
    dispatch(
      updateMentorshipProgramActionItem({
        mentorshipProgramId,
        actionItemId: id,
        connectionId,
        ...formData,
      }),
    );
  };

  const deleteActionItem = (id: number, sessionId: number, connectionId: number) => {
    dispatch(
      deleteMentorshipProgramActionItem({
        mentorshipProgramId,
        actionItemId: id,
        sessionId,
        connectionId,
      }),
    );
  };

  return {
    onAdd: addActionItem,
    onUpdate: updateActionItem,
    onDelete: deleteActionItem,
  };
};

export const byActionItemId = (
  firstActionItem: MentoringProgramActionItem,
  secondActionItem: MentoringProgramActionItem,
) => secondActionItem.id - firstActionItem.id;
