import { AppDispatch } from 'redux/store';
import PusherChannelNames from 'shared/services/pusher-channel-names';
import { ActionItemOwner, MentoringProgramActionItem } from 'redux/schemas/models/mentoring-program-action-items';
import { addCreatedActionItem, searchAndRemoveDeletedActionItem, updateModifiedActionItem } from 'redux/actions/mentoring-program-action-items';
import { addCreatedSessionActionItem, updateModifiedSessionActionItem } from 'redux/actions/mentoring-program-sessions';
import { ActionItemEvent, Event, EventList, EventType } from './event-types';
import { configureChannel } from './utils';

const ownerTypes = {
  MentorshipProgramConnection: ActionItemOwner.CONNECTION,
  MentorshipProgramSession: ActionItemOwner.SESSION,
};

const eventToActionItem = (event: ActionItemEvent) => {
  const { actionItemId, description, assignedTo: assignedToUser, status, ownerId, ownerClass, completedAt, completedBy: completedByUser } = event as ActionItemEvent;
  const actionItem: MentoringProgramActionItem = {
    id: actionItemId,
    description,
    assignedTo: {
      id: assignedToUser.id,
      user: assignedToUser,
      role: null,
    },
    dueDate: null,
    status,
    owner: {
      id: ownerId,
      type: ownerTypes[ownerClass],
    },
    completedAt,
    completedBy: {
      id: completedByUser?.id,
      user: completedByUser,
      role: null,
    },
  };
  return actionItem;
};

const getEvents = (connectionId: number) => {
  const events: EventList = [
    {
      name: EventType.NEW_ACTION_ITEM,
      handler: (event: Event, dispatch: AppDispatch) => {
        const actionItem = eventToActionItem(event as ActionItemEvent);
        dispatch(addCreatedActionItem({ connectionId, actionItem }));
        if (actionItem.owner.type === ActionItemOwner.SESSION) {
          dispatch(addCreatedSessionActionItem({ sessionId: actionItem.owner.id, actionItem }));
        }
      },
    },
    {
      name: EventType.UPDATE_ACTION_ITEM,
      handler: (event: Event, dispatch: AppDispatch) => {
        const actionItem = eventToActionItem(event as ActionItemEvent);
        dispatch(updateModifiedActionItem({ connectionId, actionItem }));
        if (actionItem.owner.type === ActionItemOwner.SESSION) {
          dispatch(updateModifiedSessionActionItem({ sessionId: actionItem.owner.id, actionItem }));
        }
      },
    },
    {
      name: EventType.REMOVE_ACTION_ITEM,
      handler: (event: Event, dispatch: AppDispatch) => {
        const { actionItemId } = event as ActionItemEvent;
        dispatch(searchAndRemoveDeletedActionItem({ connectionId, actionItemId }));
      },
    },
  ];

  return events;
};

const setupActionItemsEventsListener = (
  connectionId: number,
  dispatch: AppDispatch,
) => {
  const channelName = PusherChannelNames.mentoringProgramActionItemsChannel(connectionId);
  return configureChannel(channelName, getEvents(connectionId), dispatch);
};

export default setupActionItemsEventsListener;
