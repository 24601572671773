import { css } from '@emotion/react';
import { almostWhite, shadedWhite } from 'athena/styles/colors';
import { MentorshipProgramConnection } from 'redux/schemas/models/mentorship-program-connections';
import { RowProps } from 'shared/components/nv-table';
import { ColumnID } from './types';
import ConnectionCell from './connection-cell';
import ConnectionTypeCell from './connection-type-cell';
import OptionsCell from './options-cell';
import { config } from '@config/pendo.config.json';

const cells = {
  [ColumnID.TITLE]: ConnectionCell,
  [ColumnID.CONNECTION_TYPE]: ConnectionTypeCell,
  [ColumnID.OPTIONS]: OptionsCell,
};

const styles = css`
  border-bottom: 1px solid ${shadedWhite};

  &:hover {
    background-color: ${almostWhite};

    .options-target {
      opacity: 1;
    }
  }

  &:last-of-type {
    border-bottom: 0;
  }
`;

const ConnectionRow = ({
  row,
  className,
  getCellStyle,
}: RowProps<MentorshipProgramConnection>) => {
  const { original } = row || {};
  const { id } = original || {};

  const dataQATag = config.pendo.athena.mentorshipProgram.connections.connectionRow;

  return row.original ? (
    <div css={styles} className={className} data-qa={`${dataQATag}_${id}`}>
      {row.getVisibleCells().map((cell, index) => {
        const CellComponent = cells[cell.column.id];

        return (
          <CellComponent key={cell.id} css={getCellStyle(index)} cell={cell} />
        );
      })}
    </div>
  ) : null;
};

export default ConnectionRow;
