/* @ngInject */
export default function TeamWorkspaceManager(
  $q,
  $state,
  $timeout,
  AlertMessages,
  CommentModel,
  CurrentPermissionsManager,
  CurrentUserManager,
  DiscussionsManager,
  MentionablesModel,
  PostModel,
  PusherManager,
  ReplyModel,
  TeamModel,
  TeamWorkspaceModel,
  TeamsResources,
  UserManager,
  FlyOutPanelManager,
  PageLevelManager,
  _,
  humps,
  moment,
) {
  let decoratedNewTeamMemberEventHandler;
  let decoratedRemoveTeamMemberEventHandler;
  let decoratedNewPostEventHandler;
  let decoratedUpdatedPostEventHandler;
  let decoratedDeletedPostEventHandler;
  let decoratedLikedPostEventHandler;
  let decoratedNewCommentEventHandler;
  let decoratedUpdatedCommentEventHandler;
  let decoratedLikedCommentEventHandler;
  let decoratedNewReplyEventHandler;
  let decoratedUpdatedReplyEventHandler;
  let decoratedLikedReplyEventHandler;
  let decoratedNewMeetingRsvpEventHandler;
  let decoratedUpdatedMeetingRsvpEventHandler;

  const manager = {
    currentTeam: null,
    requestingTeam: false,
    workspacePosts: [],
    loadingWorkspacePosts: true,
    workspace: null,
    workspacePostFilter: null,
    workspacePostFilterCounts: {},
    pageNumber: 1,
    hasMorePostsToLoad: true,
    context: '',
    isTeamWorkspaceFull: false,
    hasShownAlert: {},

    requestTeam,
    requestTeamWithAllMembers,
    resetTeam,

    initializeTeamMentionables,
    setContext,

    setPostFilter,
    getDisplayNameForPostFilter,
    countForFilterType,

    fetchAllWorkspacePosts,
    fetchSingleWorkspacePost,
    loadMorePosts,
    getWorkspaceFilterCounts,
    fetchWorkspaceContributions,

    createTextPost,
    updatePost,

    afterPostCreate,
    afterPostDelete,

    createMeetingPost,
    updateMeetingPost,

    createWorkspaceDocumentPost,
    updateWorkspaceDocumentPost,
    createWorkspaceDocumentPostFromGoogleDrive,
    updateWorkspaceDocumentPostFromGoogleDrive,
    hasGoogleDriveAvailable,

    createMeetingRsvp,
    updateMeetingRsvp,
    updateMeetingData,

    createSubmissionPost,
    updateSubmissionPost,
    isSubmissionPostShareable,

    findTeamMemberById,
    meetingAttendeesToUserObjects,
    setupPusherEvents,
    unsubscribePusherEvents,
    meetingNotificationHandler,
  };

  function requestTeam(catalogId, teamId) {
    if (manager.currentTeam?.id === teamId) {
      if (MentionablesModel.getLastUpdatedSource() !== 'team') {
        initializeTeamMentionables(catalogId);
      }
      return $q.when(manager.currentTeam);
    }
    manager.requestingTeam = true;

    return TeamModel.get(catalogId, teamId, true)
      .then((team) => {
        manager.requestingTeam = false;
        manager.currentTeam = team;

        manager.workspace = new TeamWorkspaceModel({ catalogId, teamId });

        initializeTeamMentionables(catalogId);

        return team;
      });
  }

  function resetTeam() {
    manager.currentTeam = null;
    manager.workspacePosts = [];
    manager.workspace = null;
    manager.workspacePostFilter = null;
    manager.workspacePostFilterCounts = {};
    manager.pageNumber = 1;
    manager.hasMorePostsToLoad = true;
    manager.context = '';
  }

  function requestTeamWithAllMembers(catalogId, teamId) {
    const deferred = $q.defer();

    requestTeam(catalogId, teamId)
      .then((team) => {
        if ((team.currentAccountTeamMember?.approved) || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant()) {
          manager.currentTeam.requestMembers(catalogId)
            .then((teamMembers) => {
              deferred.resolve(team);
            }, () => {
              deferred.reject();
            });
        } else {
          deferred.reject();
        }
      }, () => deferred.reject());

    return deferred.promise;
  }

  function initializeTeamMentionables(catalogId) {
    const everyoneOption = {
      id: 0,
      firstNameKey: 'TEAMS.WORKSPACE.EVERYONE',
      lastName: '',
    };

    manager.currentTeam.requestMembers(catalogId)
      .then((teamMembers) => {
        const concatenated = _.map(teamMembers, (teamMember) => teamMember.user);
        concatenated.unshift(everyoneOption);

        MentionablesModel.setMentionableUsers(concatenated, 'team');
      });
  }

  function setContext(context) {
    manager.context = context;
  }

  function fetchAllWorkspacePosts(params) {
    manager.loadingWorkspacePosts = true;


    manager.workspace.getWorkspacePosts(params || {
      filter: manager.workspacePostFilter,
      pageSize: calculatePageSize(),
      page: manager.pageNumber,
    })
      .then(
        (posts) => {
          manager.loadingWorkspacePosts = false;

          if (posts.length < calculatePageSize()) {
            manager.hasMorePostsToLoad = false;
          }
        },
        (error) => {
          if (error && (error.status === 404 || error.status === 403)) {
            // redirect to workspace home
            $state.go('team-workspace');
          }
        },
      );

    function calculatePageSize() {
      return manager.pageNumber > 1 ? 10 : 20;
    }
  }

  function fetchSingleWorkspacePost(postId, isDirectLink) {
    manager.loadingWorkspacePosts = true;

    manager.workspace.getSingleWorkspacePost(postId, isDirectLink).then(
      () => {
        manager.loadingWorkspacePosts = false;
      },
      (error) => {
        if (error && (error.status === 404 || error.status === 403)) {
          // redirect to workspace home
          $state.go('team-workspace');
        }
      },
    );
  }

  function loadMorePosts($inview) {
    if (manager.workspace?.workspacePosts?.length && !manager.loadingWorkspacePosts && manager.hasMorePostsToLoad) {
      if (manager.pageNumber === 1) {
        // we have to do this hacky pageNumber recalculation because we change from pageSize 20 to pageSize 10 after the first set of results
        manager.pageNumber = 3;
      } else {
        manager.pageNumber += 1;
      }
      manager.fetchAllWorkspacePosts();
    }
  }

  function getWorkspaceFilterCounts() {
    return manager.workspace.getWorkspaceFilterCounts().then((response) => {
      const countsFromBackend = response.result;
      manager.workspacePostFilterCounts = response.result;
    });
  }

  function fetchWorkspaceContributions(memberId) {
    return manager.workspace.getWorkspaceContributions(memberId).then((result) => {
      manager.workspaceContributions = [];
      manager.numWorkspaceContributions = result.contributionCount;
      _.each(result.contributions, (discussion) => {
        manager.workspaceContributions.push(new PostModel(_.extend(discussion, { catalogId: manager.workspace.catalogId })));

        _.each(discussion.posts, (post) => {
          _.last(manager.workspaceContributions).comments.push(
            new CommentModel(_.extend(post, { catalogId: manager.workspace.catalogId, owner: discussion, replyCount: post.commentsCount })),
          );

          _.each(post.comments, (comment) => {
            _.last(_.last(manager.workspaceContributions).comments).replies.push(
              new ReplyModel(_.extend(comment, { catalogId: manager.workspace.catalogId, owner: post, replyCount: post.commentsCount })),
            );
          });
        });
      });
    });
  }

  function createTextPost(data) {
    return manager.workspace.createTextPost(data).then((createdPost) => {
      afterPostCreate(createdPost);

      return createdPost;
    });
  }

  function updatePost(postData) {
    return manager.workspace.updatePost(postData);
  }

  function createMeetingPost(data) {
    return manager.workspace.createMeetingPost(data).then((createdPost) => {
      afterPostCreate(createdPost);

      return createdPost;
    });
  }

  function updateMeetingPost(data) {
    return manager.workspace.updateMeetingPost(data);
  }


  function createWorkspaceDocumentPost(file) {
    return manager.workspace.createWorkspaceDocumentPost(file).then((createdPost) => {
      afterPostCreate(createdPost);
      return createdPost;
    });
  }

  function createWorkspaceDocumentPostFromGoogleDrive(file) {
    return manager.workspace.createWorkspaceDocumentPostFromGoogleDrive(file).then((createdPost) => {
      afterPostCreate(createdPost);
      return createdPost;
    });
  }

  function updateWorkspaceDocumentPostFromGoogleDrive(file, externalDocumentId, postId) {
    return manager.workspace.updateWorkspaceDocumentPostFromGoogleDrive(file, externalDocumentId, postId);
  }

  function updateWorkspaceDocumentPost(file) {
    return manager.workspace.updateWorkspaceDocumentPost(file);
  }

  function hasGoogleDriveAvailable() {
    const { documentSharingProvider } = CurrentUserManager.getCurrentCourse()?.institution;
    return documentSharingProvider?.toLowerCase() === 'googledrive';
  }

  function createMeetingRsvp(rsvp) {
    return manager.workspace.createMeetingRsvp(rsvp);
  }

  function updateMeetingRsvp(rsvpData) {
    return manager.workspace.updateMeetingRsvp(rsvpData);
  }

  function updateMeetingData(event) {
    manager.workspace.updateMeetingData(event);
  }

  function createSubmissionPost(submissionPostData) {
    return manager.workspace.createSubmissionPost(submissionPostData).then((createdPost) => {
      afterPostCreate(createdPost);
    });
  }

  function updateSubmissionPost(submissionPostData) {
    return manager.workspace.updatePost(submissionPostData);
  }

  function isSubmissionPostShareable(submission) {
    // team submissions are always shareable when this team is the current team
    return (submission.exercise.isTeamSubmission && submission.ownerId === manager.currentTeam.id)
        || (submission.exercise.submissionsReleased && submission.privacySetting !== 'shared_with_instructor');
  }

  function afterPostCreate(createdPost) {
    incrementPostFilterCount(createdPost.metaContent ? createdPost.metaContent.type : null);

    if (manager.workspacePostFilter) {
      if (!createdPost.metaContent || createdPost.metaContent.type !== manager.workspacePostFilter) {
        // go to 'all posts', aka unset the filter and refetch posts
        manager.workspacePostFilter = null;
        manager.fetchAllWorkspacePosts();
      }
    }
  }

  function afterPostDelete(deletedPost) {
    decrementPostFilterCount(deletedPost.metaContent ? deletedPost.metaContent.type : null);

    if (manager.context === 'workspaceDirectLink') {
      // if in direct link mode, redirect
      $state.go('team-workspace');
    } else if (manager.workspacePostFilter) {
      if ((deletedPost.metaContent?.type === manager.workspacePostFilter
            && manager.countForFilterType(manager.workspacePostFilter) === 0)) {
        // go to 'all posts', aka unset the filter and refetch posts
        manager.workspacePostFilter = null;
        manager.fetchAllWorkspacePosts();
      }
    }
  }

  function setPostFilter(filterName) {
    manager.workspacePostFilter = filterName;
    manager.pageNumber = 1;
    manager.fetchAllWorkspacePosts();
  }

  function getDisplayNameForPostFilter(filterName) {
    if (filterName === 'meeting') {
      return 'TEAMS.WORKSPACE.FILTER_OPTIONS.MEETINGS';
    }

    if (filterName === 'report') {
      return 'TEAMS.WORKSPACE.FILTER_OPTIONS.SHARED_WORK';
    }

    if (filterName === 'attachment') {
      return 'TEAMS.WORKSPACE.FILTER_OPTIONS.UPLOADED_DOCUMENTS';
    }

    if (filterName === 'teamDiscussion') {
      return 'TEAMS.WORKSPACE.FILTER_OPTIONS.TEAM_DISCUSSIONS';
    }

    // default: all posts
    return 'TEAMS.WORKSPACE.FILTER_OPTIONS.ALL_POSTS';
  }

  function countForFilterType(filterName) {
    if (!filterName) {
      return manager.workspacePostFilterCounts.total;
    }

    return manager.workspacePostFilterCounts[filterName];
  }

  function incrementPostFilterCount(filterName) {
    if (filterName) {
      filterName = filterName.toLowerCase();

      // special case: externaldoc
      if (filterName === 'externaldocument') {
        manager.workspacePostFilterCounts.attachment += 1;
      } else {
        manager.workspacePostFilterCounts[filterName] += 1;
      }
    }

    manager.workspacePostFilterCounts.total += 1;
  }

  function decrementPostFilterCount(filterName) {
    if (filterName) {
      filterName = filterName.toLowerCase();

      // special case
      if (filterName === 'externaldocument') {
        manager.workspacePostFilterCounts.attachment -= 1;
      } else {
        manager.workspacePostFilterCounts[filterName] -= 1;
      }
    }

    manager.workspacePostFilterCounts.total -= 1;
  }

  function findTeamMemberById(teamMemberId, userId) {
    const teamMember = _.findWhere(manager.currentTeam.teamMembers, { id: teamMemberId });

    // if user has not left team, return
    if (teamMember) {
      return teamMember.user;
    }
    return null;
  }

  function meetingAttendeesToUserObjects(attendanceList) {
    return _.map(attendanceList, (attendee) => {
      const teamMember = findTeamMemberById(attendee.teamMemberId, attendee.userId);

      if (!teamMember) {
        // if user has left team, fetch by user id
        const pastTeamMember = _.findWhere(manager.currentTeam.pastMembers, { id: attendee.userId });

        if (pastTeamMember) {
          attendee.user = pastTeamMember;
        } else {
          if (attendee.user) {
            return attendee;
          }
          UserManager.getUser(attendee.userId).then((user) => {
            if (!manager.currentTeam.pastMembers || !manager.currentTeam.pastMembers.length) {
              manager.currentTeam.pastMembers = [];
            }

            if (!_.findWhere(manager.currentTeam.pastMembers, { id: user.id })) {
              manager.currentTeam.pastMembers.push(user);
            }

            attendee.user = user;
          });
        }
      } else {
        attendee.user = teamMember;
      }

      return attendee;
    });
  }

  /* Pusher */

  function newTeamMemberEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    manager.currentTeam?.addMember(pusherData.teamMemberId);
  }

  function removeTeamMemberEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    const { currentAccountTeamMember } = manager.currentTeam || {};
    const { currentTeam } = manager;

    manager.currentTeam?.removeMember(pusherData.teamMemberId);

    if (currentAccountTeamMember?.id === pusherData.teamMemberId) {
      unsubscribePusherEvents();
      manager.currentTeam = null;
      if ($state.includes('team-workspace')) {
        // In team workspace state. Close the panel go back to previous state
        AlertMessages.error('', 'TEAMS.WORKSPACE.ACCESS_DENIED', {}, {
          teamAlias: currentTeam.teamSet.isTeam
            ? CurrentUserManager.getCurrentCourse().teamName.downcasedSingularized
            : CurrentUserManager.getCurrentCourse().groupName.downcasedSingularized,
        });
        FlyOutPanelManager.closeFlyoutPanelCallback();
      } else {
        // State is not team-workspace. Close the flyout and update pageLevelManager close stack
        FlyOutPanelManager.hidePanel();

        const teamWorkspaceStateName = 'team-workspace';

        if (PageLevelManager.lastParentState.state.$$state().includes[teamWorkspaceStateName]) {
          PageLevelManager.lastParentState = {
            state: FlyOutPanelManager.getParentState(),
            params: FlyOutPanelManager.getParentParam(),
          };
        }
      }
      // $state.go('team-profile-page', { catalogId: currentTeam.catalogId, teamId: currentTeam.id, forceLoad: true });
    }
  }

  function newPostEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    manager.workspace.getSingleWorkspacePost(pusherData.topicId).then((newPost) => {
      if (newPost.user.id !== CurrentUserManager.user.id && manager.context !== 'workspaceDirectLink') {
        manager.workspace.prependNewPost(newPost);
        incrementPostFilterCount(newPost.metaContent ? newPost.metaContent.type : null);
      }

      if (newPost.metaContent?.type === 'meeting') {
        manager.currentTeam.addMeeting(newPost.metaContent);
      }
    });

    updateTeamMember(pusherData.userId);
  }

  function updatedPostEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    manager.workspace.getSingleWorkspacePost(pusherData.topicId).then((updatedPost) => {
      if (updatedPost.user.id !== CurrentUserManager.user.id) {
        manager.workspace.updatePostInPlace(updatedPost);
      }
    });

    updateTeamMember(pusherData.userId);
  }

  function deletedPostEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    const deletedPost = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });

    if (deletedPost?.user.id !== CurrentUserManager.user.id) {
      manager.workspace.deletePostInPlace(deletedPost);
      manager.afterPostDelete(deletedPost);
    } else if (!deletedPost && pusherData.userId !== CurrentUserManager.user.id) {
      decrementPostFilterCount(pusherData.metaContentType || null);
    }


    if (pusherData.metaContentType === 'Meeting') {
      manager.currentTeam.removeMeeting(pusherData.metaContentId);
    }

    updateTeamMember(pusherData.userId);
  }

  function likedPostEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    const post = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });

    if (post) {
      post.votesCount = pusherData.numLikes;
    }
  }

  function fetchComment(commentId) {
    return CommentModel.getSingleComment({
      catalogId: manager.workspace.catalogId,
      id: commentId,
    });
  }

  function commentEventHandler(pusherData, eventType) {
    pusherData = humps.camelizeKeys(pusherData);

    const workspacePost = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });

    // If the user is viewing a direct link of the post and the pushed event
    // is regarding another workspace post, no need to fetch the comment.
    // Need to fetch comment only if there is a workspacePost.
    if (!workspacePost) {
      return;
    }

    if (eventType === 'new') {
      newCommentHandler();
      updateTeamMember(pusherData.userId);
    } else if (eventType === 'update') {
      updatedCommentHandler();
    } else {
      $.noop();
    }


    function newCommentHandler() {
      if (manager.context === 'workspaceDirectLink') {
        if (workspacePost.additionalCommentsAfterCount > 0) {
          // assume it's in the later portion, don't display
          workspacePost.additionalCommentsAfterCount += 1;
        } else {
          fetchComment(pusherData.postId).then(addComment);
        }
      } else if (workspacePost && !_.findWhere(workspacePost.comments, { id: pusherData.commentId })) {
        fetchComment(pusherData.postId).then(addComment);
      }
    }

    function deletedCommentHandler() {
      $.noop(); // unimplemented on backend
    }

    function updatedCommentHandler() {
      fetchComment(pusherData.postId, workspacePost).then((response) => {
        const updatedComment = response.result;
        _.extend(updatedComment, { owner: new PostModel(updatedComment.owner) });

        if (updatedComment.user.id !== CurrentUserManager.user.id) {
          const commentToUpdate = _.findWhere(workspacePost.comments, { id: pusherData.postId });

          _.extend(commentToUpdate, updatedComment);
        }
      });
    }


    function addComment(response) {
      const fetchedComment = response.result;
      if (fetchedComment.user.id !== CurrentUserManager.user.id) {
        DiscussionsManager.setCommentData(fetchedComment, workspacePost, false, true);
      }
    }
  }

  function newCommentEventHandler(data) {
    commentEventHandler(data, 'new');
  }

  function updatedCommentEventHandler(data) {
    commentEventHandler(data, 'update');
  }

  function likedCommentEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    const post = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });
    const comment = post ? _.findWhere(post.comments, { id: pusherData.postId }) : null;

    if (comment) {
      comment.votesCount = pusherData.numLikes;
    }
  }

  function replyEventHandler(pusherData, eventType) {
    pusherData = humps.camelizeKeys(pusherData);

    const workspacePost = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });
    let comment = workspacePost ? _.findWhere(workspacePost.comments, { id: pusherData.postId }) : null;
    const reply = comment ? _.findWhere(comment.replies, { id: pusherData.commentId }) : null;

    if (eventType === 'new') {
      if (comment && !reply) {
        fetchReply().then((response) => {
          DiscussionsManager.setReplyData(response.result, comment, false, true);
        });
      } else if (!comment && workspacePost.commentsFetched) {
        fetchComment().then((fetchedComment) => {
          comment = fetchedComment;

          fetchReply().then((response) => {
            DiscussionsManager.setReplyData(response.result, comment, false, true);
          });
        });
      // just increment the count
      } else if (workspacePost) {
        workspacePost.numCommentsAndReplies += 1;
      }

      // Update the team members list with the commenter's info if this user is already in the team
      updateTeamMember(pusherData.userId);
    } else if (eventType === 'update') {
      if (comment && reply) {
        fetchReply().then((response) => {
          const updatedReply = response.result;

          _.extend(reply, updatedReply);
        });
      }
    }

    function fetchReply() {
      return ReplyModel.getSingleReply({
        catalogId: manager.workspace.catalogId,
        id: pusherData.commentId,
      });
    }
  }

  function newReplyEventHandler(data) {
    replyEventHandler(data, 'new');
  }

  function updatedReplyEventHandler(data) {
    replyEventHandler(data, 'update');
  }

  function likedReplyEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    const post = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });
    const comment = post ? _.findWhere(post.comments, { id: pusherData.postId }) : null;
    const reply = comment ? _.findWhere(comment.replies, { id: pusherData.commentId }) : null;

    if (reply) {
      reply.votesCount = pusherData.numLikes;
    }
  }

  function newMeetingRsvpEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    const meetingPost = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });

    if (meetingPost?.metaContent) {
      meetingPost.addMeetingRsvp({
        id: pusherData.meetingAttendanceId,
        rsvp: pusherData.rsvp,
      },
      pusherData.teamMemberId);
    }
  }

  function updatedMeetingRsvpEventHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    const meetingPost = _.findWhere(manager.workspace.workspacePosts, { id: pusherData.topicId });

    if (meetingPost?.metaContent) {
      meetingPost.updateMeetingRsvp({
        id: pusherData.meetingAttendanceId,
        rsvp: pusherData.rsvp,
      },
      pusherData.teamMemberId);
    }
  }

  function setupPusherEvents() {
    const teamPusherChannel = PusherManager.setupChannel(manager.currentTeam.pusherChannelName);

    decoratedNewTeamMemberEventHandler = teamPusherChannel.bind('new_team_member', newTeamMemberEventHandler);
    decoratedRemoveTeamMemberEventHandler = teamPusherChannel.bind('remove_team_member', removeTeamMemberEventHandler);


    /* frontend post */
    decoratedNewPostEventHandler = teamPusherChannel.bind('new_topic', newPostEventHandler);
    decoratedUpdatedPostEventHandler = teamPusherChannel.bind('update_topic', updatedPostEventHandler);
    decoratedDeletedPostEventHandler = teamPusherChannel.bind('delete_topic', deletedPostEventHandler);
    decoratedLikedPostEventHandler = teamPusherChannel.bind('topic_vote', likedPostEventHandler);

    /* frontend comment */
    decoratedNewCommentEventHandler = teamPusherChannel.bind('new_post', newCommentEventHandler);
    decoratedUpdatedCommentEventHandler = teamPusherChannel.bind('update_post', updatedCommentEventHandler);
    decoratedLikedCommentEventHandler = teamPusherChannel.bind('post_vote', likedCommentEventHandler);

    /* frontend reply */
    decoratedNewReplyEventHandler = teamPusherChannel.bind('new_comment', newReplyEventHandler);
    decoratedUpdatedReplyEventHandler = teamPusherChannel.bind('update_comment', updatedReplyEventHandler);
    decoratedLikedReplyEventHandler = teamPusherChannel.bind('comment_vote', likedReplyEventHandler);

    // teamPusherChannel.bind('new_report', newPostEventHandler);
    // teamPusherChannel.bind('update_report', updatedPostEventHandler);
    // teamPusherChannel.bind('delete_report', deletedPostEventHandler);


    // teamPusherChannel.bind('new_meeting', newPostEventHandler);
    // teamPusherChannel.bind('update_meeting', updatedPostEventHandler);
    // teamPusherChannel.bind('delete_meeting', deletedPostEventHandler);

    // teamPusherChannel.bind('new_attachment', newPostEventHandler);
    // teamPusherChannel.bind('update_attachment', updatedPostEventHandler);
    // teamPusherChannel.bind('delete_attachment', deletedPostEventHandler);

    decoratedNewMeetingRsvpEventHandler = teamPusherChannel.bind('new_meetingattendance', newMeetingRsvpEventHandler);
    decoratedUpdatedMeetingRsvpEventHandler = teamPusherChannel.bind('updated_meetingattendance', updatedMeetingRsvpEventHandler);
  }

  function unsubscribePusherEvents() {
    if (manager.currentTeam) {
      const teamPusherChannel = PusherManager.channelsHash[manager.currentTeam.pusherChannelName];

      if (teamPusherChannel) {
        teamPusherChannel.unbind('new_team_member', decoratedNewTeamMemberEventHandler);
        teamPusherChannel.unbind('remove_team_member', decoratedRemoveTeamMemberEventHandler);

        teamPusherChannel.unbind('new_topic', decoratedNewPostEventHandler);
        teamPusherChannel.unbind('update_topic', decoratedUpdatedPostEventHandler);
        teamPusherChannel.unbind('delete_topic', decoratedDeletedPostEventHandler);
        teamPusherChannel.unbind('topic_vote', decoratedLikedPostEventHandler);

        /* frontend comment */
        teamPusherChannel.unbind('new_post', decoratedNewCommentEventHandler);
        teamPusherChannel.unbind('update_post', decoratedUpdatedCommentEventHandler);
        teamPusherChannel.unbind('post_vote', decoratedLikedCommentEventHandler);

        /* frontend reply */
        teamPusherChannel.unbind('new_comment', decoratedNewReplyEventHandler);
        teamPusherChannel.unbind('update_comment', decoratedUpdatedReplyEventHandler);
        teamPusherChannel.unbind('comment_vote', decoratedLikedReplyEventHandler);

        // teamPusherChannel.unbind('new_report', newPostEventHandler);
        // teamPusherChannel.unbind('update_report', updatedPostEventHandler);
        // teamPusherChannel.unbind('delete_report', deletedPostEventHandler);


        // teamPusherChannel.unbind('new_meeting', newPostEventHandler);
        // teamPusherChannel.unbind('update_meeting', updatedPostEventHandler);
        // teamPusherChannel.unbind('delete_meeting', deletedPostEventHandler);

        // teamPusherChannel.unbind('new_attachment', newPostEventHandler);
        // teamPusherChannel.unbind('update_attachment', updatedPostEventHandler);
        // teamPusherChannel.unbind('delete_attachment', deletedPostEventHandler);

        teamPusherChannel.unbind('new_meetingattendance', decoratedNewMeetingRsvpEventHandler);
        teamPusherChannel.unbind('updated_meetingattendance', decoratedUpdatedMeetingRsvpEventHandler);
      }
    }
  }

  /** Updates team member information from the backend for the a given user id
   * Only updates if this user is actually in the team */
  function updateTeamMember(userId) {
    if (!findTeamMemberById(userId)) {
      return;
    }

    if (manager.currentTeam.currentAccountTeamMember) {
      manager.currentTeam.updateMemberByUserId(userId);
    }
  }

  function meetingNotificationHandler(meetingData) {
    const {
      joinUrl, eventName, endTime, meetingId, startTime,
    } = meetingData;

    const minutesLeft = moment(startTime).diff(moment(), 'minutes');
    const duration = moment(endTime) - moment();
    const button = {
      text: 'TEAMS.WORKSPACE.MEETING.MS_TEAMS.VIEW.JOIN',
      href: joinUrl,
      target: '_blank',
    };
    const showAlert = () => {
      manager.hasShownAlert[meetingId] = true;

      AlertMessages.liveEvent(
        '',
        'TEAMS.WORKSPACE.MEETING.MS_TEAMS.HAS_STARTED',
        {},
        { eventName },
        duration,
        button,
        () => {},
        `liveEvent-${meetingId}`,
      );
    };
    if (!manager.hasShownAlert[meetingId]) {
      if (minutesLeft <= 1) {
        showAlert();
      } else {
        setTimeout(() => {
          showAlert();
        }, (minutesLeft - 1) * 60 * 1000);
      }
    }
    if (manager.workspace) {
      if (!manager.workspace.hasMeetingStarted) {
        manager.workspace.hasMeetingStarted = {};
      }
      manager.workspace.hasMeetingStarted[meetingId] = true;
    }
  }
  return manager;
}
