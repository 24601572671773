import {
  ActionItemStatus,
  MentoringProgramActionItem,
} from 'redux/schemas/models/mentoring-program-action-items';

export const CHECKBOX_SIZE = 24;

export const DEFAULT_ACTION_ITEM: MentoringProgramActionItem = {
  id: 0,
  description: '',
  assignedTo: null,
  dueDate: null,
  owner: null,
  status: ActionItemStatus.INCOMPLETE,
  completedAt: null,
  completedBy: null,
};
