/* eslint-disable react/require-default-props */
/** @jsx */
import React from 'react';
import uuid from 'react-uuid';
import { css, jsx } from '@emotion/react';
import { useSelector } from 'react-redux';

import { Course } from 'redux/schemas/models/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { gray6, primary } from 'styles/global_defaults/colors';
import ClickableContainer from 'components/clickable-container';
import {
  halfSpacing,
  largeSpacing,
  tripleSpacing,
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

type Props = {
  catalogId: string,
  className?: string,
  onClick?: (course: Course) => void,
};

const DEFAULT_THUMBNAIL = '/assets/brand/temp.png';

const CourseCard = (props: Props) => {
  const {
    onClick,
    catalogId,
    className,
  } = props;

  const mountedRef = React.useRef(false);
  const [hasError, setHasError] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const course = useSelector(state => state.models.courses[catalogId]);
  const [imageElementKey, setImageElementKey] = React.useState(uuid());
  const currentInstitution = useSelector((state) => getCurrentInstitution(state));

  const isDefaultThumbnail = course.thumbnail === DEFAULT_THUMBNAIL;
  const courseThumbnail = isDefaultThumbnail ? '' : course.thumbnail;
  const colorToUse = course.headerColor ?? currentInstitution.brandColor;

  React.useEffect(() => {
    if (mountedRef.current) {
      setHasError(false);
      setImageLoaded(false);
      setImageElementKey(uuid());
    } else {
      mountedRef.current = true;
    }
  }, [courseThumbnail]);

  const styles = css`
    display: block;
    cursor: pointer;
    position: relative;
    box-sizing: content-box;
    border: 1px solid ${gray6};
    box-shadow: 1px 2px 2px 0 rgba(0,0,0,0.1);
    height: ${tripleSpacing + quarterSpacing}px;

    &:hover {
      border-color: ${primary};
    }

    .top-bar {
      height: ${quarterSpacing}px;
      background-color: ${colorToUse};
    }

    .card-content {
      display: flex;

      .course-picture {
        flex-shrink: 0;
        position: relative;
        width: ${tripleSpacing}px;
        height: ${tripleSpacing}px;

        .course-picture-placeholder {
          width: 50px;
          height: 50px;
          position: absolute;
          top: ${quarterSpacing}px;
          left: ${quarterSpacing}px;
          background-color: ${colorToUse};
        }

        .course-picture-image {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
      }

      .course-info {
        flex: 1;
        display: flex;
        overflow: hidden;
        position: relative;
        align-items: center;
        height: ${tripleSpacing}px;
        padding-left: ${halfSpacing}px;
        padding-right: ${halfSpacing}px;
        padding-top: ${quarterSpacing}px;
        padding-bottom: ${quarterSpacing}px;

        .name-container {
          overflow: hidden;
          line-height: ${(50) / 3}px;
          max-height: ${doubleSpacing + halfSpacing}px;
        }

        .fade {
          left: 0;
          bottom: 0;
          width: 100%;
          position: absolute;
          padding-top: ${largeSpacing}px;
          background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), white);
        }
      }
    }

    .badge {
      display: flex;
      position: absolute;
      text-align: center;
      align-items: center;
      justify-content: center;
      top: ${-quarterSpacing}px;
      height: ${standardSpacing};
      right: ${-quarterSpacing}px;
      min-width: ${standardSpacing}px;
      border-radius: ${halfSpacing}px;
      background-color: ${colorToUse};
    }
  `;

  const handleClick = () => {
    onClick(course);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
    setHasError(false);
  };

  const handleImageError = () => {
    setImageLoaded(false);
    setHasError(true);
  };

  return (
    <ClickableContainer
      css={styles}
      onClick={handleClick}
      className={className}
    >
      <div className='top-bar' />
      <div className='card-content'>
        <div className='course-picture'>
          {!imageLoaded && (
            <div className='course-picture-placeholder' />
          )}
          {courseThumbnail && !hasError && (
            <img
              alt=''
              draggable={false}
              key={imageElementKey}
              src={courseThumbnail}
              onLoad={handleImageLoad}
              onError={handleImageError}
              className='course-picture-image'
            />
          )}
        </div>
        <div className='course-info'>
          <div className='text-small name-container'>
            {course.name}
          </div>
          <div className='fade' />
        </div>
      </div>
    </ClickableContainer>
  );
};

export default CourseCard;
