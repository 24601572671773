import React from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { camelCase } from 'lodash';

import { CommunicationAction, CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';
import { Communication, CommunicationType, ViewMode } from 'redux/schemas/models/course-communication';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { getCurrentCourseWelcomeEmails } from 'redux/selectors/course-communications';
import { getCurrentCourse } from 'redux/selectors/course';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { deleteCommunication, disableWelcomeEmail } from 'redux/actions/course-communications';
import { addAlertMessage } from 'redux/actions/alert-messages';

import { gray4, primary, success, yellow } from 'styles/global_defaults/colors';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

import ClickableContainer from 'components/clickable-container';
import { WelcomeEmailItem, WelcomeEmailItemProps } from './item';
import { AddWelcomeEmail } from './add-welcome-email';
import { config } from '../../../../../config/pendo.config.json';

type RoleKeys = 'everyone' | 'learnersAndAdmins' | 'mentors';
const allRoleBadges: WelcomeEmailItemProps['roles']['badges'] = [
  { initial: 'A', color: primary },
  { initial: 'L', color: success },
  { initial: 'M', color: yellow },
];

// Helper function to complement the welcome email data
function getWelcomeEmailsData(
  regularWelcomeEmailEnabled: boolean,
  welcomeEmails: Array<Communication>,
) {
  const regularWelcomeEmail = welcomeEmails?.find(we => we.communicationType === CommunicationType.COURSE_WELCOME_EMAIL);

  const mentorWelcomeEmail = welcomeEmails?.find(we => we.communicationType === CommunicationType.COURSE_MENTOR_WELCOME_EMAIL);

  const roleDefinitions: Record<RoleKeys, WelcomeEmailItemProps['roles']> = {
    everyone: {
      label: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.RECIPIENT.ALL(),
      badges: [...allRoleBadges],
    },
    learnersAndAdmins: {
      label: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.RECIPIENT.LEARNERS_COURSE_ADMINS(),
      badges: [allRoleBadges[0], allRoleBadges[1]],
    },
    mentors: {
      label: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.RECIPIENT.ALL_MENTORS(),
      badges: [allRoleBadges[2]],
    },
  };

  let regularWelcomeEmailData: Omit<WelcomeEmailItemProps, 'openModal'> = null;
  if (regularWelcomeEmail) {
    regularWelcomeEmailData = {
      id: regularWelcomeEmail.id,
      courseId: regularWelcomeEmail.courseId,
      communicationType: CommunicationType.COURSE_WELCOME_EMAIL,
      subject: regularWelcomeEmail?.communication.subject,
      body: regularWelcomeEmail?.communication.body,
      trigger: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.TRIGGER(),
      tooltipText: t.COURSE_COMMUNICATIONS.TOOLTIP.ITEM(),
      disabled: !regularWelcomeEmailEnabled,
      roles: !mentorWelcomeEmail ? roleDefinitions.everyone : roleDefinitions.learnersAndAdmins,
    };
  }

  let mentorWelcomeEmailData: Omit<WelcomeEmailItemProps, 'openModal'> = null;
  if (mentorWelcomeEmail) {
    mentorWelcomeEmailData = {
      id: mentorWelcomeEmail?.id,
      courseId: mentorWelcomeEmail?.courseId,
      communicationType: CommunicationType.COURSE_MENTOR_WELCOME_EMAIL,
      subject: mentorWelcomeEmail?.communication.subject,
      body: mentorWelcomeEmail?.communication.body,
      trigger: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.TRIGGER(),
      tooltipText: t.COURSE_COMMUNICATIONS.TOOLTIP.ITEM(),
      disabled: false,
      roles: roleDefinitions.mentors,
    };
  }

  return {
    regularWelcomeEmailData,
    mentorWelcomeEmailData,
  };
}

// Helper function to get the common dropdown items for the welcome email data
function getCommonDropdownItems(
  communicationType: CommunicationType,
  openModal: WelcomeEmailItemProps['openModal'],
) {
  const commonDropdownItems: WelcomeEmailItemProps['dropdownItems'] = [
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.EDIT(),
      callback: () => openModal(TriggerType.EDIT, ViewMode.FORM_VIEW, communicationType),
      dataQa: config.pendo.courseCommunication[camelCase(communicationType)].edit,
    },
    {
      type: 'text',
      text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.VIEW(),
      callback: () => openModal(TriggerType.VIEW, ViewMode.EMAIL_PREVIEW, communicationType),
      dataQa: config.pendo.courseCommunication[camelCase(communicationType)].view,
    },
    { type: 'divider' },
  ];
  return commonDropdownItems;
}

type WelcomeEmailContainerProps = {
  catalogId: string;
  enableCourseWelcomeEmail: any;
  getWelcomeEmail: any;
  alertMessage: any;
};

const enableEmailStyles = css`
  height: ${tripleSpacing}px;
  border-top: 1px solid ${gray4};
  border-bottom: 1px solid ${gray4};
`;
const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${standardSpacing}px;
`;

// Course or Journey could have defined multiple welcome emails (Regular and Mentor Welcome Emails)
export const WelcomeEmailContainer: React.FC<WelcomeEmailContainerProps> = (
  {
    catalogId,
    enableCourseWelcomeEmail,
    getWelcomeEmail,
    alertMessage,
  },
) => {
  const { injectServices } = React.useContext(AngularContext);
  const [CurrentCourseManager] = injectServices(['CurrentCourseManager']);
  const dispatch = useAppDispatch();
  const { dispatch: communicationDispatch } = React.useContext(CommunicationDispatch);
  const course = useSelector(getCurrentCourse);
  const [isRegularEmailLoading, setRegularEmailLoading] = React.useState(false);

  // Getting the welcome emails data
  const regularWelcomeEmailEnabled = course.welcomeEmailEnabled;
  const welcomeEmails: Array<Communication> = useSelector(getCurrentCourseWelcomeEmails);
  const { regularWelcomeEmailData, mentorWelcomeEmailData } = getWelcomeEmailsData(regularWelcomeEmailEnabled, welcomeEmails);

  // Set the dropdown items for each welcome email
  const openModal = React.useCallback(
    (triggerType: TriggerType, viewMode: ViewMode, communicationType: CommunicationType) => {
      communicationDispatch({
        type: CommunicationAction.SHOW_MODAL,
        triggerType,
        communicationType,
        viewMode,
      });
    },
    [communicationDispatch],
  );

  // Enable the regular welcome email, fetch the data and show the modal
  const fireEnableWelcomeEmail = async () => {
    const response = await enableCourseWelcomeEmail({
      courseId: course.id,
      catalogId,
    }) as unknown as { payload: { result: boolean, catalogId: string } };

    if (response?.payload?.result) {
      const isLoaded = await fetchWelcomeEmail();

      if (isLoaded) {
        openModal(TriggerType.CREATE, ViewMode.FORM_VIEW, CommunicationType.COURSE_WELCOME_EMAIL);
      } else {
        alertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        });
      }
    }

    setRegularEmailLoading(false);
  };

  if (regularWelcomeEmailData) {
    const dropdownItems: (typeof regularWelcomeEmailData.dropdownItems) = [];

    if (!regularWelcomeEmailData.disabled) {
      dropdownItems.push({
        type: 'text',
        text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.DISABLE(),
        class: 'text-danger',
        dataQa: config.pendo.courseCommunication.courseRegistrationEmail.disable,
        callback: () => dispatch(openConfirmationDialog({
          onConfirm: () => dispatch(disableWelcomeEmail({ courseId: regularWelcomeEmailData.courseId })),
          cancelText: t.FORM.CANCEL(),
          confirmText: t.FORM.YES_SURE(),
          title: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.DISABLE.WARNING_TITLE(),
          bodyText: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.DISABLE.WARNING_TEXT(),
        })),
      });
    } else {
      dropdownItems.push({
        type: 'text',
        text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.ENABLE(),
        callback: () => fireEnableWelcomeEmail(),
        dataQa: config.pendo.courseCommunication.courseRegistrationEmail.enable,
      });
    }

    regularWelcomeEmailData.dropdownItems = [
      ...getCommonDropdownItems(regularWelcomeEmailData.communicationType, openModal),
      ...dropdownItems,
    ];
    regularWelcomeEmailData.dropdownDataQa = config.pendo.courseCommunication.courseRegistrationEmail.actions;
  }

  if (mentorWelcomeEmailData) {
    mentorWelcomeEmailData.dropdownItems = [
      ...getCommonDropdownItems(mentorWelcomeEmailData.communicationType, openModal),
      {
        type: 'text',
        text: t.COURSE_COMMUNICATIONS.ACTIONS_DROPDOWN.DELETE(),
        class: 'text-danger',
        dataQa: config.pendo.courseCommunication.courseMentorRegistrationEmail.delete,
        callback: () => dispatch(openConfirmationDialog({
          onConfirm: () => dispatch(deleteCommunication({
            catalogId,
            lecturePageId: null,
            communicationId: mentorWelcomeEmailData.id,
            communicationType: mentorWelcomeEmailData.communicationType,
          })).then(() => {
            dispatch(addAlertMessage({
              header: t.FORM.SUCCESS_BANG(),
              type: AlertMessageType.SUCCESS,
              message: t.COURSE_COMMUNICATIONS.ALERT_MESSAGES.ADD.WELCOME_EMAIL.DELETE(),
            }));
          }),
          cancelText: t.FORM.CANCEL(),
          confirmText: t.FORM.YES_SURE(),
          title: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.DELETE.MENTOR.WARNING_TITLE(),
          bodyText: t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.DELETE.MENTOR.WARNING_TEXT(
            {
              learnersAlias: course.learnersName.downcasedPluralized,
            },
          ),
        })),
      },
    ];
    mentorWelcomeEmailData.dropdownDataQa = config.pendo.courseCommunication.courseMentorRegistrationEmail.actions;
  }

  // Getting the list of welcome emails
  const fetchWelcomeEmail = React.useCallback(async () => {
    const result = await getWelcomeEmail({ catalogId }) as any;

    if (result?.error) {
      return false;
    }

    return true;
  }, [catalogId, getWelcomeEmail]);

  React.useEffect(() => {
    fetchWelcomeEmail();
  }, []);

  // Sync welcomeEmailEnabled from redux to Angular
  React.useEffect(() => {
    CurrentCourseManager.setWelcomeEmail(regularWelcomeEmailEnabled);
  }, [CurrentCourseManager, regularWelcomeEmailEnabled]);

  // Enabling the welcome email and getting the list of welcome emails
  const onEnableWelcomeEmail = async () => {
    setRegularEmailLoading(true);
    fireEnableWelcomeEmail();
  };

  // Show button to enable welcome email, it enables the regular welcome email by default
  if (regularWelcomeEmailData?.disabled && !mentorWelcomeEmailData) {
    return (
      <section
        css={enableEmailStyles}
        className='d-flex justify-content-center align-items-center text-primary mb-4'
      >
        {isRegularEmailLoading ? (
          <div className='gray-3'>{t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ENABLING()}</div>
        ) : (
          <ClickableContainer
            onClick={() => onEnableWelcomeEmail()}
            aria-label={t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ENABLE()}
            data-qa={config.pendo.courseCommunication.courseRegistrationEmail.add}
          >
            {t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ENABLE()}
          </ClickableContainer>
        )}
      </section>
    );
  }

  // Show the container with the list of welcome emails
  return (
    <section css={containerStyles}>
      <WelcomeEmailItem openModal={openModal} {...regularWelcomeEmailData} />

      {!mentorWelcomeEmailData ? (
        <AddWelcomeEmail openModal={openModal} />
      ) : (
        <WelcomeEmailItem openModal={openModal} {...mentorWelcomeEmailData} />
      )}
    </section>
  );
};

export default WelcomeEmailContainer;
