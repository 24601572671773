import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Result } from 'redux/schemas/api';
import { CourseTemplate } from 'redux/schemas/models/course-templates';

async function fetchAllTemplates() {
  const response = await axios.get<Result<CourseTemplate[]>>('/course_templates.json');
  return response.data.result;
}

export const getAllTemplates = createAsyncThunk('GET_ALL_TEMPLATES', fetchAllTemplates);

export const setSelectedTemplateId = createAction<number>('SET_SELECTED_TEMPLATE_ID');

export const createtemplate = createAsyncThunk(
  'CREATE_TEMPLATE',
  (params: {
    courseTemplateId: number,
    catalogId: string,
    name: string,
  }, thunkAPI) => axios.post(
    `/course_templates/${params.courseTemplateId}/create_course.json`,
    {
      course: {
        catalogId: params.catalogId,
        name: params.name,
      },
    },
  )
    .then((response) => response.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response.data.error)),
);
