import React from 'react';
import t from 'react-translate';
import { NvModal, ModalType } from 'shared/components/nv-modal';
import { Global, css } from '@emotion/react';
import { gray7 } from 'styles/global_defaults/colors';
import AddToCollectionModalBody from './add-to-collection-modal-body';

export type AddToCollectionModalProps = {
  show: boolean,
  onClose: (isConverted?: boolean) => void
  lessonId: number,
};

const styles = css`
  .overflow-clip {
    overflow: clip !important;
  }
`;

const AddToCollectionModal = (props: AddToCollectionModalProps) => {
  const { show, onClose, lessonId } = props;

  return (
    <React.Fragment>
      <Global
        styles={styles}
      />
      <NvModal
        show={show}
        width={800}
        onClose={() => onClose()}
        type={ModalType.DYNAMIC}
        header={t.TIMELINE.LESSON_OPTIONS.ADD_TO_COLLECTION()}
        bodyClassName='p-0 overflow-clip'
        fullHeight={false}
        headerBgColor={gray7}
        body={(
          <AddToCollectionModalBody
            lessonId={lessonId}
            onClose={onClose}
          />
        )}
      />
    </React.Fragment>
  );
};

export default AddToCollectionModal;
