import { AiTranslationProgress } from 'shared/ai-translation-progress';


/* @ngInject */
export default function nvAiTranslationBadge() {
  return {
    scope: {
      status: '=',
      fromModal: '=?',
      retry: '&?',
    },
    controller: function ctrl($scope) {
'ngInject';
      const vm = this;
      vm.getText = getText;
      vm.getIconClass = getIconClass;
      vm.isTranslating = isTranslating;
      vm.tryAgain = tryAgain;
      vm.close = close;
      vm.hasTranslationFailed = hasTranslationFailed;
      vm.shouldShow = shouldShow;
      vm.show = true;
      vm.AiTranslationProgress = AiTranslationProgress;

      function getText() {
        switch (vm.status) {
          case AiTranslationProgress.TRANSLATING:
            return 'LECTURE_PAGES.TRANSLATE_FILE.BADGE.TRANSLATING';
          case AiTranslationProgress.CANNOT_TRANSLATE:
            return 'LECTURE_PAGES.TRANSLATE_FILE.BADGE.FAILED';
          case AiTranslationProgress.TRANSLATED:
          default:
            return 'LECTURE_PAGES.TRANSLATE_FILE.BADGE.TRANSLATED';
        }
      }
      function getIconClass() {
        switch (vm.status) {
          case AiTranslationProgress.TRANSLATING:
            return 'icon-gen-ai icon-translating';
          case AiTranslationProgress.CANNOT_TRANSLATE:
            return 'icon-warning text-warning';
          case AiTranslationProgress.TRANSLATED:
          default:
            return 'icon-gen-ai';
        }
      }

      function isTranslating() {
        return vm.status === AiTranslationProgress.TRANSLATING;
      }

      function hasTranslationFailed() {
        return vm.status === AiTranslationProgress.CANNOT_TRANSLATE;
      }

      function tryAgain() {
        vm.retry?.();
      }

      function close() {
        vm.show = false;
      }

      function shouldShow() {
        return !!vm.status && vm.show;
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'shared/templates/nv-ai-translation-badge.html',
  };
}
