import t from 'react-translate';
import { useContext, useEffect, useMemo } from 'react';
import {
  MentorshipProgramProfileQuestion,
  OrgLevelQuestion,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import { getMentorshipProgramProfileQuestionsList } from 'redux/selectors/mentorship-program-profile-questions';
import { useInView } from 'react-intersection-observer';
import {
  getMentorshipProgramProfileQuestions,
  ProgramProfileQuestionType,
} from 'redux/actions/mentoring-program-profile-questions';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { useAppDispatch } from 'redux/store';
import { config } from '@config/pendo.config.json';
import { getName, PAGE_SIZE } from '../../constants';
import { GetProgramProfileQuestionParams } from '../../program-configuration/program-profile-setup/types';
import SelectableQuestionRow from './selectable-question-row';
import MentoringProgramContext from '../../context';
import { SelectableQuestionsProps } from './types';
import EmptyQuestions from './empty-questions';

const filterSelectableQuestions = (
  orgLevelQuestions: MentorshipProgramProfileQuestion[],
) => {
  const selectableQuestions = [];
  orgLevelQuestions?.forEach((question) => {
    if (question.isNovoedQuestion) {
      const {
        id: questionId,
        novoedQuestionName,
        isRequired,
        isAttachedToRule,
        rulesAllowed = true,
        attachedRules,
      } = question;
      if (isRequired && rulesAllowed) {
        const newSelectableQuestion = {
          questionId,
          questionText: getName(novoedQuestionName, t),
          isHidden: false,
          isAttachedToRule,
          attachedRules,
        };
        selectableQuestions.push(newSelectableQuestion);
      }
    } else {
      const {
        id: questionId,
        profileQuestion,
        isRequired,
        isAttachedToRule,
        rulesAllowed = true,
        attachedRules,
      } = question;
      if ((profileQuestion as OrgLevelQuestion)?.profileSetting) {
        const { profileSetting } = profileQuestion as OrgLevelQuestion;
        const {
          name: questionText,
          isHidden,
          isCsvManaged,
          isIntegrated,
        } = profileSetting || {};
        if ((isRequired || isCsvManaged || isIntegrated) && rulesAllowed) {
          const newSelectableQuestion = {
            questionId,
            questionText,
            isHidden,
            isAttachedToRule,
            attachedRules,
          };
          selectableQuestions.push(newSelectableQuestion);
        }
      }
    }
  });
  return selectableQuestions;
};

type SelectableQuestion = {
  questionId: number;
  questionText: string;
  isHidden: boolean;
  isAttachedToRule: boolean;
  attachedRules: { id: number }[];
};

const type = ProgramProfileQuestionType.ORG_LEVEL;

const SelectableOrgLevelQuestions = ({
  modifiedQuestions,
  onChange,
}: SelectableQuestionsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const { ref: endRef, inView } = useInView();

  const params = useMemo(
    () => ({
      type,
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { result: orgLevelQuestions, loadMore } = usePaginatedApi<
  MentorshipProgramProfileQuestion,
  GetProgramProfileQuestionParams
  >(
    (p) => dispatch(
      getMentorshipProgramProfileQuestions({ mentorshipProgramId, ...p }),
    ).then((action) => action.payload),
    params,
    getMentorshipProgramProfileQuestionsList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  const selectableQuestions: SelectableQuestion[] = useMemo(
    () => filterSelectableQuestions(orgLevelQuestions),
    [orgLevelQuestions],
  );

  const dataQATag = config.pendo.athena.mentorshipProgram.settings.matchingRules.flyoutModal
    .orgLevelQuestionSwitch;

  return (
    <>
      {selectableQuestions.length ? (
        selectableQuestions.map(
          ({
            questionId,
            questionText,
            isHidden,
            isAttachedToRule,
            attachedRules,
          }) => (
            <SelectableQuestionRow
              key={questionId}
              isHidden={isHidden}
              isSelected={modifiedQuestions[questionId]?.isChecked}
              questionId={questionId}
              questionText={questionText}
              onChange={onChange}
              isAttachedToRule={isAttachedToRule}
              attachedRules={attachedRules}
              dataQa={dataQATag}
              dataQaId={`${dataQATag}_${questionId}`}
            />
          ),
        )
      ) : (
        <EmptyQuestions type={type} />
      )}
      <div ref={endRef} />
    </>
  );
};

export default SelectableOrgLevelQuestions;
