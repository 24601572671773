import { css } from '@emotion/react';
import React, {
  useContext,
  useEffect,
} from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

// Contexts
import { CommunicationDispatch, CommunicationAction } from 'communications/course_communications/contexts/communication-context';
import { CommunicationFormContext, UseCommunicationMethods, FormSubmittedState } from 'communications/course_communications/hooks/use-communication-form';

// Redux
import { getCurrentCourseWelcomeEmails } from 'redux/selectors/course-communications';
import { Communication, CommunicationType, ViewMode } from 'redux/schemas/models/course-communication';

// Components
import { NvFroala } from 'froala/components/nv-froala';
import { FroalaViewMode, RteTagValue, UploadType } from 'froala/helpers/nv-froala-constants';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import CommunicationEmailPreview from 'communications/course_communications/components/communication-item/communication-email-preview';
import EmailHeader from '../email-header';
import { config } from '../../../../../../config/config.json';

const styles = (ShowForm: boolean) => css`
  &.welcome-email-form {
    display: ${ShowForm ? 'block' : 'none'};
  }
`;

const SendTo = (props: { communicationType: CommunicationType }) => {
  const welcomeEmails: Array<Communication> = useSelector(getCurrentCourseWelcomeEmails);
  const mentorWelcomeEmail = welcomeEmails?.find(we => we.communicationType === CommunicationType.COURSE_MENTOR_WELCOME_EMAIL);

  const getUserText = () => {
    let text = 'MENTORS';

    if (props.communicationType !== CommunicationType.COURSE_MENTOR_WELCOME_EMAIL) {
      text = mentorWelcomeEmail ? 'LEARNERS_COURSE_ADMINS_WHEN_ENROLLED' : 'ALL';
    }
    return t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.RECIPIENT[text]();
  };

  return (
    <div>
      <span className='page-title-small pr-2'>
        {t.COURSE_COMMUNICATIONS.SCHEDULED_EMAIL.TO()}
      </span>
      <span className='text-large-body font-weight-bolder'>
        {getUserText()}
      </span>
    </div>
  );
};

type Props = {
  catalogId: string,
};

const WelcomeEmailForm = (props: Props) => {
  const { dispatch, State } = useContext(CommunicationDispatch);
  const { formSubmitState, formData, getCommunicationDraft } = useContext<UseCommunicationMethods>(CommunicationFormContext);

  const communicationDraft = getCommunicationDraft();

  useEffect(() => {
    if (formSubmitState === FormSubmittedState.SUBMITTED) {
      dispatch({ type: CommunicationAction.CLOSE_MODAL });
    }
  }, [formSubmitState, dispatch]);

  return (
    <React.Fragment>
      {State.viewMode === ViewMode.EMAIL_PREVIEW && (
        <React.Fragment>
          <SendTo communicationType={State.communicationType} />
          <div className='mt-3 mb-4'>
            <EmailHeader
              communicationDraft={communicationDraft}
              catalogId={props.catalogId}
            />
            <CommunicationEmailPreview
              catalogId={props.catalogId}
              subject={formData.emailSubject}
              body={formData.emailBody}
              communicationType={formData.communicationType}
            />
          </div>
        </React.Fragment>
      )}
      <div
        css={styles(State.viewMode === ViewMode.FORM_VIEW)}
        className='welcome-email-form mt-2'
      >
        <SendTo communicationType={State.communicationType} />
        <div className='w-100'>
          <div className='my-4'>
            <NvTextInput
              name='emailSubject'
              autoComplete='off'
              inputClassName='text-medium'
              required
              withForm
            />
          </div>
          <div className=''>
            <NvFroala
              preset={FroalaViewMode.NORMAL}
              withCodeView
              withInsertTag
              name='emailBody'
              uploadType={UploadType.IMAGE_ONLY}
              withForm
              extraTags={[RteTagValue.INSERT_PASSWORD_SENTENCE]}
              fileExtensions={config.files.rte.images.common}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WelcomeEmailForm;
