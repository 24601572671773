import { useState } from 'react';
import NvSelect, { SelectOption } from 'shared/components/inputs/nv-select';

type DropdownQuestionProps = {
  title: string;
  answer: string;
  options: SelectOption[];
  className?: string,
  placeholder: string;
  onChange: (answer: string) => void;
  dataQa?: string;
  dataQaId?: string;
};
const DropdownQuestion = ({ title, answer, options, className, onChange, placeholder, dataQa, dataQaId }: DropdownQuestionProps) => {
  const [value, setValue] = useState(answer);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={className} data-qa={dataQa} data-qa-id={dataQaId}>
      <span className='input-label text-regular semi-bold'>{title}</span>
      <NvSelect
        value={value}
        options={options}
        className='select-dropdown'
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default DropdownQuestion;
