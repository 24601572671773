import React, { useEffect, useMemo } from 'react';
import t from 'react-translate';
import { getFlyoutScenarios } from 'redux/actions/video-practice';
import { useAppDispatch } from 'redux/store';
import NvDropdown, {
  NvDropdownButtonStyle,
  NvDropdownAlign,
} from 'shared/components/inputs/nv-dropdown';
import NvIcon from 'shared/components/nv-icon';
import { useSelector } from 'react-redux';
import { PracticeRoomTab } from 'redux/schemas/models/video-practice';
import { fetchPracticeList } from 'redux/actions/analytics';

interface PracticeAnalyticsTabProps {
  catalogId: string;
}

const PracticeAnalyticsTab = ({ catalogId }: PracticeAnalyticsTabProps) => {
  const dispatch = useAppDispatch();
  const { practiceList, isPracticeListLoaded } = useSelector((state) => state.app.analytics);

  useEffect(() => {
    dispatch(fetchPracticeList({ catalogId }));
  }, [catalogId, dispatch]);

  const buildPracticeScenarios = useMemo(() => {
    const items = practiceList?.map((scenario) => ({
      type: 'link',
      text: scenario.title,
      link: `/#!/practice-room/${scenario.id}/?selected=${PracticeRoomTab.ANALYTICS}&galleryMode=true&catalogId=${catalogId}`,
    }));
    return items;
  }, [practiceList, catalogId]);

  if (!practiceList || !isPracticeListLoaded) {
    return null;
  }

  return (
    <div className='practice-dropdown-title'>
      <NvDropdown
        useMaxHeightModifier
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        showSelectedIndicator
        items={buildPracticeScenarios}
        title={t.COURSE_ANALYTICS.PRACTICE()}
        customTarget={() => (
          <div className='d-flex align-items-center card-title px-4'>
            {t.COURSE_ANALYTICS.PRACTICE()}
            <NvIcon
              icon='dropdown-arrow'
              size='xss-smallest'
              className='ml-3 mt-1'
            />
          </div>
        )}
        align={NvDropdownAlign.CENTER}
      />
    </div>
  );
};

export default PracticeAnalyticsTab;
