import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { useMediaQuery } from 'react-responsive';

import { setSelectedTemplateId } from 'redux/actions/course-templates';
import { CourseTemplate } from 'redux/schemas/models/course-templates';
import { getCurrentInstitution } from 'redux/selectors/institutions';

import TemplateCard from 'courses/components/course-template-card/template-card';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { white } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  extraLargeSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { screenMdMin, screenSmMin, tablet } from 'styles/global_defaults/media-queries';
import { Template } from '../course-templates-wrapper';
import { config } from '../../../../../config/pendo.config.json';

type CourseStartTemplateProps = {
  handleCloseModal: () => void;
  handleSetActiveTab: (activeTab: Template) => void;
};

const CourseTemplateList = (props: CourseStartTemplateProps) => {
  const { handleSetActiveTab, handleCloseModal } = props;

  const courseTemplates = useSelector((state) => state.models.courseTemplates);
  const areCourseTemplatesLoading = useSelector((state) => state.app.courseTemplates.isLoading);
  const currentInstitution = useSelector(getCurrentInstitution);

  const dispatch = useAppDispatch();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${screenMdMin}px)`,
  });

  const notMobile = useMediaQuery({
    query: `(min-width: ${screenSmMin}px)`,
  });

  const styles = css`
    max-width: 840px;
    .grid-container {
      display: grid;
      grid-template-columns: ${notMobile ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'};
      margin: auto;
      gap: ${notMobile ? doubleSpacing : standardSpacing}px;
      ${tablet(css`
        gap: ${standardSpacing}px;
      `)}
    }

    .grid-item {
      text-align: center;
    }
  `;

  const modalStyles = css`
    &.bs4-modal {
      &.modal-dynamic {
        .bs4-modal-dialog {
          padding-top: ${tripleSpacing}px;
          padding-bottom: ${standardSpacing}px;
          ${isDesktop && css`
            // 120px for left and right padding
            width: calc(100% - 240px) !important;

            .bs4-modal-body {
              padding-top: ${doubleSpacing}px;
              padding-bottom: ${doubleSpacing}px;
            }
          `}

          .bs4-modal-content {
            border: none;
            border-radius: 0px;

            .bs4-modal-header {
              min-height: 90px;
              height: 90px;
              border-radius: 0px;

              .bs4-modal-title {
                height: ${extraLargeSpacing}px;
                margin: ${standardSpacing}px !important;
                justify-content: normal;
              }

              .modal-title-content {
                color: ${currentInstitution.brandBarFontColor};
              }

              .close {
                padding: ${standardSpacing}px;
                color: ${white};
                position: fixed;
              }
            }
          }
        }
      }
    }
  `;

  const handleSelectModal = (selectedTemplateId: number) => {
    dispatch(setSelectedTemplateId(selectedTemplateId));
    handleSetActiveTab(Template.CREATE_FORM);
  };

  const handlePreviewModal = (selectedTemplateId: number) => {
    dispatch(setSelectedTemplateId(selectedTemplateId));
    handleSetActiveTab(Template.PREVIEW);
  };

  return (
    <React.Fragment>
      <NvModal
        show
        onClose={handleCloseModal}
        closeDataQa={config.pendo.courseTemplates.closeTemplate}
        type={ModalType.DYNAMIC}
        fullHeight
        header={t.COURSES.COURSE_TEMPLATES.START_FROM_A_TEMPLATE()}
        headerClassName={`${notMobile ? 'course-title-l' : 'course-title'} font-weight-bolder text-align-left`}
        headerBgColor={currentInstitution.brandColor}
        modalStyles={modalStyles}
        body={(
          <div className='mx-auto' css={styles}>
            <div className='grid-container'>
              {areCourseTemplatesLoading
                ? Array.from({ length: 4 }).map((_) => (
                  <div className='grid-item'>
                    <TemplateCard
                      isLoading
                    />
                  </div>
                ))
                : courseTemplates.map((templateData: CourseTemplate) => (
                  <div key={templateData.id} className='grid-item'>
                    <TemplateCard
                      cardImage={templateData.headerBackground}
                      cardHeader={templateData.name}
                      cardDescriptionText={templateData.classDescription}
                      moreInformationText={templateData.moreInformation}
                      handlePreview={() => handlePreviewModal(templateData.id)}
                      handleSelect={() => handleSelectModal(templateData.id)}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default CourseTemplateList;
