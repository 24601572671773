import { css, jsx } from '@emotion/react';
import React, { ChangeEvent, KeyboardEvent, forwardRef, useEffect } from 'react';
import { threeQuartersSpacing, standardSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import { primary, gray3 } from 'styles/global_defaults/colors';
import t from 'react-translate';
import { NvTooltip } from './nv-tooltip';
import { config } from '../../../config/pendo.config.json';

type NvExpandableSearchBarProps = {
  initialQuery?: string;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearch: (query: string, e: KeyboardEvent<HTMLInputElement>) => void;
  isExpanded?: boolean;
  /** TODO: I think we might have a bug here if you set isExpanded but not onExpanded. The passed-down isExpanded state will never get updated */
  onExpanded?: (isExpanded: boolean) => void;
  placeholder?: string;
  showPlaceholderAlways?: boolean;
};

const styles = css`
  display: flex;
  /* Keep the bar a constant size so that it will mask the NvResponsiveTabs on small devices */
  /* This will need changed if used outside of a table search experience */
  /* flex-shrink: 0; */
  max-width: none; /* Reset the max-width set in the angularjs nv-expandable-search-bar */

  .nv-search-bar {
    width: 100%;
  }

  &.expanded {
    min-width: 270px;

    .nv-search-bar {
      input {
        width: 100%;
      }
      .search-button {
        margin-right: ${threeQuartersSpacing}px;
      }
    }
  }

  &:not(.expanded) {
    .nv-search-bar {
      input {
        width: 0;
        display: none;
      }

      .search-button {
        margin-right: 0;
      }
    }
  }

  .icon-close {
    cursor: pointer;
    &:hover {
      color: ${primary} !important;
    }
  }

  .close-button {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    margin-left: ${standardSpacing * 2}px;
    align-self: center;
    font-size: 10px;
    height: 10px;

    body:not(.keyboard_user) &:focus {
      outline: none;
    }

    &:hover i {
      color: ${primary} !important;
    }
  }
`;

/**
 * wraps the NvSearchBar with the collapse/expand vertically functionality.
 * mockups: https://projects.invisionapp.com/d/main#/projects/prototypes/9280433?scrollOffset=872
 */
export const NvExpandableSearchBar = forwardRef<HTMLInputElement, NvExpandableSearchBarProps>((props, ref) => {
  const {
    onInputChange,
    isExpanded,
    onExpanded,
    showPlaceholderAlways = false,
    ...rest
  } = props;

  const [value, setValue] = React.useState('');
  const refValue = (ref as React.MutableRefObject<HTMLInputElement>)?.current?.value;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (props.onInputChange) {
      props.onInputChange(e);
    }
  };

  const handleClick = () => {
    onExpanded?.(!isExpanded);
  };

  // Resets the value when the reference is reset from outside,
  // as in institution dashboard courses and journeys dashboard organization.
  useEffect(() => {
    if (refValue !== value && refValue === '') {
      setValue(refValue);
    }
  }, [refValue]);

  return (
    <div css={styles} className={`nv-expandable-search-bar ${isExpanded ? 'expanded' : ''}`}>
      <NvSearchBar
        ref={ref}
        value={value}
        onChange={handleChange}
        searchButtonEnabled={!props.isExpanded}
        onSearchButtonClick={handleClick}
        showPlaceholderAlways={showPlaceholderAlways}
        {...rest}
      />
      {props.isExpanded && (
        <NvTooltip text={t.NOVOED.CLOSE()}>
          <button onClick={() => handleClick()} className='close-button' type='button'>
            <i className='icon icon-close text-gray-2' />
          </button>
        </NvTooltip>
      )}
    </div>
  );
});

const searchButtonStyle = css`
  border: none;
  background: none;
  padding: 0;

  body:not(.keyboard_user) &:focus {
    outline: none;
  }

  &:enabled {
    cursor: pointer;
  }

  &:enabled:hover {
    i, span {
      color: ${primary} !important;
    }
  }
`;

const searchBarContainerStyle = css`
  display: flex;

  input {
    margin-left: ${quarterSpacing}px;
    background-color: transparent;
    border: none;
    &::placeholder {
      color: ${gray3};
    }

    /* Resets the default padding and corrects the height set in iOS Safari */
    padding: 0;
    height: ${standardSpacing}px;
  }

  body:not(.keyboard_user) & input:focus {
    outline: none;
  }

  .clear-button {
    border: none;
    background: none;
  }
`;

export type NvSearchBarProps = React.ComponentProps<'input'> & {
  onSearch: (query: string, e: KeyboardEvent<HTMLInputElement>) => void;
  onSearchButtonClick?: Function;
  searchButtonEnabled?: boolean;
  clearButtonEnabled?: boolean;
  autoHideClearbutton?: boolean;
  onClearButton?: Function;
  showPlaceholderAlways?: boolean;
  isReset?: boolean;
};

/**
 * Text input with a search button listening for Enter press, calls the `onSearch` callback.
 * This component is used by NvExpandableSearchBar. Make sure sure that changes (e.g. styling)
 * to this component don't break the other one.
 */
export const NvSearchBar = forwardRef<HTMLInputElement, NvSearchBarProps>((props, ref) => {
  const {
    onSearch,
    onSearchButtonClick,
    searchButtonEnabled = false,
    clearButtonEnabled = false,
    onClearButton,
    onKeyDown,
    showPlaceholderAlways,
    autoHideClearbutton,
    isReset,
    ...rest
  } = props;

  const [value, setValue] = React.useState('');

  useEffect(() => {
    if (isReset) {
      setValue('');
    }
  }, [isReset]);
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Fire the search callback when the enter key is pressed
    if (e.keyCode === 13) {
      onSearch(e.currentTarget.value, e);
    }

    onKeyDown?.(e);
  };

  const handleClick = () => {
    // @ts-ignore
    const refValue = ref?.current?.value;
    setTimeout(() => {
      // @ts-ignore
      ref?.current?.focus();
    }, 0);
    props.onSearchButtonClick(ref ? refValue : value);
  };

  const handleOnClear = () => {
    setValue('');
    onClearButton?.();
  };

  const showClearButton = clearButtonEnabled && (value.length || !autoHideClearbutton);
  return (
    <div css={searchBarContainerStyle} className={`nv-search-bar ${props.className ?? ''}`}>
      <NvTooltip text={t.COURSES.SEARCH.TITLE()} placement='bottom' enabled={searchButtonEnabled}>
        <button
          className='d-flex search-button'
          css={searchButtonStyle}
          onClick={handleClick}
          disabled={!searchButtonEnabled}
          type='button'
          data-qa={config.pendo.orgDashboard.searchIcon}
          aria-label={t.COURSES.SEARCH.TITLE()}
        >
          <i className='icon icon-small icon-search text-gray-2' />
          {searchButtonEnabled && showPlaceholderAlways && <span className='pl-1 text-gray-2'>{props.placeholder}</span>}
        </button>
      </NvTooltip>
      <input
        ref={ref}
        className='page-title-xs'
        type='text'
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={props.placeholder}
        value={value}
        data-qa={config.pendo.orgDashboard.searchInputField}
        {...rest}
      />
      {showClearButton && (
        <button
          className='clear-button'
          onClick={handleOnClear}
          type='button'
          data-qa={config.pendo.learningCatalog.clear}
        >
          <span className='text-small text-gray-2'>
            {t.SHARED.CLEAR()}
          </span>
        </button>
      )}
    </div>
  );
});
