import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { normalize } from 'normalizr';
import mergeWith from 'lodash/mergeWith';
import { createReducer } from '@reduxjs/toolkit';

import { initialRootState } from 'redux/reducers';
import { replaceArrays } from 'shared/lodash-utils';
import {
  addQuizQuestion,
  getQuizQuestion,
  editQuizQuestion,
  submitQuizAttempt,
  deleteQuizQuestion,
  addQuizQuestionOption,
  duplicateQuizQuestion,
  reviewPreviousAttempt,
  editQuizQuestionOption,
  addQuizQuestionFeedback,
  deleteQuizQuestionOption,
  editQuizQuestionFeedback,
  addQuestionOptionFeedback,
  unsetQuizQuestionResponse,
  deleteQuizQuestionFeedback,
  editQuestionOptionFeedback,
  deleteQuestionOptionFeedback,
  createQuizQuestionSubmission,
  reorderQuestions,
  updateQuestionsOrder,
  editAnswerFeedback,
  editNumberAnswer,
  resetProgressiveQuizResponses,
  addMultipleQuizQuestionOption,
} from 'redux/actions/quizzes';
import {
  QuizQuestionSchema,
  QuizSubmissionSchema,
  QuizQuestionSubmissionSchema,
  QuizQuestionOptionSchema,
} from 'redux/schemas/api/progressive-quiz';
import {
  QuizSubmission,
  QuizQuestionSubmission,
  QuizQuestionsNormalized,
  QuizQuestionResponseNormalized,
  QuizQuestionSubmissionNormalized,
  QuizSubmissionsNormalized,
  QuizQuestionType,
} from 'redux/schemas/models/progressive-quiz';
import { extend, find, indexBy, isEmpty, map, union } from 'underscore';

const updateTotalPoints = (state, quiz) => {
  const pointsConfiguration = state.models.pointsConfigurations[quiz.pointsConfiguration];

  if (pointsConfiguration?.rewardsPointsProportionally) {
    const newTotalPoints = quiz.answerableQuestionsCount * pointsConfiguration.points;
    quiz.totalPoints = [newTotalPoints, newTotalPoints];
  }
};

const setDisplayIndex = (state, quiz) => {
  let displayIndex = 1;
  quiz.questions.forEach((eachQuestionId) => {
    if (state.models.quizQuestions[eachQuestionId].type !== QuizQuestionType.STATEMENT) {
      state.models.quizQuestions[eachQuestionId].displayIndex = displayIndex;
      displayIndex += 1;
    } else {
      state.models.quizQuestions[eachQuestionId].displayIndex = null;
    }
  });
};

/**
 * The backend only provides the right-side option IDs in the response's feedback
 * field for matching question. Therefore, the is_correct values for the other
 * response options are determined based on the feedback and response fields.
 */
const getAllResponseOptionsFeedback = (responses, responseOptions) => {
  const feedbackMap = indexBy(responses.feedback, 'id');

  return responseOptions.map((option) => {
    let feedbackId = option.id;

    if (isEmpty(feedbackMap[option.id])) {
      const transformedPairs = extend({}, ...(responses.response ?? []));
      feedbackId = transformedPairs[option.id][0];
    }

    return {
      ...option,
      isCorrect: feedbackMap[feedbackId].isCorrect,
    };
  });
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getQuizQuestion.fulfilled, (state, action) => {
      // Correcting isCorrect field to all matching question response_options
      if (action.payload.type === QuizQuestionType.MATCHING_QUESTION && !isEmpty(action.payload.responses)) {
        action.payload.responses.feedback = getAllResponseOptionsFeedback(action.payload.responses, action.payload.responseOptions);
      }

      const normalized = normalize(action.payload, QuizQuestionSchema);
      const { quizQuestions } = normalized.entities;
      const currentQuestion = quizQuestions[normalized.result];

      state.models.progressiveQuizzes[action.meta.arg.questionSetId].correctAnswersCount = state.models.progressiveQuizzes[action.meta.arg.questionSetId].resumeQuiz
        ? currentQuestion.correctAnswersCount
        : 0;

      mergeWith(state.models, normalized.entities, replaceArrays);

      setDisplayIndex(state, state.models.progressiveQuizzes[action.meta.arg.questionSetId]);
    })
    .addCase(addQuizQuestion.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, QuizQuestionSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);

      const ownerQuiz = state.models.progressiveQuizzes[action.meta.arg.questionSetId];

      ownerQuiz.totalQuestions += 1;

      const currentQuestionIndex = ownerQuiz.questions.findIndex((questionId) => questionId === action.meta.arg.currentQuestionId);

      if (currentQuestionIndex !== -1) {
        ownerQuiz.questions.splice(currentQuestionIndex + 1, 0, normalized.result);
      }
      const { type } = action.payload;

      if (type !== QuizQuestionType.STATEMENT) {
        ownerQuiz.answerableQuestionsCount += 1;
      }
      setDisplayIndex(state, ownerQuiz);

      updateTotalPoints(state, ownerQuiz);
    })
    .addCase(editQuizQuestion.pending, (state, action) => {
      mergeWith(
        state.models.quizQuestions[action.meta.arg.id],
        {
          ...pick(action.meta.arg.patch, ['questionText']),
          responseOptions: action.meta.arg.patch.newOrder,
        },
        replaceArrays,
      );
    })
    .addCase(editQuizQuestion.fulfilled, (state, action) => {
      const normalized = normalize(omit(action.payload, ['questionText']), QuizQuestionSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(deleteQuizQuestion.fulfilled, (state, action) => {
      const questionToDeleteId = action.meta.arg;
      const questionToDelete = state.models.quizQuestions[questionToDeleteId];

      delete state.models.quizQuestions[questionToDeleteId];

      const quiz = state.models.progressiveQuizzes[questionToDelete.questionSetId];

      quiz.questions = quiz.questions.filter((questionId) => questionId !== questionToDeleteId);

      quiz.totalQuestions -= 1;

      if (questionToDelete.type !== QuizQuestionType.STATEMENT) {
        quiz.answerableQuestionsCount -= 1;
      }

      setDisplayIndex(state, quiz);

      updateTotalPoints(state, quiz);
    })
    .addCase(duplicateQuizQuestion.fulfilled, (state, action) => {
      const duplicatedQuestion = action.payload;
      const normalized = normalize(duplicatedQuestion, QuizQuestionSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);

      const ownerQuiz = state.models.progressiveQuizzes[duplicatedQuestion.questionSetId];

      ownerQuiz.totalQuestions += 1;

      const originalQuestionIndex = ownerQuiz.questions.findIndex((questionId) => questionId === action.meta.arg);

      if (originalQuestionIndex !== -1) {
        ownerQuiz.questions.splice(originalQuestionIndex + 1, 0, normalized.result);
      }
      const { type } = action.payload;
      if (type !== QuizQuestionType.STATEMENT) {
        ownerQuiz.answerableQuestionsCount += 1;
      }
      setDisplayIndex(state, ownerQuiz);

      updateTotalPoints(state, ownerQuiz);
    })
    .addCase(addQuizQuestionOption.fulfilled, (state, action) => {
      state.models.quizQuestionOptions[action.payload.id] = action.payload;

      state.models.quizQuestions[action.meta.arg.questionId].responseOptions.push(action.payload.id);
    })
    .addCase(addMultipleQuizQuestionOption.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, [QuizQuestionOptionSchema]);
      const allResponseOptions = union(state.models.quizQuestions[action.meta.arg.questionId].responseOptions, normalized.result);

      mergeWith(state.models.quizQuestionOptions, normalized.entities.quizQuestionOptions, replaceArrays);
      state.models.quizQuestions[action.meta.arg.questionId].responseOptions = allResponseOptions;
    })
    .addCase(editQuizQuestionOption.pending, (state, action) => {
      mergeWith(state.models.quizQuestionOptions[action.meta.arg.id], action.meta.arg.patch, replaceArrays);
    })
    .addCase(deleteQuizQuestionOption.pending, (state, action) => {
      const optionToDeleteId = action.meta.arg;
      const toDeleteOptionIds = [optionToDeleteId];
      const optionToDelete = state.models.quizQuestionOptions[optionToDeleteId];

      /**
       * The backend will remove the associated response option if it has any
       * associations (in the case of matching questions). Therefore, we should
       * also remove the same associated response option from Redux.
       */
      const matchingOption = find(state.models.quizQuestionOptions, ((option) => option.associationId === optionToDeleteId));

      if (!isEmpty(matchingOption)) {
        toDeleteOptionIds.push(matchingOption.id);
        delete state.models.quizQuestionOptions[matchingOption.id];
      }

      delete state.models.quizQuestionOptions[optionToDeleteId];

      const question = state.models.quizQuestions[optionToDelete.questionId];
      question.responseOptions = question.responseOptions.filter((optionId) => !toDeleteOptionIds.includes(optionId));
    })
    .addCase(addQuestionOptionFeedback.fulfilled, (state, action) => {
      state.models.quizQuestionOptions[action.meta.arg.questionOptionId].optionFeedback = action.payload.feedbackText;
    })
    .addCase(editQuestionOptionFeedback.pending, (state, action) => {
      state.models.quizQuestionOptions[action.meta.arg.questionOptionId].optionFeedback = action.meta.arg.feedback;
    })
    .addCase(deleteQuestionOptionFeedback.fulfilled, (state, action) => {
      state.models.quizQuestionOptions[action.meta.arg].optionFeedback = null;
    })
    .addCase(addQuizQuestionFeedback.fulfilled, (state, action) => {
      state.models.quizQuestionFeedbacks[action.payload.id] = action.payload;
      state.models.quizQuestions[action.meta.arg.questionId].attemptFeedbacks.push(action.payload.id);
    })
    .addCase(editQuizQuestionFeedback.pending, (state, action) => {
      state.models.quizQuestionFeedbacks[action.meta.arg.questionFeedbackId].feedbackText = action.meta.arg.feedback;
    })
    .addCase(deleteQuizQuestionFeedback.fulfilled, (state, action) => {
      const feedbackToDeleteId = action.meta.arg.questionFeedbackId;

      delete state.models.quizQuestionFeedbacks[feedbackToDeleteId];

      const question = state.models.quizQuestions[action.meta.arg.questionId];
      question.attemptFeedbacks = question.attemptFeedbacks.filter((feedbackId) => feedbackId !== feedbackToDeleteId);
    })
    .addCase(createQuizQuestionSubmission.fulfilled, (state, action) => {
      // Correcting isCorrect field to all matching question response_options
      if (action.payload.questions.type === QuizQuestionType.MATCHING_QUESTION) {
        action.payload.questions.responses.feedback = getAllResponseOptionsFeedback(action.payload.questions.responses, action.payload.questions.responseOptions);
      }

      const normalized = normalize<QuizQuestionSubmission, {
        quizQuestions: QuizQuestionsNormalized,
        quizQuestionSubmissions: QuizQuestionSubmissionNormalized,
      }>(action.payload, QuizQuestionSubmissionSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);

      const progressiveQuiz = state.models.progressiveQuizzes[action.payload.questionSetId];

      const responseQuestion = action.payload.questions;

      progressiveQuiz.correctAnswersCount = responseQuestion.correctAnswersCount;

      const isFirstQuestion = responseQuestion.questionIndex === 0;

      if (isFirstQuestion) {
        progressiveQuiz.resumeQuiz = true;
        if (!progressiveQuiz.attemptsCompleted) {
          progressiveQuiz.progress = 'in_progress';
        }
      }
    })
    .addCase(unsetQuizQuestionResponse, (state, action) => {
      state.models.quizQuestions[action.payload].responses = null;
    })
    .addCase(submitQuizAttempt.fulfilled, (state, action) => {
      const questionSetId = action.meta.arg;

      const { pointsReceived, quiz: {
        progress,
        correctAnswersCount,
      } } = action.payload;

      const progressiveQuiz = state.models.progressiveQuizzes[questionSetId];

      progressiveQuiz.resumeQuiz = false;
      progressiveQuiz.progress = progress;
      progressiveQuiz.pointsReceived = pointsReceived;
      progressiveQuiz.correctAnswersCount = correctAnswersCount;
      progressiveQuiz.attemptsCompleted = (progressiveQuiz.attemptsCompleted ?? 0) + 1;
      progressiveQuiz.submission = action.payload;
    })
    .addCase(reviewPreviousAttempt.fulfilled, (state, action) => {
      // Correcting isCorrect field to all matching question response_options
      map(action.payload.questions, (question) => {
        if (question.type === QuizQuestionType.MATCHING_QUESTION) {
          const response = find(action.payload.responses, (res) => res.questionId === question.id);
          if (response && response?.feedback && response?.response) {
            question.responseOptions = getAllResponseOptionsFeedback(response, question.responseOptions);
          } else if (state.models.progressiveQuizzes[action.meta.arg.questionSetId]?.progress === 'missed') {
            // All options are marked as incorrect when the quiz is missed without response.
            question.responseOptions = question.responseOptions.map((option) => ({
              ...option,
              isCorrect: false,
            }));
          }
        }
      });

      const normalized = normalize<QuizSubmission, {
        quizQuestions: QuizQuestionsNormalized,
        quizSubmissions: QuizSubmissionsNormalized,
        quizQuestionResponses: QuizQuestionResponseNormalized,
      }>(action.payload, QuizSubmissionSchema);

      const { quizQuestions, quizQuestionResponses } = normalized.entities;
      const submission = action.payload;

      if (quizQuestionResponses) {
        Object.values(quizQuestionResponses).forEach((quizQuestionResponse) => {
          quizQuestions[quizQuestionResponse.questionId].previousAttemptResponses = quizQuestionResponse.id;
        });
      }

      mergeWith(state.models, normalized.entities, replaceArrays);

      state.models.progressiveQuizzes[action.meta.arg.questionSetId].submission = submission;
    })
    .addCase(updateQuestionsOrder, (state, action) => {
      const { questionSetId, addedIndex, removedIndex } = action.payload;
      const quiz = state.models.progressiveQuizzes[questionSetId];
      const [movedQuestion] = quiz.questions.splice(removedIndex, 1);
      quiz.questions.splice(addedIndex, 0, movedQuestion);
      setDisplayIndex(state, quiz);
    })
    .addCase(reorderQuestions.rejected, (state, action) => {
      const { prevQuestions, questionSetId } = action.meta.arg;
      state.models.progressiveQuizzes[questionSetId].questions = prevQuestions;
      setDisplayIndex(state, state.models.progressiveQuizzes[questionSetId]);
    })
    .addCase(editAnswerFeedback.pending, (state, action) => {
      state.models.quizQuestionOptions[action.meta.arg.questionOptionId].explanation = action.meta.arg.explanation;
      state.models.quizQuestionOptions[action.meta.arg.questionOptionId].secondaryExplanation = action.meta.arg.secondaryExplanation;
    })
    .addCase(editNumberAnswer.pending, (state, action) => {
      state.models.quizQuestionOptions[action.meta.arg.questionOptionId].topOfRange = action.meta.arg.topOfRange;
      state.models.quizQuestionOptions[action.meta.arg.questionOptionId].bottomOfRange = action.meta.arg.bottomOfRange;
    })
    .addCase(resetProgressiveQuizResponses, (state, action) => {
      const { questionSetId } = action.payload;

      state.models.progressiveQuizzes[questionSetId].questions.forEach((eachQuestionId, i) => {
        state.models.quizQuestions[eachQuestionId].responses = null;
      });
    });
});
