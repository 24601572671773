/**
 * Service which manages the FlyoutModal. It injects modal on open and
 * destroys it on close.
 */

/* @ngInject */
export default function FlyoutModalManager(
  $q,
  $state,
  PageLevelManager,
  $rootScope,
  $compile,
  $controller,
  $timeout,
  ModalFocusService,
  FlyOutPanelManager,
  $templateCache,
  L4Modal,
) {
  const manager = {
    openFlyout,
    closeFlyout,
    isVisible,
    flyoutName,
  };

  let flyoutVisible = false;
  let flyoutScope;
  let flyoutEl;
  let currentFlyoutName = '';
  let lastFocusedElementPreFlyout = null;

  const ModalFocusManager = new ModalFocusService(keydownListener);


  /**
   * Opens the flyout, sets focus and sets the state
   *
   * @param {object} options Flyout options
   * @param {string} options.template Template URL
   * @param {string} options.controller Controller name string
   * @param {boolean} options.isNavigational Is a navigational flyout
   */
  function openFlyout(options) {
    // Destroy any flyout already present
    destroyElement();

    let closePromise = Promise.resolve();

    if ($state.current.data.level === 4) {
      PageLevelManager.callL4CloseCallbacks();
    }

    if (L4Modal.isVisible()) {
      L4Modal.close();
    }

    // Close FlyoutPanel if any one present
    if (FlyOutPanelManager.isVisible()) {
      closePromise = FlyOutPanelManager.closeFlyoutPanelCallback();
    }

    const template = $templateCache.get(options.template);

    closePromise.then(() => {
      lastFocusedElementPreFlyout = document.activeElement;

      flyoutScope = $rootScope.$new(true);

      // Append template to content
      const flyoutContent = angular.element(`<div class="flyout-content ${options.isNavigational ? 'navigational' : ''}" role="dialog" tabindex="0"></div>`)
        .append(template);

      // Setup flyout element and append backdrop and content
      flyoutEl = angular.element('<div class="flyout-modal"></div>')
        .append('<div class="flyout-backdrop"></div>')
        .append(flyoutContent);

      // Initialize controller if there is one
      if (options.controller) {
        $controller(options.controller, { $scope: flyoutScope });
      }

      $compile(flyoutEl)(flyoutScope);

      // Append to body
      angular.element('body').append(flyoutEl);

      // Trigger focus. Do it on next cycle so that content gets loaded
      // in flyout
      $timeout(() => {
        ModalFocusManager.triggerFocus('.flyout-content', flyoutContent);
      });

      flyoutVisible = true;
      currentFlyoutName = options.name;
    });
  }

  /**
   * Closes the flyout and destroys element
   */
  function closeFlyout() {
    flyoutVisible = false;
    $timeout(() => {
      currentFlyoutName = '';
    });
    destroyElement();

    if (lastFocusedElementPreFlyout) {
      lastFocusedElementPreFlyout.focus();
      lastFocusedElementPreFlyout = null;
    }
  }

  /**
   * Whether FlyoutModal is visible
   *
   * @returns {boolean} Visible or not
   */
  function isVisible() {
    return flyoutVisible;
  }
  function flyoutName() {
    return currentFlyoutName;
  }

  function destroyElement() {
    if (flyoutScope) {
      flyoutScope.$destroy();
      flyoutScope = null;
    }
    if (flyoutEl) {
      flyoutEl.remove();
      flyoutEl = null;
    }
  }

  function keydownListener(evt) {
    if (evt.isDefaultPrevented()) {
      return evt;
    }

    if (isVisible()) {
      switch (evt.which) {
        case 9: {
          const list = ModalFocusManager.loadFocusElementList();
          let focusChanged = false;
          if (evt.shiftKey) {
            if (ModalFocusManager.isFocusInFirstItem(evt, list) || ModalFocusManager.isModalFocused(evt)) {
              focusChanged = ModalFocusManager.focusLastFocusableElement(list);
            }
          } else if (ModalFocusManager.isFocusInLastItem(evt, list)) {
            focusChanged = ModalFocusManager.focusFirstFocusableElement(list);
          }
          if (focusChanged) {
            evt.preventDefault();
            evt.stopPropagation();
          }

          break;
        }
        default:
          break;
      }
    }
    return null;
  }
  return manager;
}
