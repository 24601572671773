import omit from 'lodash/omit';
import t from 'react-translate';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { useMediaQuery } from 'react-responsive';
import { config } from '@config/pendo.config.json';
import { AngularContext } from 'react-app';
import { white } from 'styles/global_defaults/colors';
import setupMentoringProgramEventsListener from 'athena/helpers/mentoring-program-channel-events';
import React, { useContext, useEffect } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { screenMdMin } from 'styles/global_defaults/media-queries';
import ResponsiveTabs, { Tab } from 'athena/components/responsive-tabs';
import Connections from 'athena/components/mentoring-program/connections';
import { darkGray, almostWhite, shadedWhite } from 'athena/styles/colors';
import Participants from 'athena/components/mentoring-program/participants';
import useMentoringProgramRole from 'athena/hooks/use-mentoring-program-role';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { LoadingTabs, LoadingContent } from 'athena/components/mentoring-program/loading-ui';
import { getCreateConnectionModalSettings } from 'redux/selectors/mentorship-program-connections';
import CreateConnectionFlyoutModal from 'athena/components/mentoring-program/modals/create-connection-flyout-modal';
import { HomeProps } from '../types';
import MentoringProgramContext from '../../context';
import { withOverflowFlexGrowClass } from '../../constants';

const ProgramAdminHome = ({ setInnerScroll, scrollPercentage }: HomeProps) => {
  const dispatch = useAppDispatch();
  const { isAdmin } = useMentoringProgramRole();
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const location = useLocation();
  const { isLoading, countState, mentoringProgram } = React.useContext(MentoringProgramContext);
  const { showModal: showCreateConnectionFlyoutModal } = useSelector(getCreateConnectionModalSettings);

  const isInnerScrollEnabled = scrollPercentage === 100;
  const tabsContainerTranslate = ((60 / 100) * scrollPercentage) + (isInnerScrollEnabled ? -30 : 0);

  const isTablet = useMediaQuery({
    query: `(max-width: ${screenMdMin}px)`,
  });

  const innerScrollHandler = (e) => {
    setInnerScroll(e.currentTarget.scrollTop);
  };

  const getPadding = () => {
    if (isInnerScrollEnabled) return 0;
    return isTablet ? standardSpacing : 120;
  }

  const withOverflowFlexGrowStyles = css`
    .${withOverflowFlexGrowClass} {
      flex: 1;
      min-height: 0;
    }
  `;

  const styles = css`
    ${isTablet && css`
      padding-bottom: ${tripleSpacing}px;
    `};
    .program-content {
      height: calc(100vh - 150px);
      background-color: ${almostWhite};
      padding: 0 ${getPadding()}px;

      & > .tabs-container {
        border-radius: 10px;
        background-color: ${white};
        height: calc(100% + ${120 - tabsContainerTranslate}px);
        transform: translateY(-${120 - tabsContainerTranslate}px);
        ${withOverflowFlexGrowStyles};

        .tabs-header {
          height: ${tripleSpacing}px;
          border-bottom: 1px solid ${shadedWhite};
        }
      }
    }
  `;

  const tabs: (Tab & { renderContent: () => React.ReactNode, path: string, allowed: boolean })[] = React.useMemo(() => {
    // Anchors have styles on hover and on focus by default so I make sure they
    // don't show up in this case with these styles
    const tabStyles = css`
      &:hover, &:focus {
        color: ${darkGray};
      }
    `;

    // Only defining tabs when mentoring program is loaded, one reason to do
    // that is because variable `isAdmin` is only defined correctly when
    // mentoring program is fetched (given they come together in the same API),
    // otherwise it may return null (not loaded yet) being interpreted as falsy
    // causing unintended behavior in hooks of this component that depend on the
    // value of `allowed` property, so making tabs available only when it's
    // safe.
    return mentoringProgram ? [
      {
        allowed: isAdmin,
        id: 'participants',
        path: '/participants',
        text: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANTS(),
        renderContainer: (props) => <Link to='/participants' css={tabStyles} {...props} />,
        renderContent: () => <Participants onScroll={innerScrollHandler} scrollEnabled={isInnerScrollEnabled} />,
        renderAfterText: () => <span css={css`white-space: pre;`}> ({countState.result?.enrollmentsCount})</span>,
        dataQa: config.pendo.athena.mentorshipProgram.tabs.participantsTab,
      },
      {
        allowed: isAdmin,
        id: 'connections',
        path: '/connections',
        text: t.MENTORING_PROGRAMS.CONNECTIONS.TITLE(),
        renderContainer: (props) => <Link to='/connections' css={tabStyles} {...props} />,
        renderContent: () => <Connections onScroll={innerScrollHandler} scrollEnabled={isInnerScrollEnabled} />,
        renderAfterText: () => <span css={css`white-space: pre;`}> ({countState.result?.connectionsCount})</span>,
        dataQa: config.pendo.athena.mentorshipProgram.tabs.connectionsTab,
      },
    ] : [];
  }, [isAdmin, mentoringProgram, countState.result, isInnerScrollEnabled]);

  const isChangingInstitution = location.hash.includes('change-institution')
    || location.hash.includes('courses');

  const isRoot = isChangingInstitution ? false : matchPath(location.pathname, {
    path: '/',
  }).isExact;

  useEffect(() => {
    if (isRoot) {
      const defaultTab = tabs.find((tab) => tab.allowed);

      if (defaultTab) {
        $state.go(`mentoring-program-${defaultTab.id}`);
      }
    }
  }, [isRoot, tabs, $state]);

  const currentTab = React.useMemo(() => tabs.find((tab) => matchPath(location.pathname, {
    path: tab.path,
  })), [tabs, location.pathname]);

  useEffect(() => {
    if (currentTab && !currentTab.allowed) {
      $state.go('mentoring-programs');
    }
  }, [currentTab, $state]);

  React.useEffect(() => {
    const cleanup = setupMentoringProgramEventsListener(mentoringProgram?.id, dispatch);

    return () => {
      cleanup();
    };
  }, [mentoringProgram?.id, dispatch]);

  return (
    <div css={styles}>
      <div className='program-content'>
        <div className='tabs-container d-flex flex-column'>
          <div className='tabs-header'>
            {(isLoading || countState.isLoading) ? (
              <LoadingTabs className='h-100' />
            ) : (
              <ResponsiveTabs
                selectedTab={currentTab?.id}
                tabs={tabs.filter((tab) => tab.allowed).map((tab) => omit(tab, ['path', 'renderContent']) as Tab)}
              />
            )}
          </div>
          <div className={`p-4 d-flex flex-column ${withOverflowFlexGrowClass}`}>
            {isLoading ? (
              <LoadingContent />
            ) : (
              <>
                {mentoringProgram && currentTab?.renderContent()}
              </>
            )}
          </div>
        </div>
      </div>
      {showCreateConnectionFlyoutModal && <CreateConnectionFlyoutModal />}
    </div>
  );
};

export default ProgramAdminHome;
