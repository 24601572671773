import { User } from 'redux/schemas/models/my-account';
import { MentoringProgramEnrollmentRole } from 'redux/schemas/models/mentoring-program-enrollments';

import { css } from '@emotion/react';
import { gray6 } from 'styles/global_defaults/colors';

import { useInView } from 'react-intersection-observer';
import { useMentoringProgramQuestionsAnswers } from 'athena/hooks/use-mentoring-program-questions-answers';
import { ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile-questions';
import { useEffect, useMemo } from 'react';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import ProgramLevelInfo from './program-level-info';
import OrgLevelInfo from './org-level-info';
import AccountLevelInfo from './account-level-info';

const styles = css`
  .avatar {
    margin-top: -70px;
  }
  .info-container {
    border: 1px solid ${gray6};
    &:not(:last-child) {
      border-bottom: none !important;
    }
  }
`;

type ConnectionInfoProps = {
  user: User;
  enrollmentId: number;
  mentorshipProgramId: number;
  role: MentoringProgramEnrollmentRole;
};

const ConnectionInfo = ({
  user,
  role,
  enrollmentId,
  mentorshipProgramId,
}: ConnectionInfoProps) => {
  const { name: roleName } = role || {};
  const { ref: orgLevelRef, inView: isOrgLevelEndReached } = useInView();
  const { ref: programLevelRef, inView: isProgramLevelEndReached } = useInView();
  const { questions: orgLevelQuestions, loadMore: loadMoreOrgLevelQuestions }: {
    questions: MentorshipProgramProfileAnswer[];
    loadMore: () => void;
  } = useMentoringProgramQuestionsAnswers({
    type: ProgramProfileQuestionType.ORG_LEVEL,
    mentorshipProgramId,
    enrollmentId,
  });
  const [accountLevelInfo, orgLevelInfo] = useMemo(() => {
    const accountLevel = orgLevelQuestions?.filter(question => question.isNovoedQuestion);
    const orgLevel = orgLevelQuestions?.filter(question => !question.isNovoedQuestion);
    return [accountLevel, orgLevel];
  }, [orgLevelQuestions]);
  const { questions: programLevelInfo, loadMore: loadMoreProgramLevelQuestions }: {
    questions: MentorshipProgramProfileAnswer[];
    loadMore: () => void;
  } = useMentoringProgramQuestionsAnswers({
    type: ProgramProfileQuestionType.PROGRAM_LEVEL,
    mentorshipProgramId,
    enrollmentId,
  });

  useEffect(() => {
    if (isOrgLevelEndReached) {
      loadMoreOrgLevelQuestions();
    }
  }, [isOrgLevelEndReached, loadMoreOrgLevelQuestions]);

  useEffect(() => {
    if (isProgramLevelEndReached) {
      loadMoreProgramLevelQuestions();
    }
  }, [isProgramLevelEndReached, loadMoreProgramLevelQuestions]);

  return (
    <div css={styles}>
      <AccountLevelInfo user={user} role={roleName} accountInfo={accountLevelInfo} />
      <OrgLevelInfo endRef={orgLevelRef} orgInfo={orgLevelInfo} />
      <ProgramLevelInfo endRef={programLevelRef} programInfo={programLevelInfo} />
    </div>
  );
};

export default ConnectionInfo;
