import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import t from 'react-translate';
import React, { useContext } from 'react';
import { css } from '@emotion/core';
import * as yup from 'yup';

// redux
import { NQuizQuestion, ResponseOption } from 'redux/schemas/models/progressive-quiz';
import { useSelector, useDispatch } from 'react-redux';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { RootState } from 'redux/schemas';
import { getQuizQuestionOptions } from 'redux/selectors/quizzes';
import { editAnswerFeedback } from 'redux/actions/quizzes';
import { wrapThunkAction } from 'redux/utils';

import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import NvFroala from 'froala/components/nv-froala';
import NvTextArea from 'shared/components/inputs/nv-text-area';
import { quarterSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { danger, gray2, gray5, primary, success } from 'styles/global_defaults/colors';
import ViewAnswerFeedBack from 'quizzes/components/feedback-components/view-answer-feedback';
import { textLargeBodyFontSize, textLargeLineHeight } from 'styles/global_defaults/fonts';
import NvIcon from 'shared/components/nv-icon';
import ViewValidationError from 'quizzes/components/view-validation-error';
import NvPopover from 'shared/components/nv-popover';
import ValidationErrorMessage from 'shared/components/validation-error-message';
import { config } from '../../../../../config/pendo.config.json';

type LongAnswerSectionProps = {
  currentQuestion: NQuizQuestion
};

const LongAnswerSection = (props: LongAnswerSectionProps) => {
  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
    currentQuestionResponse,
  } = useContext(ProgressiveQuizContext);

  const {
    answerState,
    setAnswerState,
    setAnswerInputError,
    answerInputError,
  } = React.useContext(QuestionContext);

  const getBorderColor = () => {
    if (answerInputError) {
      return danger;
    }
    if (currentQuestionResponse) {
      if (currentQuestionResponse?.isCorrect) {
        return success;
      }
      return danger;
    }
    return gray5;
  };

  const styles = css`
    .froala-answer-input {
      .fr-wrapper {
        border: 1px solid ${getBorderColor()} !important;
        font-size: ${textLargeBodyFontSize}px !important;
        border-radius: ${quarterSpacing}px !important;
        .fr-element {
          border-radius: ${quarterSpacing}px !important;
          background-color: transparent !important;
        }
        .fr-element:focus {
          outline: 1px solid ${answerInputError ? danger : primary} !important;
        }
        .fr-placeholder {
          background-color: transparent !important;
          border: none !important;
        }
        .fr-disabled {
          cursor: default !important;
        }
      }
    }

    .feedback-container {
      .fr-placeholder {
        font-size: ${textLargeBodyFontSize}px !important;
        background-color: white !important;
        border: none !important;
        line-height: ${textLargeLineHeight}px !important;
      }
      .fr-wrapper:not(.show-placeholder) .fr-element {
        background-color: white !important;
      }
      .fr-element {
        border: 1px solid ${gray5};
        border-radius: ${quarterSpacing}px;
        font-size: ${textLargeBodyFontSize}px !important;
        padding: ${threeQuartersSpacing}px !important;
        line-height: ${textLargeLineHeight}px !important;
        p {
          margin-bottom: 0px;
        }
      }
      .fr-element:focus {
        outline: none !important;
        border: 1px solid ${primary};
      }
    }
  `;
  const defaultFeedbackTemplate = '<p class="froala-style-medium"><br /></p>';
  const { currentQuestion } = props;

  const dispatch = useDispatch();

  const courseAliases = useSelector(getFlatCourseAliases);
  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );
  const { explanation } = responseOptions[0];

  const {
    isEditMode,
    isAnswerMode,
  } = useQuizModeAndQuestionType();

  const answerInputValidationSchema = yup.string()
    .required(t.VALIDATION.REQUIRED())
    .max(16000, t.VALIDATION.CHARACTER_LIMIT_POPOVER_ERROR('16,000'));

  const response: string = currentQuestionResponse?.response?.toString();
  const [feedback, setFeedback] = React.useState(explanation ?? defaultFeedbackTemplate);

  const handleFeedbackChange = (html) => {
    setFeedback(html);
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);
    wrapThunkAction(dispatch(editAnswerFeedback(
      {
        explanation: html,
        secondaryExplanation: '',
        questionOptionId: responseOptions[0].id,
      },
    ))).then(() => {
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const handleAnswerChange = (html) => {
    setAnswerState(html);
    const textContent = html
      .replace(/<[^>]*>?/gm, '') // Remove HTML tags
      .replace(/&nbsp;/g, ' ') // Replace &nbsp; with a normal space
      .trim();

    answerInputValidationSchema.validate(textContent)
      .then(() => {
        setAnswerInputError(null);
      })
      .catch((err) => {
        setAnswerInputError(err);
      });
  };

  const getValue = () => {
    if (currentQuestionResponse) {
      return response;
    }
    if (typeof answerState !== 'string') {
      return '';
    }
    return answerState;
  };

  return (
    <div css={styles}>
      {!isEditMode && (
        <div>
          <NvPopover
            placement='top'
            show={!!answerInputError}
            preventOverflow
            content={(
              <ValidationErrorMessage
                headerClassName='semi-bold'
                header={t.VALIDATION.ERROR()}
                text={answerInputError?.message}
              />
          )}
          >
            <NvFroala
              value={getValue()}
              minHeight={120}
              className='froala-answer-input'
              onChange={handleAnswerChange}
              placeholder={t.FORM.PLACEHOLDER.INPUT_YOUR_ANSWER()}
              isDisabled={!!currentQuestionResponse}
            />
          </NvPopover>
          {answerInputError?.type === 'max' && (
            <ViewValidationError characterLimit='16,000' />
          )}
          {currentQuestionResponse?.isCorrect && (
            <ViewAnswerFeedBack currentQuestion={currentQuestion} />
          )}
        </div>
      )}
      {isEditMode && (
        <>
          <div className='d-flex mb-2'>
            <p className='semi-bold mb-0'>{t.QUIZZES.LONG_ANSWER_QUESTION.FEEDBACK_FOR_ANY_ANSWER()}: </p>
            <NvPopover
              showOnHover
              className='d-flex align-items-center'
              placement='top'
              content={(
                <div className=''>
                  {t.QUIZZES.LONG_ANSWER_QUESTION.SETTING_LONG_ANSWER_INFO(courseAliases)}
                </div>
              )}
            >
              <NvIcon className='d-flex' icon='info ml-1' size='xs-smallest text-primary' />
            </NvPopover>
          </div>
          <div>
            <NvFroala
              allowToolbar
              value={feedback}
              className='feedback-container'
              minHeight={60}
              onChange={handleFeedbackChange}
              placeholder={t.QUIZZES.LONG_ANSWER_QUESTION.FEEDBACK_PLACEHOLDER()}
              dataQa={config.pendo.activities.progressiveQuiz.questionModal.longAnswerQuestion.addFeedback}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LongAnswerSection;
