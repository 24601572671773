import { getMomentDate } from 'shared/components/inputs/nv-datepicker';

export default {
  bindings: {
    context: '@',
    directLinkInfo: '<',
    hideDirectLink: '<',
    onDelete: '&',
    onMeetingUpdate: '&',
    onSubmissionUpdate: '&',
    post: '<',
    readonlyMode: '=',
  },
  controller: function ctrl(
    $element,
    $scope,
    $timeout,
    $uibModal,
    $window,
    $interval,
    CurrentUserManager,
    DiscussionsManager,
    InstitutionsManager,
    MentionablesModel,
    ScrollFocusConnectorFactory,
    TeamWorkspaceManager,
    TimezonesService,
    _,
    config,
    nvUtil,
    $state,
    moment,
  ) {
'ngInject';
    const _this = this;
    this.config = config;
    let adminOptionsVisible;

    this.$onInit = function () {
      this.workspacePostUpdated = false;
      this.inlineEditMode = false;
      this.TeamWorkspaceManager = TeamWorkspaceManager;
      this.DiscussionsManager = DiscussionsManager;
      this.institutionsManager = InstitutionsManager;
      this._ = _;
      this.expandComments = this.context === 'workspaceDirectLink';
      this.showNewCommentPrompt = false;
      this.submitting = false;
      /** Whether the current user is a member of this team */
      this.isNonMember = !_this.TeamWorkspaceManager.currentTeam.currentAccountTeamMember;
      this.canHighlight = CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse() || TeamWorkspaceManager.currentTeam.currentAccountTeamMember.isAdmin;
      this.institutionsManager.initialize(CurrentUserManager.currentInstitution.id);
      this.nvUtil = nvUtil;
      this.$state = $state;
      this.currentTeamCourse = CurrentUserManager.getCurrentCourse();
      this.interval = null;
      this.currentTime = moment();
      this.moment = moment;

      adminOptionsVisible = CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse();

      if (this.post.metaContent?.type === 'meeting') {
        const timeZone = this.post.metaContent?.sessions?.[0]?.timeZone;
        const timeZoneString = TimezonesService.correctSpelling(CurrentUserManager.user.timeZone || moment.tz.guess());
        const timeZoneKey = TimezonesService.timezones.find((t) => t.longName === timeZoneString)?.translationKey;
        this.startTime = moment(this.post?.metaContent?.meetingTime);
        this.endTime = moment(this.post?.metaContent?.sessions?.[0]?.endTime);

        this.post.metaContent.currentTimeZone = timeZoneKey ? `TIMEZONE.${timeZoneKey}` : timeZoneString;
        $scope.$watchCollection(
          () => _this.post.metaContent.attendance,
          (newValue) => {
            _this.attendees = TeamWorkspaceManager.meetingAttendeesToUserObjects(newValue);
          },
        );
      }

      const teamOption = {
        id: 0,
        firstName: CurrentUserManager.getCurrentCourse().teamName.downcasedSingularized,
        lastName: '',
      };

      const mentionableUsers = MentionablesModel.getMentionableUsers();

      if (this.post.metaContent?.type === 'TeamDiscussion') {
        mentionableUsers.shift();
        mentionableUsers.unshift(teamOption);
        MentionablesModel.setMentionableUsers(mentionableUsers);
      }
    };

    this.isMeetingActive = function () {
      const buttonActiveStartTime = moment(this.startTime).subtract(10, 'minute').format('L HH:mm');
      if (this.currentTime.isSameOrAfter(buttonActiveStartTime) && this.currentTime.isSameOrBefore(this.endTime)) {
        return true;
      }
      return false;
    };

    this.isMeetingConcluded = function () {
      return this.post.metaContent?.meetingType === 'teams_meeting'
      && this.currentTime.isAfter(this.endTime);
    };

    this.$onChanges = function (changes) {
      if (changes.post && !changes.post.isFirstChange()) {
        _this.post = changes.post.currentValue;

        if (_this.post.metaContent?.type === 'meeting') {
          _this.attendees = TeamWorkspaceManager.meetingAttendeesToUserObjects(_this.post.metaContent.attendance);
        }
      }
    };

    this.fetchComments = function () {
      this.showNewCommentPrompt = !this.expandComments;
      this.expandComments = !this.expandComments;
    };

    this.canToggleComments = function () {
      return true;
    };

    this.toggleExpandedComments = function () {
      this.expandComments = !this.expandComments;
      this.newCommentUi = false;
    };

    this.updateWorkspacePost = function ($event) {
      _this.submitting = true;
      this.TeamWorkspaceManager.workspace.updatePost($event.workspacePost).then((editedPost) => {
        _this.inlineEditMode = false;
        _this.submitting = false;
      });
    };

    this.editWorkspacePost = function () {
      let editModal = null;

      // text posts: toggle inline editing text box
      if (!this.post.metaContent) {
        this.inlineEditMode = true;
      } else if (this.post.metaContent.type === 'meeting') {
        editModal = this.launchMeetingEditModal();

        editModal.result.then((updatedMeeting) => {
          _this.onMeetingUpdate({
            $event: {
              meeting: updatedMeeting,
              postId: _this.post.id,
            },
          });
        });
      } else if (this.post.metaContent.type === 'Attachment' || this.post.metaContent.type === 'externaldocument') {
        this.launchWorkspaceDocumentModal();
      } else if (this.post.metaContent.type === 'report') {
        editModal = this.launchShareSubmissionModal();

        editModal.result.then((updatedSubmission) => {
          _this.onSubmissionUpdate({
            $event: {
              submission: updatedSubmission,
              postId: _this.post.id,
            },
          });
        });
      }
    };

    this.deleteWorkspacePost = function ($event) {
      const deleteModal = $uibModal.open({
        backdropClass: 'modal-overlay-backdrop',
        templateUrl: 'discussions/templates/nv-delete-post-confirmation-overlay.html',
        windowClass: 'modal-overlay',
      });

      if ($event.workspacePost.metaContent.meetingType === 'teams_meeting') {
        return deleteModal.result.then(() => {
          TeamWorkspaceManager.workspace.deleteMeeting($event.workspacePost).then(TeamWorkspaceManager.afterPostDelete);
        });
      }
      return deleteModal.result.then(() => {
        TeamWorkspaceManager.workspace.deletePost($event.workspacePost).then(TeamWorkspaceManager.afterPostDelete);
      });
    };

    this.launchMeetingRsvpModal = function () {
      const templateUrl = _this.post.metaContent?.meetingType === 'teams_meeting'
        ? 'team_workspace/templates/view-teams-meeting.html'
        : 'team_workspace/templates/team-workspace-meeting-rsvp-modal.html';
      const rsvpModal = $uibModal.open({
        templateUrl,
        controller: 'MeetingRsvpModalInstanceController as vm',
        windowClass: 'full-screen-modal-handheld team-workspace-rsvp-modal',
        resolve: {
          rsvpStatus() {
            if (!_this.post.metaContent.attendance.length || _this.isNonMember) {
              return null;
            }

            const currentUserRsvp = _.findWhere(_this.post.metaContent.attendance, { teamMemberId: _this.TeamWorkspaceManager.currentTeam.currentAccountTeamMember.id });
            return currentUserRsvp ? currentUserRsvp.rsvp : null;
          },
          meeting() {
            return _.clone(_this.post.metaContent);
          },
        },
      });

      rsvpModal.result.then((rsvp) => {
        if (rsvp?.newRsvp) {
          _this.post.addMeetingRsvp(rsvp.rsvpData, _this.TeamWorkspaceManager.currentTeam.currentAccountTeamMember.id);
        } else if (rsvp) {
          // updating rsvp
          _this.post.updateMeetingRsvp(rsvp.rsvpData, _this.TeamWorkspaceManager.currentTeam.currentAccountTeamMember.id);
        }
      });
    };

    this.launchWorkspaceDocumentModal = function () {
      $uibModal.open({
        templateUrl: 'team_workspace/templates/document-upload-modal.html',
        controller: 'DocumentWorkspacePostModalInstanceController as vm',
        resolve: {
          existingWorkspacePost() {
            return _this.post;
          },
        },
      });
    };

    this.launchMeetingEditModal = function () {
      const modal = $uibModal.open({
        templateUrl: 'team_workspace/templates/team-workspace-new-meeting-modal.html',
        controller: 'WorkspaceMeetingModalInstanceController as vm',
        windowClass: 'full-screen-modal-handheld team-workspace-new-meeting-modal',
        resolve: {
          existingMeeting() {
            return _.clone(_this.post.metaContent);
          },
        },
      });

      return modal;
    };

    this.launchShareSubmissionModal = function () {
      return $uibModal.open({
        templateUrl: 'team_workspace/templates/team-workspace-share-submission-modal.html',
        controller: 'TeamWorkspaceShareSubmissionModalInstanceController as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet team-workspace-share-submission-modal',
        resolve: {
          existingSubmissionPost() {
            return _this.post;
          },
        },
      });
    };

    /** Copied from nv-discussion-post.js */
    this.highlight = () => {
      $uibModal.open({
        templateUrl: 'discussions/templates/nv-highlight-post-modal.html',
        windowClass: 'discussions-modal',
        controller: 'HighlightPostModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          post() {
            return _this.post;
          },
        },
      });
    };

    this.unHighlight = () => {
      this.post.unHighlight();
    };

    this.launchSubmissionPermissionsErrorOverlay = function () {
      $uibModal.open({
        backdropClass: 'modal-overlay-backdrop',
        templateUrl: 'team_workspace/templates/submission-permissions-error-overlay.html',
        windowClass: 'modal-overlay',
      });
    };

    this.showNewComment = () => {
      this.expandComments = true;
      this.newCommentMode = true;
    };

    this.canDelete = function () {
      return (this.post.belongsToCurrentUser() || adminOptionsVisible)
      && this.post.metaContent?.type !== 'TeamDiscussion'
      && !this.currentTeamCourse.inArchiveMode;
    };

    this.canEdit = function () {
      return !this.institutionsManager.institution.disableDiscussionEdits
        && !this.currentTeamCourse.endDateIsPast
        && this.canDelete(); // checking other conditions
    };

    this.isNewCommentButtonDisabled = function () {
      return this.currentTeamCourse.inArchiveMode;
    };
  },
  controllerAs: 'vm',
  templateUrl: 'team_workspace/templates/nv-team-workspace-post.html',
};
