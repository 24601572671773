import { BookmarkType } from 'redux/schemas/models/bookmark';
import SkillsRatingTabs from '../components/skills-rating/skills-rating-tabs';
import SkillsRatingForm from '../components/skills-rating/skills-rating-form';


/* @ngInject */
export default function IndividualSubmissionBaseController(
  $controller,
  $scope,
  $state,
  $stateParams,
  $translate,
  $uibModal,
  AlertMessages,
  CurrentCourseManager,
  CurrentUserManager,
  ExercisesManager,
  TeamManager,
  InteroperabilityRoutes,
  RailsRoutes,
  ReportsManager,
  ScrollFocusConnectorFactory,
  config,
  moment,
  BookmarkModel,
) {
  const MINUTE_THRESHOLD = 60 * 1000;
  const HOUR_THRESHOLD = 60 * MINUTE_THRESHOLD;
  const DAY_THRESHOLD = 24 * HOUR_THRESHOLD;

  const DUE_DATE_STRINGS = {
    days: 'TIMELINE.EXERCISE_DUE.DUE_IN_DAYS',
    hours: 'TIMELINE.EXERCISE_DUE.DUE_IN_HOURS',
    minutes: 'TIMELINE.EXERCISE_DUE.DUE_IN_MINUTES',
    date: 'TIMELINE.DUE_DEADLINE',
  };

  const vm = angular.extend(this, $controller('L3HeaderCtrl', { $scope }));

  Object.assign(vm, {
    $state,
    $stateParams,
    CurrentUserManager,
    ExercisesManager,
    InteroperabilityRoutes,
    RailsRoutes,
    ReportsManager,
    config,
    course: CurrentUserManager.hasLoggedIn() ? CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId] : CurrentCourseManager.course,
    manager: ExercisesManager,
    moment,
    teamsGroupsInCurrentCourse: [],
  });

  vm.ReportsManager.showRatingForm = !!$stateParams.isRatingFeedback;

  vm.HAS_SKILL_RATING_MAIN_CLASS = 'has-skill-rating';
  vm.RATING_VIEW_MAIN_CLASS = 'rating-view';

  $scope.SkillsRatingTabs = SkillsRatingTabs;
  $scope.SkillsRatingForm = SkillsRatingForm;
  $scope.ReportsManager = ReportsManager;

  $scope.toggleRatingForm = (show) => {
    vm.ReportsManager.showRatingForm = show;

    if (show) {
      $('.lightbox-panel, .modal-content-wrapper').scrollTop(0);
    }
  };

  $scope.$on('$destroy', () => {
    if (vm.lastState === 'submissions-gallery' && vm.$state.current.name === 'submissions-gallery') {
      ReportsManager.updateSelectedExercise();
    }
  });

  vm.isRatingView = () => vm.ReportsManager.showRatingForm
      && !vm.ReportsManager.isLoadingSubmission
      && !ReportsManager.currentReport?.belongsToCurrentUser()
      && ReportsManager.currentReport?.exercise
      && ReportsManager.currentReport?.hasSkillsRating()
      && ReportsManager.currentReport?.exercise?.skillsRatingFeedback?.progress !== 'missed'
      && !ReportsManager.currentReport?.isRatedByCurrentUser;

  $scope.$watchCollection(
    () => [
      vm.isRatingView(),
      ReportsManager.currentReport?.exercise?.hasSkillsRating,
    ],
    () => {
      let { mainClass } = $state.current.data;
      if (vm.isRatingView()) {
        if (!mainClass.includes(vm.RATING_VIEW_MAIN_CLASS)) {
          mainClass += ` ${vm.RATING_VIEW_MAIN_CLASS}`;
        }
      } else {
        mainClass = mainClass.replace(` ${vm.RATING_VIEW_MAIN_CLASS}`, '');
      }
      if (!vm.isRatingView() && ReportsManager.currentReport?.exercise?.hasSkillsRating) {
        if (!mainClass.includes(vm.HAS_SKILL_RATING_MAIN_CLASS)) {
          mainClass += ` ${vm.HAS_SKILL_RATING_MAIN_CLASS}`;
        }
      } else {
        mainClass = mainClass.replace(` ${vm.HAS_SKILL_RATING_MAIN_CLASS}`, '');
      }
      $state.current.data.mainClass = mainClass;
    },
  );

  vm.modalTitle = () => {
    if (vm.isRatingView()
      && ReportsManager.currentReport?.exercise?.skillsRatingFeedback) {
      const pendingCount = ReportsManager.currentReport?.exercise?.skillsRatingFeedback?.requiredToComplete
        - ReportsManager.currentReport?.exercise?.skillsRatingFeedback?.numReviewesCompleted;

      if (!pendingCount || pendingCount <= 0) {
        return vm.ReportsManager.currentReport?.title;
      }

      return $translate.instant('LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.ASSIGNMENT.SKILLS_FEEDBACK.SUBMISSION.GIVE_FEEDBACK', {
        pendingEvaluationCount: pendingCount,
      });
    }

    return vm.ReportsManager.currentReport?.title;
  };

  vm.isReview = () => ReportsManager.currentReport?.canReview && ReportsManager.currentReport.isPendingApproval();

  vm.getSubtitleSref = () => {
    if (!ReportsManager.currentReport) {
      return '';
    }
    return vm.isReview() ? 'submission-approval-dashboard' : 'submissions-gallery({ exerciseId: vm.ExercisesManager.currentExercise.id })';
  };

  vm.getSubtitleTextKey = () => (vm.isReview() ? 'SUBMISSION_APPROVAL.DASHBOARD.LINK_TO' : 'COURSE_HOME.HEADER.GALLERY');

  vm.getShowBackButton = () => vm.isReview();

  vm.showNewCommentUi = (isExpanded) => {
    if (CurrentUserManager.hasLoggedIn()) {
      ReportsManager.currentReport.commentable = true;
      ScrollFocusConnectorFactory.scrollTo(null, null, { elem: $('.single-submission .comments-section'), offset: (isExpanded ? 280 : 60) });
      ScrollFocusConnectorFactory.focus(ReportsManager.currentReport.id, 'report');
    }
  };

  vm.launchAssignmentIntroductionModal = () => {
    $uibModal.open({
      templateUrl: 'submissions/templates/assignment-introduction-modal.html',
      windowClass: 'assignment-intro-modal full-screen-modal-handheld full-screen-modal-tablet',
      controller: 'AssignmentIntroModalController as introModalCtrl',
      resolve: {
        exercise: vm.ExercisesManager.currentExercise,
      },
    });
  };

  vm.launchCopiedSuccessMessage = () => AlertMessages.success('EXERCISES.SUBMISSION_LINK_COPIED', '');

  vm.shouldShowStickyFooter = () => (ReportsManager.currentReport?.isCompleted() || ReportsManager.currentReport?.isApproved())
    && !ReportsManager.currentReport?.exercise?.hasSkillsRating
    && (!CurrentUserManager.hasEnrolledInCourse(vm.course) || !CurrentUserManager.hasLoggedIn() || CurrentUserManager.hasEnrolledInCourse(vm.course));

  vm.getDueDateText = () => {
    if (ReportsManager.currentReport?.exercise.deadline) {
      const dueDateMoment = moment(ReportsManager.currentReport.exercise.deadline);
      const timeDifference = dueDateMoment - moment();
      if (timeDifference && timeDifference > 0) {
        if (Math.floor(timeDifference / DAY_THRESHOLD)) {
          if (Math.floor(timeDifference / DAY_THRESHOLD) <= 7) {
            return $translate.instant(DUE_DATE_STRINGS.days, { count: Math.floor(timeDifference / DAY_THRESHOLD) });
          }
          return $translate.instant(DUE_DATE_STRINGS.date, { deadline: dueDateMoment.format('MOMENT.MONTH_DAY_YEAR') });
        } if (Math.floor(timeDifference / HOUR_THRESHOLD)) {
          return $translate.instant(DUE_DATE_STRINGS.hours, { count: Math.floor(timeDifference / HOUR_THRESHOLD) });
        }
        return $translate.instant(DUE_DATE_STRINGS.minutes, { count: Math.floor(timeDifference / MINUTE_THRESHOLD) || 1 });
      }
    }
    return null;
  };

  vm.getIsDueSoon = () => {
    if (ReportsManager.currentReport?.exercise.deadline) {
      const dueDate = ReportsManager.currentReport.exercise.deadline;
      const timeDifference = moment(dueDate) - moment();
      return timeDifference && timeDifference > 0 && Math.floor(timeDifference / DAY_THRESHOLD) <= 7;
    }
    return null;
  };

  vm.shouldShowEvaluationsReceived = () => ExercisesManager.currentExercise
        && !ReportsManager.currentReport?.exercise?.hasSkillsRating
        && ReportsManager.currentReport?.numReviewsReceived
        && !ExercisesManager.currentExercise.customQuestions?.isFeedbackPublic
        && (ReportsManager.currentReport.isCollaborator || CurrentUserManager.isAdminForCurrentCourse());

  vm.shouldShowApprovalStatus = () => ReportsManager.currentReport?.exercise?.approvalRequired && ReportsManager.currentReport.isCollaborator;

  vm.hasPendingApproval = () => ReportsManager.currentReport?.isPendingApproval() && ReportsManager.currentReport?.canReview;

  vm.allowShareToTeam = () => {
    if (!CurrentUserManager.hasLoggedIn()) {
      return false;
    }

    const courseCloseDate = CurrentUserManager.user.enrollments.find(enrollment => enrollment.course.catalogId === $stateParams.catalogId)?.course.closeDate;

    return ReportsManager.currentReport?.currentRevision?.creator?.id === CurrentUserManager.user.id
      && (ExercisesManager.currentExercise?.submissionsViewableBeforeDeadline || ExercisesManager.currentExercise?.submissionsReleased)
      && ExercisesManager.currentExercise.promptShareSubmission
      && !ReportsManager.currentReport.isPendingApproval()
      && !ReportsManager.currentReport.isRejectedCanRevise()
      && (!courseCloseDate || moment().isBefore(moment(courseCloseDate)))
      && TeamManager.getTeamsGroupsInCurrentCourse()?.length
      && ReportsManager.currentReport.privacySetting !== 'shared_with_instructor';
  };

  vm.shareSubmissionToTeam = () => {
    $uibModal.open({
      templateUrl: 'lecture_pages/templates/components/exercise-share-submission-modal.html',
      windowClass: 'large-modal',
      controller: 'ExerciseShareSubmissionModalCtrl as vm',
      resolve: {
        submission: vm.ReportsManager.currentReport,
      },
    });
  };

  vm.highlightBookmark = () => {
    $scope.stickyHeaderFooterCtrl.closeL4();
    BookmarkModel.highlightBookmark(ReportsManager.currentReport.bookmarkId);
  };

  vm.createBookmark = () => {
    $scope.stickyHeaderFooterCtrl.closeL4();
    BookmarkModel.createBookmark({
      type: BookmarkType.SUBMISSION,
      catalogId: $stateParams.catalogId,
      componentId: ReportsManager.currentReport.id,
    })
      .then(bookmark => {
        ReportsManager.currentReport.bookmarkId = bookmark.id;
      });
  };
}
