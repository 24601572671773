import { useSelector } from 'react-redux';
import {
  AssignedConnection,
  MentoringProgramEnrollment,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { getMentoringProgramActionItems } from 'redux/selectors/mentorship-program-action-items';
import ActionItemsPanelContent from './action-items-panel-content';
import { useState } from 'react';
import { FirstSeenKey } from 'athena/components/constants';
import SlideTransition, { Slide } from 'athena/components/slide-transition';
import StarsConfetti from 'athena/components/stars-confetti';

type ActionItemsPanelProps = {
  connection: AssignedConnection;
  enrollment: MentoringProgramEnrollment;
  showPlanSessionFlyout: (session?: MentorshipProgramSession) => void;
};

const ActionItemsPanel = ({
  connection,
  enrollment,
  showPlanSessionFlyout,
}: ActionItemsPanelProps) => {
  const { connectionId } = connection;
  const actionItems = useSelector(getMentoringProgramActionItems(connectionId));
  const ACTION_ITEMS_SEEN_KEY = `${FirstSeenKey.ACTION_ITEMS_SEEN}-${enrollment.id}`;
  const [showConfetti, setShowConfetti] = useState(false);

  const { firstConnectionAnnounced } = enrollment;
  if (!firstConnectionAnnounced) {
    return null;
  }

  const onSeen = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  };

  const isActionItemsListEmpty = !actionItems?.length;

  return (
    <StarsConfetti
      showLeft={showConfetti && isActionItemsListEmpty}
      showRight={showConfetti && isActionItemsListEmpty}
    >
      <SlideTransition
        type={Slide.IN}
        seenKey={ACTION_ITEMS_SEEN_KEY}
        onSeen={onSeen}
        showTransition={isActionItemsListEmpty}
      >
        <ActionItemsPanelContent
          actionItems={actionItems}
          connection={connection}
          enrollment={enrollment}
          showPlanSessionFlyout={showPlanSessionFlyout}
          className={
            showConfetti && isActionItemsListEmpty ? 'show-gradient' : ''
          }
        />
      </SlideTransition>
    </StarsConfetti>
  );
};

export default ActionItemsPanel;
