import t from 'react-translate';
import { useContext, useEffect } from 'react';

// Redux
import { useAppDispatch } from 'redux/store';
import { setSaveStatus, updateMentoringProgram } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { wrapThunkAction } from 'redux/utils';

// Form
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

// Styles
import { css } from '@emotion/react';
import { almostBlack } from 'athena/styles/colors';
import { headerLineHeight } from 'styles/global_defaults/fonts';

// Components
import MatchAnnouncementDate from './match-announcement-date';
import MatchingRules from './matching-rules';

import MentoringProgramContext from '../../context';
import moment from 'moment';

const shouldSubmitMatchAnnouncementDate = (data, matchAnnouncementDate) => {
  if (!data?.matchAnnouncementDate && matchAnnouncementDate) return true;
  const matchAnnouncementDateISO = new Date(data.matchAnnouncementDate).toISOString();
  const dataAnnouncementDateISO = new Date(matchAnnouncementDate).toISOString();
  return matchAnnouncementDateISO !== dataAnnouncementDateISO;
}

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  .description {
    color: ${almostBlack};
    line-height: ${headerLineHeight}px;
  }
`;

const MatchingSettings = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const {
    id,
    endDate,
    releasedDate,
    autoMatchingEnabled,
    matchAnnouncementDate,
  } = mentoringProgram || {};

  const validationSchema = yup.object().shape({
    matchAnnouncementDate: yup.string().nullable()
      .test(
        'is-after-start-date',
        t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_SETTINGS.MATCH_ANNOUNCEMENT_DATE.ERROR_BEFORE_RELEASE_DATE(),
        (value) => {
          if (!releasedDate || !value) return true;
          const minDate = moment(releasedDate).isAfter(moment()) ? moment(releasedDate) : moment();
          return moment(value).isAfter(minDate);
        }
      )
      .test(
        'is-before-end-date',
        t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_SETTINGS.MATCH_ANNOUNCEMENT_DATE.ERROR_AFTER_CLOSURE_DATE(),
        (value) => {
          if (!endDate || !value) return true;
          return new Date(value) < new Date(endDate);
        }
      ),
    });

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      matchAnnouncementDate: null,
    },
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (mentoringProgram) {
      reset({
        matchAnnouncementDate: mentoringProgram?.matchAnnouncementDate || null,
      });
    }
  }, [mentoringProgram, reset]);

  const onSubmit = (data: { matchAnnouncementDate: string }) => {
    if (shouldSubmitMatchAnnouncementDate(data, matchAnnouncementDate)) {
      dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
      wrapThunkAction(dispatch(updateMentoringProgram({ id, autoMatchingEnabled, ...data }))).then(() => {
        dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
      }).finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
    }
  };

  return (
    <div css={styles}>
      <div className='pb-4 mb-8'>
        <div className='title heading-2 mb-2'>
          {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_SETTINGS.TITLE()}
        </div>
        <div className='description text-regular mb-6'>
          {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_SETTINGS.DESCRIPTION()}
        </div>
        <FormProvider {...methods}>
          <form>
            <MatchAnnouncementDate
              onSubmit={handleSubmit(onSubmit)}
              disabled={!releasedDate}
            />
          </form>
        </FormProvider>
        <MatchingRules />
      </div>
    </div>
  );
};

export default MatchingSettings;
