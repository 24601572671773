import { useMentoringProgramQuestionsAnswers } from 'athena/hooks/use-mentoring-program-questions-answers';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile-questions';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { css } from '@emotion/react';
import {
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { textMediumFontSize } from 'styles/global_defaults/fonts';
import { OrgLevelQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';
import { gray2 } from 'styles/global_defaults/colors';
import { novoEdBlack } from 'athena/styles/colors';
import { QuestionsProps } from '../types';
import { config } from '@config/pendo.config.json';
import AccountQuestions from './account-questions';
import Question from '../question';

const styles = css`
  .account-level-fields,
  .org-level-fields {
    gap: ${standardSpacing}px;
  }
  .upload-photo-btn {
    height: 35px;
    padding: 0 ${threeQuartersSpacing}px;
  }
  .name {
    gap: ${standardSpacing}px;
  }
  .input-label {
    font-size: ${textMediumFontSize}px;
  }
  .org-question {
    .title {
      color: ${gray2};
    }
    .answer {
      color: ${novoEdBlack};
    }
  }
`;

const OrgQuestions = ({
  enrollmentId,
  mentorshipProgramId,
}: QuestionsProps) => {
  const { ref, inView: isEndReached } = useInView();
  const {
    questions,
    loadMore,
  }: {
    questions: MentorshipProgramProfileAnswer[];
    loadMore: () => void;
  } = useMentoringProgramQuestionsAnswers({
    type: ProgramProfileQuestionType.ORG_LEVEL,
    mentorshipProgramId,
    enrollmentId,
  });

  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.steps.profile.questions.orgLevel;

  const orgLevelFields = useMemo(
    () => (
      <div className='d-flex flex-column org-level-fields'>
        {questions
          ?.filter(question => !question.isNovoedQuestion)
          .map(question => {
            const { id, profileQuestion, answer, isRequired } = question;
            const { profileSetting } = (profileQuestion as OrgLevelQuestion) || {};
            const {
              name: questionText,
              isHidden,
              isCsvManaged,
              isIntegrated,
              inputType,
              selectOptions,
            } = profileSetting || {};
            if (isCsvManaged || isIntegrated) {
              if (!isHidden && answer) {
                return (
                  <div key={id} className='org-question'>
                    <div className='title text-small semi-bold mb-1'>
                      {questionText}
                    </div>
                    <div className='answer bold'>{answer}</div>
                  </div>
                );
              }
            } else {
              return (
                <Question
                  id={id}
                  key={id}
                  type={inputType}
                  isRequired={isRequired}
                  title={questionText}
                  answer={answer}
                  options={selectOptions}
                  dataQa={dataQATag}
                  dataQaId={`${dataQATag}_${id}`}
                />
              );
            }
            return null;
          })}
      </div>
    ),
    [questions],
  );

  useEffect(() => {
    if (isEndReached) {
      loadMore();
    }
  }, [isEndReached, loadMore]);

  return (
    <div css={styles}>
      <AccountQuestions questions={questions} />
      {orgLevelFields}
      <div ref={ref} />
    </div>
  );
};

export default OrgQuestions;
