import t from 'react-translate';
import ClickableContainer from 'components/clickable-container';
import moment from 'moment';
import ProfilePicture from 'org_level_profile/components/profile-picture';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AssignedEnrollment,
  MentoringProgramEnrollment,
} from 'redux/schemas/models/mentoring-program-enrollments';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { config } from '@config/pendo.config.json';
import { tooltipZIndex } from 'athena/components/constants';
import { css } from '@emotion/react';
import { optionStyles } from './styles';

type SwitchAsigneeOptionProps = {
  actionItemId: number;
  assignedTo: number;
  enrollments: { [id: number]: MentoringProgramEnrollment };
  isCompleted: boolean;
  completedAt: string;
  completedBy: AssignedEnrollment;
};

const styles = css`
  .switch-assignee-icon {
    display: none !important;
  }

  &:hover {
    .switch-assignee-icon {
      ${optionStyles}
      display: inline !important;

    }
    div {
      display: none !important;
    }
  }
`;

const SwitchAssigneeOption = ({
  actionItemId,
  isCompleted,
  assignedTo,
  enrollments,
  completedAt,
  completedBy,
}: SwitchAsigneeOptionProps) => {
  const { setValue } = useFormContext();
  const [currentAssignee, setCurrentAssignee] = useState(
    enrollments[assignedTo],
  );
  const { id, user } = currentAssignee || {};
  const connectionEnrollmentId = Object.keys(enrollments).find(
    (enrollmentId) => Number(enrollmentId) !== id,
  );
  const connectionEnrollment: MentoringProgramEnrollment = enrollments[connectionEnrollmentId];
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.ACTION_ITEMS.ITEM.TOOLTIP;
  const dataQATag = config.pendo.athena.mentorshipProgram.participantHome.actionItems.item
    .editActionItemAssignedTo;

  const handleSwitchAssignee = () => {
    setCurrentAssignee(connectionEnrollment);
    setValue('assignedTo', connectionEnrollmentId);
  };

  useEffect(() => {
    setCurrentAssignee(enrollments[assignedTo]);
  }, [assignedTo]);

  if (isCompleted && completedBy && completedAt) {
    const {
      user: { fullName },
    } = completedBy;
    const dateFormat = translationBase.DATE_FORMAT();
    const formattedDate = moment(completedAt).format(dateFormat);
    const tooltip = translationBase.COMPLETED_BY(formattedDate, fullName);

    return user ? (
      <NvTooltip placement='top' text={tooltip} zIndex={tooltipZIndex}>
        <div>
          <ProfilePicture profile={user} size={24} />
        </div>
      </NvTooltip>
    ) : null;
  }

  return currentAssignee ? (
    <ClickableContainer
      css={styles}
      onClick={handleSwitchAssignee}
      data-qa={dataQATag}
      data-qa-id={`${dataQATag}-${actionItemId}`}
    >
      <NvTooltip
        placement='top'
        text={translationBase.CHANGE_OWNER(connectionEnrollment.user.fullName)}
        zIndex={tooltipZIndex}
      >
        <NvIcon
          icon='switch'
          size='smallest'
          className='switch-assignee-icon'
        />
      </NvTooltip>
      <ProfilePicture profile={user} size={24} />
    </ClickableContainer>
  ) : null;
};

export default SwitchAssigneeOption;
