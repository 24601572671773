import { AssignedEnrollment, MentoringProgramEnrollment } from './mentoring-program-enrollments';

export enum ActionItemStatus {
  COMPLETED = 'completed',
  INCOMPLETE = 'incomplete',
}

export enum ActionItemOwner {
  SESSION = 'mentorship_program_session',
  CONNECTION = 'mentorship_program_connection',
}

export type MentoringProgramActionItem = {
  id: number;
  description: string;
  assignedTo: AssignedEnrollment;
  dueDate: string;
  status: ActionItemStatus;
  owner: {
    id: number;
    type: ActionItemOwner;
  }
  completedAt: string;
  completedBy: AssignedEnrollment;
};
