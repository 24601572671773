import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';
import { useFormContext } from 'react-hook-form';

// Schemas
import { RootState } from 'redux/schemas';
import { CommunicationCategory, CommunicationType, ScheduledForType, ItemState } from 'redux/schemas/models/course-communication';

// Contexts
import { CommunicationDispatch, getCategory, TriggerType } from 'communications/course_communications/contexts/communication-context';
import { CommunicationFormContext, FormSubmittedState, UseCommunicationMethods } from 'communications/course_communications/hooks/use-communication-form';

// Selectors
import { getActivity, getItemState } from 'redux/selectors/course-communications';

// Actions
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

// Components
import Button from 'react-bootstrap/Button';
import { isPastDate } from './send_on/send-on';

const mapDispatchToProps = {
  openConfirmationDialog,
};

type SubmitButtonProps = {
  isValid?: boolean
};

const SubmitButton = (props: SubmitButtonProps & typeof mapDispatchToProps) => {
  const { State } = useContext(CommunicationDispatch);
  const activity: any = useSelector((state: RootState) => getActivity(state, State.activityType, State.activityId));
  const { getCommunicationDraft, formSubmitState, submitForm,
  } = useContext<UseCommunicationMethods>(CommunicationFormContext);
  const itemState = useSelector<RootState, ItemState>((state) => getItemState(state, State.communicationId));
  const { watch } = useFormContext() ?? {};

  // Use watch when available,
  // Step3 is not wrapped with RHF, so using communicationDraft
  let scheduledFor;
  let xDays;

  if (watch) {
    const [watchScheduledFor, watchXDays] = watch(['scheduledFor', 'xDays']);

    xDays = watchXDays;
    scheduledFor = watchScheduledFor;
  } else {
    const {
      xDays: draftXDays,
      scheduledFor: draftScheduledFor,
    } = getCommunicationDraft();

    xDays = draftXDays;
    scheduledFor = draftScheduledFor;
  }

  const hasEmail = watch ? watch('hasEmail') : null;
  const referenceDate = State.communicationType === CommunicationType.DUE_DATE_EMAIL
    || State.communicationType === CommunicationType.DUE_DATE_ANNOUNCEMENT
    ? activity?.dueDate : activity?.releaseDate;
  const isScheduledPast = State.communicationType === CommunicationType.MANUAL_EMAIL
    || State.communicationType === CommunicationType.MANUAL_ANNOUNCEMENT
    || State.communicationCategory === CommunicationCategory.TRIGGERS
    ? false : isPastDate(referenceDate, scheduledFor, xDays);

  const isEditAnnouncementOnly = itemState === ItemState.SENT
    && State.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT
    && State.triggerType === TriggerType.EDIT;
  const stateKey = formSubmitState === FormSubmittedState.SUBMITTING ? 'SUBMITTING' : 'NAME';
  let typeKey: string = '';
  let buttonLabel: string = '';

  if (getCategory(State.communicationType) === CommunicationCategory.TRIGGERS
    || getCategory(State.communicationType) === CommunicationCategory.WELCOME_EMAIL) {
    // Triggers and welcome email have similar buttonText
    typeKey = 'TRIGGERS';
  } else {
    typeKey = 'SCHEDULED';
  }

  switch (State.triggerType) {
    case TriggerType.CREATE:
    case TriggerType.ADD:
      buttonLabel = t.COURSE_COMMUNICATIONS[typeKey].CREATE[stateKey]();
      break;
    case TriggerType.EDIT:
      buttonLabel = t.COURSE_COMMUNICATIONS.TRIGGERS.EDIT[stateKey]();
      break;
    case TriggerType.DUPLICATE:
      buttonLabel = t.COURSE_COMMUNICATIONS.TRIGGERS.DUPLICATE[stateKey]();
      break;
    default: buttonLabel = '';
      break;
  }

  if (!isEditAnnouncementOnly
    && (scheduledFor === ScheduledForType.SEND_NOW || isScheduledPast)) {
    buttonLabel = t.COURSE_COMMUNICATIONS.SCHEDULED.CREATE.SEND_NOW();
  }

  const onSubmit = () => {
    if (!isEditAnnouncementOnly
      && (isScheduledPast || scheduledFor === ScheduledForType.SEND_NOW)) {
      let warningTextKey: string = 'EMAIL';
      let titleKey: string = 'EMAIL';

      if (State.communicationCategory === CommunicationCategory.SCHEDULED_ANNOUNCEMENT) {
        warningTextKey = hasEmail ? 'ANNOUNCEMENT_AND_EMAIL' : 'ANNOUNCEMENT_ONLY';
        titleKey = 'ANNOUNCEMENT';
      }

      props.openConfirmationDialog({
        title: t.COURSE_COMMUNICATIONS.SUBMIT_CONFIRMATION.TITLE[titleKey](),
        bodyText: t.COURSE_COMMUNICATIONS.SUBMIT_CONFIRMATION.WARNING[warningTextKey](),
        confirmText: t.FORM.YES_SURE(),
        confirmButtonVariant: 'primary',
        onConfirm: () => submitForm(),
      });
    } else {
      submitForm();
    }
  };

  return (
    <Button
      type='button'
      disabled={formSubmitState === FormSubmittedState.SUBMITTING || !props.isValid}
      onClick={onSubmit}
    >
      { buttonLabel }
    </Button>
  );
};

const ConnectedSubmitButton = connect(null, mapDispatchToProps)(SubmitButton);

export default ConnectedSubmitButton;
