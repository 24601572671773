/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */

import { css } from '@emotion/react';
import t from 'react-translate';
import { Fragment, MutableRefObject, useCallback, useContext, useEffect, useState } from 'react';
import NvFroalaInline, {
  InlineRichTextEditorProps,
  InlineRichTextEditorImplerativeInterface,
} from 'froala/components/nv-froala-inline';
import { AccordionSectionLectureComponent } from 'redux/schemas/models/lecture-component';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { standardSpacing, halfSpacing } from 'styles/global_defaults/scaffolding';
import { black, getColorBrightness, gray4, gray5 } from 'styles/global_defaults/colors';
import ClickableContainer from 'components/clickable-container';
import ColorIconButton from 'components/color-icon-button';
import ColorPickerPopover, { ColorPickerPopoverProps, ColorValuesMap } from 'components/color-picker-popover';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { deleteAccordionSection } from 'redux/actions/lecture-pages';
import { useAppDispatch } from 'redux/store';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { normalizeHTMLInput, normalizeHTMLOutput } from './accordion-utils';
import { LecturePageMode } from '..';

type Rename<T, K extends keyof T, N extends string> = Pick<T, Exclude<keyof T, K>> & { [key in N]: T[K] };

type ColorPickerInheritedProps<ColorValuesMapT extends ColorValuesMap> = Rename<
Pick<ColorPickerPopoverProps<ColorValuesMapT>, 'colorValues' | 'onChange' | 'sections' | 'renderBeforePalette'>,
'onChange', 'onColorChange'
>;

interface RtTitleProps<ColorValuesMapT extends ColorValuesMap> extends ColorPickerInheritedProps<ColorValuesMapT> {
  sectionData: AccordionSectionLectureComponent;
  mode: LecturePageMode;
  isOnlySection: boolean;
  onBlur?: InlineRichTextEditorProps['onBlur'];
  onFocus?: InlineRichTextEditorProps['onFocus'];
  editorRef?: MutableRefObject<InlineRichTextEditorImplerativeInterface>;
  onChange?(html: string): void;
  autoFocus?: boolean;
  defaultTextColor?: string;

  currentLecture: NLecturePage;

  // used to calculate the proper placeholder text color
  backgroundColor?: string;
}

//
// the following normalization functions are meant to fix a iOS issue where
// an HTML template without text causes the container to be taller
//

/* @ngInject */
export default function AccordionRtHeader<ColorValuesMapT extends ColorValuesMap>(
  props: RtTitleProps<ColorValuesMapT>,
) {
  const defaultHeaderTemplate = `<p class="froala-style-title-small"><strong><span style="color: ${
    props.defaultTextColor || black
  }"><br /></span></strong></p>`;

  // we would rely only on the value prop but unfortunately the prop updates
  // asynchronously and that's undesired to uses as the value of the controlled
  // component
  const [value, setValue] = useState<string>(normalizeHTMLInput(props.sectionData.header, defaultHeaderTemplate));

  // keep prop and internal state sync
  useEffect(() => {
    setValue(normalizeHTMLInput(props.sectionData.header, defaultHeaderTemplate));
  }, [defaultHeaderTemplate, props.sectionData.header]);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(props.autoFocus);
  const [isRightSideColorPickerOpen, setIsRightSideColorPickerOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const baseComponentContext = useContext(BaseLectureComponentContext);
  const { deleteComponent } = baseComponentContext;
  const params = useLecturePageParams();

  function handleModelChange(html: string) {
    setValue(html);
  }

  function handleBur(e) {
    setIsEditorFocused(false);
    props.onBlur?.(e);
    props.onChange?.(normalizeHTMLOutput(value));
  }

  function handleFocus(e) {
    setIsEditorFocused(true);
    props.onFocus?.(e);
  }

  /* eslint-disable react/no-danger */
  let content = (
    <h3
      css={css`
        padding: ${standardSpacing}px ${standardSpacing}px ${standardSpacing}px 0;
        margin: 0;
      `}
      dangerouslySetInnerHTML={{ __html: props.sectionData.header }}
    />
  );

  let placeholderColor = gray4;
  // if gray4 is too dark for the backgroundColor, use gray5
  if (props.backgroundColor && getColorBrightness(props.backgroundColor) - getColorBrightness(gray4) < 10) {
    placeholderColor = gray5;
  }

  if (props.mode === LecturePageMode.EDIT) {
    content = (
      <NvTooltip
        text={t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.HEADER_RTE()}
        show={!isEditorFocused && showTooltip && !props.currentLecture.isLinked}
        onToggle={(nextShow) => {
          setShowTooltip(nextShow);
        }}
      >
        <NvFroalaInline
          keepFormatOnDelete
          containerStyle={css`
            cursor: pointer;
          `}
          placeholderColor={placeholderColor}
          autoFocus={props.autoFocus}
          value={value}
          onBlur={handleBur}
          onFocus={handleFocus}
          ref={props.editorRef}
          placeholder={t.LECTURE_PAGES.COMPONENTS.ACCORDION.TITLE_PLACEHOLDER()}
          onModelChange={handleModelChange}
          scrollableContainer='.main-panel-scrollable'
        />
      </NvTooltip>
    );
  }

  const onDelete = useCallback(() => {
    if (props.isOnlySection) {
      deleteComponent();
    } else {
      dispatch(
        openConfirmationDialog({
          title: t.LECTURE_PAGES.COMPONENTS.DELETION_MODAL.TITLE(
            t.LECTURE_PAGES.COMPONENTS.ACCORDION.SECTION.DESCRIPTION_WITH_INDEX(String(props.sectionData.index + 1)),
          ),
          bodyText: t.LECTURE_PAGES.COMPONENTS.DELETION_MODAL.SIMPLE_DESCRIPTION(),
          cancelText: t.FORM.CANCEL(),
          confirmText: t.FORM.YES_SURE(),
          onConfirm: () => {
            dispatch(
              deleteAccordionSection({
                lecturePageId: props.currentLecture.id,
                catalogId: params.catalogId,
                accordionId: props.sectionData.ownerId,
                childLectureComponentId: props.sectionData.id,
              }),
            );
          },
        }),
      );
    }
  }, [dispatch, deleteComponent, props.currentLecture.id, params.catalogId, props.sectionData.ownerId, props.sectionData.id, props.sectionData.index, props.isOnlySection]);

  return (
    <div
      className='d-flex flex-grow-1 w-100 accordion-rt-header-container'
      css={css`
        /* for text wrapping: https://css-tricks.com/flexbox-truncated-text/ */
        min-width: 0;
      `}
    >
      <div
        css={css`
          flex: 1;

          /* for text wrapping: https://css-tricks.com/flexbox-truncated-text/ */
          min-width: 0;

          .fr-box {
            overflow: hidden;
          }

          .fr-element {
            padding: ${standardSpacing}px ${halfSpacing}px ${standardSpacing}px 0;
          }

          p {
            margin: 0;
          }
        `}
      >
        {content}
      </div>
      {props.mode === LecturePageMode.EDIT && (
        <Fragment>
          <ColorPickerPopover<ColorValuesMapT>
            placement='top-end'
            sections={props.sections}
            show={isRightSideColorPickerOpen}
            onToggle={(nextShow) => {
              setIsRightSideColorPickerOpen(nextShow);
            }}
            colorValues={props.colorValues}
            onChange={props.onColorChange}
            renderBeforePalette={props.renderBeforePalette}
          >
            <ColorIconButton
              tooltip={t.LECTURE_PAGES.COMPONENTS.ACCORDION.TOOLTIP.RIGHT_HAND_TOGGLE_COLOR_PICKER()}
              css={css`
                visibility: ${isEditorFocused || isRightSideColorPickerOpen ? 'visible' : 'hidden'};
                .accordion-rt-header-container:hover & {
                  visibility: visible;
                }
                margin-right: ${standardSpacing}px;
              `}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
            />
          </ColorPickerPopover>
          <ClickableContainer
            css={css`
              visibility: ${isEditorFocused || isRightSideColorPickerOpen ? 'visible' : 'hidden'};
              .accordion-rt-header-container:hover & {
                visibility: visible;
              }
              margin-right: 4px;
              color: ${props.sectionData.viewOptions.iconColor};
            `}
            className='d-flex align-items-center justify-content-end pr-2'
            onClick={onDelete}
          >
            <NvTooltip text={t.LECTURE_PAGES.COMPONENTS.ACCORDION.DELETE_THIS_SECTION()}>
              <NvIcon size='small' icon='trash' />
            </NvTooltip>
          </ClickableContainer>
        </Fragment>
      )}
    </div>
  );
}
