/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

import { RootState } from 'redux/schemas';
import { MentoringProgramActionItem } from 'redux/schemas/models/mentoring-program-action-items';
import { byActionItemId } from 'athena/components/mentoring-program/home/participant/utils';

export const getMentoringProgramActionItemsList = (connectionId: number) => createSelector(
  (state: RootState) => state.models.mentorshipProgramActionItems[connectionId],
  (state, ids: number[]) => ids,
  (
    mentorshipProgramActionItems: Record<number, MentoringProgramActionItem>,
    ids: number[],
  ) => ids.map((id) => mentorshipProgramActionItems[id]),
);

export const getMentoringProgramActionItems = (connectionId: number) => (state: RootState) => (
  Object.values(state.models.mentorshipProgramActionItems[connectionId] || {}).sort(byActionItemId)
);
