import moment from 'moment';
import pick from 'lodash/pick';
import t from 'react-translate';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DeepPartial } from 'utility-types';

import { RootState } from 'redux/schemas';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { gray6 } from 'styles/global_defaults/colors';
import { CourseAliases } from 'redux/schemas/models/course';
import { getProgressiveQuiz } from 'redux/selectors/quizzes';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { ProgressiveQuizModal } from 'quizzes/components/quiz-modal';
import { setNovoAIProgressiveQuizQuestionModalId, updateLectureComponent } from 'redux/actions/lecture-pages';
import { resetNewComponent } from 'redux/actions/lecture-components';
import ActivityTitle from 'shared/components/activity/activity-title';
import ActivityStatus from 'shared/components/activity/activity-status';
import ActivityPoints from 'shared/components/activity/activity-points';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import LectureComponentDeadline from 'components/lecture-component-deadline';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import LectureComponentHeaderInput from 'shared/components/lecture-component-header-input';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import NvIcon from 'shared/components/nv-icon';
import { resetProgressiveQuizResponses } from 'redux/actions/quizzes';
import { config } from '../../../../config/config.json';

type ProgressiveQuizLectureComponentType = LectureComponent<ComponentType.PROGRESSIVE_QUIZ>;

const ProgressiveQuizLectureComponent = (props: LectureComponentProps<ComponentType.PROGRESSIVE_QUIZ>) => {
  const {
    mode,
    currentLecture,
    lectureComponent,
  } = props;

  const dispatch = useAppDispatch();
  const currentLectureId = currentLecture.id;
  const isEditMode = mode === LecturePageMode.EDIT;
  const currentCourse = useSelector(getCurrentCourse);
  const isViewMode = props.mode === LecturePageMode.VIEW;
  const isNotViewMode = props.mode !== LecturePageMode.VIEW;
  const isCurrentCourseSelfPaced = currentCourse.isSelfPaced;
  const isDeadlineEditable = mode === LecturePageMode.EDIT || mode === LecturePageMode.LINKED_EDIT;

  const aliases = useSelector<RootState, CourseAliases>(
    (state: RootState) => getCourseAliases(state),
  );

  const { injectServices } = React.useContext(AngularContext);
  const [L4Modal] = injectServices(['L4Modal']);

  const [showEditBasics, setShowEditBasics] = React.useState(false);
  const currentCatalogId = useSelector((state) => state.app.currentCatalogId);
  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);

  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);
  const initialSharedProps = React.useRef(sharedProps).current;

  const forwardedOnModalCloseRef = React.useRef<(e: Event, closeModal: Function) => void>();
  const lecturePageId = useSelector((state: RootState) => state.app.lecturePage.currentLectureId);
  const isNewComponent = useSelector((state) => state.app.newComponent === lectureComponent?.id) ?? false;

  const progressiveQuiz = useSelector((state) => getProgressiveQuiz(state, lectureComponent.progressiveQuiz));
  const { expirationDate, hardDeadlinePassed } = progressiveQuiz;
  const [title, setTitle] = React.useState<string>(progressiveQuiz.title);

  const course = useSelector((state) => getCurrentCourse(state));
  const lecturePage = useSelector(state => state.models.lecturePages[lecturePageId]);
  const progressiveQuizQuestionModalComponentId = useSelector(state => state.app.lecturePage.novoAI?.progressiveQuizQuestionModalComponentId);

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, progressiveQuiz.pointsConfiguration || 0)
  ));

  const isCompleted = progressiveQuiz.progress === 'completed';
  const isInProgress = progressiveQuiz.progress === 'in_progress';
  const isMissed = progressiveQuiz.progress === 'missed' || hardDeadlinePassed;
  const notStarted = progressiveQuiz.progress === 'not_started';

  const lectureComponentId = lectureComponent.id;

  const openQuizModal = React.useCallback((modalMode: ProgressiveQuizMode, extra?: any) => {
    L4Modal.open('quizzes/templates/progressive-quiz-modal.html', 'ProgressiveQuizModalCtrl', {
      mode: modalMode,
      lectureComponentId,
      ...(extra ?? { reveal: false, initialQuestionIndex: 0 }),
    });
  }, [L4Modal, lectureComponentId]);

  const openEditQuestions = React.useCallback((extras = {}) => {
    openQuizModal(
      ProgressiveQuizMode.EDIT,
      { reveal: false, initialQuestionIndex: extras.initialIndex ?? 0 },
    );
  }, [openQuizModal]);

  const openQuiz = React.useCallback((isRetry = false) => {
    // Resetting the current question responses when retrying the quiz.
    if (isRetry) {
      dispatch(resetProgressiveQuizResponses({
        questionSetId: progressiveQuiz.id,
      }));
    }
    openQuizModal(ProgressiveQuizMode.ANSWER);
  }, [dispatch, openQuizModal, progressiveQuiz.id]);

  const reviewPreviousAttempt = React.useCallback((reveal: boolean = false) => {
    openQuizModal(ProgressiveQuizMode.REVIEW, { reveal, initialQuestionIndex: 0 });
  }, [openQuizModal]);

  React.useEffect(() => {
    if (isNewComponent && mode === LecturePageMode.EDIT) {
      openEditQuestions();
      dispatch(resetNewComponent());
    }
  }, [mode, dispatch, isNewComponent, openEditQuestions]);

  // Open edit model when adding new questions to AI created quiz
  React.useEffect(() => {
    if (progressiveQuizQuestionModalComponentId
      && progressiveQuizQuestionModalComponentId === lectureComponentId
      && !L4Modal.isVisible()
    ) {
      dispatch(setNovoAIProgressiveQuizQuestionModalId(null));
      openEditQuestions({ initialIndex: progressiveQuiz.totalQuestions - 1 });
    }
  }, [
    L4Modal,
    dispatch,
    lectureComponentId,
    openEditQuestions,
    progressiveQuiz.totalQuestions,
    progressiveQuizQuestionModalComponentId,
  ]);

  const editBasics = () => setShowEditBasics(true);

  const saveComponent = React.useCallback((componentDataPatch: DeepPartial<ProgressiveQuizLectureComponentType['progressiveQuiz']>) => {
    const componentDataUpdate = {
      catalogId: currentCatalogId,
      lecturePageId: currentLectureId,
      componentData: {
        id: lectureComponentId,
        type: lectureComponent.type,
        progressiveQuiz: {
          ...pick(progressiveQuiz, [
            'isTodo',
            'hardDeadline',
            'expirationDate',
            'maximumAttempts',
            'questionMaximumAttempts',
          ]),
          ...componentDataPatch,
        },
      },
    };

    return dispatch(updateLectureComponent(componentDataUpdate as any));
  }, [
    dispatch,
    progressiveQuiz,
    currentLectureId,
    currentCatalogId,
    lectureComponentId,
    lectureComponent.type,
  ]);

  React.useEffect(() => {
    const options: NvDropdownOption[] = [{
      type: 'text',
      callback: editBasics,
      text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
      disabled: !!lecturePage.isLinked,
      tooltip: {
        enabled: !!lecturePage.isLinked,
        text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
        placement: 'left',
        offset: 20,
      },
    }, {
      type: 'text',
      callback: openEditQuestions,
      text: t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.EDIT_QUESTIONS(),
      disabled: !!lecturePage.isLinked,
      tooltip: {
        enabled: !!lecturePage.isLinked,
        text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
        placement: 'left',
        offset: 20,
      },
    }];

    if (!isCurrentCourseSelfPaced) {
      options.push(
        { type: 'text',
          text: expirationDate ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
          disabled: course.isContentManagementCollection,
          tooltip: {
            enabled: course.isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            offset: 20,
          },
          callback: () => {
            saveComponent({
              expirationDate: expirationDate
                ? null
                : moment(currentLecture.releaseDate).add(1, 'weeks').endOf('day').toISOString(),
            });
          },
        },
      );
    }

    setSharedProps({
      ...initialSharedProps,
      extraOptions: {
        mode: 'prepend',
        renderOnMount: true,
        options,
      },
    });
  }, [
    saveComponent,
    setSharedProps,
    expirationDate,
    openEditQuestions,
    initialSharedProps,
    isCurrentCourseSelfPaced,
    currentLecture.releaseDate,
  ]);

  const styles = css`
    width: 100%;
    padding-top: ${doubleSpacing}px;
    border-bottom: 1px solid ${gray6};
  `;

  const handleTitleInputChange = (newTitle: string) => setTitle(newTitle);

  const handleTitleBlur = () => saveComponent({
    title,
  });

  const closeModal = () => {
    setShowEditBasics(false);
  };

  const handleModalClose = (e) => {
    forwardedOnModalCloseRef.current?.(e, closeModal);
    if (!e.defaultPrevented) {
      closeModal();
    }
  };

  const attemptsLeft = Math.max(0, progressiveQuiz.maximumAttempts - progressiveQuiz.attemptsCompleted);

  const areUnlimitedAttempts = progressiveQuiz.maximumAttempts === -1;

  const isRequirementEnabled = pointsConfiguration && !pointsConfiguration.rewardsPointsProportionally;

  const requiredCorrectQuestionsCount = isRequirementEnabled ? Math.ceil(progressiveQuiz.answerableQuestionsCount * pointsConfiguration.threshold) : 0;

  const getCorrectAnswersCount = () => {
    /**
     * As per the back-end request, the correct_answers_count field should be used
     * to get the correctAnswersCount when the quiz is in the resume state.
     * In other cases, the score field should be used.
     */
    if (progressiveQuiz.resumeQuiz) {
      return progressiveQuiz.correctAnswersCount;
    }
    return progressiveQuiz?.submission?.score;
  };

  const isFinished = (() => {
    if (isMissed) {
      return true;
    }

    const areAllQuestionsCorrect = progressiveQuiz.answerableQuestionsCount === getCorrectAnswersCount();

    if (areAllQuestionsCorrect && !progressiveQuiz.resumeQuiz) {
      return true;
    }

    if (!areUnlimitedAttempts && !attemptsLeft) {
      return true;
    }

    return false;
  })();

  const canAnswer = !isFinished;

  return (
    <div className='d-flex flex-column align-items-center pb-6'>
      <div className='mb-2'>
        <ActivityStatus icon='quiz' status={progressiveQuiz.progress} />
      </div>
      <ActivityTitle
        status={progressiveQuiz.progress}
        statusesTexts={{
          not_started: t.QUIZZES.TAKE(),
          completed: t.FORM.SUBMITTED(),
        }}
      />
      <div className='d-flex flex-column align-items-center mb-6 w-100'>
        <LectureComponentHeaderInput
          required
          value={title}
          editable={isEditMode}
          onBlur={handleTitleBlur}
          onChange={handleTitleInputChange}
        />
        {!!progressiveQuiz.answerableQuestionsCount
          && ((pointsConfiguration && !!pointsConfiguration.points) ? (() => {
            const pointsText = (() => {
              const notCompletedText = pointsConfiguration.rewardsPointsProportionally ? t.POINTS.UP_TO_X_POINTS({
                maxPoints: progressiveQuiz.totalPoints?.[0],
                pointsAliasSingularized: aliases.pointsAliases.pointAlias,
                pointsAliasPluralized: aliases.pointsAliases.pointsAlias,
              }) : undefined;

              if (isViewMode) {
                if (isCompleted) {
                  return undefined;
                }

                return notCompletedText;
              }
              return notCompletedText;
            })();

            const label = (() => {
              const awardForPassingScore = !pointsConfiguration.rewardsPointsProportionally;

              if (awardForPassingScore) {
                if (isViewMode) {
                  if (progressiveQuiz.resumeQuiz) {
                    return t.TIMED_QUIZZES.ANSWERED_X_CORRECT(progressiveQuiz.correctAnswersCount, progressiveQuiz.answerableQuestionsCount);
                  }
                  if (isCompleted || isMissed) {
                    if (getCorrectAnswersCount() === progressiveQuiz.answerableQuestionsCount) {
                      return t.POINTS.ALL_CORRECT();
                    }

                    return isMissed
                      ? t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount)
                      : t.TIMED_QUIZZES.ANSWERED_X_CORRECT(getCorrectAnswersCount(), progressiveQuiz.answerableQuestionsCount);
                  }

                  if (isInProgress && !progressiveQuiz.resumeQuiz) {
                    return t.POINTS.NEED_MORE_FOR_POINTS(
                      requiredCorrectQuestionsCount.toString(),
                      getCorrectAnswersCount().toString(),
                    );
                  }

                  return t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount);
                }

                return t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount);
              }
              if (!awardForPassingScore && isViewMode) {
                if (isCompleted) {
                  if (getCorrectAnswersCount() === progressiveQuiz.answerableQuestionsCount) {
                    return t.POINTS.ALL_CORRECT();
                  }
                  return t.TIMED_QUIZZES.ANSWERED_X_CORRECT(getCorrectAnswersCount(), progressiveQuiz.answerableQuestionsCount);
                }
              }

              return undefined;
            })();

            const questionsText = (() => {
              if (notStarted || isMissed) {
                return t.QUIZZES.QUESTIONS(progressiveQuiz.answerableQuestionsCount);
              }

              if (getCorrectAnswersCount() < requiredCorrectQuestionsCount) {
                return t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount);
              }

              return undefined;
            })();

            return (
              <div className='activity-points'>
                {pointsConfiguration.rewardsPointsProportionally
                  && isCompleted
                  ? (
                    <div className='d-flex'>
                      {progressiveQuiz.pointsReceived ? (
                        <div className='d-flex text-small font-weight-bold'>
                          <NvIcon icon='highlight' size='xss-smallest' className='text-success mr-2' />
                          <span>
                            <span className='text-success'>{progressiveQuiz.pointsReceived}</span>
                            { (progressiveQuiz.totalPoints?.[0] > progressiveQuiz.pointsReceived) && (
                            <span className='text-gray-2'>
                              /
                              {progressiveQuiz.totalPoints?.[0]}
                            </span>
                            )}
                          </span>
                        </div>
                      ) : (
                        <div className='d-flex text-small font-weight-bold'>
                          <NvIcon icon='points' size='xss-smallest' className='text-gray-2 mr-2' />
                          <span className='text-gray-2'>
                            {t.POINTS.UP_TO_X_POINTS({
                              maxPoints: progressiveQuiz.totalPoints?.[0],
                              pointsAliasSingularized: aliases.pointsAliases.pointAlias,
                              pointsAliasPluralized: aliases.pointsAliases.pointsAlias,
                            })}
                          </span>
                        </div>
                      )}
                      {label && (
                      <div>
                        <span className='dot-separator' />
                        <span className='text-gray-3'>
                          {label}
                        </span>
                      </div>
                      )}
                    </div>
                  )
                  : (
                    <ActivityPoints
                      label={label}
                      pointsText={pointsText}
                      preLabelText={questionsText}
                      totalPoints={progressiveQuiz.totalPoints?.[0]}
                      pointsReceived={progressiveQuiz.pointsReceived}
                    />
                  )}
              </div>
            );
          })() : (() => {
            const questionsText = (() => {
              if (notStarted || isMissed) {
                return t.QUIZZES.QUESTIONS(progressiveQuiz.answerableQuestionsCount);
              }

              if (getCorrectAnswersCount() < progressiveQuiz.answerableQuestionsCount) {
                return t.POINTS.GOT_POINTS('', progressiveQuiz.answerableQuestionsCount, getCorrectAnswersCount());
              }

              if (getCorrectAnswersCount() === progressiveQuiz.answerableQuestionsCount) {
                return t.POINTS.ALL_CORRECT();
              }

              return undefined;
            })();

            return (
              <div className='activity-points'>
                <span className='text-gray-2'>
                  {questionsText}
                </span>
              </div>
            );
          })())}
        <div className={progressiveQuiz.expirationDate ? 'mt-2' : ''}>
          <LectureComponentDeadline
            isEdit={isEditMode}
            catalogId={catalogId}
            componentName='progressive-quiz'
            lecturePageId={currentLecture.id}
            expirationDate={progressiveQuiz.expirationDate}
            hasHardDeadlines={progressiveQuiz.hardDeadline}
            onHardDeadlineChange={(hardDeadline) => {
              saveComponent({ hardDeadline });
            }}
            onDeadlineChange={(date) => {
              saveComponent({ expirationDate: date?.toISOString() });
            }}
            isDeadlineEditable={isDeadlineEditable}
          />
        </div>
      </div>
      {(canAnswer || !attemptsLeft || (!progressiveQuiz.answerableQuestionsCount && !hardDeadlinePassed)) && (
        <>
          {areUnlimitedAttempts ? (
            <div className='label gray-3 mb-2'>
              {t.QUIZZES.UNLIMTED_ATTEMPTS()}
            </div>
          ) : (
            <>
              {progressiveQuiz.attemptsCompleted <= progressiveQuiz.maximumAttempts && (
                <div className='label gray-3 mb-2'>
                  {attemptsLeft
                    ? t.QUIZZES.ATTEMPT((progressiveQuiz.attemptsCompleted ?? 0) + 1, progressiveQuiz.maximumAttempts)
                    : t.QUIZZES.NO_ATTEMPTS_LEFT()}
                </div>
              )}
            </>
          )}
        </>
      )}
      {hardDeadlinePassed && (
        <div className='label gray-3 mb-2'>
          {t.QUIZZES.DEADLINE_PASSED()}
        </div>
      )}
      {(() => {
        const showReview = progressiveQuiz.attemptsCompleted || isMissed;

        const areTwoButtonsShown = showReview && canAnswer;

        // If progressive quiz only have statements
        if (!progressiveQuiz.answerableQuestionsCount) {
          return (
            <div className='d-flex'>
              {(notStarted || isInProgress) && (
                <Button
                  size='sm'
                  onClick={openQuiz}
                  disabled={isNotViewMode}
                  className={areTwoButtonsShown ? 'ml-2' : undefined}
                  data-qa={config.pendo.activities.progressiveQuiz[isInProgress ? 'resume' : 'start']}
                >
                  {isInProgress ? t.QUIZZES.RESUME_QUIZ() : t.QUIZZES.START()}
                </Button>
              )}
              {(isCompleted || hardDeadlinePassed) && (
                <Button
                  size='sm'
                  disabled={isNotViewMode}
                  onClick={() => reviewPreviousAttempt(true)}
                  data-qa={config.pendo.activities.progressiveQuiz.revealAnswers}
                >
                  {t.QUIZZES.REVEAL_ANSWERS()}
                </Button>
              )}
            </div>
          );
        }

        return (
          <div className='d-flex'>
            {showReview && (() => {
              const revealAnswers = (areUnlimitedAttempts || isFinished) && !progressiveQuiz.resumeQuiz;

              return (
                <Button
                  size='sm'
                  disabled={isNotViewMode}
                  variant={isFinished ? undefined : 'secondary'}
                  onClick={() => reviewPreviousAttempt(revealAnswers)}
                  data-qa={config.pendo.activities.progressiveQuiz[revealAnswers ? 'revealAnswers' : 'reviewPrevious']}
                >
                  {revealAnswers ? t.QUIZZES.REVEAL_ANSWERS() : t.QUIZZES.REVIEW_PREVIOUS()}
                </Button>
              );
            })()}
            {canAnswer && (
              <Button
                size='sm'
                onClick={() => openQuiz(!progressiveQuiz.resumeQuiz && !!progressiveQuiz.attemptsCompleted)}
                disabled={isNotViewMode || course.isContentManagementCollection}
                className={areTwoButtonsShown ? 'ml-2' : undefined}
                // eslint-disable-next-line no-nested-ternary
                data-qa={config.pendo.activities.progressiveQuiz[progressiveQuiz.resumeQuiz ? 'resume' : (progressiveQuiz.attemptsCompleted ? 'retry' : 'start')]}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {progressiveQuiz.resumeQuiz ? t.QUIZZES.RESUME_QUIZ() : (progressiveQuiz.attemptsCompleted ? t.QUIZZES.DECAYED_POINTS.RETRY_QUIZ() : t.QUIZZES.START())}
              </Button>
            )}
          </div>
        );
      })()}
      <div css={styles} />
      <NvModal
        header={t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TITLE.EDIT()}
        show={showEditBasics}
        fullHeight={false}
        type={ModalType.DYNAMIC}
        onClose={handleModalClose}
        body={(
          <ProgressiveQuizModal
            isEdit
            forwardOnModalClose={(fn) => {
              forwardedOnModalCloseRef.current = fn;
            }}
            initialValues={{
              quiz: progressiveQuiz.maximumAttempts,
              question: progressiveQuiz.questionMaximumAttempts,
            }}
            onCancel={() => setShowEditBasics(false)}
            onConfirm={async (payload) => {
              wrapThunkAction(dispatch(updateLectureComponent({
                catalogId,
                lecturePageId,
                componentData: {
                  id: lectureComponent.id,
                  type: lectureComponent.type,
                  ...payload,
                },
              }))).then(() => setShowEditBasics(false));
            }}
          />
        )}
      />
    </div>
  );
};

export default ProgressiveQuizLectureComponent;
