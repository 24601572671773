
import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import t from 'react-translate';

// schemas
import { Communication, CommunicationCategory, CommunicationType, ItemState, ViewMode } from 'redux/schemas/models/course-communication';
import { RootState } from 'redux/schemas';

// contexts
import {
  CommunicationDispatch,
  CommunicationAction,
  TriggerType,
} from 'communications/course_communications/contexts/communication-context';

// Selectors
import { getCommunication, getCurrentCourseWelcomeEmail, getItemState } from 'redux/selectors/course-communications';
import { getCurrentCourse } from 'redux/selectors/course';

// actions
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

// styles
import { standardModalWidth } from 'styles/global_defaults/scaffolding';
import { gray6 } from 'styles/global_defaults/colors';

// components
import NvModal, { ModalType } from 'shared/components/nv-modal';
import JourneyScheduledEmail from 'communications/journey_communications/components/communication-modal/journey-scheduled-email';
import AutomatedEmail from 'communications/journey_communications/components/communication-modal/journey-automated-email';
import ViewCommunication from './view-communication';
import OnComplete from './on-complete';
import OnNotComplete from './on-not-complete';
import ScheduledEmail from './scheduled-email';
import ScheduledAnnouncement from './scheduled-announcement';
import WelcomeEmail from './welcome-email';

const mapDispatchToProps = {
  openConfirmationDialog,
};

type Props = {
  catalogId: string,
} & typeof mapDispatchToProps;

const CommunicationModal = (props: Props) => {
  const { State, dispatch } = useContext(CommunicationDispatch);
  const { isJourney } = useSelector((state) => getCurrentCourse(state)) || {};
  const itemState = useSelector<RootState, ItemState>((state) => getItemState(state, State.communicationId));
  const communication: Communication = useSelector((state: RootState) => getCommunication(state, State.communicationId));
  const regularWelcomeEmail: Communication = useSelector(getCurrentCourseWelcomeEmail);

  const getModalTitle = () => {
    let titleKey: string = '';
    let title: string = '';
    switch (State.communicationCategory) {
      case CommunicationCategory.WELCOME_EMAIL:
        titleKey = 'WELCOME_EMAIL';
        if (State.viewMode === ViewMode.EMAIL_PREVIEW) {
          title = t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.VIEW.TITLE();
        }
        break;
      case CommunicationCategory.AUTOMATED_JOURNEY_EMAIL:
        titleKey = 'AUTOMATED';
        break;
      case CommunicationCategory.SCHEDULED_EMAIL:
        titleKey = 'SCHEDULED';
        break;
      case CommunicationCategory.SCHEDULED_ANNOUNCEMENT:
        if (State.viewMode !== ViewMode.FORM_VIEW) {
          title = t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.FORM[State.viewMode]();
        }
        if (itemState === ItemState.SENT
          && State.triggerType === TriggerType.EDIT
          && communication?.communication?.hasEmail
        ) {
          title = t.COURSE_COMMUNICATIONS.ANNOUNCEMENT.EDIT_ANNOUNCEMENT_ONLY();
        }
        titleKey = 'ANNOUNCEMENT';
        break;
      case CommunicationCategory.TRIGGERS:
        titleKey = 'TRIGGERS';
        break;
      default:
        break;
    }
    if (title === '') {
      const translationSet = isJourney ? t.JOURNEY_COMMUNICATIONS[titleKey] : t.COURSE_COMMUNICATIONS[titleKey];
      if (translationSet) {
        switch (State.triggerType) {
          default:
          case TriggerType.CREATE:
            title = translationSet.CREATE.TITLE();
            break;
          case TriggerType.ADD:
            title = translationSet.ADD.TITLE();
            break;
          case TriggerType.EDIT:
            title = translationSet.EDIT.TITLE();
            break;
          case TriggerType.DUPLICATE:
            title = translationSet.DUPLICATE.TITLE();
            break;
          case TriggerType.VIEW:
            title = translationSet.VIEW.TITLE();
            break;
        }
      }
    }
    return title;
  };

  const getModalBody = () => {
    if (State.communicationCategory === CommunicationCategory.WELCOME_EMAIL) {
      return regularWelcomeEmail && <WelcomeEmail catalogId={props.catalogId} />;
    }
    switch (State.triggerType) {
      case TriggerType.VIEW:
        return (<ViewCommunication communicationId={State.communicationId} />);
      default:
        switch (State.communicationType) {
          case CommunicationType.AUTOMATED_JOURNEY_EMAIL:
            return <AutomatedEmail />;
          case CommunicationType.DUE_DATE_EMAIL:
          case CommunicationType.RELEASE_DATE_EMAIL:
          case CommunicationType.MANUAL_EMAIL:
            if (isJourney) {
              return <JourneyScheduledEmail />;
            }
            return <ScheduledEmail />;
          case CommunicationType.DUE_DATE_ANNOUNCEMENT:
          case CommunicationType.RELEASE_DATE_ANNOUNCEMENT:
          case CommunicationType.MANUAL_ANNOUNCEMENT:
            return (
              <ScheduledAnnouncement />
            );
          case CommunicationType.NOT_COMPLETED:
            return (
              <OnNotComplete />
            );
          case CommunicationType.COMPLETED:
          default:
            return (
              <OnComplete />
            );
        }
    }
  };

  const closeModal = () => {
    // If there is a change ask a confirmation
    if (State.isDirty && State.triggerType !== TriggerType.VIEW) {
      props.openConfirmationDialog({
        title: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.TITLE(),
        bodyText: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.DESCRIPTION(),
        confirmText: t.FORM.CLOSE_ANYWAY(),
        cancelText: t.FORM.DO_NOT_CLOSE(),
        onConfirm: () => dispatch({ type: CommunicationAction.CLOSE_MODAL }),
      });
    } else {
      dispatch({ type: CommunicationAction.CLOSE_MODAL });
    }
  };

  return (
    <NvModal
      type={ModalType.DYNAMIC}
      width={standardModalWidth}
      header={getModalTitle()}
      headerBgColor={gray6}
      body={getModalBody()}
      show={State.showModal}
      onClose={closeModal}
    />
  );
};

const ConnectedCommunicationModalFlow = connect(null, mapDispatchToProps)(CommunicationModal);

export default ConnectedCommunicationModalFlow;
