import React, { useEffect, useState } from 'react';
import LinkCollectionLessonsModal from 'courses/components/course-lesson-link/link-lessons-modal';
import AddToCollectionModal from 'courses/components/course-lesson-to-collection/add-to-collection-modal';
import t from 'react-translate';
import store from 'redux/store';
import {
  canConvertToCollectionLecture,
  getCanLinkCollectionLesson,
  getCanConvertToCollectionLesson,
} from 'redux/actions/collections';
import { openConfirmationDialog, closeConfirmationDialog } from 'redux/actions/confirmation-dialogs';

export default {
  bindings: {
    manager: '<',
    course: '<',
  },
  controller: function ctrl(
    CurrentUserManager,
    TimelinesManager,
    CurrentPermissionsManager,
    $scope,
  ) {
'ngInject';
    const vm = this;
    vm.CurrentUserManager = CurrentUserManager;
    vm.TimelinesManager = TimelinesManager;
    vm.CurrentPermissionsManager = CurrentPermissionsManager;
    vm.showReactLessonLinkModal = null;
    vm.showReactAddToCollectionModal = null;
    vm.hasCollectionLessons = false;
    vm.hasCollectionsToConvert = false;

    function initialize() {
      const state = store.getState();
      vm.hasCollectionLessons = state.app.collection.canLinkCollectionLesson;
      vm.hasCollectionsToConvert = state.app.collection.canConvertToCollectionLesson;

      // Requesting the can_convert and can_link API only if they haven't already been requested
      if (vm.TimelinesManager.isEditMode()) {
        if (vm.hasCollectionLessons === null) {
          store.dispatch(getCanLinkCollectionLesson()).then((res) => {
            vm.hasCollectionLessons = res.payload.result;
          });
        }

        if (vm.hasCollectionsToConvert === null) {
          store.dispatch(getCanConvertToCollectionLesson()).then((res) => {
            vm.hasCollectionsToConvert = res.payload.result;
          });
        }
      }
    }

    initialize();

    vm.showAddLessonFromLibraryModal = (sectionId) => {
      vm.showReactLessonLinkModal?.(sectionId);
    };

    vm.showAddToCollectionModal = (lessonId) => {
      store.dispatch(canConvertToCollectionLecture({ id: lessonId })).then((res) => {
        const canConvertLesson = res.payload.result.canConvert;
        if (canConvertLesson) {
          vm.showReactAddToCollectionModal?.(lessonId);
        } else {
          const body = () => {
            const activityKeys = [
              'CONTENT', 'VIDEO', 'AUDIO', 'INDIVIDUAL_ASSIGNMENT', 'COURSE_DISCUSSION',
              'THIRD_PARTY_LINKS_AND_TOOLS', 'QUIZ', 'SURVEY', 'TIMED_QUIZ', 'POLL', 'VIDEO_PRACTICE', 'VIDEO_WITH_DISCUSSION',
            ];

            return (
              <div className='d-flex flex-column text-left text-large-body font-weight-normal'>
                <p>{t.TIMELINE.ADD_TO_COLLECTION_WARNING_MODAL.DESCRIPTION_1()}</p>
                <ul>
                  {activityKeys.map((key) => (
                    <li>{t.TIMELINE.ADD_TO_COLLECTION_WARNING_MODAL[key]()}</li>
                  ))}
                </ul>
                <p className='mt-4'>{t.TIMELINE.ADD_TO_COLLECTION_WARNING_MODAL.DESCRIPTION_2()}</p>
              </div>
            );
          };
          store.dispatch(openConfirmationDialog({
            icon: 'info',
            title: t.TIMELINE.ADD_TO_COLLECTION_WARNING_MODAL.TITLE({ lectureAlias: vm.course.lectureName.downcasedSingularized }),
            bodyText: body(),
            confirmText: t.TIMELINE.ADD_TO_COLLECTION_WARNING_MODAL.GOT_IT(),
            confirmButtonVariant: 'primary',
            noCancelButton: true,
            onConfirm: () => {
              store.dispatch(closeConfirmationDialog());
            },
          }));
        }
      });
    };

    vm.showAddSectionComponent = function (cond = null) {
      let show = vm.TimelinesManager.isEditMode() && vm.TimelinesManager.currentTimeline && (vm.TimelinesManager.currentTimeline.lectureSections.length || vm.TimelinesManager.currentTimeline.lectureSubsections.length);
      if (cond != null) {
        show = show && cond;
      }
      return show;
    };

    const LessonLinkModal = () => {
      const [show, setShow] = useState(false);
      const [sectionId, setSectionId] = useState(null);

      useEffect(() => {
        vm.showReactLessonLinkModal = (id) => {
          setShow(true);
          setSectionId(id);
        };
      }, []);

      const closeModal = () => {
        setShow(false);
        setSectionId(null);
      };

      return (
        <LinkCollectionLessonsModal
          show={show}
          onClose={closeModal}
          sectionId={sectionId}
          courseId={vm.course.id}
        />
      );
    };

    const AddLessonToCollectionModal = () => {
      const [show, setShow] = useState(false);
      const [lessonId, setLessonId] = useState(null);

      useEffect(() => {
        vm.showReactAddToCollectionModal = (id) => {
          setShow(true);
          setLessonId(id);
        };
      }, []);

      const closeModal = (isConverted = false) => {
        setShow(false);
        setLessonId(null);

        /**
         * Setting the `hasCollectionLessons` to true  after the convert, if it
         * is false before the convert. Since there will be at least one lesson
         * in a collection after the convert, so the `hasCollectionLessons` can be
         * reset accordingly without requiring a call to the `can_link.json` API.
         */
        if (isConverted && !vm.hasCollectionLessons) {
          vm.hasCollectionLessons = true;
        }
      };

      return (
        <AddToCollectionModal
          show={show}
          onClose={closeModal}
          lessonId={lessonId}
        />
      );
    };

    $scope.LessonLinkModal = LessonLinkModal;
    $scope.AddLessonToCollectionModal = AddLessonToCollectionModal;

    return vm;
  },
  controllerAs: 'vm',
  templateUrl: 'timelines/templates/nv-new-timeline-full.html',
};
