import { animated, useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export enum Slide {
  IN,
  OUT,
}

type SlideTransitionProps = {
  children: React.ReactElement;
  type: Slide;
  seenKey?: string;
  onSeen?: () => void;
  showTransition?: boolean;
  delay?: number;
};

const ONE_SECOND_DELAY = 1000;

const SlideTransition = ({
  children,
  type,
  seenKey,
  onSeen,
  showTransition = true,
  delay,
}: SlideTransitionProps) => {
  const [seen, setSeen] = useState(
    seenKey ? localStorage.getItem(seenKey) === 'true' : false,
  );
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const shownState = { transform: 'translateX(0%)', height: 'auto' };
  const hiddenState = { transform: 'translateX(-110%)', height: 0 };
  const transitions = {
    [Slide.IN]: {
      from: showTransition && !seen ? hiddenState : shownState,
      to: shownState,
    },
    [Slide.OUT]: {
      from: shownState,
      to: showTransition ? hiddenState : shownState,
    },
  };

  useEffect(() => {
    if (inView && !seen) {
      setTimeout(() => {
        if (seenKey) {
          setSeen(true);
          localStorage.setItem(seenKey, 'true');
        }
        onSeen?.();
      }, 1000);
    }
  }, [inView, seen, seenKey, onSeen]);

  const animation = useSpring({
    ...transitions[type],
    config: { tension: 280, friction: 60 },
    delay: delay || ONE_SECOND_DELAY,
  });

  return (
    <animated.div ref={ref} style={animation}>
      {children}
    </animated.div>
  );
};

export default SlideTransition;
