export enum RoleResourceType {
  COLLECTION = 'ContentManagementCollection',
  COURSE = 'Course',
  MENTORSHIP_PROGRAM = 'MentorshipProgram',
}

export interface Role {
  id: number;
  name: string;
  badge: string;
  permission: number;
  menteeName: null | string;
  variabalizedName: VariabalizedName;
  resourceType: RoleResourceType | null;
  courseRoleUsersCount?: number;
}

export interface VariabalizedName {
  capitalizedPluralized: string;
  capitalizedSingularized: string;
  downcasedSingularized: string;
  downcasedPluralized: string;
  pluralizedTitleized: string;
  singularizedTitleized: string;
}

export interface RolesNormalized {
  [id: string]: Role
}

export interface CourseCloneRoles {
  courseClone: {
    admins: Role[];
    courseRoleUsersCount: {
      [role: string]: number,
    }
  }
}
