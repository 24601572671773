import t from 'react-translate';
import NvTooltip from 'shared/components/nv-tooltip';
import moment from 'moment';
import { PROGRAM_DATES_FORMAT, PROGRAM_DATES_FORMAT_WITH_TIME } from '../constants';

const hasBeenReleased = (date) => date && moment(date).isBefore(moment());
const hasBeenClosed = (date) => date && moment().isAfter(date);
const getFormattedDate = (date: string, withTime: boolean = false) => (
  moment(date).format(withTime ? PROGRAM_DATES_FORMAT_WITH_TIME : PROGRAM_DATES_FORMAT)
);

const getTooltipText = (releasedDate, endDate) => {
  const released = hasBeenReleased(releasedDate);
  const closed = hasBeenClosed(endDate);
  const translationBase = t.MENTORING_PROGRAMS.PROGRAM_START_DATE.TOOLTIP;
  const tooltipDates = endDate
    ? [getFormattedDate(endDate, true), getFormattedDate(releasedDate, true)]
    : [getFormattedDate(releasedDate, true)];

  let tooltipKey;
  if (closed) {
    tooltipKey = 'CLOSED_PROGRAM';
  } else if (released && endDate) {
    tooltipKey = 'STARTED_AND_HAS_END_DATE';
  } else if (released && !endDate) {
    tooltipKey = 'STARTED_AND_HAS_NO_END_DATE';
  } else if (!released && endDate) {
    tooltipKey = 'NOT_STARTED_AND_HAS_END_DATE';
  } else {
    tooltipKey = 'NOT_STARTED_AND_HAS_NO_END_DATE';
  }

  return translationBase[tooltipKey](...tooltipDates);
};

const getProgramDatesText = (releasedDate, endDate) => {
  const released = hasBeenReleased(releasedDate);

  const getReleaseText = () => {
    const labelKey = released ? 'STARTED' : 'NOT_STARTED';
    return t.MENTORING_PROGRAMS.PROGRAM_START_DATE[labelKey](
      getFormattedDate(releasedDate),
    );
  };

  return endDate ? t.MENTORING_PROGRAMS.PROGRAM_RANGE(
    getFormattedDate(endDate),
    getFormattedDate(releasedDate),
  ) : getReleaseText();
};

const getProgramDatesComponent = (releasedDate, endDate) => {
  const programDateText = getProgramDatesText(releasedDate, endDate);
  const tooltipText = getTooltipText(releasedDate, endDate);

  return (
    <NvTooltip enabled text={tooltipText} placement='right'>
      <div>{programDateText}</div>
    </NvTooltip>
  );
};

export default getProgramDatesComponent;
