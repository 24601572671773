/* eslint-disable import/prefer-default-export */
import { PAGE_SIZE } from 'athena/components/mentoring-program/constants';
import { useMemo } from 'react';
import {
  GetMentorshipProgramActionItemParams,
  getMentorshipProgramActionItems,
} from 'redux/actions/mentoring-program-action-items';
import { MentoringProgramActionItem } from 'redux/schemas/models/mentoring-program-action-items';
import { getMentoringProgramActionItemsList } from 'redux/selectors/mentorship-program-action-items';
import { useAppDispatch } from 'redux/store';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';

type UseMentoringProgramActionItems = {
  mentorshipProgramId: number;
  connectionId: number;
};
export const useMentoringProgramActionItems = ({
  mentorshipProgramId,
  connectionId,
}: UseMentoringProgramActionItems) => {
  const dispatch = useAppDispatch();

  const params = useMemo(
    () => ({
      pageSize: PAGE_SIZE,
      mentorshipProgramId,
      connectionId,
    }),
    [connectionId, mentorshipProgramId],
  );

  const { result: retrievedActionItems, reset, loadMore } = usePaginatedApi<
  MentoringProgramActionItem,
  Omit<GetMentorshipProgramActionItemParams, 'page'>
  >(
    (p) => dispatch(
      getMentorshipProgramActionItems({
        ...p,
      }),
    ).then((action) => action.payload),
    params,
    getMentoringProgramActionItemsList(connectionId),
  );

  return {
    reset,
    loadMore,
    actionItems: retrievedActionItems,
  };
};
