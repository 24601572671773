import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import NvFilePicker from 'shared/components/nv-filepicker';
import ProfilePicture from 'org_level_profile/components/profile-picture';
import { useState } from 'react';
import { css } from '@emotion/react';
import { getCurrentUser } from 'redux/selectors/users';
import { User } from 'redux/schemas/models/my-account';

type ProfilePhotoFieldProps = {
  title: string;
  className?: string;
  onChange: (newImageFile: File) => void;
  dataQa?: string;
  dataQaId?: string;
};

type ProfileImageProps = {
  profile: User | File;
};

const IMAGE_SIZE = 48;

const styles = css`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: ${IMAGE_SIZE}px;
    height: ${IMAGE_SIZE}px;
    border-radius: 50%;
  `;

const ProfileImage = ({ profile }: ProfileImageProps) => {
  let profileImage = null;
  if (profile instanceof File) {
    const url = URL.createObjectURL(profile);
    profileImage = <img css={styles} src={url} alt='selectedImage' />;
  } else {
    profileImage = <ProfilePicture profile={profile} size={IMAGE_SIZE} />;
  }
  return profileImage;
};

const ProfilePhotoField = ({
  title,
  className,
  onChange,
  dataQa,
  dataQaId,
}: ProfilePhotoFieldProps) => {
  const user = useSelector(getCurrentUser);
  const [selectedFile, setSelectedFile] = useState<File>(null);

  const onSelectFile = (file: File) => {
    onChange(file);
    setSelectedFile(file);
  };

  return (
    <div className={className}>
      <div className='input-label text-regular semi-bold pb-2'>{title}</div>
      <div className='d-flex align-items-center'>
        <ProfileImage profile={selectedFile || user} />
        <Button
          className='upload-photo-btn ml-2'
          variant='outline-primary'
          data-qa={dataQa}
          data-qa-id={dataQaId}
        >
          <NvFilePicker
            tabIndex={0}
            multiple={false}
            accept={['image/*']}
            onChange={([file]) => onSelectFile(file)}
          >
            {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.ORG_QUESTIONS.ACCOUNT_FIELDS.PROFILE_PHOTO.UPLOAD_PHOTO()}
          </NvFilePicker>
        </Button>
      </div>
    </div>
  );
};

export default ProfilePhotoField;
