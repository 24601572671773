// Froala
import NvFroala from 'froala/components/nv-froala';
import { SanitizationLevel } from 'froala/helpers/sanitizer';
import { FroalaViewMode, NvFroalaProps, UploadType } from 'froala/helpers/nv-froala-constants';

// Styles
import { css } from '@emotion/react';
import { danger, white } from 'styles/global_defaults/colors';
import { dividerMedium, novoEdBlue } from 'athena/styles/colors';
import { omit } from 'underscore';
import { getSanitizedStyles } from 'shared/utils';
import NvPopover from 'shared/components/nv-popover';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

import t from 'react-translate';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';

export enum ResizeType {
  NONE = '',
  BOTH = 'both',
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

const FROALA_MAX_HEIGHT = 900;

const defaultConfig = {
  withForm: true,
  allowToolbar: true,
  className: 'w-100',
  uploadType: UploadType.NONE,
  preset: FroalaViewMode.NORMAL,
  sanitizationLevel: SanitizationLevel.BASIC,
};

const ErrorPopoverContent = ({ message = '' }) => (
  <div>
    <div className='text-danger text-center semi-bold mb-2'>
      {t.VALIDATION.ERROR()}
    </div>
    <div>{message}</div>
  </div>
);

const styles = css`
  .fr-wrapper {
    border: 1px solid ${dividerMedium};
    border-radius: ${quarterSpacing}px;
    box-shadow: none;
    background-color: transparent;
  }

  .fr-element {
    background-color: transparent !important;
    resize: vertical;

    &:hover,
    &:focus {
      border-radius: ${quarterSpacing}px;
    }

    &:focus {
      outline: 2px solid ${novoEdBlue} !important;
      outline-offset: -2px !important;
      box-shadow: none;
    }
  }

  .fr-placeholder {
    background-color: ${white} !important;
    border: none !important;
  }
`;

type NvAthenaFroalaProps = NvFroalaProps & {
  containerClassName?: string;
  maxHeight?: number;
  resize?: ResizeType;
};

const NvAthenaFroala = (props: NvAthenaFroalaProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const methods = useFormContext();
  const { formState } = methods || {};
  const hasError = formState?.errors[props.name];

  const froalaStyles = css`
    .fr-wrapper {
      border: ${hasError ? `1px solid ${danger}` : `1px solid ${dividerMedium}`};
    }
    .fr-element {
      resize: ${props.resize || ResizeType.VERTICAL};
      max-height: ${props.maxHeight || FROALA_MAX_HEIGHT}px;
      ${hasError ? `
        &:focus {
          outline: none !important;
        }
      ` : ''}
    }
  `;

  const froalaConfig = {
    css: getSanitizedStyles([styles, froalaStyles]),
    ...defaultConfig,
    ...(omit(props, 'containerClassName', 'maxHeight', 'resize')),
  };

  const handleBlur = (event?, content?) => {
    props.onBlur?.(event, content);
    setIsFocused(false);
  };

  return (
    <div className={props.containerClassName}>
      <NvPopover
        enabled={hasError}
        show={hasError && isFocused}
        content={<ErrorPopoverContent message={hasError?.message} />}
        placement='top'
      >
        <NvFroala
          {...froalaConfig}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
        />
      </NvPopover>
    </div>
  );
};

export default NvAthenaFroala;
