import { css } from '@emotion/react';
import { novoEdBlack, novoEdBlue } from 'athena/styles/colors';
import ClickableContainer from 'components/clickable-container';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import { white } from 'styles/global_defaults/colors';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

export enum InitialState {
  COLLAPSED,
  EXPANDED,
}

type PanelStateProps = {
  initialState?: InitialState;
  persistState?: boolean;
  localStorageKey?: string;
};

type CollapsiblePanelProps = {
  title: string | React.ReactElement;
  children: React.ReactElement;
  iconSize?: string;
  dataQa?: string;
  dataQaId?: string;
  isNested?: boolean;
  panelState?: PanelStateProps;
  className?: string;
};

const styles = (isNested?: boolean) => css`
  border-radius: 10px;
  padding: 24px ${standardSpacing}px;
  background-color: ${white};

  .collapsible-panel-header {
    cursor: pointer;
    color: ${novoEdBlack};
  }

  ${isNested
    ? css`
        padding: 0;
        .collapsible-panel-header {
          font-size: ${textSmallFontSize}px;
        }
      `
    : css`
        &:hover {
          box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
        }
        .collapsible-panel-header {
          &:hover {
            color: ${novoEdBlue};
          }
        }
      `}

  &.show-gradient {
    background: linear-gradient(91.45deg, rgba(247, 251, 232, 0.3) -2.56%, rgba(220, 253, 253, 0.3) 134.69%), #FFFFFF;
    transition: background 0.3s;
  }
`;

const getPanelState = (initialState: InitialState, key: string) => {
  const currentState = localStorage.getItem(key);
  if (currentState) {
    return currentState === 'true';
  }
  return initialState === InitialState.EXPANDED;
};

const setPanelState = (key: string, newState: boolean) => localStorage.setItem(key, `${newState}`);

const CollapsiblePanel = ({
  title,
  children,
  iconSize = 'small',
  dataQa,
  dataQaId,
  isNested = false,
  panelState,
  className,
}: CollapsiblePanelProps) => {
  const {
    initialState = InitialState.EXPANDED,
    persistState = false,
    localStorageKey = '',
  } = panelState || {};
  const [isExpanded, setIsExpanded] = useState<boolean>(
    persistState
      ? getPanelState(initialState, localStorageKey)
      : initialState === InitialState.EXPANDED,
  );

  const togglePanelState = () => {
    setIsExpanded((currentState) => {
      const newState = !currentState;
      if (persistState) {
        setPanelState(localStorageKey, newState);
      }
      return newState;
    });
  };

  return (
    <div css={styles(isNested)} className={`${className} collapsible-panel`}>
      <ClickableContainer
        className='heading-5 collapsible-panel-header d-flex align-items-center justify-content-between'
        onClick={togglePanelState}
        data-qa={dataQa}
        data-qa-id={dataQaId}
      >
        <div className='text-truncate'>{title}</div>
        <NvIcon
          icon={isExpanded ? 'arrow-down' : 'arrow-right'}
          size={iconSize}
        />
      </ClickableContainer>
      <Collapse in={isExpanded}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsiblePanel;
