import React, { useContext, useEffect, useState } from 'react';
import { getLiveSessionSettings } from 'redux/actions/courses';
import { useAppDispatch } from 'redux/store';
import { AngularServicesContext } from 'react-app';
import { getTeamInfo, getTeamMembers } from 'redux/actions/teams';
import MsTeamsMeeting from './ms-teams-meeting';
import MeetingOptions from './meeting-options';

type Props = {
  onSelect: (any) => void
  onCancel: (isDirty?: boolean, isAuthenticate?: boolean) => void
  afterSubmit?: (data: any) => void
};

const WorkspaceMeeting = ({ onCancel, onSelect, afterSubmit }: Props) => {
  const [showMsTeamsForm, setShowMsTeamsForm] = useState(false);
  const [settingsloaded, setSettingsLoaded] = useState(false);
  const { $state } = useContext(AngularServicesContext);

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchSettings() {
      await dispatch(getLiveSessionSettings({ catalogId: $state.params.catalogId }));
      setSettingsLoaded(true);
    }
    fetchSettings();
  }, [$state.params.catalogId, dispatch]);

  useEffect(() => {
    async function fetchTeamInfo() {
      await dispatch(getTeamInfo({
        catalogId: $state.params.catalogId,
        teamId: $state.params.teamId,
      }));
      dispatch(getTeamMembers({
        catalogId: $state.params.catalogId,
        teamId: $state.params.teamId,
      }));
    }
    fetchTeamInfo();
  }, [$state.params.catalogId, $state.params.teamId, dispatch]);

  const onSelectOption = (option) => {
    if (option === 'ms_teams') {
      setShowMsTeamsForm(true);
    }
    onSelect(option);
  };

  if (settingsloaded && (showMsTeamsForm || $state.params.meeting === 'ms_teams')) {
    return (
      <MsTeamsMeeting
        onCancel={(isDirty, isAuthenticate) => onCancel(isDirty, isAuthenticate)}
        afterSubmit={afterSubmit}
      />
    );
  }

  return (
    <MeetingOptions onCancel={() => onCancel()} onSelect={onSelectOption} />
  );
};

export default WorkspaceMeeting;
