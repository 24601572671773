import ReactDOM from 'react-dom';
import { random, sample } from 'underscore';

import { isHandheld, isNotDesktop, isRtl } from 'styles/global_defaults/media-queries';

/* STAR SHAPES */

const STAR_COLORS = ['#FFB77C', '#39B0E5'];

// A plus svg star
const Plus = ({ fill, className = 'plus-star' }) => (
  <svg className={className} viewBox='0 0 12 12'>
    {' '}
    <path
      style={{ fill }}
      d='M10.3,4.3H7.7V1.7C7.7,0.8,7,0,6,0S4.3,0.8,4.3,1.7v2.5H1.7C0.8,4.3,0,5,0,6s0.8,1.7,1.7,1.7h2.5v2.5 C4.3,11.2,5,12,6,12s1.7-0.8,1.7-1.7V7.7h2.5C11.2,7.7,12,7,12,6S11.2,4.3,10.3,4.3z'
    />
  </svg>
);

// Close svg star, which is the rotated version of plus itself
const Close = (props) => <Plus className='close-star' {...props} />;

/* HELPERS */

// Get a random color from the list
const getColor = () => sample(STAR_COLORS);

// Get a random star shape with a random color
const getRandomShape = () => sample([<Plus fill={getColor()} />, <Close fill={getColor()} />]);

// Returns a random float number between the min and max
const randomFloat = (min: number, max: number): number => Math.random() * (max - min) + min;

// Percentage between two values
const percentage = (a: number, b: number, current: number): number => (current - a) / (b - a);

/* ANIMATION HELPERS */
/* Reference: https://javascript.info/js-animation */

// Animate helper which updates requestAnimation recursively
const animate = ({ timing, draw, duration }) => {
  const start = performance.now();

  requestAnimationFrame(function step(time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    // calculate the current animation state
    const progress = timing(timeFraction);

    draw(progress); // draw it

    if (timeFraction < 1) {
      requestAnimationFrame(step);
    }
  });
};

// Make the timing function to easeOut equavalent
const makeEaseOut = (timing) => (timeFraction) => 1 - timing(1 - timeFraction);
// Timing function - following is Power of n
const timing = (timeFraction) => timeFraction ** 5;

// Each star particle
export default class Star {
  // Parent to which the stars are inserted
  private parent: HTMLElement;

  // Star particle element
  private element: HTMLElement;

  // X position of element
  private x: number;

  // Y position of element
  private y: number;

  // Velocity in X direction
  private vx: number;

  // Velocity in Y direction
  private vy: number;

  // To start Fade In at a random point of progress
  private fadeInEnd: number;

  // To Fade out at a random point of progress
  private fadeOutStart: number;

  // Get a random star shape and position it randomly with a random size.
  // Calculate velocity and the animate it
  constructor(parent, maxSizeValue?) {
    this.parent = parent;

    // Lets create a star
    this.element = document.createElement('span');
    this.element.classList.add('star');

    // Apply the size on each star according to device
    let minSize = 4;
    let maxSize = maxSizeValue || 8;
    if (isHandheld()) {
      minSize = 1;
      maxSize = 3;
    } else if (isNotDesktop()) {
      minSize = 2;
      maxSize = 4;
    }
    const size = random(minSize, maxSize);
    this.element.style.width = `${size}px`;
    this.element.style.height = `${size}px`;

    // Position the star
    this.setPos(
      random(
        0,
        this.parent.offsetWidth - maxSize,
      ),
      random(
        0,
        this.parent.offsetHeight - maxSize,
      ),
    );

    // Set Opacity
    this.setOpacity(0);

    // Setup velocity
    const speed = 0.1;
    this.vx = Math.random() * speed * sample([1, -1]);
    this.vy = Math.random() * speed * sample([1, -1]);

    this.fadeInEnd = randomFloat(0, 0.2);
    this.fadeOutStart = randomFloat(0.2, 0.8);

    // Insert a random shape with a random color
    ReactDOM.render(getRandomShape(), this.element);

    // Append the particle into the parent
    this.parent.appendChild(this.element);

    // Start the animation
    setTimeout(() => {
      animate({
        timing: makeEaseOut(timing),
        draw: (progress) => this.move(progress),
        duration: 5000,
      });
    }, random(10, 500));
  }

  move(progress: number) {
    if (progress === 1) {
      // When the animation is completed, destroy this
      this.destroy();
      return;
    }

    if (progress < this.fadeInEnd) {
      this.setOpacity(percentage(0, this.fadeInEnd, progress));
    } else if (progress < this.fadeOutStart) {
      this.setOpacity(1);
    } else {
      this.setOpacity(1 - percentage(this.fadeOutStart, 1, progress));
    }
    this.setPos(this.x + this.vx, this.y + this.vy);
  }

  setPos(x: number, y: number) {
    this.x = x;
    this.y = y;

    // Change position as new
    this.element.style.transform = `translate(${isRtl() ? '-' : ''}${this.x}px, ${this.y}px)`;
  }

  setOpacity(opacity) {
    this.element.style.opacity = opacity;
  }

  destroy() {
    this.element?.remove();
  }
}


